import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-compare-balance',
  templateUrl: './compare-balance.component.html',
  styleUrls: ['./compare-balance.component.scss']
})
export class CompareBalanceComponent {
  @Input() data: any = {};
  //@Input() F22: any = {};
  //public showCompareBalance: boolean = false;
  //public f22Selected: any = {};

  /*public mapeos: any[] = [
    {titulo: "Activos Circulantes", balanceManual: "activoscorrientes", f22: "totalactivocirculante"},
    {titulo: "Activos Fijos", balanceManual: "activosnocorrientes", f22: "totalactivosfijos"},
    {titulo: "Total Activos", balanceManual: "activo", f22: "totalactivos"},
    {titulo: "Pasivo Exigible", balanceManual: "pasivo", f22: "totalpasivoexigible"},
    {titulo: "Patrimonio", balanceManual: "patrimonio", f22: "totalpatrimonio"},
    {titulo: "Ingresos de la Explotación", balanceManual: "ingresodeactiviadesordinariaser", f22: "ingresosdelaExplotacion"},
    {titulo: "(Costo de Explotación)", balanceManual: "costodeventaser", f22: "costodeExplotacion"},
    {titulo: "RESULTADO BRUTO", balanceManual: "gananciabruta", f22: "resultadobruto"},
    {titulo: "(Gastos Administrativos y Ventas)", balanceManual: "gastosdeadministracion", f22: "gastosAdministrativosyVentas"},
    {titulo: "EBITDA", balanceManual: "ebitda", f22: "ebitda"},
    {titulo: "(Depreciación del Ejercicio)", balanceManual: "depreciacionyamortizacion", f22: "depreciaciondelEjercicio"},
    {titulo: "EBIT (RESULTADO OPERACIONAL)", balanceManual: "resultadooperacional", f22: "resultadooperacional"},
    {titulo: "Ingresos Financieros", balanceManual: "ingresosfinancieros", f22: "ingresosFinancieros"},
    {titulo: "Ingresos fuera de la Explotación", balanceManual: "otrasganaciasperdidas", f22: "ingresosfueradelaExplotación"},
    {titulo: "(Gastos Financieros)", balanceManual: "costosfinancieros", f22: "gastosFinancieros"},
    {titulo: "Diferencia de Cambio (+/-)", balanceManual: "diferenciasdecambio", f22: "diferenciadeCambio"},
    {titulo: "Corrección Monetaria (+/-)", balanceManual: "correccionmonetaria", f22: "correccionMonetaria"},
    {titulo: "UTILIDAD ANTES DE IMPUESTOS", balanceManual: "utilidadantesdeimpuestos", f22: "utilidadantesdeimpuestos"},
    {titulo: "(Impuesto Renta)", balanceManual: "gastosporimpuestosalasganacias", f22: "impuestoRenta"},
    {titulo: "UTILIDAD NETA", balanceManual: "gananciaperdida", f22: "utilidadneta"}
  ]*/

  ngOnInit(): void {
    /*if(this.validarF22VsBalanceManualReporte()) {
      this.showCompareBalance = true;
      this.f22Selected = this.F22.find((e: any) => e?.calculado?.fecha === this.getDateBalanceManualReporte());
    }*/
  }

  /*ultimoDiaMes(mes: number, anio: number) : number {
    return new Date(anio, mes, 0).getDate();
  }

  validarF22VsBalanceManualReporte() {
    if(this.balanceManualReporte.length > 0 && this.F22.length > 0){
      const balanceManual = this.balanceManualReporte[0];
      if(balanceManual){
        const balanceManualFecha = this.getDateBalanceManualReporte();
        if(this.F22.find((e: any) => e?.calculado?.fecha === balanceManualFecha)){
          return true;
        }
      }
      
    }
    return false;
  }

  getDateBalanceManualReporte() {
    const balanceManual = this.balanceManualReporte[0];
    if(balanceManual){
      const periodoBalanceManual = balanceManual?.BalanceManualReporte?.Reporte?.periodo || '';
      const mesFinBalanceManual = balanceManual?.BalanceManualReporte?.Reporte?.mesFin || '';
      return `${this.ultimoDiaMes(Number(mesFinBalanceManual), Number(periodoBalanceManual))}/${mesFinBalanceManual}/${periodoBalanceManual}`;
    }
    return '';
  }

  getDiferencia(valueManual: any, valueF22: any) {
    if(valueManual != undefined && valueF22 != undefined) {
      return Number(valueManual) - Number(valueF22);
    }
    return '';
  }*/
}
