import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { rutValidate } from 'rut-helpers';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CarteraRutService } from 'src/app/shared/services/cartera-rut.service';
import { catchError, map } from 'rxjs/operators';
import { RUT } from 'src/app/shared/utils/rut';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-asignador-rut',
  templateUrl: './asignador-rut.component.html',
  styleUrls: ['./asignador-rut.component.scss']
})
export class AsignadorRutComponent {
  @Output() backAsignar = new EventEmitter<void>();
  @Input() usuario: string = '';
  public showCarteraUsuario = true;
  public showSincronizadoUsuario = true;
  public showOtrosRuts = true;

  public currentItemDisponibles = env.initItemPerPage;
  public currentPageDisponibles = 0;
  public paginasActualesDisponibles: any[] = []; // paginas cargadas en memoria
  public totalDisponibles: number = 0; // total de elementos
  public paginasActualesErroresDisponibles: any[] = []; // paginas identificadas con errores en memoria
  public paginaActualDisponibles: any[] = [undefined];

  public currentItemCartera = env.initItemPerPage;
  public currentPageCartera = 0;
  public paginasActualesCartera: any[] = [];
  public totalCartera: number = 0;
  public paginasActualesErroresCartera: any[] = [];
  public paginaActualCartera: any[] = [undefined];

  public paginationOptions = env.itemPerPageOptions;

  public listRutsDisponiblesSeleccionados: any[] = [];
  public listRutsCarteraSeleccionados: any[] = [];

  public inputTextRut: string = '';
  public rutBuscado: any[] = [];
  public listRutsBuscadosSeleccionados: any[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private carteraRutService: CarteraRutService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if(this.usuario) {
      this.llamadasIniciales(this.usuario);
    } else {
      this.alertService.error('No se logró encontrar un usuario seleccionado');
      this.volver();
    }
  }

  llamadasIniciales(usuarioConsultado: string): void {
    // en la primera llamada se debe obtener la cantidad de elementos y se consulta la primera pagina
    const apiServices: any = [];
    apiServices.push(this.getServicesPaginas('rutsCartera', usuarioConsultado, this.currentItemCartera, 1));
    apiServices.push(this.getServicesPaginas('rutsUsuario', usuarioConsultado, this.currentItemDisponibles, 1));

    this.llamadaObtenerPaginas(apiServices, 1, true, true);
  }

  llamadaObtenerPaginas(apiServices: any[], paginaActual: number,isCartera: boolean, isDisponible: boolean): void {
    if(apiServices && apiServices.length > 0 && (isCartera || isDisponible)) {
      if(isCartera) {
        this.paginasActualesErroresCartera = [];
      }
      if(isDisponible) {
        this.paginasActualesErroresDisponibles = [];
      }
      this.spinner.show();
      forkJoin(apiServices).subscribe(
        resp => {
          if(isDisponible) {
            this.mostrarErroresPaginas(this.paginasActualesErroresDisponibles);
            const elementoDisponibles: any = this.paginasActualesDisponibles.find(e => e.pagina == paginaActual)
            this.paginaActualDisponibles = elementoDisponibles?.response ? this.addRut(elementoDisponibles?.response) : [undefined];
          }
          
          if(isCartera) {
            this.mostrarErroresPaginas(this.paginasActualesErroresCartera);
            const elementoCartera: any = this.paginasActualesCartera.find(e => e.pagina == paginaActual)
            this.paginaActualCartera = elementoCartera?.response ? this.addRut(elementoCartera?.response) : [undefined];
          }
  
          this.spinner.hide();
        },
        error => {
          this.alertService.error(error.message || 'Ocurrió un error al consultar por las paginas');
          this.spinner.hide();
        }
      );
    }
  }

  addRut(array: any[]): any[] {
    return array.map(e => {
      return {
        ...e,
        rutFormatted: e?.rut + '-' + e?.dv
      }
    });
  }

  addRutObject(obj: any): any {
    if(obj && Object.keys(obj).length > 0) {
      return {
        ...obj,
        rutFormatted: obj?.rut + '-' + obj?.dv
      }
    }
    return obj;
  }

  mostrarErroresPaginas(arrayErrores: any[]): void {
    if(arrayErrores && arrayErrores.length > 0) {
      const mensajesMostrados: Set<string> = new Set();
  
      arrayErrores.forEach(e => {
        const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas consultadas';
        if (!mensajesMostrados.has(mensaje)) {
          this.alertService.error(mensaje);
          mensajesMostrados.add(mensaje);
        }
      });
    }
  }

  volver(): void {
    this.backAsignar.emit();
  }

  getServicesPaginas(
    service: string, 
    usuarioConsultado: string,
    itemsByPage: number,
    page: number
  ): any {
    const objeto: any = {
      'rutsCartera': () => {
        return this.carteraRutService.obtenerListaCarteraUsuario(usuarioConsultado, itemsByPage, page)
          .pipe(
            map(resp => {
              this.processResponsePaginas(service, resp, page);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrió un error al buscar los ruts de la cartera del usuario', page), of(null))));
      },
      'rutsUsuario': () => {
        return this.carteraRutService.obtenerListaRutsUsuarioSecretInteraction(usuarioConsultado, itemsByPage, page)
        .pipe(
          map(resp => {
            this.processResponsePaginas(service, resp, page);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrió un error al buscar los ruts del usuario', page), of(null))));
      }
    };
    return objeto[service]();
  }

  processResponsePaginas(service: string, resp: any, page: number): void {
    if (service === 'rutsCartera') {
      this.paginasActualesCartera.push({
        pagina: page,
        response: resp?.items || null
      });
      this.totalCartera = resp?.totalElements || 0;
    } else if (service === 'rutsUsuario') {
      this.paginasActualesDisponibles.push({
        pagina: page,
        response: resp?.items || null
      });
      this.totalDisponibles = resp?.totalElements || 0;
    }
  }

  setError(service: string, error: string, page: number): void {
    if (service === 'rutsCartera') {
      this.paginasActualesErroresCartera.push({
        pagina: page,
        error: error
      });
    } else if (service === 'rutsUsuario') {
      this.paginasActualesErroresDisponibles.push({
        pagina: page,
        error: error
      });
    }
  }

  getPage(event: number, tabla: string,forzar: boolean = false): void {
    if(tabla === 'cartera') {
      this.paginasActualesErroresCartera = [];
      if(this.totalCartera > 0|| forzar) {
        const elemento: any = this.paginasActualesCartera.find(e => e.pagina == (event))
  
        if(elemento == undefined || forzar){
          if(forzar) {
            this.paginasActualesCartera = [];
          }
  
          const cantidadPaginas = Math.ceil(this.totalCartera / this.currentItemCartera);
          if(event == 1){
            const paginas: number[] = [];
            for(let i = 0; i < 3; i++){
              const pagina = event + i;
              if(pagina > 0 && pagina <= cantidadPaginas){
                paginas.push(pagina);
              }
            }
            if(forzar) {
              paginas.push(event);
            }
            this.obtenerPaginas(tabla, paginas, event, true);
          } else if(event > 1 && event <= cantidadPaginas) {
            const pagina: number = event;
            const paginas: number[] = [pagina - 1, pagina];
            if(event + 1 <= cantidadPaginas)
              paginas.push(pagina + 1);
            this.obtenerPaginas(tabla, paginas, event);
          } 
        }
        if(elemento != undefined) {
          this.paginaActualCartera = elemento?.response ? this.addRut(elemento?.response) : [undefined];
        }
      }

      this.currentPageCartera = event;

    } else if(tabla === 'disponibles') {
      this.paginasActualesErroresDisponibles = [];
      if(this.totalDisponibles > 0|| forzar) {
        const elemento: any = this.paginasActualesDisponibles.find(e => e.pagina == (event))
  
        if(elemento == undefined || forzar){
          if(forzar) {
            this.paginasActualesDisponibles = [];
          }
  
          const cantidadPaginas = Math.ceil(this.totalDisponibles / this.currentItemCartera);
          if(event == 1){
            const paginas: number[] = [];
            for(let i = 0; i < 3; i++){
              const pagina = event + i;
              if(pagina > 0 && pagina <= cantidadPaginas){
                paginas.push(pagina);
              }
            }
            if(forzar) {
              paginas.push(event);
            }
            this.obtenerPaginas(tabla, paginas, event, true);
          } else if(event > 1 && event <= cantidadPaginas) {
            const pagina: number = event;
            const paginas: number[] = [pagina - 1, pagina];
            if(event + 1 <= cantidadPaginas)
              paginas.push(pagina + 1);
            this.obtenerPaginas(tabla, paginas, event);
          } 
        }

        if(elemento != undefined) {
          this.paginaActualDisponibles = elemento?.response ? this.addRut(elemento?.response)  : [undefined];
        }
      }
      this.currentPageDisponibles = event;
    }
  }

  obtenerPaginas(tabla: string, paginas:  number[], paginaActual: number, forzar: boolean = false): void {
    if(tabla === 'cartera' || tabla === 'disponibles') {
      let cantidadPaginas = 0;
      let paginasActuales: any[] = [];
      let service = '';
      let itemPerPage = 0;
      if(tabla === 'cartera') {
        cantidadPaginas = this.totalCartera;
        paginasActuales = this.paginasActualesCartera;
        service = 'rutsCartera';
        itemPerPage = this.currentItemCartera;
      } else if(tabla === 'disponibles') {
        cantidadPaginas = this.totalDisponibles;
        paginasActuales = this.paginasActualesDisponibles;
        service = 'rutsUsuario';
        itemPerPage = this.currentItemDisponibles;
      }

      if(paginaActual == 1 && forzar) {
        cantidadPaginas = cantidadPaginas + 1;
      }

      const apiServices: any = [];
      if(cantidadPaginas > 0 && paginas.length > 0) {
        paginas.forEach(pagina => {
          if(pagina > 0 && pagina <= cantidadPaginas && !paginasActuales.some(e => e?.pagina == pagina)) {
            apiServices.push(this.getServicesPaginas(service, this.usuario, itemPerPage, pagina));
          }
        });
    
        // se quitan elementos que no se desean consultar
        const paginasFiltradas = paginasActuales.filter(item =>
          paginas.includes(item.pagina) && 
          item.pagina > 0 && 
          item.pagina <= cantidadPaginas
        );

        if(tabla === 'cartera') {
          this.paginasActualesCartera = paginasFiltradas;
        } else if(tabla === 'disponibles') {
          this.paginasActualesDisponibles = paginasFiltradas;
        }
  
      }
      if(apiServices.length > 0) {
        this.spinner.show();
        const isCartera = tabla === 'cartera';
        const isDisponible = tabla === 'disponibles';
        this.llamadaObtenerPaginas(apiServices, paginaActual, isCartera, isDisponible);
      }
    }
  }

  cambioPaginacion(tabla: string): void {
    if(tabla === 'cartera') {
      if(this.totalCartera > 0) {
        this.getPage(1, tabla, true);
      }
    } else if(tabla === 'disponibles') {
      if(this.totalDisponibles > 0) {
        this.getPage(1, tabla, true);
      }
    }
  }

  agregarRut(event: any, rut: string, tabla: string): void {    
    if(!rutValidate(rut)) {
      this.alertService.error('El rut no es valido');
      event.target.checked = !event.target.checked;
      return;
    }

    if(tabla === 'cartera') {
      // validar que no exista en la lista de cartera
      if(event?.target?.checked) {
        if(!this.listRutsCarteraSeleccionados.includes(rut)) {
          this.listRutsCarteraSeleccionados.push(rut);
        } else {
          this.alertService.error('El rut ya se encuentra en la lista de cartera');
        }
      } else {
        const index = this.listRutsCarteraSeleccionados.indexOf(rut);
        if(index > -1) {
          this.listRutsCarteraSeleccionados.splice(index, 1);
        }
      }
    } else if(tabla === 'disponibles') {
      if(event?.target?.checked) {
        if(!this.listRutsDisponiblesSeleccionados.includes(rut)) {
          this.listRutsDisponiblesSeleccionados.push(rut);
        } else {
          this.alertService.error('El rut ya se encuentra en la lista de disponibles');
        }
      } else {
        const index = this.listRutsDisponiblesSeleccionados.indexOf(rut);
        if(index > -1) {
          this.listRutsDisponiblesSeleccionados.splice(index, 1);
        }
      }
    } else if (tabla === 'buscado') {
      if(event?.target?.checked) {
        if(!this.listRutsBuscadosSeleccionados.includes(rut)) {
          this.listRutsBuscadosSeleccionados.push(rut);
        } else {
          this.alertService.error('El rut ya se encuentra en la lista de buscados');
        }
      } else {
        const index = this.listRutsBuscadosSeleccionados.indexOf(rut);
        if(index > -1) {
          this.listRutsBuscadosSeleccionados.splice(index, 1);
        }
      }
    }
  }

  asignar(): void {
    if(this.listRutsDisponiblesSeleccionados.length > 0 || this.listRutsBuscadosSeleccionados.length > 0) {
      const listaCompleta: any[] = [];
      if(this.listRutsDisponiblesSeleccionados.length > 0)
        listaCompleta.push(...this.listRutsDisponiblesSeleccionados);
      if(this.listRutsBuscadosSeleccionados.length > 0)
        listaCompleta.push(...this.listRutsBuscadosSeleccionados);
      this.llamadaUpdateCartera(listaCompleta, []);
    } else {
      this.alertService.error('No se han seleccionado ruts para asignar');
    }
  }

  desasignar(): void {
    if(this.listRutsCarteraSeleccionados.length > 0) {
      this.llamadaUpdateCartera([], this.listRutsCarteraSeleccionados);
    } else {
      this.alertService.error('No se han seleccionado ruts para desasignar');
    }
  }

  guardar(): void {
    if(this.listRutsDisponiblesSeleccionados.length > 0 || this.listRutsCarteraSeleccionados.length > 0 || this.listRutsBuscadosSeleccionados.length > 0) {
      const listaCompleta: any[] = [];
      if(this.listRutsDisponiblesSeleccionados.length > 0)
        listaCompleta.push(...this.listRutsDisponiblesSeleccionados);
      if(this.listRutsBuscadosSeleccionados.length > 0)
        listaCompleta.push(...this.listRutsBuscadosSeleccionados);
      this.llamadaUpdateCartera(listaCompleta, this.listRutsCarteraSeleccionados);
    } else {
      this.alertService.error('No se han seleccionado ruts');
    }
  }

  validarDuplicadosEntreListas(rutsDiponibles: any[], rutsCartera: any[]): boolean {
    const set1 = new Set(rutsDiponibles);
    const set2 = new Set(rutsCartera);

    for (const item of set2) {
      if (set1.has(item)) {
        return true;
      }
    }
    return false; 
  }

  validaDuplicados(array: any[]): boolean {
    const setArray = new Set(array);
    return setArray.size === array.length
  }

  llamadaUpdateCartera(rutsDiponibles: any[], rutsCartera: any[]): void {
    // validar que no salgan ruts iguales en ambas listas, mostrar error y retornar
    if(rutsDiponibles.length > 0 && rutsCartera.length > 0) {
      if(this.validarDuplicadosEntreListas(rutsDiponibles, rutsCartera)) {
        this.alertService.error('No se pueden asignar y desasignar ruts iguales en ambas listas');
        return;
      }
    }

    if(rutsDiponibles.length > 0 && !this.validaDuplicados(rutsDiponibles)) {
      this.alertService.error('No se pueden asignar ruts duplicados');
      return;
    }

    if(rutsCartera.length > 0 && !this.validaDuplicados(rutsCartera)) {
      this.alertService.error('No se pueden desasignar ruts duplicados');
      return;
    }

    this.spinner.show();
    this.carteraRutService.updateCartera(this.usuario, rutsDiponibles, rutsCartera)
    .subscribe(
      resp => {
        this.spinner.hide();
        this.alertService.success(resp?.message || 'Se han guardado los cambios');
        this.listRutsDisponiblesSeleccionados = [];
        this.listRutsCarteraSeleccionados = [];
        this.listRutsBuscadosSeleccionados = [];
        this.limpiarBuscados();
        this.getPage(1, 'cartera', true);
        this.getPage(1, 'disponibles', true);
      },
      error => {
        this.spinner.hide();
        this.alertService.error(error.message || 'Ocurrió un error al guardar los cambios');
      }
    );
  }

  isChecked(tabla: string, rut: string): boolean {
    if(tabla === 'cartera') {
      return this.listRutsCarteraSeleccionados.includes(rut);
    } else if(tabla === 'disponibles') {
      return this.listRutsDisponiblesSeleccionados.includes(rut);
    } else if(tabla === 'buscado') {
      return this.listRutsBuscadosSeleccionados.includes(rut);
    }
    return false;
  }  
  
  buscarByRut(): void {
    if(this.inputTextRut && rutValidate(this.inputTextRut)){
      //buscar si el rut ya se encuentra en la lista de ruts buscados
      if(this.rutBuscado.some(e => (e.rut+'-'+e.dv) === RUT.applyBackendFormat(this.inputTextRut))) {
        this.alertService.error('El rut ya se encuentra en la lista de ruts buscados');
        return;
      }
      this.obtenerElementByRut();
    } else {
      this.alertService.error('Debe ingresar un rut válido');
    }
  }
  
  obtenerElementByRut(): void {
    this.spinner.show();
    this.carteraRutService.buscarRut(RUT.applyBackendFormat(this.inputTextRut)).subscribe(
      (data: any) => {
        if(data && Object.keys(data).length > 0) {
          // agregar a this.rutBuscado al comienzo
          this.rutBuscado.unshift(this.addRutObject(data));
          this.spinner.hide();
        } else {
          this.alertService.error('Ha ocurrido un error al buscar el rut indicado.');
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al buscar el rut indicado.');
        this.spinner.hide();
    });
  }

  limpiarBuscados(): void {
    this.rutBuscado = [];
    this.listRutsBuscadosSeleccionados = [];
  }

}
