import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { rutValidate } from 'rut-helpers';
import { SessionService } from 'src/app/shared/services/session.service';
import { CredencialesService } from 'src/app/shared/services/credenciales.service';
import { TesoreriaService } from 'src/app/shared/services/tesoreria.service';
import { RUT } from 'src/app/shared/utils/rut';
import { AsincronoService } from 'src/app/shared/services/asincrono.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { Subject } from 'rxjs';
import { CarteraRutService } from 'src/app/shared/services/cartera-rut.service';

interface RutFormValue {
  rut: string;
  claveSii?: string;
  sincronizar?: boolean;
}

@Component({
  selector: 'app-reporte-tesoreria-consultar',
  templateUrl: './reporte-tesoreria-consultar.component.html',
  styleUrls: ['./reporte-tesoreria-consultar.component.scss']
})
export class ReporteTesoreriaConsultarComponent implements OnInit {
  private access: string[] = [ESystemAccess.TESORERIA, ESystemProfileList.WEB,
    ESystemAccess.CLIENTES_CREDS_READ,
    ESystemAccess.CLIENTES_CREDS_ADMIN
  ];
  public showBuscador: boolean = true;
  public rutForm!: UntypedFormGroup;
  public activateRutHelper = false;
  public rut: string = '';
  public hasUserAccess = false;
  public consultacredencialesSinc = false;
  public credencialesSinc = false;
  public reporte: any = null;
  public today: string = '';

  public isResumida = false;
  printClick: Subject<void> = new Subject<void>()

  constructor(
    private formBuilder: UntypedFormBuilder,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private credencialesService: CredencialesService,
    private tesoreriaService: TesoreriaService,
    private asyncService: AsincronoService,
    private router: Router,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private carteraRutService: CarteraRutService
  ) {
    this.rutForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
    }) as FormGroupTyped<RutFormValue>;

    this.onChanges();
  }

  onPrintClick() {
    this.printClick.next();
  }

  onHoverCompleta() {
    this.isResumida = false;
  }

  onHoverResumida() {
    this.isResumida = true;
  }
  ngOnInit(): void {
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.setToday();
  }

  onChanges(): void {
    this.rutForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  clearForm(): void {
    this.limpiarClave();
    this.rutForm.reset();
    this.activateRutHelper = false;
  }

  consultarCrearReporte(rut: string, password: string = ''): void {
    this.spinner.show();

      this.tesoreriaService.crearReporte(RUT.applyBackendFormat(rut), password).subscribe(
        (data: any) => {
          if(data?.TesoreriaReporte?.DatosBasicosSolicitud?.IDTransaccion){
            //this.llamadaTesoreria(data?.TesoreriaReporte?.DatosBasicosSolicitud?.IDTransaccion); // esta llamada iria en otro lado
            this.spinner.hide();
            const variables: any = {
              rut: rut
            }
            this.router.navigate(['/reporte-tesoreria/buscar'], { queryParams: variables });
            this.alertService.warn('Se esta ejecutando la creación del reporte, cuando este finalizado se le notificará.', {autoClose: true, buttonRedirect: true, componente: 'reporte-tesoreria/buscar', arguments: variables});
            this.asyncService.llamadaTesoreria(data?.TesoreriaReporte?.DatosBasicosSolicitud?.IDTransaccion, rut);
          } else {
            this.alertService.error('Ha ocurrido un error al crear el reporte de tesoreria.');
            this.spinner.hide();
          }
        },
        ({ error }) => {
          this.alertService.error(error.message || 'Ha ocurrido un error al crear el reporte de tesoreria.');
          this.spinner.hide();
      });
  }

  guardarCreds(rut: string, contrato: any){
    this.spinner.show();
    this.credencialesService.crearActualizarCredenciales(rut, contrato).subscribe(
      (data: any) => {
        this.consultarCrearReporte(rut);
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al crear/actualizar las credenciales.');
        this.spinner.hide();
    });
  }

  obtenerContratoCreds(rut: string, password: string): void {
    this.spinner.show();

    this.credencialesService.obtenerContrato().subscribe(
      (data: any) => {
        const services: any = data?.services?.find((e: any) => e.name === 'SII')
        if(services){
          services.attributes.forEach((element: any) => {
            if(element.name === 'userName') element.value = rut;
            if(element.name === 'password') element.value = password;
            if(element.name === 'orgAccess') element.value = true;
          });
          this.guardarCreds(rut, [services])
        } else {
          this.alertService.error('Ha ocurrido un error al obtener el contrato de credenciales.');
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al validar las credenciales.');
        this.spinner.hide();
    });
  }


  validarCreds(rut: string, password: string): void {
    this.spinner.show();

    this.credencialesService.validarCredenciales(RUT.applyBackendFormat(rut), password, 'SII').subscribe(
      (data: any) => {
        this.obtenerContratoCreds(rut, password);
      },
      ({ error }) => {
        const mensaje: string = error.message ? error.message : error && typeof(error) == 'string' ? error : 'Ha ocurrido un error al validar las credenciales.'; 
        this.alertService.error(mensaje);
        this.spinner.hide();
    });
  }

  validaCredsAdmin(): boolean {
    if(this._sessionService.getUserAccess().includes(this.access[3])) {
      return true;
    } else {
      return false;
    }
  }

  submitRut(): void {
    if(this.consultacredencialesSinc) {
      if (!this.rutForm.valid) {
        this.alertService.error("Formulario no valido");
        return
      }

      const formTemp = this.rutForm.getRawValue();
      const rut = formTemp?.rut;
      const password = formTemp?.claveSii;
      const sincronizar = formTemp?.sincronizar;

      this.carteraRutService.validateRutCartera(rut).subscribe((isValid: boolean) => {
        if (!isValid) {
          return;
        } else {
          if(sincronizar === true){
            this.validarCreds(rut, password);
          } else {
            this.consultarCrearReporte(rut, password);
          }
        }
      });
    } else {
      /*if(!this._sessionService.getUserAccess().includes(this.access[2]) && !this.validaCredsAdmin()){
        this.alertService.error('No tiene permisos para realizar esta acción.');
        return;
      }*/

      this.rut = this.rutForm.get('rut')?.value;
      if (!this.rutForm.valid) {
        this.alertService.warn("Rut no valido");
        return
      }

      this.carteraRutService.validateRutCartera(this.rut).subscribe((isValid: boolean) => {
        if (!isValid) {
          return;
        } else {
          this.spinner.show();
          this.credencialesService.buscarSecretos(this.rut).subscribe(
            (data: any) => {
              if(this.validaCredencialReturn(data?.services)){
                this.consultarCrearReporte(this.rut);
              } else {
                this.alertService.warn('No se han encontrado las credenciales del rut sincronizadas.');
                this.addClaveForm();
                this.consultacredencialesSinc = true;
                this.spinner.hide();
              }
            },
            ({ error }) => {
              this.alertService.error(error.message || 'Ha ocurrido un error al obtener las credenciales del rut.');
              this.spinner.hide();
          });
        }
      });

    }
  }

  /*llamadaTesoreria(idTrasaccion: string): void {
    const maxRetries = 12;

    timer(0, 5000)
    .pipe(
      take(maxRetries),
      mergeMap(() => this.tesoreriaService.buscarTX(idTrasaccion)),
      map(response => ({ response: response, isSatisfactory: this.isResponseSatisfactory(response) })),
      takeWhile(({ response, isSatisfactory }) => !isSatisfactory),
      finalize(() => {
        this.spinner.hide();
      })
    )
    .subscribe(
      response => {
        // Llega al ultimo pero debe estar esta definicion
      },
      error => {
        this.alertService.error(error?.error?.message || 'Ha ocurrido un error al obtener el reporte de tesoreria.');
        this.spinner.hide();
      },
      () => {
        if(!this.reporte){
          this.alertService.error('Ha ocurrido un error al obtener el reporte de tesoreria.');
        } else {
          this.showBuscador = false;
        }
        this.spinner.hide();
      }
    );
  }*/

  isResponseSatisfactory(response: any): boolean {
    const message = response?.TesoreriaReporte?.DatosBasicosSolicitud;
    if(message && message?.EstadoTransaccion === 'COMPLETED' && message?.SubEstadoTransaccion === 'COMPLETED'){
      this.reporte = response;
      return true
    }
    return false;
  }

  validaCredencialReturn(lista: any[]): boolean {
    if(lista && lista.length > 0){
      const objeto = lista.find(e => e?.name === 'SII')
      if(objeto && objeto?.configurationStatus === true){
        this.credencialesSinc = true;
        return true;
      } else {
        this.credencialesSinc = false;
        return false;
      }
    } else {
      this.credencialesSinc = false;
      return false;
    }
  }

  addClaveForm(): void {
    this.rutForm.addControl('claveSii', new UntypedFormControl('', [Validators.required]));
    this.rutForm.addControl('sincronizar', new UntypedFormControl(false, [Validators.required]));
  }

  changeRut(): void {
    this.limpiarClave();
  }

  limpiarClave(): void {
    this.rutForm.removeControl('claveSii');
    this.rutForm.removeControl('sincronizar');
    this.credencialesSinc = false;
    this.consultacredencialesSinc = false;
  }

  closeReport(): void {
    this.clearForm();
    this.showBuscador = true;
    this.reporte = null;
  }

  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

}
