import { Component, OnInit, SecurityContext } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
//import { rutValidate, RutValidator } from 'ng9-rut';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { RUT } from 'src/app/shared/utils/rut';
import { IListLibroCompraVentaReporte } from 'src/app/models/compra-venta/compra-venta.model';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CarteraRutService } from 'src/app/shared/services/cartera-rut.service';

interface CompraVentaReportFormValues {
  rut: string;
}

@Component({
  selector: 'app-compra-venta-buscar',
  templateUrl: './compra-venta-buscar.component.html',
  styleUrls: ['./compra-venta-buscar.component.scss']
})
export class CompraVentaBuscarComponent implements OnInit {
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.REPORTE_LIBRO_COMPRA_VENTA_BUSCAR, ESystemProfileList.WEB];
  public groupNameConsulting: string[] = ['primerPeriodo', 'segundoPeriodo', 'tercerPeriodo'];
  public hasUserAccess = false;
  public compraVentaReportForm: UntypedFormGroup;
  public rut: string = '';
  public today: string = '';
  public searchReportDataSource: IListLibroCompraVentaReporte[] = [];
  public actualPeriodo: number = 0;
  public showReport: boolean = false;

  constructor(
    public alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    //private rutValidator: RutValidator,
    private _sessionService: SessionService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private sanitizer: DomSanitizer,
    private carteraRutService: CarteraRutService
  ) {
    this.compraVentaReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
      informeComercial: [false, Validators.requiredTrue]
    }) as FormGroupTyped<CompraVentaReportFormValues>;

    this.cambiosEnUrl();
    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.setToday();
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
  }

  cambiosEnUrl(): void {
    this.route.queryParamMap.subscribe((val: any) => {
      const rut = val?.params?.['rut'];
      this.datosPorUrl(rut)
    });
  }

  datosPorUrl(rut: string | null): void {
    const rutSeguro = this.sanitizer.sanitize(SecurityContext.HTML, rut);

    if(!rutSeguro){
      return
    }

    if(!rutValidate(rutSeguro)){
      this.alertService.error('Rut detectado inválido.');
      return
    }

    this.carteraRutService.validateRutCartera(rutSeguro).subscribe((isValid: boolean) => {
      if (!isValid) {
        return;
      } else {
        const today = new Date();
        this.actualPeriodo = today.getFullYear();
        
        this.rut = rutSeguro;
        this.showReport = true;
      }
    });

  }

  onChanges(): void {
    this.compraVentaReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  public searchReport() {
    const today = new Date();
    this.actualPeriodo = today.getFullYear();
    const { rut } = this.compraVentaReportForm.value;

    if (!rutValidate(rut)) {
      return;
    }

    this.carteraRutService.validateRutCartera(rut).subscribe((isValid: boolean) => {
      if (!isValid) {
        return;
      } else {
        this.rut = rut;
        this.showReport = true;
      }
    });
  }

  public clearForm() {
    this.compraVentaReportForm.reset();
    this.activateRutHelper = false;
  }

  public backToSearchForm() {
    this.showReport = false;
  }

}
