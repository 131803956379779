<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<div class="row" *ngIf="hasUserAccess && showDashboard">
    <div class="col-12">
        <h1 class="ml-2">
            <strong>Administrar Sujetos de Crédito</strong>
        </h1>
    </div>
    <hr class="col-sm-12 col-md-10 col-lg-8 ml-3"/>
    <div class="col-sm-12 col-md-10 mx-auto">
        <div class="card text-center my-4">
            <div class="card-header">
                <div class="my-0 row d-flex px-4">
                    <div class="col-xs-3 col-sm-auto mr-auto p-2">
                        <app-paginator-by-items title="Cantidad de filas a mostrar en la tabla" [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                    </div>
                    <div class="col-xs-3 col-sm-auto p-2">
                        <div class="input-group mb-3">
                            <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                title="Ingrese texto para filtrar tabla"
                                placeholder="Filtrar tabla"
                                [(ngModel)]="inputText" 
                                (keyup)="filtrarObjeto()"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table id="tableUsuarios" class="table table-hover border my-0">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center font-size">Ejecutivo</th>
                            <th scope="col" class="text-center font-size">Nombre</th>
                            <th scope="col" class="text-center font-size">Apellido</th>
                            <th scope="col" class="text-center font-size">Ruts Asignados</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of usersFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                            <td class="text-center cursor-pointer" (click)="seleccionarUsuario(item?.userName)">{{item?.userName}}</td>
                            <td class="text-center cursor-pointer" (click)="seleccionarUsuario(item?.userName)">{{item?.name}}</td>
                            <td class="text-center cursor-pointer" (click)="seleccionarUsuario(item?.userName)">{{item?.givenName}}</td>
                            <td class="text-center cursor-pointer" (click)="seleccionarUsuario(item?.userName)">{{item?.rutCount | number: '1.0-0'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer text-muted">
                <div class="p-2">
                    <div id="pagination">
                        <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                            <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-asignador-rut *ngIf="hasUserAccess && showAsignar" [usuario]="usuarioSeleccionado" (backAsignar)="volver()"></app-asignador-rut>


