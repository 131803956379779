<div class="mx-auto div-print">

    <div class="row">

        <div class="col-sm-12 px-0">
            <div class="container-balance bg-light">

                <div class="row print-container">

                    <div class="col-sm-12 col-lg-10 mx-auto">
                        <div class="card w-100 mx-auto border-0">
                            <div class="card-body">

                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover border my-0">
                                        <thead>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Año</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{valor?.periodo}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Tipo de balance (1)</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{obtenerTipoString(valor?.tipo)}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Tipo de balance (2)</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{valor?.subTipo || ''}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Auditado</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{valor?.auditado === true ? 'Si' : (valor?.auditado === false ? 'No' : '')}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Moneda</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{valor?.moneda || ''}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Desde</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{obtenerNombreMes(valor?.mesInicio)}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Hasta</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{obtenerNombreMes(valor?.mesFin)}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div class="row print-container">
                    <div class="col-sm-12 px-0">
                        <ng-container *ngFor="let nivel1 of arrayContrato; let inivel1 = index;">
    
                            <div class="break-box">
                                <ng-container *ngIf="!nivel1?.nivel1; else nivel1Template">
        
                                    <ng-container *ngIf="nivel1?.variables && nivel1?.variables.length > 0">
                                        <div class="card w-100 mx-auto border-0">
                                            <div class="card-body">
        
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover border my-0 table-balance">
                                                        <tbody>
                                                            <ng-container *ngFor="let campo of nivel1?.variables">
        
                                                                <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: ''} }"></ng-container>
                
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
        
                                            </div>
                                        </div>
                                    </ng-container>
                                    
                                </ng-container>
        
                                <ng-template #nivel1Template>
        
                                    <div class="card w-100 mx-auto border-0">
                                        <div class="card-body">
        
                                            <div class="table-responsive">
                                                <table class="table table-bordered table-hover border my-0 table-balance">
                                                    <thead>
                                                        <tr class="bg-nivel1" [ngClass]="{'cursor-pointer': validaVariables(nivel1?.variables, nivel1?.nivel1)}" (click)="toggleContent($event, 'header-nivel1' + inivel1)">
                                                            <th scope="col" rowspan="1" class="align-middle font-size font-weight-bold bg-nivel1">
                                                                {{  nivel1?.nivel1Name  }}
                                                            </th>
    
                                                            <ng-container *ngFor="let valor of valores; let i = index">
                                                                <th rowspan="1" class="align-middle font-size text-right bg-nivel1">
                                                                    <div class="row">
                                                                        <div class="col-12 text-center text-white text-capitalize">
                                                                            {{ obtenerDatosColumna(i) || '' }}
                                                                        </div>
                                                                        <div class="col-12 text-center text-white">
                                                                            ({{ i + 1 }})
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </ng-container>
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngIf="nivel1?.nivel2 && nivel1?.nivel2.length > 0; else notNivel2Template">
        
                                                            <ng-container *ngFor="let nivel2 of nivel1?.nivel2; let inivel2 = index;">
            
                                                                <ng-container *ngIf="!nivel2?.nivel2; else nivel2Template">
            
                                                                    <ng-container *ngFor="let campo of nivel2?.variables">
                                                                        <ng-container *ngIf="validaCampo(campo)">
                
                                                                            <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' +inivel2} }"></ng-container>
                
                                                                        </ng-container>
                                                                    </ng-container>
            
                                                                </ng-container>
                                                                <ng-template #nivel2Template>
                                                                    <ng-container *ngIf="nivel1?.nivel1 === 'liquideseindicadores'">
                                                                        <tr [id]="'header-nivel1' + inivel1" class="bg-nivel2" [ngClass]="{'cursor-pointer': validaVariables(nivel2?.variables, nivel2?.nivel2)}" (click)="toggleContent($event, 'header-nivel1' + inivel1 + '-nivel2' + inivel2)">
                                                                            <th scope="col" class="align-middle font-size bg-nivel2">
                                                                                {{  nivel2?.nivel2Name  }}
                                                                            </th>
                                                                            <ng-container *ngFor="let valor of valores">
                                                                                <th class="align-middle font-size text-right bg-nivel2">
                                                                                    <ng-container *ngTemplateOutlet="totalTemplate; context: { $implicit: {valor: obtenerValorTotal(nivel2?.nivel2, valor), type: obtenerValorTotalType(nivel2?.nivel2, valor)} }"></ng-container>
                                                                                </th>
                                                                            </ng-container>

                                                                        </tr>
                                                                    </ng-container>

                                                                    <ng-container *ngIf="nivel2?.nivel3 && nivel2?.nivel3.length > 0; else notNivel3Template">
            
                                                                        <ng-container *ngFor="let nivel3 of nivel2?.nivel3; let inivel3 = index;">
                                                                            
                                                                            <ng-container *ngIf="!nivel3?.nivel3; else nivel3Template">
    
                                                                                <ng-container *ngFor="let campo of nivel3?.variables">
                                                                                    <ng-container *ngIf="validaCampo(campo)">
            
                                                                                        <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' + inivel2 + '-' + 'nivel3' + inivel3 + 'element'} }"></ng-container>
                
                                                                                    </ng-container>
                                                                                </ng-container>
        
        
                                                                            </ng-container>
                                                                            <ng-template #nivel3Template>
        
                                                                                <ng-container *ngIf="nivel1?.nivel1 === 'liquideseindicadores'">
                                                                                    <tr [id]="'header-nivel1' + inivel1 + '-nivel2' + inivel2" class="bg-nivel3" [ngClass]="{'cursor-pointer': validaVariables(nivel3?.variables, nivel3?.nivel3)}" (click)="toggleContent($event, 'header-nivel1' + inivel1 + '-nivel2' + inivel2 + '-nivel3' + inivel3 + 'element')">
                                                                                        <th scope="col" class="align-middle font-size bg-nivel3">
                                                                                            {{  nivel3?.nivel3Name  }}
                                                                                        </th>
                                                                                        <ng-container *ngFor="let valor of valores">
                                                                                            <th class="align-middle font-size text-right bg-nivel3">
                                                                                                <ng-container *ngTemplateOutlet="totalTemplate; context: { $implicit: {valor: obtenerValorTotal(nivel3?.nivel3, valor), type: obtenerValorTotalType(nivel3?.nivel3, valor)} }"></ng-container>
                                                                                            </th>
                                                                                        </ng-container>
                                                                                    </tr>
                                                                                </ng-container>

                                                                                <ng-container *ngFor="let campo of nivel3?.variables">
                                                                                    
                                                                                    <ng-container *ngIf="validaCampo(campo)">
                                                                                        <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' + inivel2 + '-' + 'nivel3' + inivel3 + 'element'} }"></ng-container>
                                                                                    </ng-container>
            
                                                                                </ng-container>


                                                                                <ng-container *ngIf="nivel1?.nivel1 !== 'liquideseindicadores'">
                                                                                    <tr [id]="'header-nivel1' + inivel1 + '-nivel2' + inivel2" class="bg-nivel3" [ngClass]="{'cursor-pointer': validaVariables(nivel3?.variables, nivel3?.nivel3)}" (click)="toggleContent($event, 'header-nivel1' + inivel1 + '-nivel2' + inivel2 + '-nivel3' + inivel3 + 'element')">
                                                                                        <th scope="col" class="align-middle font-size bg-nivel3">
                                                                                            {{  nivel3?.nivel3Name  }}
                                                                                        </th>
                                                                                        <ng-container *ngFor="let valor of valores">
                                                                                            <th class="align-middle font-size text-right bg-nivel3">
                                                                                                <ng-container *ngTemplateOutlet="totalTemplate; context: { $implicit: {valor: obtenerValorTotal(nivel3?.nivel3, valor), type: obtenerValorTotalType(nivel3?.nivel3, valor)} }"></ng-container>
                                                                                            </th>
                                                                                        </ng-container>
                                                                                    </tr>
                                                                                </ng-container>
        
                                                                            </ng-template>
        
                                                                        </ng-container>
        
                                                                    </ng-container>
        
                                                                    <ng-template #notNivel3Template>
    
                                                                        <ng-container *ngFor="let campo of nivel2?.variables">
    
                                                                            <ng-container *ngIf="validaCampo(campo)">
                                                                                <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' + inivel2} }"></ng-container>
                                                                            </ng-container>
    
                                                                        </ng-container>
        
                                                                    </ng-template>

                                                                    <ng-container *ngIf="nivel1?.nivel1 !== 'liquideseindicadores'">
                                                                        <tr [id]="'header-nivel1' + inivel1" class="bg-nivel2" [ngClass]="{'cursor-pointer': validaVariables(nivel2?.variables, nivel2?.nivel2)}" (click)="toggleContent($event, 'header-nivel1' + inivel1 + '-nivel2' + inivel2)">
                                                                            <th scope="col" class="align-middle font-size bg-nivel2">
                                                                                {{  nivel2?.nivel2Name  }}
                                                                            </th>
                                                                            <ng-container *ngFor="let valor of valores">
                                                                                <th class="align-middle font-size text-right bg-nivel2">
                                                                                    <ng-container *ngTemplateOutlet="totalTemplate; context: { $implicit: {valor: obtenerValorTotal(nivel2?.nivel2, valor), type: obtenerValorTotalType(nivel2?.nivel2, valor)} }"></ng-container>
                                                                                </th>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-container>
            
                                                                </ng-template>
            
                                                            </ng-container>
            
                                                        </ng-container>
                                                        <ng-template #notNivel2Template>
    
                                                            <ng-container *ngFor="let campo of nivel1?.variables">
                                                                
                                                                <ng-container *ngIf="validaCampo(campo)">
                                                                    <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1} }"></ng-container>
                                                                </ng-container>
    
                                                            </ng-container>
                                                            
                                                        </ng-template>
                                                    </tbody>
                                                    <thead *ngIf="validaFilaTotal(nivel1?.nivel1)">
                                                        <!--Esto debe ir abajo-->
                                                        <tr class="bg-nivel1">
                                                            <th class="align-middle font-size font-weight-bold bg-nivel1">
                                                                {{'Total ' + nivel1?.nivel1Name}}
                                                            </th>
                                                            <ng-container *ngFor="let valor of valores">
                                                                <th rowspan="1" class="align-middle font-size text-right bg-nivel1">
                                                                    {{(obtenerValorTotal(nivel1?.nivel1, valor) || obtenerValorTotal(nivel1?.nivel1, valor) == 0) ? 
                                                                        (obtenerValorTotal(nivel1?.nivel1, valor) | number: '1.0-0') : 
                                                                        ('')
                                                                    }}
                                                                </th>
                                                            </ng-container>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
        
                                        </div>
                                    </div>
        
                                </ng-template>
                            </div>
        
                        </ng-container>
                    </div>

                </div>

            </div>
        </div>

    </div>
</div>



<!-- Template td -->
<ng-template #inputsTemplate let-elemento>

    <tr [id]="'header-' + elemento?.index">

        <ng-container *ngFor="let item of elemento?.campo; let i = index">
            <td class="font-size align-middle" [ngClass]="{'text-right': i!=0}">
                <ng-container *ngIf="(item?.valor || item?.valor == 0)">
                    <ng-container *ngIf="esNumeroOStringNumerico(item?.valor); else elseTemplateType">
                        <ng-container [ngSwitch]="item?.type">
                            <ng-container *ngSwitchCase="'number'">
                                {{item?.valor | number: '1.0-0'}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'decimal'">
                                {{item?.valor | number: '1.0-2'}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'porc-decimal'">
                                {{item?.valor | number: '1.0-2'}}%
                            </ng-container>
                            <ng-container *ngSwitchCase="'porc-int'">
                                {{item?.valor | number: '1.0-0'}}%
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{item?.valor}}
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #elseTemplateType>
                        {{item?.valor}}
                    </ng-template>
                </ng-container>
            </td>
        </ng-container>
        
    </tr>
    
</ng-template>

<ng-template #totalTemplate let-elemento>

    <ng-container [ngSwitch]="elemento?.type">
        <ng-container *ngSwitchCase="'number'">
            {{elemento?.valor | number: '1.0-0'}}
        </ng-container>
        <ng-container *ngSwitchCase="'decimal'">
            {{elemento?.valor | number: '1.0-2'}}
        </ng-container>
        <ng-container *ngSwitchCase="'porc-decimal'">
            {{elemento?.valor | number: '1.0-2'}}%
        </ng-container>
        <ng-container *ngSwitchCase="'porc-int'">
            {{elemento?.valor | number: '1.0-0'}}%
        </ng-container>
        <ng-container *ngSwitchDefault>
            {{elemento?.valor}}
        </ng-container>
    </ng-container>
    
</ng-template>
