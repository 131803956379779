<ng-container *ngIf="entidadesValidadas.length > 0 && contratoServicios.length > 0">
    <div class="row">
        <div class="col-sm-12">
            <button type="button" class="btn btn-sm btn-primary float-right mx-4" (click)="abrirModal()"
                title="Agregar o eliminar ruts a las entidades">
                Agregar/Eliminar
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="entidadesValidadas.length > 0 && contratoServicios.length > 0">
    <ng-container *ngFor="let entidad of entidadesValidadas; let i = index;">
        <div class="container-fluid my-3">
            <accordion [isAnimated]="true" class="m-0">
                <accordion-group [isOpen]="i === 0 ? true : false" panelClass="m-0" #accGroupEntidad>
                    <!--titulo entidad-->
                    <span class="font-weight-bold" accordion-heading>
                        <i class="fa" [ngClass]="{'fa-angle-down': accGroupEntidad?.isOpen, 'fa-angle-right': !accGroupEntidad?.isOpen}"></i>
                        {{entidad.name}}
                    </span>

                    <ng-container *ngTemplateOutlet="tableTemplate; context: { $implicit: {campo: entidad} }">
                    </ng-container>


                    <ng-container *ngIf="entidadesDerivadasMalla && objectKeys(entidadesDerivadasMalla).length > 0 && entidadesDerivadasMalla[entidad.entidad] && entidadesDerivadasMalla[entidad.entidad].length > 0">
                        <ng-container *ngTemplateOutlet="tableTemplate; context: { $implicit: {campo: entidadesDerivadasMalla[entidad.entidad], malla: true, entidad: entidad.entidad} }">
                        </ng-container>
                    </ng-container>
                    
                    
            
                </accordion-group>
            </accordion>
        </div>
    </ng-container>
</ng-container>


<div class="row mt-5">
    <div class="col-sm-12 text-center">
        <button type="button" class="btn btn-sm btn-primary" (click)="avanzarFlujo()">
            Continuar
        </button>
    </div>
</div>



<app-modal-entidades-workflow 
    [contrato]="contratoEntidades" 
    [rut]="rut" 
    [idTransaccion]="idTransaccion" 
    [entidades]="entidades" 
    [entidadesValidadas]="entidadesValidadas" 
    (entidadesAct)="entidades = $event" 
    (contratoAct)="contratoEntidades = $event" 
    (entidadesValidadasAct)="entidadesValidadas = $event" 
    (entidadesRutAct)="entidadRutList = $event"
    (responseConsultaAct)="responseConsulta = $event"
    (entidadesDerivadasAConsultarAct)="entidadesDerivadasAConsultar = $event"
    (requestOriginalAct)="requestOriginal = $event"
></app-modal-entidades-workflow>


<ng-template #tableTemplate let-elemento>
    <!--selector todos entidad-->
    <div class="row d-flex justify-content-end my-2">
        <div class="col-sm-6 col-md-2">
            <div class="row">
                <div class="col-sm-12 d-flex justify-content-center">
                    Buscar Todos
                    <label class="switch switch-primary switch-sm ml-3">
                        <input #checkboxesbuscarentidad class="checkbox-buscar" type="checkbox" 
                            id="{{elemento?.malla ? 'checkboxbuscar-' + elemento?.entidad + '-entidadMalla' : 'checkboxbuscar-' + elemento?.campo?.entidad }}"
                            (click)="buscarTodosEntidad($event, elemento?.malla ? elemento?.entidad + '-entidadMalla' : elemento?.campo?.entidad)"
                        >
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-2">
            <div class="row">
                <div class="col-sm-12 d-flex justify-content-center">
                    Actualizar Todos
                    <label class="switch switch-primary switch-sm ml-3">
                        <input #checkboxesactualizarentidad class="checkbox-actualizar" type="checkbox"
                            id="{{elemento?.malla ? 'checkboxactualizar-' + elemento?.entidad + '-entidadMalla' : 'checkboxactualizar-' + elemento?.campo?.entidad }}"
                            (click)="actualizarTodosEntidad($event, elemento?.malla ? elemento?.entidad + '-entidadMalla' : elemento?.campo?.entidad)"
                        >
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <!--tabla entidad-->
    <div class="row table-height">
        <div class="col-sm-12">

            <div class="table-responsive py-3">
                <table class="table table-hover table-bordered border">
                    <thead>
                        <ng-container *ngIf="elemento?.malla">
                            <tr>
                                <th colspan="100%" class="font-weight-bold bg-primary text-center align-middle th-atributo">
                                    <span>
                                        Rut's Asociados
                                    </span>
                                </th>
                            </tr>
                        </ng-container>
                        <tr>

                            <ng-container *ngIf="(!elemento?.malla && elemento?.campo?.items?.length > 0) || (elemento?.malla && elemento?.campo?.length > 0)">
                                <th scope="col" rowspan="2" class="bg-primary th-head-size">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="row">
                                                        <div class="col-sm-12 text-center mb-1">
                                                            Habilitar/Deshabilitar
                                                        </div>
                                                        <div class="col-sm-12 d-flex justify-content-center my-4">
                                                            <label class="switch switch-primary switch-sm">
                                                                <input #checkboxesfilatodos class="checkbox-fila" type="checkbox" 
                                                                    id="{{elemento?.malla ? 'checkboxfilatodos-' + elemento?.entidad + '-entidadMalla' : 'checkboxfilatodos-' + elemento?.campo?.entidad }}"
                                                                    (click)="habilitaFilaTodos($event, elemento?.malla ? elemento?.entidad + '-entidadMalla' : elemento?.campo?.entidad)">
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </ng-container>

                            <th scope="col" rowspan="2" class="font-weight-bold bg-primary text-center align-middle th-atributo" (click)="elemento?.malla ? null : cambioDeCampo(elemento?.campo?.entidad, 'rut')">
                                <span>Rut</span>
                                <div class="float-right" *ngIf="!elemento?.malla">
                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                </div>
                            </th>

                            <!--<th scope="col" rowspan="2" class="font-weight-bold bg-primary text-center align-middle th-atributo" *ngIf="elemento?.malla">
                                <span>Rut Padre</span>
                            </th>-->

                            <th scope="col" rowspan="2" class="font-weight-bold bg-primary text-center align-middle th-atributo" *ngIf="elemento?.malla">
                                <span>Relacion del Rut</span>
                            </th>
                            
                            <th scope="col" rowspan="2" class="font-weight-bold bg-primary text-center align-middle th-atributo" *ngIf="elemento?.malla">
                                <span>Nivel</span>
                            </th>

                            <ng-container *ngFor="let contrato of contratoEntidades">
                                
                                <ng-container *ngIf="contrato.key === (elemento?.malla ? elemento?.entidad : elemento?.campo?.entidad)">

                                    <ng-container *ngFor="let variable of contrato.outputVars">

                                        <ng-container *ngIf="(variable.name !== 'rut' && variable.name !== 'Rut') && variable?.display !== false">

                                            <th scope="col" rowspan="2" class="font-weight-bold bg-primary text-center align-middle th-atributo" (click)="elemento?.malla ? null : cambioDeCampo(elemento?.campo?.entidad, variable.name)">
                                                <span>{{ variable.displayName }}</span>
                                                <div class="float-right" *ngIf="!elemento?.malla">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>

                                        </ng-container>

                                    </ng-container>
                                    
                                </ng-container>
                                
                            </ng-container>

                            <ng-container *ngFor="let servicio of contratoServicios; let servicioiIndex = index">
                                <ng-container *ngIf="servicio.display">
                                    <th scope="col" class="text-center font-weight-bold bg-primary">{{servicio.name}}</th>
                                </ng-container>
                            </ng-container>

                        </tr>
                        <tr>
                            <ng-container *ngFor="let servicio of contratoServicios; let servicioiIndex = index">
                                <ng-container *ngIf="servicio.display">
                                    <th scope="col" class="th-btn-head">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6">
                                                <div class="row" *ngIf="validarTipoServicio(servicio?.values, 'buscarHistorico')">
                                                    <div class="col-sm-12 text-center">
                                                        Buscar Todos
                                                    </div>
                                                    <div class="col-sm-12 d-flex justify-content-center">
                                                        <label class="switch switch-primary switch-sm">
                                                            <input #checkboxesbuscarreporte class="checkbox-buscar" type="checkbox"
                                                                id="{{'checkboxbuscar-' + servicio.key + '-' + (elemento?.malla ? elemento?.entidad + '-entidadMalla' : elemento?.campo?.entidad) }}"
                                                                (click)="buscarTodosReporte($event, elemento?.malla ? elemento?.entidad + '-entidadMalla' : elemento?.campo?.entidad, servicio.key)"
                                                            >
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-6">
                                                <div class="row" *ngIf="validarTipoServicio(servicio?.values, 'crear')">
                                                    <div class="col-sm-12 text-center">
                                                        Actualizar Todos
                                                    </div>
                                                    <div class="col-sm-12 d-flex justify-content-center">
                                                        <label class="switch switch-primary switch-sm">
                                                            <input #checkboxesactualizarreporte class="checkbox-actualizar" type="checkbox" 
                                                                id="{{'checkboxactualizar-' + servicio.key + '-' + (elemento?.malla ? elemento?.entidad + '-entidadMalla' : elemento?.campo?.entidad) }}"
                                                                (click)="actualizarTodosReporte($event, elemento?.malla ? elemento?.entidad + '-entidadMalla' : elemento?.campo?.entidad, servicio.key)"
                                                            >
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template #sinRuts>
                            <tr>
                                <td colspan="100%">
                                    Sin ruts en la entidad
                                </td>
                            </tr>
                        </ng-template>

                        <ng-container *ngIf="(elemento?.malla && elemento?.campo?.length > 0) || (!elemento?.malla && elemento?.campo?.items?.length > 0) else sinRuts">
                            <!--listado de ruts-->
                            <ng-container *ngFor="let item of (elemento?.malla ? elemento?.campo : elemento?.campo?.items)">
                                <tr>

                                    <td class="td-btn-head">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="row">
                                                            <div class="col-sm-12 d-flex justify-content-center">
                                                                <label class="switch switch-primary switch-sm" title="Seleccionar para habilitar fila">
                                                                    <input #checkboxesfila class="checkbox-fila" type="checkbox" 
                                                                        id="{{ 'checkboxfila-' + (elemento?.malla ? (item.id) : (item.rut))+ '-' + (elemento?.malla ? (elemento?.entidad + '-entidadMalla') : (elemento?.campo?.entidad)) }}"
                                                                        (click)="habilitaFila($event, elemento?.malla ? elemento?.entidad + '-entidadMalla' : elemento?.campo?.entidad, item.rut, elemento.malla ? item.id : null)"
                                                                    >
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    

                                    <ng-container *ngIf="elemento?.malla; else notRutMalla">
                                        <th class="text-nowrap cursor-pointer" scope="row"
                                            (click)="showMalla(item)"
                                            [ngClass]="validaAnyResponse(elemento?.entidad, item.rut) ? 'bg-consulted' : ''">
                                            {{item.rut || '' | rutFormat}}
                                        </th>
                                    </ng-container>
                                    <ng-template #notRutMalla>
                                        <th class="text-nowrap" scope="row"
                                            [ngClass]="validaAnyResponse(elemento?.campo?.entidad, item.rut) ? 'bg-consulted' : ''">
                                            {{item.rut || '' | rutFormat}}
                                        </th>
                                    </ng-template>


                                    <!--<th class="text-nowrap" scope="row" *ngIf="elemento?.malla"
                                        [ngClass]="validaAnyResponse(elemento?.malla ? (elemento?.entidad) : (elemento?.campo?.entidad), item.rut) ? 'bg-consulted' : ''">
                                        {{item.rutPadreDisplay || '' | rutFormat}}
                                    </th>-->

                                    <th scope="row" *ngIf="elemento?.malla"
                                        [ngClass]="validaAnyResponse(elemento?.malla ? (elemento?.entidad) : (elemento?.campo?.entidad), item.rut) ? 'bg-consulted' : ''">
                                        {{item.relacion || ''}}
                                    </th>

                                    <th class="text-nowrap text-center" scope="row" *ngIf="elemento?.malla"
                                        [ngClass]="validaAnyResponse(elemento?.malla ? (elemento?.entidad) : (elemento?.campo?.entidad), item.rut) ? 'bg-consulted' : ''">
                                        {{item.nivel || ''}}
                                    </th>
                                        
                                    <ng-container *ngFor="let atributo of item.atributos">


                                        <ng-template #notNumber>

                                            <td [ngClass]="validaAnyResponse(elemento?.malla ? (elemento?.entidad) : (elemento?.campo?.entidad), item.rut) ? 'bg-consulted' : ''">
                                                {{ atributo.value }}
                                            </td>

                                        </ng-template>
                                        

                                        <ng-container *ngIf="atributo?.typeAttribute === 'Number' || isNumber(atributo?.value) === true else notNumber">

                                            <td [ngClass]="validaAnyResponse(elemento?.malla ? (elemento?.entidad) : (elemento?.campo?.entidad), item.rut) ? 'bg-consulted' : ''">
                                                {{ atributo.value | number: '1.0-2' }}
                                            </td>

                                        </ng-container>


                                    </ng-container>
                                    
                                    <ng-container *ngFor="let servicio of contratoServicios; let servicioiIndex = index">
                                        <ng-container *ngIf="servicio.display">

                                            <ng-container *ngIf="elemento?.malla; else noMallaTemplate"><!--Consultar ruts derivados malla-->

                                                <td [ngClass]="validaResponse(elemento?.entidad, item.rut, servicio.key) ? 'bg-consulted' : ''">

                                                    <ng-container *ngIf="item.nivel === 3 && servicio.key === 'mallaSocietaria'; else elseTemplate">
                                                        -
                                                    </ng-container>
                                                    <ng-template #elseTemplate>
                                                        <div class="row">
                                                            <div [ngClass]="validaResponse(elemento?.entidad, item.rut, servicio.key) ? 'col-md-5' : 'col-sm-12'">
                                                                <div class="row">
                                                                    <div [ngClass]="validaResponse(elemento?.entidad, item.rut, servicio.key) ? 'col-sm-12' : 'col-sm-12 col-md-6'">
                                                                        <div class="row" *ngIf="validarTipoServicio(servicio?.values, 'buscarHistorico')">
                                                                            <div class="col-sm-12 text-center text-nowrap">
                                                                                Buscar
                                                                            </div>
                                                                            <div class="col-sm-12 d-flex justify-content-center">
                                                                                <label class="switch switch-primary switch-sm">
                                                                                    <input #checkboxesbuscar class="checkbox-buscar" type="checkbox" 
                                                                                        id="checkboxbuscar-{{item.id}}-{{elemento?.entidad}}-{{servicio.key}}-entidadMalla"
                                                                                        (click)="buscarServicioMalla($event, elemento?.entidad, servicio.key, servicioiIndex, item.rut, item.id, item?.levelOneRut)"
                                                                                        disabled
                                                                                    >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div [ngClass]="validaResponse(elemento?.entidad, item.rut, servicio.key) ? 'col-sm-12' : 'col-sm-12 col-md-6'">
                                                                        <div class="row" *ngIf="validarTipoServicio(servicio?.values, 'crear')">
                                                                            <div class="col-sm-12 text-center text-nowrap">
                                                                                Actualizar
                                                                            </div>
                                                                            <div class="col-sm-12 d-flex justify-content-center">
                                                                                <label class="switch switch-primary switch-sm">
                                                                                    <input #checkboxesactualizar class="checkbox-actualizar" type="checkbox" 
                                                                                        id="checkboxactualizar-{{item.id}}-{{elemento?.entidad}}-{{servicio.key}}-entidadMalla"
                                                                                        (click)="actualizarServicioMalla($event, elemento?.entidad, servicio.key, servicioiIndex, item.rut, item.id, item?.levelOneRut)"
                                                                                        disabled
                                                                                    >
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <!--post consulta servicio-->
                                                            <div class="col-md-7" *ngIf="validaResponse(elemento?.entidad, item.rut, servicio.key)">
                                                                <div class="container-fluid">
                                                                    <div class="row text-center pt-3">
                                                                        <div class="col-sm-12">
                                                                            <span class="badge badge-pill badge-warning" 
                                                                                *ngIf="responseConsulta[elemento?.entidad][item.rut][servicio.key]?.errors?.hassError === false && (!responseConsulta[elemento?.entidad][item.rut][servicio.key]?.fechaReporte || responseConsulta[elemento?.entidad][item.rut][servicio.key]?.fechaReporte === 'Sin Información')"
                                                                                title="Sin información histórica disponible">
                                                                                <i class="fa fa-exclamation" aria-hidden="true"></i>
                                                                            </span>
                                                                            <span class="badge badge-pill badge-success" *ngIf="responseConsulta[elemento?.entidad][item.rut][servicio.key].errors.hassError === false && (responseConsulta[elemento?.entidad][item.rut][servicio.key]?.fechaReporte && responseConsulta[elemento?.entidad][item.rut][servicio.key]?.fechaReporte !== 'Sin Información')">
                                                                                <i class="fa fa-check" aria-hidden="true"></i>
                                                                            </span>
                                                                            <span class="badge badge-pill badge-danger" 
                                                                                *ngIf="responseConsulta[elemento?.entidad][item.rut][servicio.key].errors.hassError === true"
                                                                                title="{{responseConsulta[elemento?.entidad][item.rut][servicio.key]?.errors?.details?.message || 'Ocurrio un Error'}}">
                                                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                                            </span>
                                                                        </div>
                                                                        <div class="col-sm-12" *ngIf="responseConsulta[elemento?.entidad][item.rut][servicio.key].errors.hassError === false && obtieneFecha(responseConsulta[elemento?.entidad][item.rut][servicio.key]?.fechaReporte) !== ''">
                                                                            <span class="badge badge-info">
                                                                                {{obtieneFecha(responseConsulta[elemento?.entidad][item.rut][servicio.key]?.fechaReporte) | date: 'dd-MM-YYYY'}}
                                                                            </span>
                                                                        </div>
                                                                        <div class="col-sm-12" *ngIf="responseConsulta[elemento?.entidad][item.rut][servicio.key].errors.hassError === true">
                                                                            <button class="btn btn-primary btn-sm button-reconsultar mt-2"
                                                                                (click)="reconsultar(elemento?.entidad, item.rut, servicio.key)"
                                                                                title="Reconsultar"
                                                                            >
                                                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div class="col-sm-12">
                                                                            <button class="btn" (click)="borrarServicio(elemento?.entidad, item.rut, servicio.key)"><i class="fa fa-trash text-danger"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    
                                                </td>


                                            </ng-container>
                                            <ng-template #noMallaTemplate><!--Consultar ruts derivados-->
                                                <td [ngClass]="validaResponse(elemento?.campo?.entidad, item.rut, servicio.key) ? 'bg-consulted' : ''">
                                                    <div class="row">
                                                        <div [ngClass]="validaResponse(elemento?.campo?.entidad, item.rut, servicio.key) ? 'col-md-5' : 'col-sm-12'">
                                                            <div class="row">
                                                                <div [ngClass]="validaResponse(elemento?.campo?.entidad, item.rut, servicio.key) ? 'col-sm-12' : 'col-sm-12 col-md-6'">
                                                                    <div class="row" *ngIf="validarTipoServicio(servicio?.values, 'buscarHistorico')">
                                                                        <div class="col-sm-12 text-center text-nowrap">
                                                                            Buscar
                                                                        </div>
                                                                        <div class="col-sm-12 d-flex justify-content-center">
                                                                            <label class="switch switch-primary switch-sm">
                                                                                <input #checkboxesbuscar class="checkbox-buscar" type="checkbox" 
                                                                                    id="checkboxbuscar-{{item.rut}}-{{elemento?.campo?.entidad}}-{{servicio.key}}"
                                                                                    (click)="buscarServicio($event, elemento?.campo?.entidad, servicio.key, servicioiIndex, item.rut)"
                                                                                    disabled
                                                                                >
                                                                                <span></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div [ngClass]="validaResponse(elemento?.campo?.entidad, item.rut, servicio.key) ? 'col-sm-12' : 'col-sm-12 col-md-6'">
                                                                    <div class="row" *ngIf="validarTipoServicio(servicio?.values, 'crear')">
                                                                        <div class="col-sm-12 text-center text-nowrap">
                                                                            Actualizar
                                                                        </div>
                                                                        <div class="col-sm-12 d-flex justify-content-center">
                                                                            <label class="switch switch-primary switch-sm">
                                                                                <input #checkboxesactualizar class="checkbox-actualizar" type="checkbox" 
                                                                                    id="checkboxactualizar-{{item.rut}}-{{elemento?.campo?.entidad}}-{{servicio.key}}"
                                                                                    (click)="actualizarServicio($event, elemento?.campo?.entidad, servicio.key, servicioiIndex, item.rut)"
                                                                                    disabled
                                                                                >
                                                                                <span></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
    
                                                        <!--post consulta servicio-->
                                                        <div class="col-md-7" *ngIf="validaResponse(elemento?.campo?.entidad, item.rut, servicio.key)">
                                                            <div class="container-fluid">
                                                                <div class="row text-center pt-3">
                                                                    <div class="col-sm-12">
                                                                        <span class="badge badge-pill badge-warning" 
                                                                            *ngIf="responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key]?.errors?.hassError === false && (!responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key]?.fechaReporte || responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key]?.fechaReporte === 'Sin Información')"
                                                                            title="Sin información histórica disponible">
                                                                            <i class="fa fa-exclamation" aria-hidden="true"></i>
                                                                        </span>
                                                                        <span class="badge badge-pill badge-success" *ngIf="responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key].errors.hassError === false && (responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key]?.fechaReporte && responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key]?.fechaReporte !== 'Sin Información')">
                                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                                        </span>
                                                                        <span class="badge badge-pill badge-danger" 
                                                                            *ngIf="responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key].errors.hassError === true"
                                                                            title="{{responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key]?.errors?.details?.message || 'Ocurrio un Error'}}">
                                                                            <i class="fa fa-times" aria-hidden="true"></i>
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-sm-12" *ngIf="responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key].errors.hassError === false && obtieneFecha(responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key]?.fechaReporte) !== ''">
                                                                        <span class="badge badge-info">
                                                                            {{obtieneFecha(responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key]?.fechaReporte) | date: 'dd-MM-YYYY'}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-sm-12" *ngIf="responseConsulta[elemento?.campo?.entidad][item.rut][servicio.key].errors.hassError === true">
                                                                        <button class="btn btn-primary btn-sm button-reconsultar mt-2"
                                                                            (click)="reconsultar(elemento?.campo?.entidad, item.rut, servicio.key)"
                                                                            title="Reconsultar"
                                                                        >
                                                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div class="col-sm-12">
                                                                        <button class="btn" (click)="borrarServicio(elemento?.campo?.entidad, item.rut, servicio.key)"><i class="fa fa-trash text-danger"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-template>

                                        </ng-container>
                                    </ng-container>

                                </tr>
                            </ng-container>

                        </ng-container>
                        
                    </tbody>
                </table>
            </div>
            
        </div>
    </div>


    <!--boton consultar-->
    <div class="row">
        <div class="col-12 my-3 p-5 text-center">
            <button class="btn btn-primary"
                (click)="consultar([(elemento?.malla ? elemento?.entidad : elemento?.campo?.entidad)], elemento?.malla ? true : false, false)"
            >
                {{elemento?.malla ? 'Consultar Asociados' : 'Consultar'}}
            </button>
        </div>
    </div>
</ng-template>