<div class="modal-content">
    <div class="row">
        <div class="col-sm-12">
            <button class="close mr-4 mt-2" type="button" (click)="close()"><i class="fa fa-times"></i></button>
        </div>

        <div class="col-12">
            <app-alert-message *ngFor="let message of messages; let i = index"
                [message]="message.text"
                [isError]="message.isError"
                [isSuccess]="message.isSuccess"
                (close)="removeMessage(i)"
            ></app-alert-message>
        </div>
    
        <ng-container *ngIf="confirmData && objectKeys(confirmData).length > 0; else error">
            <div class="col-sm-12 px-5 mb-2">
                <button type="button" class="btn btn-primary btn-sm mx-1" (click)="zoomIn()">
                    <i class="fa fa-search-plus" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-secondary btn-sm mx-1" (click)="zoomOut()">
                    <i class="fa fa-search-minus" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-warning btn-sm mx-1" (click)="clear()">
                    Limpiar
                </button>

                <button type="button" class="btn btn-success btn-sm float-right mx-1" (click)="seleccionar()">
                    Seleccionar
                </button>
            </div>

            <div class="col-12 shadow">
                <div #chartContainer class="myChart min-size-graph" id="chartContainer"></div>
            </div>
            
            <!--<div id="print-section-malla" *ngIf="confirmData?.printable">
                <div class="nested-table-container">
                    <div class="nested-table-row print-table-header">
                        <div class="rs-nested-table-cl">Razon Social</div>
                        
                        <div class="type-and-rut">
                            <div>Tipo</div>
                            <div>RUT</div>
                        </div>
                    </div>
                
                    <br>
                
                    <app-print-nested-table [dataSource]="confirmData?.dataOrgPrint"></app-print-nested-table>
                </div>
            </div>-->
        </ng-container>
        <ng-template #error>
            <div class="col-sm-12">
                <span>
                    Ha ocurrido un error al intentar generar el gráfico.
                </span>
            </div>
        </ng-template>
    </div>
</div>

