<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="showoptions || showContent">
    <div class="container-gtp no-print">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="d-flex">
                    <button (click)="closeReport()" class="btn btn-primary mr-auto p-2">Volver</button>
                    <a *ngIf="pdfDescargaCTE" type="button" class="btn btn-primary p-2 mx-2" [attr.href]="pdfDescargaCTE" target="_blank">
                        Descargar CTE</a>
                    <button *ngIf="showContent" [printTitle]='"ReporteFinanciero_"+(rut | rutFormat)+"_"+today'
                        [useExistingCss]="true" printSectionId="print-section" ngxPrint
                        class="btn btn-primary p-2 mx-2">Descargar PDF</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="showoptions">
    <div class="container-gtp">
        <div class="panel panel-default">
            <div class="panel-body box-reporte-full">
                <form (ngSubmit)="submit()" [formGroup]="reportFullFormSend">
                    <tabset>
                        <tab heading="Procesar" (selectTab)="onSelect($event)" class="m-0">
                            <div *ngIf="valueTab === 'Procesar'">
                                <div class="input-group mt-5 mx-auto col-sm-6">
                                    <label class="uploadbutton mx-auto">
                                        <input class="input-file" id="file" type="file" formControlName="pdf"
                                            name="file" accept=".pdf" (change)="handleUpload($event)" />
                                        <div class="button btn">PDF Carpeta
                                        </div>
                                        <div class='input-file-text'
                                            [ngClass]="{'text-success': reportFullFormSend?.controls?.pdf?.value}">
                                            {{reportFullFormSend?.controls?.pdf?.value || 'Subir Archivo'}}
                                        </div>
                                    </label>
                                    <div class="w-100">
                                        <app-error [control]="reportFullFormSend.get('pdf')"></app-error>
                                    </div>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="mx-auto col-sm-6">
                                        <div class="form-group mt-5 px-5">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Validar Sii</span>
                                                </div>
                                                <label class="switch switch-primary ml-3">
                                                    <input type="checkbox" id="validar" formControlName="validar">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="Crear" (selectTab)="onSelect($event)">
                            <div *ngIf="valueTab === 'Crear'">

                                <div class="d-flex w-100" *ngIf="consultacredencialesSinc">
                                    <div class="mx-auto col-sm-6">
                                        <div class="form-group mt-5">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Email</span>
                                                </div>
                                                <input type="email" class="form-control ml-1" formControlName="email"
                                                    autocomplete="off">
                                            </div>
                                            <div class="w-100">
                                                <app-error [control]="reportFullFormSend.get('email')" [email]="true"></app-error>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="credencialesSinc === false">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Clave SII</span>
                                                    </div>
                                                    <input type="password" class="form-control" formControlName="claveSii"
                                                        autocomplete="off">
                                                </div>
                                                <div class="w-100">
                                                    <app-error [control]="reportFullFormSend.get('claveSii')"></app-error>
                                                </div>
                                            </div>

                                            <div class="form-group mt-5"><!-- *ngIf="validaCredsAdmin()"-->
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" title="Sincronizar credenciales">Sincronizar Credenciales</span>
                                                    </div>
                                                    <label class="switch switch-primary ml-3 pt-2">
                                                        <input type="checkbox" id="sincronizar" formControlName="sincronizar">
                                                        <span></span>
                                                    </label>
                                                    <div class="col-sm-12">
                                                        <span 
                                                            class="d-inline-block mb-3 text-muted cursor-pointer cursor-pointer-info"
                                                            >
                                                            Al sincronizar credenciales se da acceso a nivel de organización.
                                                            <i class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="Descargar" (selectTab)="onSelect($event)">
                            <div *ngIf="valueTab === 'Descargar'">
                                <div class="d-flex w-100">
                                    <div class="mx-auto col-sm-6">
                                        <div class="form-group mt-5">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Codigo</span>
                                                </div>
                                                <input type="text" class="form-control" formControlName="codigoCte"
                                                    autocomplete="off">
                                            </div>
                                            <div class="w-100">
                                                <app-error [control]="reportFullFormSend.get('codigoCte')"></app-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Clave</span>
                                                </div>
                                                <input type="password" class="form-control" formControlName="claveCte"
                                                    autocomplete="off">
                                            </div>
                                            <div class="w-100">
                                                <app-error [control]="reportFullFormSend.get('claveCte')"></app-error>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </tab>
                    </tabset>
                    <div class="col-12" *ngIf="valueTab === 'Procesar' || (valueTab === 'Crear' && consultacredencialesSinc) || valueTab === 'Descargar'">
                        <div class="row">
                            <div class="mx-auto">
                                <div class="btn-group mt-5 text-center">
                                    <button type="submit" class="btn btn-primary mr-3">{{valueTab}}</button>
                                    <button type="button" (click)="Limpiar()" class="btn btn-warning">Limpiar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="showContent">
    <div id="print-section">
        <app-reporte-full-viewer [reporteFullContent]="reporteFullContent" [informacionErrores]="informacionErrores" [nombreBasico]="nombreBasico"></app-reporte-full-viewer>
    </div>
</ng-container>