import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, from, of, Subscription } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { SolicitudCreditoServiceWorkflow } from '../../../../shared/services/solicitud-credito-workflow.service';
import { catchError, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { ModalBasicoConsolidadoService } from '../../../../shared/services/modal-basico-consolidado.service';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { ChartType } from 'angular-google-charts';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ModalPdfViewerService } from '../../../../shared/services/modal-pdf-viewer.service';
import { PdfGeneratorService } from '../../../../shared/services/pdf-generator.service';

@Component({
  selector: 'app-plantilla-cesce',
  templateUrl: './plantilla-cesce.component.html',
  styleUrls: ['./plantilla-cesce.component.scss']
})
export class PlantillaCesceComponent implements OnInit {
  //Informacion para plantilla no editable
  @Input() plantillaNoEditable: boolean = false;
  @Input() objEntradaFicha: any = {};
  @Input() objEntradaMotor: any = {};

  @Output() operacionNuevo = new EventEmitter<boolean>();
  @Output() finalizarFlujo = new EventEmitter<void>();
  @Output() bloqueaPasos5 = new EventEmitter<boolean>();
  @Output() bloqueaPasos6 = new EventEmitter<boolean>();
  @Input() idTransaccion: string = '';
  @Input() stepper!: number;
  @Input() rut: string = '';
  @Input() closed: boolean = true;
  public objectKeys = Object.keys;
  public servicioFichaForm!: UntypedFormGroup;

  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];

  public activateRutComiteHelper: boolean = false;
  public activateRutClienteComiteHelper: boolean = false;

  public subscriptions: Subscription[] = [];
  public data: any;
  public today = '';
  public dataFiltrada: any;
  public dataF29: any[] = [];
  public dataCargada: boolean = false;
  public groupNameConsulting: string[] = ['buscarReportePrincipal', 'buscarReportesDerivados'];
  public groupNameConsultingFicha: string[] = ['buscarFichaDeCredito', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingScore: string[] = ['buscarCalculoScore', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingAprobacion: string[] = ['buscarReportesDerivados', 'buscarReportePrincipal'];
  //public reportesPrincipales: any = {};
  public contratoServicios: any[] = [];
  public atributos: any[] = [];
  public reportesPrincipalesObj: any = {};
  public reportesDerivadosObj: any = {};
  public reportesDerivados: any = {};
  public entidadesDerivadas: any = {};
  public errores: any[] = [];
  public bodyModelos: any = {};
  public fichaGuardada: any = {}; // es para validar la existencia de una ficha guardada y habilitar el boton continuar
  public fichaComiteCredito: any = {};
  public sociosComposicion: any[] = [];
  public sociosInformes: any[] = [];
  public titularInforme: any[] = [];
  public empresasRelacionadasComposicion: any[] = [];
  public empresasRelacionadasInformes: any[] = [];
  public informacionFinanciera: any = {};
  public showReportes: boolean = false;
  public showArchivos: boolean = false;
  public servicioFichaFormSaved: any = {};
  public consolidadoTitular: any = {};
  public plantillaAntecedentes = 'cesceDemo';
  public urlDownloadPreview = '';
  public urlDownload = '';
  public modalDefault = true;

  public valorUFActual: number = 0;
  public valorDolarActual: number = 0;
  public montoSolicitado: any = 0;

  public reportesSeleccionado: any = {};
  public rutSeleccionado: string = '';
  public informeComercial: string = '';
  public motorCalculos: any;
  public motorConDatos: boolean = false;
  public balanceManual: any[] = [];
  public dataManualCargada: boolean = false;
  public valorCambioMoneda: number = 0;

  public totalesInformacionFinanciera: any = {};
  public informacionFinancieraTable: any[] = [];

  public listaReportesPrincipales: any[] = [];
  public listaReportesDerivados: any = {};

  public tipoBalance: string = 'automatico';

  public formatos: any = {
    // carga financiera
    fechaCurse: 'text',
    institucion: 'text',
    tipoCredito: 'text',
    ultimoVencimiento: 'text',
    garantiasVigentes: 'text',
    creditoOriginal: 'decimal',
    deudaActual: 'decimal',
    cuota: 'decimal',
    nCuotasPorPagar: 'numero',
    nCuotasMorosas: 'numero',
  }

  public formatosLinea: any = {
    // linea / operacion propuesta
    lineaVigentemontoSolicitado: 'decimal',
    lineaPropuestamontoSolicitado: 'decimal',
    opPuntualVigentemontoSolicitado: 'decimal',
    opPuntualPropuestamontoSolicitado: 'decimal',

    vigentemontoSolicitado: 'decimal',
    morosamontoSolicitado: 'decimal',
    canceladamontoSolicitado: 'decimal',

    porcentajemontoSolicitado: 'decimal',

    plazoDiasmontoSolicitado: 'numero',

    vencimiento: 'text',
    opPuntualM: 'decimal',
    riesgoTotalM: 'decimal',
    totalLineaM: 'decimal',
    presentacionCliente: 'text'
  }

  public compareBalances: any/*{fechas: {f22: string, balanceManual: string}, tabla: {titulo: string, f22: any, balanceManual: any, diferencia: any}[]}*/ = {};

  constructor(
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalBasicoConsolidadoService,
    private _sessionService: SessionService,
    private utilsService: UtilsService,
    private modalPdfViewerService: ModalPdfViewerService,
    private pdfGeneratorService: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    this.operacionNuevo.emit(false);
    this.setToday();
    this.initForm();
    if(this.plantillaNoEditable === false){
      this.obtenerFlujo();
    } else {
      this.mapeoNoEditable();
      this.showArchivos = true;
    }
    this.onChanges();
  }

  redireccionPasoAnterior(ultimoPasoCompletado: number): void {
    let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
    if (!step[0]?.step){
      step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
    }
    this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
    this.solicitudCreditoService.setStep(Number(step[0]?.step));
  }

  mapeoNoEditable(): void {
    this.callServicesFichaAprobacion();    
  }

  // Logica de revision de paso y llamada a servicios

  obtenerFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      let paso: any;
      let pasoAnterior: any;
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
      this.closed = (resp.closed === null || resp.closed === undefined) ? true : resp.closed;
      this.showArchivos = true;

      if(this.stepper === 5){
        resp.stages.forEach((e: any) => {
          if(e.stageID === '10'){
            paso = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(paso?.stageID === '10' && (paso?.completed === false && paso?.optional === false)){ //pasoAnterior && !pasoAnterior?.completed
          let pasoAnteriorCompleted = ultimoPasoCompletado;
          if(ultimoPasoCompletado === 9)
            pasoAnteriorCompleted = 8;

          this.redireccionPasoAnterior(pasoAnteriorCompleted);
        } else {
          if (paso?.stageID === '10' && paso?.completed === true) { // Se realizo el calculo del score
            this.callServicesFichaScore();
          } else {
            this.redireccionPasoAnterior(ultimoPasoCompletado);
          }
        }
      } else {
        resp.stages.forEach((e: any) => {
          if(e.stageID === '9'){
            paso = e;
          } else if (e.stageID === '8'){
            pasoAnterior = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(pasoAnterior && (!pasoAnterior?.completed && pasoAnterior?.optional === false)){
          this.redireccionPasoAnterior(ultimoPasoCompletado);
        } else {
          if (paso?.stageID === '9' && paso?.completed === false) { // primera vez en esta pantalla -> cambiar para pruebas
            this.callServices();
          }
          else if(paso?.stageID === '9' && paso?.completed === true){ // ya se habia llegado antes a esta pantalla -> cambiar para pruebas
            this.callServicesFicha();
          }
        }
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));

  }

  // Llamada a servicios para obtener ficha con score guardada

  async callServicesFichaAprobacion(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingAprobacion.length > 0) {
      for await (const value of this.groupNameConsultingAprobacion) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaAprobacion");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  // Llamada a servicios para obtener ficha con score guardada

  async callServicesFichaScore(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingScore.length > 0) {
      for await (const value of this.groupNameConsultingScore) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaScore");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  // Llamada a servicios para obtener ficha guardada

  async callServicesFicha(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingFicha.length > 0) {
      for await (const value of this.groupNameConsultingFicha) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFicha");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  // Inicio Balances Automaticos

  obtenerBalanceAutomatico(): void {
    const datos = this.obtenerDatosF22();

    if(datos.length > 0){
      this.data = this.utilsService.calculosVariacion(datos);
      let periodos = this.utilsService.obtenerPeriodos(this.data);
      periodos = periodos.slice(0, 2);
      this.dataFiltrada = this.utilsService.obtenerReporteFiltrado(this.data, periodos);
      this.dataF29 = this.obtenerDatosF29();
      this.dataCargada = true;
    } else {
      this.dataCargada = false;
    }
  }

  // FIN Balances Automaticos

  // Mapeo de error en consultas asincronas

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  // Mapeo de respuestas correctas en consultas asincronas

  setResponse(service: string, response: any): void {
    if (service === 'buscarReportePrincipal'){
      this.contratoServicios = response?.Stage3Response?.ContratoServicios || [];
      this.atributos = response?.Stage2Response?.Atributos || [];
      this.listaReportesPrincipales = response?.ReportKeys || [];
    }
    else if (service === 'buscarReportesDerivados'){

      this.listaReportesDerivados = response?.ReportKeys || {};
      this.entidadesDerivadas = response?.Stage5Response?.Entidades;

    } else if (service === 'buscarFichaDeCredito'){
      this.fichaGuardada = response?.FichaDeCredito;
      const ficha = response?.FichaDeCredito?.ficha;
      this.servicioFichaFormSaved = ficha?.servicioFichaForm;
      this.sociosComposicion = ficha?.sociosComposicion || [];
      this.empresasRelacionadasComposicion = ficha?.empresasRelacionadasComposicion || [];
      this.sociosInformes = ficha?.sociosInformes || [];
      this.titularInforme = ficha?.titularInforme || [];
      this.empresasRelacionadasInformes = ficha?.empresasRelacionadasInformes || [];
      this.dataFiltrada = ficha?.dataFiltrada || {};
      this.dataF29 = ficha?.dataF29 || [];
      this.rut = ficha?.rut || '';
      this.informacionFinanciera = ficha?.informacionFinanciera || {};
      this.consolidadoTitular = ficha?.consolidadoTitular || {};
      this.totalesInformacionFinanciera = ficha?.totalesInformacionFinanciera || {};
      this.informacionFinancieraTable = ficha?.informacionFinancieraTable || [];

      this.balanceManual = ficha?.balanceManual || [];
      (this.balanceManual.length > 0) ? this.dataManualCargada = true : this.dataManualCargada = false;
      this.tipoBalance = ficha?.tipoBalance || 'automatico';
      this.compareBalances = ficha?.compareBalances || {};

    } else if (service === 'buscarCalculoScore'){
      this.fichaGuardada = response?.FichaDeCredito;
      const ficha = response?.FichaDeCredito?.ficha;
      this.servicioFichaFormSaved = ficha?.servicioFichaForm;
      this.sociosComposicion = ficha?.sociosComposicion || [];
      this.empresasRelacionadasComposicion = ficha?.empresasRelacionadasComposicion || [];
      this.sociosInformes = ficha?.sociosInformes || [];
      this.titularInforme = ficha?.titularInforme || [];
      this.empresasRelacionadasInformes = ficha?.empresasRelacionadasInformes || [];
      this.dataFiltrada = ficha?.dataFiltrada || {};
      this.dataF29 = ficha?.dataF29 || [];
      this.rut = ficha?.rut || '';
      this.balanceManual = ficha?.balanceManual || [];
      this.informacionFinanciera = ficha?.informacionFinanciera || {};
      this.consolidadoTitular = ficha?.consolidadoTitular || {};
      this.totalesInformacionFinanciera = ficha?.totalesInformacionFinanciera || {};
      this.informacionFinancieraTable = ficha?.informacionFinancieraTable || [];

      this.motorCalculos = response?.MotorDeCalculos;

      if(this.motorCalculos?.ResponseReglas?.message){
        this.motorConDatos = false;
      } else {
        this.motorConDatos = true;
      }

      (this.balanceManual.length > 0) ? this.dataManualCargada = true : this.dataManualCargada = false;
      (ficha && Object.keys(ficha).length > 0) ? this.showReportes = true : this.showReportes = false;
      (this.dataFiltrada && Object.keys(this.dataFiltrada).length > 0) ? this.dataCargada = true : this.dataCargada = false;
      this.tipoBalance = ficha?.tipoBalance || 'automatico';
      this.compareBalances = ficha?.compareBalances || {};
    }
  }

  //Definicion llamada a servicios asincronos por primera vez en la vista

  getServices(service: string): any {
    const objeto: any = {
      'buscarReportePrincipal': () => {
        return this.solicitudCreditoService.buscarReportePrincipal(this.idTransaccion, this.rut)
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes principales'), of(null))));
      },
      'buscarReportesDerivados': () => {
        return this.solicitudCreditoService.buscarReportesDerivados(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes derivados'), of(null))));
      },
      'buscarFichaDeCredito': () => {
        return this.solicitudCreditoService.buscarFichaDeCredito(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha'), of(null))));
      },
      'buscarCalculoScore': () => {
        return this.solicitudCreditoService.buscarCalculoScore(this.rut, this.idTransaccion)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha con Score'), of(null))));
      }
    };
    return objeto[service]();
  }

  // Llamada a servicios asincronos por primera vez en la vista

  async callServices(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsulting) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServices");
      }
    },
      (error) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    ));
  }

  buscarReportes(etapa: string): void {
    const apiServicesReportes: any = this.utilsService.obtenerServicesReportes(this.listaReportesPrincipales, this.listaReportesDerivados) || [];
    let count = 0;

    this.spinner.show();
    from(apiServicesReportes).pipe( 
      mergeMap(
        (service: any) => 
          this.solicitudCreditoService.buscarReportes(
            this.idTransaccion, 
            this.rut, 
            service.principal, 
            service.reportKey, 
            service.entidad, 
            service.rutDerivado
          )
          .pipe(
            catchError(error => {
              //console.error(`Error en la consulta del servicio: ${service.principal}, ${service.reportKey}, ${service.entidad}, ${service.rutDerivado}`);
              return of(null); // Retorna un observable que emite un valor nulo
            }),
            map(response => {
              if (response !== null) {
                count++;
              }
              return {
                service: service,
                response: response
              };
            }),
          ),
          20
        ), 
        filter(response => response !== null),
      toArray()
    ).subscribe(responses => {
      if (count === 0) { // Si no hubo respuestas válidas
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      } else if (count < apiServicesReportes.length) { // Si hubo respuestas válidas pero no para todos los servicios
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      }
      if(count > 0){
        this.reportesPrincipalesObj = this.utilsService.mapeoReportesPrincipales(responses);
        this.reportesDerivadosObj = this.utilsService.mapeoReportesDerivados(responses);
        if (etapa === 'callServices'){
          this.obtenerBalanceAutomatico();
          //this.identificarInformeComercial();
          this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
          this.validacionMonedas()
        } else if (etapa === 'callServicesFicha'){
          this.initForm();
          this.showReportes = true;
          this.dataFiltrada && Object.keys(this.dataFiltrada).length > 0 ? this.dataCargada = true : this.dataCargada = false;
          this.spinner.hide();
        } else if (etapa === 'callServicesFichaAprobacion'){
          this.initForm();
          this.mapeoFichaGuardada();
          this.spinner.hide();
        } else {
          this.initForm();
          this.spinner.hide();
        }
      }
    }, error => {
      if (count > 0) {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      } else {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      }
    });
  }

  mapeoFichaGuardada(): void {
    this.fichaGuardada = this.objEntradaFicha;
    const ficha = this.objEntradaFicha?.ficha;
    this.servicioFichaFormSaved = ficha?.servicioFichaForm;
    this.sociosComposicion = ficha?.sociosComposicion || [];
    this.empresasRelacionadasComposicion = ficha?.empresasRelacionadasComposicion || [];
    this.sociosInformes = ficha?.sociosInformes || [];
    this.titularInforme = ficha?.titularInforme || [];
    this.empresasRelacionadasInformes = ficha?.empresasRelacionadasInformes || [];
    this.dataFiltrada = ficha?.dataFiltrada || {};
    this.dataF29 = ficha?.dataF29 || [];
    this.rut = ficha?.rut || '';
    this.balanceManual = ficha?.balanceManual || [];
    this.informacionFinanciera = ficha?.informacionFinanciera || {};
    this.consolidadoTitular = ficha?.consolidadoTitular || {};
    this.totalesInformacionFinanciera = ficha?.totalesInformacionFinanciera || {};
    this.informacionFinancieraTable = ficha?.informacionFinancieraTable || [];

    this.motorCalculos = this.objEntradaMotor;

    if(this.motorCalculos?.ResponseReglas?.message){
      this.motorConDatos = false;
    } else {
      this.motorConDatos = true;
    }

    (this.balanceManual.length > 0) ? this.dataManualCargada = true : this.dataManualCargada = false;
    (ficha && Object.keys(ficha).length > 0) ? this.showReportes = true : this.showReportes = false;
    (this.dataFiltrada && Object.keys(this.dataFiltrada).length > 0) ? this.dataCargada = true : this.dataCargada = false;
    this.tipoBalance = ficha?.tipoBalance || 'automatico';
    this.compareBalances = ficha?.compareBalances || {};
  }

  validacionMonedas(): void {
    const date = new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let today = '';
    
    if(month < 10){
      today = day < 10 ? `0${day}-0${month}-${year}` : `${day}-0${month}-${year}`;
    }else{
      today = day < 10 ? `0${day}-${month}-${year}` : `${day}-${month}-${year}`;
    }

    const atributos = this.atributos || [];
    let [canal, sucursal] = '';
    let montoSolicitado: any = 0;
    let moneda: string = '';
    atributos.forEach((element: any) => {
      if(element?.nameAttribute.toUpperCase() === 'SUCURSAL CONTRATANTE' && !isNaN(Number(element?.value))){
        sucursal = element?.allowedValues[Number(element?.value)] || '';
      }
      if(element?.nameAttribute.toUpperCase() === 'CANAL ENTRADA' ){
        canal = element?.allowedValues[Number(element?.value)] || '';
      }
      if(element?.nameAttribute.toUpperCase() === 'MONTO A SOLICITAR' ){
        montoSolicitado = Number(element?.value) || 0;
      }
      if(element?.nameAttribute.toUpperCase() === 'TIPO MONEDA' ){
        moneda = element?.value || ''; // 1: USD, 2: CLP, 3: UF
        if(element?.value === '1'){
          moneda = 'USD';
        } else if (element?.value === '2'){
          moneda = 'CLP';
        } else if(element?.value === '3'){
          moneda = 'UF';
        }
      }
    });

    date.setUTCHours(0,0,0,0);
    const dateFormatted: string = date.toISOString();
    if(moneda === 'CLP'){
      this.obtenerDolar(dateFormatted, today, canal, sucursal, montoSolicitado, moneda); // actual
    } else if (moneda === 'UF'){
      this.obtenerUF(dateFormatted, today, canal, sucursal, montoSolicitado, moneda); //
    } else if (moneda === 'USD') {
      this.crearFichaComiteCredito(today, canal, sucursal, montoSolicitado, moneda)
    } else {
      this.alertService.error("Solicitud de Crédito - No se ha recuperado un tipo de moneda correcto.");
      this.spinner.hide();
    }
  }

  obtenerDolar(fecha: string, today: string, canal: string, sucursal: string, montoSolicitado: any = 0, moneda: string): void {
    let dolar = 0;
    this.spinner.show();
    this.solicitudCreditoService.obtenerDolar(fecha).subscribe(resp => { //"2021-12-30T00:00:00.000Z"
      dolar = resp?.valores?.[0]?.valor || 0;
      if(dolar > 0){
        this.spinner.hide();
        this.valorDolarActual = dolar;
        montoSolicitado = montoSolicitado / this.valorDolarActual;
        this.crearFichaComiteCredito(today, canal, sucursal, montoSolicitado, moneda)
      }
      else{
        this.alertService.error('Solicitud de Crédito - No se logró recuperar el valor del dólar.');
        console.error('Solicitud de Crédito - No se logró recuperar el valor del dólar.', resp?.valores?.[0]?.valor)
        this.valorDolarActual = dolar;
        this.spinner.hide();
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
      this.valorDolarActual = dolar;
      this.spinner.hide();
    });
  }

  obtenerUF(fecha: string, today: string, canal: string, sucursal: string, montoSolicitado: any = 0, moneda: string): void {
    let uf = 0;
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.calcularUF(fecha).subscribe(resp => { //"2021-12-30T00:00:00.000Z"
      uf = resp?.valores?.[0]?.valor || 0;
      if(uf > 0){
        this.spinner.hide();
        this.valorUFActual = uf;
        montoSolicitado = montoSolicitado * this.valorUFActual;
        this.obtenerDolar(fecha, today, canal, sucursal, montoSolicitado, moneda);
      }
      else{
        this.alertService.error('Solicitud de Crédito - No se logró recuperar el valor de la UF.');
        console.error('Solicitud de Crédito - No se logró recuperar el valor de la UF.', resp?.valores?.[0]?.valor)
        this.valorUFActual = uf;
        this.spinner.hide();
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
      this.valorUFActual = uf;
      this.spinner.hide();
    }));
    
  }

  crearFichaComiteCredito(today: string, canal: string, sucursal: string, montoSolicitado: any = 0, moneda: string): any {
    this.montoSolicitado = montoSolicitado;
    const dateInicio = new Date(this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades);
    const fechaInicio = `${dateInicio.getDate() < 10? '0'+(dateInicio.getDate()) : dateInicio.getDate()}-${(dateInicio.getMonth() + 1 < 10?  '0'+(dateInicio.getMonth() + 1) : 
    dateInicio.getMonth() + 1)}-${dateInicio.getFullYear()}`;

    this.montoSolicitado = montoSolicitado;

    this.fichaComiteCredito = {
      nombre: this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '',
      fecha: today, // fecha de hoy
      giro: this.reportesPrincipalesObj?.basicReport?.reporte?.Riesgo?.ActividadEconomica || '',
      rut: this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.Rut || '',
      canal: canal, // Stage 2
      sucursal: sucursal, // Stage 2
      pagina: '', // dato manual
      enrolado: this.utilsService.obtenerEnrolado(this.contratoServicios), // libro compra venta
      fechaInicio: this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades? fechaInicio : '',
      montoSolicitado: montoSolicitado, // Stage 2
      moneda: moneda
    }

    this.mapeoReportes();
  }

  crearTitularInforme(): void {
    const objeto: any = this.reportesPrincipalesObj;
    const mesesDeuda: any[] = objeto?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item || [];
    mesesDeuda.sort(function (a, b) {
      const [mesA, añoA] = a?.periodo?.split('-').map(Number);
      const [mesB, añoB] = b?.periodo?.split('-').map(Number);
    
      const dateA: any = new Date(añoA, mesA - 1);
      const dateB: any = new Date(añoB, mesB - 1);
    
      // Verifica si las fechas son válidas
      const isValidDateA = !isNaN(dateA.getTime());
      const isValidDateB = !isNaN(dateB.getTime());
    
      // Si dateA no es válida, colócala al final
      if (!isValidDateA && isValidDateB) return 1;
      // Si dateB no es válida, colócala al final
      if (isValidDateA && !isValidDateB) return -1;
      // Si ambas fechas no son válidas, mantén el orden original
      if (!isValidDateA && !isValidDateB) return 0;
    
      // Orden descendente
      return dateB - dateA;
    });
    const deudaReciente: any = mesesDeuda[0];

    let morosidad: any = 0;
    let deudaTotal: any = 0;
    let mesAnio: string = '';
    if(deudaReciente){
      morosidad = Number(deudaReciente?.dirvencida) + Number(deudaReciente?.dirven180mayor3year) + Number(deudaReciente?.dirmorosa);
      deudaTotal = Number(deudaReciente?.dirvigente);
      mesAnio = deudaReciente?.periodo;
    }
    else{
      morosidad = 0;
      deudaTotal = 0;
      mesAnio = '';
    }

    let motivo = '';
    if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.ProtestosMonto > 0){
      motivo += 'Protesto';
    }
    if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.MorosidadesMonto > 0){
      if(motivo.length > 0)
        motivo += ', '; 
      motivo += 'Morosidad';
    }
    if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto > 0){
      if(motivo.length > 0)
        motivo += ', ';
      motivo += 'Infraciones Laborales';
    }
    const numero = objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad;
    const monto = objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto;
    const objetoInformes = {
      numero: numero || numero == 0 ? numero : '',
      monto: monto || monto == 0 ? monto : '',
      motivo: motivo || '',
      morosidad: morosidad,
      deudaTotal: deudaTotal,
      mesAnio: mesAnio
    }
    
    this.titularInforme.push(objetoInformes);
  }

  // Funcion para generar datos de tablas de composicion de la sociedad e informes comerciales

  crearSociosComposicionInforme(entidad: string, isEntidadSocio: boolean): void { 
    const indexEntidadSocios = this.entidadesDerivadas?.findIndex((elemento: any) => elemento.entidad === entidad);
    let socios: any[] = [];
    if(indexEntidadSocios >= 0){
      socios = this.entidadesDerivadas[indexEntidadSocios].items;
    }

    if(this.reportesDerivadosObj[entidad]){
      for (const [key, value] of Object.entries(this.reportesDerivadosObj[entidad])) {
        const objeto: any = value;

        const mesesDeuda: any[] = objeto?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item || [];
        mesesDeuda.sort(function (a, b) {
          const [mesA, añoA] = a?.periodo?.split('-').map(Number);
          const [mesB, añoB] = b?.periodo?.split('-').map(Number);
        
          const dateA: any = new Date(añoA, mesA - 1);
          const dateB: any = new Date(añoB, mesB - 1);
        
          // Verifica si las fechas son válidas
          const isValidDateA = !isNaN(dateA.getTime());
          const isValidDateB = !isNaN(dateB.getTime());
        
          // Si dateA no es válida, colócala al final
          if (!isValidDateA && isValidDateB) return 1;
          // Si dateB no es válida, colócala al final
          if (isValidDateA && !isValidDateB) return -1;
          // Si ambas fechas no son válidas, mantén el orden original
          if (!isValidDateA && !isValidDateB) return 0;
        
          // Orden descendente
          return dateB - dateA;
        });
        const deudaReciente: any = mesesDeuda[0];

        let morosidad: any = 0;
        let deudaTotal: any = 0;
        let mesAnio: string = '';
        if(deudaReciente){
          morosidad = Number(deudaReciente?.dirvencida) + Number(deudaReciente?.dirven180mayor3year) + Number(deudaReciente?.dirmorosa);
          deudaTotal = Number(deudaReciente?.dirvigente);
          mesAnio = deudaReciente?.periodo;
        }
        else{
          morosidad = 0;
          deudaTotal = 0;
          mesAnio = '';
        }

        let nombreSocio = ''; 
        const indexSocio = socios.findIndex((elemento: any) => elemento.rut === key);
        if(indexSocio >= 0){
          const indexAtributo = socios[indexSocio].atributos.findIndex((elemento: any) => elemento.name.toUpperCase() === 'NOMBRE');
          if(indexAtributo >= 0){
            nombreSocio = socios[indexSocio].atributos[indexAtributo].value;
          }
        }
        const objetoComposicion = {
          socio: nombreSocio || '', // regla
          rut: key || '', // regla
          porcentaje: '', // ingreso amnual
          fs: '', // ingreso amnual
        }
        let motivo = '';
        if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.ProtestosMonto > 0){
          motivo += 'Protesto';
        }
        if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.MorosidadesMonto > 0){
          if(motivo.length > 0)
            motivo += ', '; 
          motivo += 'Morosidad';
        }
        if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto > 0){
          if(motivo.length > 0)
            motivo += ', ';
          motivo += 'Infraciones Laborales';
        }
        const numero = objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad;
        const monto = objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto;
        const objetoInformes = {
          numero: numero || numero == 0 ? numero : '',
          monto: monto || monto == 0 ? monto : '',
          motivo: motivo || '',
          morosidad: morosidad,
          entidad: entidad,
          deudaTotal: deudaTotal,
          mesAnio: mesAnio
        }
        if(isEntidadSocio){ // Si isEntidadSocio es true corresponde a la tabla de socios sino de empresas relacionadas
          this.sociosComposicion.push(objetoComposicion)
          this.sociosInformes.push(objetoInformes)
        } else {
          this.empresasRelacionadasComposicion.push(objetoComposicion)
          this.empresasRelacionadasInformes.push(objetoInformes)
        }
      }

    }
    
  }

  // Se obtienen los f22 mas actualizado de entre los reportes disponibles

  obtenerDatosF22(): any[] {
    const carpetaF22 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f22 || [];
    const historicoF22 = this.reportesPrincipalesObj?.f22?.reporte?.ListadoF22Reportes || [];
    const calculadoF22 = this.reportesPrincipalesObj?.f22BalancesRentas?.reporte?.Formulario22Reporte || {};

    const F22: any[] = [];
    carpetaF22.forEach((element: any) => {
      if(element) {
        element.FechaReporte = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(element);
      }
    });
    historicoF22.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });
    if(calculadoF22 && Object.keys(calculadoF22).length > 0){
      const elemento: any = calculadoF22?.Reporte;
      if(elemento) {
        elemento.FechaReporte = calculadoF22?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(elemento);
      }
    }

    const ordenadoF22 = F22.sort((a: any,b: any) => Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo)); // mayor a menor en año
    const F22Limpio: any[] = [];

    ordenadoF22.forEach((element: any) => {
      if(element?.calculado?.periodo){
        const indexRepetido = F22Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo));
        if(indexRepetido >= 0){
          if(F22Limpio[indexRepetido]?.FechaReporte < element.FechaReporte){
            F22Limpio[indexRepetido] = element;
          }
        } else {
          F22Limpio.push(element);
        }
      
      }
    });
    return F22Limpio;
  }

  // Se obtienen los f29 mas actualizado de entre los reportes disponibles

  obtenerDatosF29(): any[] {
    const carpetaF29 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f29 || [];
    const historicoF29 = this.reportesPrincipalesObj?.f29?.reporte?.ListadoF29Reportes || [];
    const calculadoF29 = this.reportesPrincipalesObj?.f29Ventas?.reporte?.Formulario29Reporte?.Reporte || {};
    
    const F29: any[] = [];
    carpetaF29.forEach((element: any) => {
      if(!element?.message){
        F29.push(element);
      }
    });
    historicoF29.forEach((element: any) => {
      if(!element?.Formulario29Reporte?.Reporte.message){
        F29.push(element?.Formulario29Reporte?.Reporte);
      }
    });
    if(calculadoF29 && Object.keys(calculadoF29).length > 0 && !calculadoF29?.message){
      F29.push(calculadoF29);
    }

    const ordenadoF29 = F29.sort((a: any,b: any) => { // mayor a menor en año y menor a mayor en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b.calculado.periodo) - Number(a.calculado.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(a.calculado.mes) - Number(b.calculado.mes);
    });
    const F29Limpio: any[] = [];

    ordenadoF29.forEach((element: any) => {
      const indexRepetido = F29Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo) && Number(e?.calculado?.mes) === Number(element?.calculado?.mes));
      if(indexRepetido >= 0){
        if(F29Limpio[indexRepetido]?.fecha_ingreso < element.fecha_ingreso){
          F29Limpio[indexRepetido] = element;
        }
      } else {
        F29Limpio.push(element);
      }
    })

    return F29Limpio;
  }

  cambioCLPADolar(valor: any, valorDolar: any): number{
    return Number(valor) / Number(valorDolar);
  }

  cambioUFAPesos(cantidadUF: any, valorUF: any): number{
    return Number(cantidadUF) * Number(valorUF);
  }

  validaMonedaBalance(f22: any, dolar: number, fechaDolar: string, tipoBalance: string, balanceManual: any): void {
    const moneda: string = tipoBalance === 'manual' ? balanceManual?.BalanceManualReporte?.Reporte?.moneda : f22?.calculado?.moneda;
    const fechaFormatted: string = this.formatterFecha(fechaDolar);
    const data: any = {
      construccion: f22?.calculado?.sector?.toUpperCase() === 'CONSTRUCCIÓN',
      periodo: tipoBalance == 'manual' ? balanceManual?.BalanceManualReporte?.Reporte?.periodo : f22?.calculado?.periodo,
      ingresos: 0,
      patrimonio: 0,
      capital: 0,
      margen: 0,
      ebitda: 0,
      maniobra: 0,
      liquidez: 0,
      endeudamiento: 0,
      dfnEbitda: 0,
      ebitdaGastos: 0,
      dfn: 0,
      total: 0,
      tipoBalanceManual: tipoBalance == 'manual' ? ((balanceManual?.BalanceManualReporte?.Reporte?.tipo || '') + ' ' + (balanceManual?.BalanceManualReporte?.Reporte?.subTipo || '')) : ''
    }

    if(tipoBalance === 'automatico'){

      const sbifPrincipal: any[] = this.reportesPrincipalesObj?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item || [];
      const arraySbif: any[] = sbifPrincipal.filter(e => e.periodo.includes(data.periodo));

      let sbifReciente: any = null;
      if(arraySbif.length <= 0) {
        if(sbifPrincipal.length > 0){
          
          // Ordena el array en base a la distancia de cada elemento al periodo buscado
          sbifPrincipal.sort((a, b) => {
            const [mes1, anio1] = a?.periodo?.split("-");
            const distancia1 = Math.abs(12 * (Number(data.periodo) - anio1) - parseInt(mes1) + 12);

            const [mes2, anio2] = b?.periodo?.split("-");
            const distancia2 = Math.abs(12 * (Number(data.periodo) - anio2) - parseInt(mes2) + 12);

            return distancia1 - distancia2;
          });

          sbifReciente = sbifPrincipal[0];
        }
      } else {
        arraySbif.sort(function (a, b) {
          // Extraer el mes y el año de cada objeto
          const periodA = a?.periodo?.split("-");
          const periodB = b?.periodo?.split("-");
        
          // Convertir el mes y el año en números
          const monthA = parseInt(periodA[0]);
          const yearA = parseInt(periodA[1]);
          const monthB = parseInt(periodB[0]);
          const yearB = parseInt(periodB[1]);
        
          // Comparar primero por año
          if (yearA !== yearB) {
              return yearB - yearA;
          }
        
          // Comparar luego por mes
          return monthB - monthA;
        });
    
        sbifReciente = arraySbif[0];
      }

      const dirvigente = sbifReciente?.dirvigente ? (Number(sbifReciente?.dirvigente) * 1000) : 0;
      const dirvencida = sbifReciente?.dirvencida ? (Number(sbifReciente?.dirvencida) * 1000) : 0;
      const dirven180mayor3year = sbifReciente?.dirven180mayor3year ? (Number(sbifReciente?.dirven180mayor3year) * 1000) : 0;
      const dirmorosa = sbifReciente?.dirmorosa ? (Number(sbifReciente?.dirmorosa) * 1000) : 0;

      data.liquidez = Number(f22?.calculado?.razonCorriente) || 0;
      data.dfnEbitda = (((dirvigente + dirvencida + dirven180mayor3year + dirmorosa) - Number(f22?.calculado?.disponible)) / Number(f22?.calculado?.ebitda)) || 0;
      data.endeudamiento = ((Number(f22?.calculado?.totalpasivoexigible) / Number(f22?.calculado?.totalactivos)) * 100) || 0;
      data.ebitdaGastos = Number(f22?.calculado?.ebitdagf) || 0;
      data.dfn = ((((dirvigente + dirvencida + dirven180mayor3year + dirmorosa) - Number(f22?.calculado?.disponible)) / Number(f22?.calculado?.totalpatrimonio)) * 100) || 0;
      data.total = Number(f22?.calculado?.leverage) || 0;

      if(moneda.toUpperCase() === 'PESOS'){
        // se debe convertir a dolares
        data.ingresos = this.cambioCLPADolar(Number(f22?.calculado?.ingresosdelaExplotacion) / 1000, dolar) || 0;
        data.patrimonio = this.cambioCLPADolar(Number(f22?.calculado?.totalpatrimonio) / 1000, dolar) || 0;
        data.capital = this.cambioCLPADolar(Number(f22?.calculado?.capitalPagado) / 1000, dolar) || 0;
        data.margen = this.cambioCLPADolar(Number(f22?.calculado?.resultadobruto) / 1000, dolar) || 0;
        data.ebitda = this.cambioCLPADolar(Number(f22?.calculado?.ebitda) / 1000, dolar) || 0;
        data.maniobra = this.cambioCLPADolar(Number(f22?.calculado?.capitaldeTrabajo) / 1000, dolar) || 0;

        this.crearInformacionFinanciera(dolar, fechaDolar, tipoBalance, data);

      } else if(moneda.toUpperCase() === 'DOLARES'){
        // ya esta en dolares pasar a miles
        data.ingresos = Number(f22?.calculado?.ingresosdelaExplotacion) / 1000 || 0;
        data.patrimonio = Number(f22?.calculado?.totalpatrimonio) / 1000 || 0;
        data.capital = Number(f22?.calculado?.capitalPagado) / 1000 || 0;
        data.margen = Number(f22?.calculado?.resultadobruto) / 1000 || 0;
        data.ebitda = Number(f22?.calculado?.ebitda) / 1000 || 0;
        data.maniobra = Number(f22?.calculado?.capitaldeTrabajo) / 1000 || 0;

        this.crearInformacionFinanciera(dolar, fechaDolar, tipoBalance, data);

      } else if(moneda.toUpperCase() === 'EUROS') {
        this.solicitudCreditoService.obtenerEuro(fechaFormatted).subscribe(resp => {
          const euro = resp?.valores?.[0]?.valor || 0;
          if(euro > 0){
            // se debe convertir a pesos y luego a dolares
            const ingresosPesos = this.cambioCLPADolar(Number(f22?.calculado?.ingresosdelaExplotacion), euro) || 0;
            const patrimonioPesos = this.cambioCLPADolar(Number(f22?.calculado?.totalpatrimonio), euro) || 0;
            const capitalPesos = this.cambioCLPADolar(Number(f22?.calculado?.capitalPagado), euro) || 0;
            const margenPesos = this.cambioCLPADolar(Number(f22?.calculado?.resultadobruto), euro) || 0;
            const ebitdaPesos = this.cambioCLPADolar(Number(f22?.calculado?.ebitda), euro) || 0;
            const maniobraPesos = this.cambioCLPADolar(Number(f22?.calculado?.capitaldeTrabajo), euro) || 0;

            data.ingresos = this.cambioCLPADolar(Number(ingresosPesos) / 1000, dolar) || 0;
            data.patrimonio = this.cambioCLPADolar(Number(patrimonioPesos) / 1000, dolar) || 0;
            data.capital = this.cambioCLPADolar(Number(capitalPesos) / 1000, dolar) || 0;
            data.margen = this.cambioCLPADolar(Number(margenPesos) / 1000, dolar) || 0;
            data.ebitda = this.cambioCLPADolar(Number(ebitdaPesos) / 1000, dolar) || 0;
            data.maniobra = this.cambioCLPADolar(Number(maniobraPesos) / 1000, dolar) || 0;

            this.crearInformacionFinanciera(dolar, fechaDolar, tipoBalance, data);
          }
        },
        (error: any) => {
          this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
        });
      } else {
        this.crearInformacionFinanciera(dolar, fechaDolar, tipoBalance, data);
      }
    } else { // manual
      data.liquidez = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.razoncorriente) || 0;
      data.endeudamiento = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.leverage) || 0;
      data.dfnEbitda = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.dfebitda) || 0;
      data.ebitdaGastos = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.ebitdagf) || 0;
      data.dfn = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.leveragefinanciero) || 0;
      data.total = (Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.pasivo) / Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.patrimonio)) || 0;

      switch(moneda.toUpperCase()){
        case 'M$':
          // se debe convertir a dolares
          data.ingresos = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.ingresodeactiviadesordinariaser) / 1000, dolar) || 0;
          data.patrimonio = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.patrimonio) / 1000, dolar) || 0;
          data.capital = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.capitalemitidop) / 1000, dolar) || 0;
          data.margen = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.gananciabruta) / 1000, dolar) || 0;
          data.ebitda = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.ebitda) / 1000, dolar) || 0;
          data.maniobra = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.fondomaniobra) / 1000, dolar) || 0;

          this.crearInformacionFinanciera(dolar, fechaDolar, tipoBalance, data);
          break;
        case 'UF':
          // se debe convertir a pesos y luego a dolares
          this.solicitudCreditoService.calcularUF(fechaFormatted).subscribe(resp => {
            const uf = resp?.valores?.[0]?.valor || 0;
            if(uf > 0){
              // pasar a pesos
              const ingresosPesos = this.cambioUFAPesos(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.ingresodeactiviadesordinariaser), uf) || 0;
              const patrimonioPesos = this.cambioUFAPesos(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.patrimonio), uf) || 0;
              const capitalPesos = this.cambioUFAPesos(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.capitalemitidop), uf) || 0;
              const margenPesos = this.cambioUFAPesos(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.gananciabruta), uf) || 0;
              const ebitdaPesos = this.cambioUFAPesos(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.ebitda), uf) || 0;
              const maniobraPesos = this.cambioUFAPesos(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.fondomaniobra), uf) || 0;
              // pasar a miles de dolares
              data.ingresos = this.cambioCLPADolar(Number(ingresosPesos) / 1000, dolar) || 0;
              data.patrimonio = this.cambioCLPADolar(Number(patrimonioPesos) / 1000, dolar) || 0;
              data.capital = this.cambioCLPADolar(Number(capitalPesos) / 1000, dolar) || 0;
              data.margen = this.cambioCLPADolar(Number(margenPesos) / 1000, dolar) || 0;
              data.ebitda = this.cambioCLPADolar(Number(ebitdaPesos) / 1000, dolar) || 0;
              data.maniobra = this.cambioCLPADolar(Number(maniobraPesos) / 1000, dolar) || 0;

              this.crearInformacionFinanciera(dolar, fechaDolar, tipoBalance, data);
            }
          },
          (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
          });
          break;
        case 'USD':
          // ya esta en dolares
          data.ingresos = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.ingresodeactiviadesordinariaser)/1000 || 0;
          data.patrimonio = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.patrimonio)/1000 || 0;
          data.capital = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.capitalemitidop)/1000 || 0;
          data.margen = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.gananciabruta)/1000 || 0;
          data.ebitda = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.ebitda)/1000 || 0;
          data.maniobra = Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.fondomaniobra)/1000 || 0;
          
          this.crearInformacionFinanciera(dolar, fechaDolar, tipoBalance, data);
          break;
        case 'EUR':
          // se debe convertir a pesos y luego a dolares
          this.solicitudCreditoService.obtenerEuro(fechaFormatted).subscribe(resp => {
            const euro = resp?.valores?.[0]?.valor || 0;
            if(euro > 0){
              // pasar a pesos
              const ingresosPesos = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.ingresodeactiviadesordinariaser), euro) || 0;
              const patrimonioPesos = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.patrimonio), euro) || 0;
              const capitalPesos = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.capitalemitidop), euro) || 0;
              const margenPesos = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.gananciabruta), euro) || 0;
              const ebitdaPesos = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.ebitda), euro) || 0;
              const maniobraPesos = this.cambioCLPADolar(Number(balanceManual?.BalanceManualReporte?.Reporte?.balance?.fondomaniobra), euro) || 0;
              // pasar a miles de dolares
              data.ingresos = this.cambioCLPADolar(Number(ingresosPesos) / 1000, dolar) || 0;
              data.patrimonio = this.cambioCLPADolar(Number(patrimonioPesos) / 1000, dolar) || 0;
              data.capital = this.cambioCLPADolar(Number(capitalPesos) / 1000, dolar) || 0;
              data.margen = this.cambioCLPADolar(Number(margenPesos) / 1000, dolar) || 0;
              data.ebitda = this.cambioCLPADolar(Number(ebitdaPesos) / 1000, dolar) || 0;
              data.maniobra = this.cambioCLPADolar(Number(maniobraPesos) / 1000, dolar) || 0;
              this.crearInformacionFinanciera(dolar, fechaDolar, tipoBalance, data);
            }
          },
          (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
          });
          break;
        default:
          this.crearInformacionFinanciera(dolar, fechaDolar, tipoBalance, data);
          break;

      }
    }
  }

  crearInformacionFinanciera(dolar: number, fechaDolar: string, tipoBalance: string, data: any): void {
    let montoSolicitado: number = 0;
    montoSolicitado = Number(this.montoSolicitado) / 1000;

    if(!data?.construccion){
      this.informacionFinanciera = {
        tipoBalance: tipoBalance,
        tipoBalanceManual: data?.tipoBalanceManual, 
        valorDolar: dolar,
        fechaValorDolar: fechaDolar,
        construccion: data?.construccion,
        periodo: data?.periodo,
        ingresos: {
          monto: data?.ingresos, cumple: data?.ingresos > Number(montoSolicitado) ? "Si" : "No"
        },
        patrimonio: {
          monto: data?.patrimonio, cumple: data?.patrimonio > 0 ? "Si" : "No"
        },
        capital: {
          monto: data?.capital, cumple: "-"
        },
        margen: {
          monto: data?.margen, cumple: data?.margen > 0 ? "Si" : "No"
        },
        ebitda: {
          monto: data?.ebitda, cumple: data?.ebitda > 0 ? "Si" : "No"
        },
        maniobra: {
          monto: data?.maniobra, cumple: data?.maniobra > 0 ? "Si" : "No"
        },
        liquidez: {
          monto: data?.liquidez, cumple: data?.liquidez > 1 ? "Si" : "No"
        },
        endeudamiento: {
          monto: data?.endeudamiento, cumple: data?.endeudamiento < 60 ? "Si" : "No"
        },
        dfnEbitda: {
          monto: data?.dfnEbitda, cumple: (data?.dfnEbitda < 3.5 && data?.dfnEbitda >= 0) ? "Si" : "No"
        },
        ebitdaGastos: {
          monto: data?.ebitdaGastos, cumple: data?.ebitdaGastos > 1 ? "Si" : "No"
        },
        dfn: {
          monto: data?.dfn, cumple: (data?.dfn < 100 && data?.dfn >= 0) ? "Si" : "No"
        },
        total: {
          monto: data?.total, cumple: (data?.total < 1.5 && data?.total >= 0) ? "Si" : "No"
        }
      };
    } else {
      this.informacionFinanciera = {
        tipoBalance: tipoBalance,
        tipoBalanceManual: data?.tipoBalanceManual, 
        valorDolar: dolar,
        fechaValorDolar: fechaDolar,
        construccion: data?.construccion,
        periodo: data?.periodo,
        ingresos: {
          monto: data?.ingresos, cumple: data?.ingresos > Number(montoSolicitado) ? "Si" : "No"
        },
        patrimonio: {
          monto: data?.patrimonio, cumple: data?.patrimonio > 0 ? "Si" : "No"
        },
        capital: {
          monto: data?.capital, cumple: "-"
        },
        margen: {
          monto: data?.margen, cumple: data?.margen > 0 ? "Si" : "No"
        },
        ebitda: {
          monto: data?.ebitda, cumple: data?.ebitda > 0 ? "Si" : "No"
        },
        maniobra: {
          monto: data?.maniobra, cumple: data?.maniobra > 0 ? "Si" : "No"
        },
        liquidez: {
          monto: data?.liquidez, cumple: data?.liquidez > 1 ? "Si" : "No"
        },
        endeudamiento: {
          monto: data?.endeudamiento, cumple: data?.endeudamiento < 40 ? "Si" : "No"
        },
        dfnEbitda: {
          monto: data?.dfnEbitda, cumple: (data?.dfnEbitda < 3 && data?.dfnEbitda >= 0) ? "Si" : "No"
        },
        ebitdaGastos: {
          monto: data?.ebitdaGastos, cumple: data?.ebitdaGastos > 1.5 ? "Si" : "No"
        },
        dfn: {
          monto: data?.dfn, cumple: (data?.dfn < 80 && data?.dfn >= 0) ? "Si" : "No"
        },
        total: {
          monto: data?.total, cumple: (data?.total < 1.5 && data?.total >= 0) ? "Si" : "No"
        }
      };
    }
  }

  formatterFecha(fechaEntrada: string): string {
    let fechaFormatted = '';
    if(fechaEntrada.includes("/")) {
      const dateArray = fechaEntrada.split("/");  
      const fecha = new Date(parseInt(dateArray[2]),parseInt(dateArray[1])-1,parseInt(dateArray[0]));
      fecha.setUTCHours(0,0,0,0);
      fechaFormatted = fecha.toISOString()
    } else if (fechaEntrada.includes("T") && fechaEntrada.includes("Z")) {
      const fecha = new Date(fechaEntrada);
      fecha.setUTCHours(0,0,0,0);
      fechaFormatted = fecha.toISOString()
    } else if (fechaEntrada.includes("-")) {
      const dateArray = fechaEntrada.split("-");  
      const fecha = new Date(parseInt(dateArray[2]),parseInt(dateArray[1])-1,parseInt(dateArray[0]));
      fecha.setUTCHours(0,0,0,0);
      fechaFormatted = fecha.toISOString()
    }
    return fechaFormatted;
  }

  ultimoDiaMes(mes: number, anio: number) : number {
    return new Date(anio, mes, 0).getDate();
  }

  getTipoF22(f22Fecha: string, balanceManual: any): string {
    const periodoBalanceManual = balanceManual?.BalanceManualReporte?.Reporte?.periodo || '';
    const mesFinBalanceManual = balanceManual?.BalanceManualReporte?.Reporte?.mesFin || '';
    if(!periodoBalanceManual && !mesFinBalanceManual && f22Fecha){
      return "automatico";
    }
    const balanceManualFecha = `${this.ultimoDiaMes(Number(mesFinBalanceManual), Number(periodoBalanceManual))}/${mesFinBalanceManual}/${periodoBalanceManual}`;
    if(!f22Fecha && balanceManualFecha){
      return "manual";
    }

    if(f22Fecha && balanceManualFecha){
      const dateArrayF22 = f22Fecha.split("/");  
      const dateArrayManual = balanceManualFecha.split("/");  
      const fechaF22 = new Date(parseInt(dateArrayF22[2]),parseInt(dateArrayF22[1])-1,parseInt(dateArrayF22[0]));
      const fechaManual = new Date(parseInt(dateArrayManual[2]),parseInt(dateArrayManual[1])-1,parseInt(dateArrayManual[0]));

      if(fechaF22 > fechaManual){
        return "automatico";
      } else {
        return "manual";
      }
    }
    return '';
  }

  getFechaManualBalance(balanceManual: any): string {
    const periodoBalanceManual = balanceManual?.BalanceManualReporte?.Reporte?.periodo || '';
    const mesFinBalanceManual = balanceManual?.BalanceManualReporte?.Reporte?.mesFin || '';
    const balanceManualFecha = `${this.ultimoDiaMes(Number(mesFinBalanceManual), Number(periodoBalanceManual))}/${mesFinBalanceManual}/${periodoBalanceManual}`;
    return balanceManualFecha;
  }

  async consultarDolarInformacionFinanciera(f22: any): Promise<void> {
    let dolar: number = 0;

    const tipoBalance = this.getTipoF22(f22?.calculado?.fecha, this.balanceManual[0]);
    this.tipoBalance = tipoBalance;
    const fechaBalance = tipoBalance == 'manual' ? this.getFechaManualBalance(this.balanceManual[0]) : f22?.calculado?.fecha || '';

    // se debe validar por f22 (f22?.calculado?.fecha) y por balance manual, segun cual es el mas actualizado se usara en el calculo****
    if((fechaBalance) && this.montoSolicitado > 0){ // CAMBIAR POR MONTO SOLICITADO
      this.spinner.show();
      const fechaFormatted = this.formatterFecha(fechaBalance); // se debe enviar la fecha del f22 o balance manual segun cual es el mas actualizado

      await this.subscriptions.push(this.solicitudCreditoService.obtenerDolar(fechaFormatted).subscribe(resp => { //"2021-12-30T00:00:00.000Z"
        dolar = resp?.valores?.[0]?.valor || 0;
        if(dolar > 0){
          this.spinner.hide();
          this.validaMonedaBalance(f22, dolar, fechaBalance, tipoBalance, this.balanceManual[0]);
        }
        else{
          this.alertService.error('Solicitud de Crédito - No se logró recuperar el valor del dólar para la tabla de información financiera');
          console.error('Solicitud de Crédito - No se logró recuperar el valor del dólar para la tabla de información financiera ', resp?.valores?.[0]?.valor)
          this.spinner.hide();
        }
      }, (error: any) => {
        this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
        this.spinner.hide();
      }));
    }
    
  }

  getBalanceManual(): void {
    const listadoBalances: any = [];
  
    if(this.reportesPrincipalesObj?.balanceManual?.reporte?.listadoBalances && this.reportesPrincipalesObj?.balanceManual?.reporte?.listadoBalances.length > 0) {
      listadoBalances.push(...this.reportesPrincipalesObj?.balanceManual?.reporte?.listadoBalances);
    }
    
    if(this.reportesPrincipalesObj?.preBalanceManual?.reporte?.listadoPreBalances && this.reportesPrincipalesObj?.preBalanceManual?.reporte?.listadoPreBalances.length > 0) {
      listadoBalances.push(...this.reportesPrincipalesObj?.preBalanceManual?.reporte?.listadoPreBalances);
    }
    
    if(!listadoBalances || listadoBalances.length === 0){
      return;
    }
  
    const sortedBalances = listadoBalances.sort((a: any, b:any) => {
      const periodoA = a?.BalanceManualReporte?.Reporte?.periodo || '';
      const periodoB = b?.BalanceManualReporte?.Reporte?.periodo || '';
      const mesInicioA = a?.BalanceManualReporte?.Reporte?.mesInicio || 0;
      const mesInicioB = b?.BalanceManualReporte?.Reporte?.mesInicio || 0;
      const mesFinA = a?.BalanceManualReporte?.Reporte?.mesFin || 0;
      const mesFinB = b?.BalanceManualReporte?.Reporte?.mesFin || 0;
  
      // Si ambos periodos están vacíos, mantén el orden original
      if (periodoA === '' && periodoB === '') {
        return 0;
      } else if (periodoA === '') {
        return 1; // Coloca el balance con periodo vacío al final
      } else if (periodoB === '') {
        return -1; // Coloca el balance con periodo vacío al final
      }
  
      // Ordena por periodo en orden descendente
      if (periodoA !== periodoB) {
        return parseInt(periodoB) - parseInt(periodoA);
      }
  
      // Ordena por mesFin en orden descendente
      if (mesFinA !== mesFinB) {
        return mesFinB - mesFinA;
      }
  
      // Si mesFin es igual, da prioridad a los balances que cubren un año completo
      const isYearCompleteA = mesInicioA === 1 && mesFinA === 12;
      const isYearCompleteB = mesInicioB === 1 && mesFinB === 12;
  
      if (isYearCompleteA && !isYearCompleteB) {
        return -1;
      } else if (!isYearCompleteA && isYearCompleteB) {
        return 1;
      }
  
      // Si ambos cubren un año completo o ninguno lo hace, mantén el orden original
      return 0;
    });
  
    if(sortedBalances.length > 0){
      this.dataManualCargada = true;
      this.balanceManual = [sortedBalances[0]];
    }
  }

  // Mapeo y generacion de objetos para tablas

  mapeoReportes(): void {
    this.getBalanceManual();
    const F22Limpio = this.obtenerDatosF22(); // Funcion donde hace la logica
    const F29Limpio = this.obtenerDatosF29();
    /* FIN Logica de obtencion de datos de F22 y F29 */

    this.consultarDolarInformacionFinanciera(F22Limpio[0]);

    let anioActual = new Date().getFullYear();
    let anioAnterior = 0;
    
    if(F22Limpio.length > 1){
      anioActual = Number(F22Limpio[0]?.calculado?.periodo);
      anioAnterior = Number(F22Limpio[1]?.calculado?.periodo);
    } else {
      if (F22Limpio.length > 0){
        anioActual = Number(F22Limpio[0]?.calculado?.periodo)
      }
      else {
        anioActual = new Date().getFullYear();
      }
      anioAnterior = anioActual - 1;
    }

    let anioActualVentas = new Date().getFullYear();
    let anioAnteriorVentas = Number(anioActualVentas - 1);

    if(F29Limpio.length > 0){
      const periodos = F29Limpio
      .filter(objeto => objeto && objeto.periodo)
      .map(objeto => objeto.periodo);

      periodos.sort((a, b) => b - a);

      const periodosLimpio = periodos.filter((valor, indice, array) => {
        return array.indexOf(valor) === indice;
      });

      const dosPeriodosMasRecientes = periodosLimpio.slice(0, 2);

      if(dosPeriodosMasRecientes[0]){
        anioActualVentas = dosPeriodosMasRecientes[0];
      } else {
        if(dosPeriodosMasRecientes[1]){
          anioActualVentas = (Number(dosPeriodosMasRecientes[1]) + 1)
        }
      }
      if(dosPeriodosMasRecientes[1]){
        anioAnteriorVentas = dosPeriodosMasRecientes[1];
      } else {
        if(dosPeriodosMasRecientes[0]){
          anioAnteriorVentas = (Number(dosPeriodosMasRecientes[0]) - 1);
        }
      }

    }

    const { totalesInformacionFinanciera, informacionFinancieraTable } = this.utilsService.crearInformacionFinancieraTable(anioActualVentas, anioAnteriorVentas, F29Limpio);
    this.totalesInformacionFinanciera = totalesInformacionFinanciera;
    this.informacionFinancieraTable = informacionFinancieraTable;
    
    const f22ActualIndex = F22Limpio?.findIndex((elemento: any) => Number(elemento?.calculado?.periodo) === anioActual);
    let f22Actual: any = null;

    if(f22ActualIndex >= 0){
      f22Actual = F22Limpio[f22ActualIndex] || null;
    }

    this.crearTitularInforme();
    this.consolidadoTitular = this.utilsService.crearConsolidadoTitular(F22Limpio, F29Limpio, this.reportesPrincipalesObj);
    this.crearSociosComposicionInforme('socios', true); // crea las tablas composicion de sociedad para socios e informes comerciales para socios
    this.crearSociosComposicionInforme('empresasRelacionadas', false); // crea las tablas composicion de sociedad para empresa e informes comerciales para empresa -> se debe cambiar socios por el que corresponda

    this.initForm();
    this.setCompareBalances();
    
    this.showReportes = true;
    this.spinner.hide();
  }

  // Logicas de Formulario y funciones

  // Se inicializa el formulario de los datos de las cabeceras con datos a ingresar

  initForm(): void {
    if(this.stepper === 5 || this.plantillaNoEditable === true){
      this.servicioFichaForm = this.formBuilder.group({
        nombreComite: [{value: this.servicioFichaFormSaved?.nombreComite || this.fichaComiteCredito?.nombre || '', disabled: true}, [Validators.required]],
        fechaComite: [{value: this.servicioFichaFormSaved?.fechaComite || this.fichaComiteCredito?.fecha || '', disabled: true}, [Validators.required]],
        giroComite: [{value: this.servicioFichaFormSaved?.giroComite || this.fichaComiteCredito?.giro || '', disabled: true}, [Validators.required]],
        rutComite: [{value: this.servicioFichaFormSaved?.rutComite || this.fichaComiteCredito?.rut, disabled: true}, [Validators.required, gtpRutValidator()]],
        inicioSiiComite: [{value: this.servicioFichaFormSaved?.inicioSiiComite || this.fichaComiteCredito?.fechaInicio || '', disabled: true}, [Validators.required]],
        montoSolicitado: [{value: this.servicioFichaFormSaved?.montoSolicitado || this.fichaComiteCredito?.montoSolicitado || '', disabled: true}, [Validators.required]],
        moneda: [{value: this.servicioFichaFormSaved?.moneda || this.fichaComiteCredito?.moneda || '', disabled: true}, [Validators.required]]
      });

    } else {
      this.servicioFichaForm = this.formBuilder.group({
        nombreComite: [{value: this.servicioFichaFormSaved?.nombreComite || this.fichaComiteCredito?.nombre || '', disabled: true}, [Validators.required]],
        fechaComite: [{value: this.servicioFichaFormSaved?.fechaComite || this.fichaComiteCredito?.fecha || '', disabled: true}, [Validators.required]],
        giroComite: [{value: this.servicioFichaFormSaved?.giroComite || this.fichaComiteCredito?.giro || '', disabled: true}, [Validators.required]],
        rutComite: [{value: this.servicioFichaFormSaved?.rutComite || this.fichaComiteCredito?.rut, disabled: true}, [Validators.required, gtpRutValidator()]],
        inicioSiiComite: [{value: this.servicioFichaFormSaved?.inicioSiiComite || this.fichaComiteCredito?.fechaInicio || '', disabled: true}, [Validators.required]],
        montoSolicitado: [{value: this.servicioFichaFormSaved?.montoSolicitado || this.fichaComiteCredito?.montoSolicitado || '', disabled: true}, [Validators.required]],
        moneda: [{value: this.servicioFichaFormSaved?.moneda || this.fichaComiteCredito?.moneda || '', disabled: true}, [Validators.required]]
      });
    }
  }

  onChanges(): void {
    this.servicioFichaForm.valueChanges.subscribe(val => {
      this.activateRutComiteHelper = rutValidate(val.rutComite) ? false : true;
    });
  }

  // Valida el tipo de dato si es correcto segun los formatos establecidos, si lo es devuelve el valor sino devuelve nulo

  validaFormatoElemento(value: any, key: string, elementoHTML: any, indexHTML: string): number | string | null {
    let formatos: any = {}
    if(indexHTML !== 'linea-'){
      formatos = this.formatos;
    } else {
      formatos = this.formatosLinea;
    }
    if(formatos[key]){
      if (formatos[key] === 'decimal') {
        if(typeof(value) === 'string'){
          if(!value.includes(',') && !value.includes('.')){ // valores enteros incluye el ,0 al final
            let element: any = parseFloat(value);
            element = element.toFixed(1); // lo deja como string para numeros como 2 -> '2.0' y no 2.0
            if(!(typeof(element) === 'number') && isNaN(element)){
              if(elementoHTML){
                elementoHTML.className = elementoHTML.className + " error-input";
                elementoHTML.title = 'Error de Formato - Debe ser un número entero o decimal';
              }
              return null;
            } else {
              element = element.toString().replace('.', ',');
              return element;
            }
          } else {
            if(!value.includes('.')){ // valores con formato x,xx
              let element: any = parseFloat(value.replace(',', '.'));              
              if(!(this.utilsService.isFloat(element))){
                if (typeof(element) === 'number'){
                  element = element.toFixed(1);
                  element = element.toString().replace('.', ',');
                  return element;
                } else {
                  if(elementoHTML){
                    elementoHTML.className = elementoHTML.className + " error-input";
                    elementoHTML.title = 'Error de Formato - Debe ser un número entero o decimal';
                  }
                  return null;
                }
              } else {
                element = element.toString().replace('.', ',');
                return element;
              }
            } else { // valores con formato x.xx
              let element: any = parseFloat(value);
              if(isNaN(element)){
                if(elementoHTML){
                  elementoHTML.className = elementoHTML.className + " error-input";
                  elementoHTML.title = 'Error de Formato - Debe ser un número entero o decimal';
                }
                return null;
              } else {
                element = value.toString().replace('.', ',');
                return element;
              }
            }
          }
        } 

        if(value === null) {
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className + " error-input";
            elementoHTML.title = 'Error de Formato - Debe ser un número entero o decimal';
          }
        }
      }
      if(formatos[key] === 'si-no'){
        const element = String(value).toUpperCase().trim();
        if(!(element === 'SI' || element === 'NO')){
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className + " error-input";
            elementoHTML.title = 'Error de Formato - Debe ser un valor Si o No';
          }
          return null;
        } else {
          return element;
        }
      }
      if (formatos[key] === 'numero') {
        if(value !== null){
          if(typeof(value) === 'string'){
            let element: any = parseInt(value);
            element = Math.round(element);
            if(!(typeof(element) === 'number') && isNaN(element)){
              if(elementoHTML){
                elementoHTML.className = elementoHTML.className + " error-input";
                elementoHTML.title = 'Error de Formato - Debe ser un número entero';
              }
              return null;
            } else {
              return element;
            }
          } else if (typeof(value) === 'number'){
            const element = Math.round(value);
            if(!(typeof(element) === 'number')){
              if(elementoHTML){
                elementoHTML.className = elementoHTML.className + " error-input";
                elementoHTML.title = 'Error de Formato - Debe ser un número entero';
              }
              return null;
            } else {
              return element;
            }
          } else {
            if(elementoHTML){
              elementoHTML.className = elementoHTML.className + " error-input";
              elementoHTML.title = 'Error de Formato - Debe ser un número entero';
            }
            return null;
          }
        } else {
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className + " error-input";
            elementoHTML.title = 'Error de Formato - Debe ser un número entero';
          }
          return null;
        }
      }
      if(formatos[key] === 'text' && (value === '' || value === null)){
        if(elementoHTML){
          elementoHTML.className = elementoHTML.className + " error-input";
          elementoHTML.title = 'Error de Formato - No debe ser un valor vacio';
        }
        return null;
      }
    }
    return value;
  }

  // Valida objeto de entrada y si tiene un error en un campo lo marca en la vista

  validaObjeto(obj: any, idElement: string, index: number, htmlKey: string = ''): boolean {
    if (typeof(obj) === 'object' && !Array.isArray(obj)) {
      let hasData = false;
      let hasEmptyData = false;
      let hasFormatDataError = false;
      const arrayInputsVacios: string[] = [];
      for(const key in obj) {
        if (obj[key] !== null && typeof(obj[key]) === 'object' && !Array.isArray(obj[key])) {
          if(!this.validaObjeto(obj[key], idElement, index, key)){
            hasFormatDataError = true;
            hasData = true;
          }
        } else {
          const elementoHTML = document.getElementById(idElement + htmlKey + key + index);
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className.replace(" error-input", "");
            elementoHTML.title = '';
          }
          if(String(obj[key]) === "" && elementoHTML !== null) { // Dato vacio
            hasEmptyData = true;
            if(elementoHTML){
              elementoHTML.className = elementoHTML.className + " error-input";
              elementoHTML.title = 'Error de Formato - No debe ser un valor vacio';
            }
          }
          if(String(obj[key]) !== "") { // Dato no vacio
            hasData = true;
            const formato = this.validaFormatoElemento(obj[key], htmlKey + key, elementoHTML, idElement) // Validacion de formato
            if(formato === null){ // Si hay un error de formato lo marca en la vista
              hasFormatDataError = true;
            } else { // Si no hay error se quita la posible marca en la vista
              obj[key] = formato;
              if(elementoHTML){
                elementoHTML.className = elementoHTML.className.replace(" error-input", "");
                elementoHTML.title = '';
              }
            }
          }
        }
      }
      if(hasEmptyData && hasData){ // Faltan datos por llenar
        arrayInputsVacios.forEach((key: string) => {
          const elementoHTML = document.getElementById(idElement + htmlKey + key + index);
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className + " error-input";
            elementoHTML.title = 'Error de Formato - Completar dato';
          }
        });
        return false
      }
      if(hasData && hasFormatDataError){ // Tiene un error de formato del dato ingresado
        return false
      }
      return true;
    } else {
      return false;
    }
  }

  // Valida el objeto de la ficha dependiendo del tipo de dato

  validaObjetoFicha(obj: any, idElement: string): boolean {
    if (typeof(obj) === 'object' && Array.isArray(obj)){
      let valido = true;
      obj.forEach((element: any, index: number) => {
        if(!this.validaObjeto(element, idElement, index)){
          valido = false;
        }
      });
      return valido;
    } else if (typeof(obj) === 'object' && !Array.isArray(obj)) {
      return this.validaObjeto(obj, idElement, 0);
    } else {
      return false;
    }
  }

  // Funcion que valida el llenado de los datos y guarda los datos de la ficha

  guardarFicha(): void{
    
    // Valida los datos de las tablas con datos de ingreso manual
    if(!this.validaObjetoFicha(this.sociosComposicion, 'socio-') || !this.validaObjetoFicha(this.empresasRelacionadasComposicion, 'empresa-')
    ){
      this.alertService.error('Solicitud de Crédito - Debe completar correctamente los datos en las filas de las tablas con información ingresada.');
      return;
    }

    if(this.rut === ''){
      this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar el rut.');
      return;
    }

    const servicioFichaForm = this.servicioFichaForm.getRawValue();

    if(!servicioFichaForm?.montoSolicitado && servicioFichaForm?.montoSolicitado !== 0 && servicioFichaForm?.montoSolicitado !== '0'){
      this.alertService.error('Solicitud de Crédito - No se logró recuperar el monto solicitado.');
      return;
    }

    const ficha: any = {
      ficha: {
        servicioFichaForm: servicioFichaForm, // se valida con archivo service
        sociosComposicion: this.sociosComposicion,
        empresasRelacionadasComposicion: this.empresasRelacionadasComposicion,
        sociosInformes: this.sociosInformes, // no se debe validar porque no se modifica
        titularInforme: this.titularInforme,
        empresasRelacionadasInformes: this.empresasRelacionadasInformes, // no se debe validar porque no se modifica
        dataFiltrada: this.dataFiltrada, // no se debe validar porque no se modifica
        dataF29: this.dataF29,
        totalesInformacionFinanciera: this.totalesInformacionFinanciera, // no se debe validar porque no se modifica
        informacionFinancieraTable: this.informacionFinancieraTable, // no se debe validar porque no se modifica
        balanceManual: this.balanceManual,
        rut: this.rut,
        informacionFinanciera: this.informacionFinanciera,
        consolidadoTitular: this.consolidadoTitular,
        // informacion para etapa de aprobacion
        lineaOperacionPropuesta: {
          producto: [
            { id: "montoSolicitado", texto: "Monto Solicitado" }
          ],
          lineaPropuesta: {
            montoSolicitado: this.montoSolicitado || 0
          }
        },
        lineaOperacionPropuestaTotales: {
          totalesLineaPropuesta: this.montoSolicitado || 0,
          totalesPropuesta: this.montoSolicitado || 0,
        },
        tipoBalance: this.tipoBalance,
        compareBalances: this.compareBalances
      }
    }

    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.guardarFichaDeCredito(this.rut, this.idTransaccion, ficha).subscribe(resp => {
      this.fichaGuardada = ficha;
      this.alertService.success(resp.message)
      this.spinner.hide();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error con el servicio de guardado de la ficha');
      this.spinner.hide();
    }));
  }

  // Boton Continuar Primera etapa

  avanzarFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[8]?.optional === false){
        if(this.bodyModelos?.stages[8]?.completed){

          this.subscriptions.push(this.solicitudCreditoService.calcularScore(this.idTransaccion, this.rut).subscribe(resp => {
            if(resp?.MotorDeCalculos?.errors?.hassError === false){
              if(resp?.DatosBasicosSolicitud?.Automatico === true){

                this.bloqueaPasos6.emit(true);
                this.solicitudCreditoService.setStep(6);
                this.spinner.hide();

              } else {
                
                this.solicitudCreditoService.setStep('next');
                // bloquea pasos 5
                if(!this.validaUsuarioAdmin()){
                  this.bloqueaPasos5.emit(true);
                }
                this.spinner.hide();
              }
            } else {
              this.alertService.error(resp?.MotorDeCalculos?.details?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del motor de calculos');
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicio del motor de calculos');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        this.solicitudCreditoService.setStep('next');
        this.spinner.hide();
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  // Funciones para navegacion

  mapeoSbif(): void {
    if(this.reportesSeleccionado?.deudaSbif?.reporte){
      /*if(this.reportesSeleccionado?.deudaSbif?.reporte?.DeudaSistemaFinancieroReporte?.Documentos?.SbifOnline?.Reporte?.rowsMeses?.item){

      } else */
      if (this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item) {
        this.reportesSeleccionado.deudaSbif.reporte = {
          Documentos: {
            SbifOnline: {
              Reporte: {
                rowsMeses: this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses
              }
            }
          }
        }
      }
    }

  }

  // Mapea datos para abrir el modal

  mapeoPoderJudicial(): void{
    if(this.reportesSeleccionado?.poderJudicial?.reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte["Totalizadores Poder Judicial"];
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: {
            Totales: datos
          }
        }
      };
      this.reportesSeleccionado.poderJudicial.reporteMapeado = reporte;
    }
    if(this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte;
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: datos
        }
      };

      this.reportesSeleccionado.poderJudicial.ReporteFull = reporte;
    }
  }

  mapeoVehiculos(): void{ // validar
    //this.reportesPrincipales?.Stage4Response?.vehiculos?.reporte? - .vehiculoReporte?.vehiculos?.listadoVehiculos
    if(this.reportesSeleccionado?.vehiculos?.reporte){
      this.reportesSeleccionado.objReporteVehiculos = this.reportesSeleccionado?.vehiculos?.reporte;
    }
  }

  mapeoBienesRaices(): void{ // validar
      //this.reportesPrincipales?.Stage4Response?.bienesRaices?.reporte? - .bienesraicesReporte?.bienesRaices?.listadoBienesRaices
    if(this.reportesSeleccionado?.bienesRaices?.reporte){
      this.reportesSeleccionado.objReporteBienesRaices = this.reportesSeleccionado?.bienesRaices?.reporte;
    }
  }

  // Valida los objetos para abrir el modal

  validaObjetos(): void{
    if(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte && Object.keys(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte).length === 0){
      this.reportesSeleccionado.cteVentasBalancesRentas.reporte = null;
    }
    if(this.reportesSeleccionado?.basicReport?.reporte && Object.keys(this.reportesSeleccionado?.basicReport?.reporte).length === 0){
      this.reportesSeleccionado.basicReport.reporte = null;
    }
    if(this.reportesSeleccionado?.deudaSbif?.reporte && Object.keys(this.reportesSeleccionado?.deudaSbif?.reporte).length === 0){
      this.reportesSeleccionado.deudaSbif.reporte = null;
    }
    if(this.reportesSeleccionado?.boletinConcursal?.reporte && Object.keys(this.reportesSeleccionado?.boletinConcursal?.reporte).length === 0){
      this.reportesSeleccionado.boletinConcursal.reporte = null;
    }
    if(this.reportesSeleccionado?.reporteEmpresaDia?.reporte && Object.keys(this.reportesSeleccionado?.reporteEmpresaDia?.reporte).length === 0){
      this.reportesSeleccionado.reporteEmpresaDia.reporte = null;
    }
  }

  // Funcion para abrir modal con datos basicos en tabla de informes comerciales

  abrirModal(entidad: string, rut: string): void{
    if(rut && entidad){
      if(this.reportesDerivadosObj[entidad]?.[rut]){
        this.reportesSeleccionado = this.reportesDerivadosObj[entidad][rut];
        this.rutSeleccionado = rut;
        this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
        this.mapeoPoderJudicial();
        this.mapeoVehiculos();
        this.mapeoBienesRaices();
        this.mapeoSbif();
        this.validaObjetos();
        this.plantillaAntecedentes = '';
        this.modalDefault = true;
  
        this.modalService.open();
      }
    }
    
  }

  abrirModalTitular(): void{
    if(this.reportesPrincipalesObj){
      this.mapeoReporteTitular();
      this.mapeoPoderJudicial();
      this.mapeoSbif();
      this.validaObjetos();
    }

    this.reportesSeleccionado.consolidadoTitular = this.consolidadoTitular;
  
    this.rutSeleccionado = this.rut;
    this.plantillaAntecedentes = 'cesceDemo';
    this.modalDefault = false;

    this.modalService.open();
  }

  mapeoReporteTitular(): void {
    this.reportesSeleccionado = {
      deudaSbif: this.reportesPrincipalesObj?.deudaSbif,
      poderJudicial: this.reportesPrincipalesObj?.poderJudicial,
      boletinConcursal: this.reportesPrincipalesObj?.boletinConcursal,
      objReporteVehiculos: this.reportesPrincipalesObj?.vehiculos?.reporte,
      objReporteBienesRaices: this.reportesPrincipalesObj?.bienesRaices?.reporte
    } 

  }

  // Scrollea automaticamente la vista a la seccion seleccionada

  scroll(el: string, event: any) {
    let button = event.target as HTMLElement | null;
    const elementHTML = document.getElementById(el) as HTMLElement | null;
    if(button?.classList.contains('fa')){
      button = button?.parentElement as HTMLElement | null;
    }
    elementHTML?.classList.toggle('container-desplegables');
    if ( !elementHTML?.classList.contains('container-desplegables') ){
      elementHTML?.scrollIntoView(true);
      button?.classList.add('button-selected');
      button?.classList.remove('border');
      button?.classList.remove('border-buttons');
      button?.classList.add('border-0'); 

    } else {
      button?.classList.remove('button-selected');
      button?.classList.add('border');
      button?.classList.add('border-buttons');
      button?.classList.remove('border-0'); 
    }
  }

  // Muestra u oculta seccion dependiendo de seleccion del usuario

  show(el: string, buttonId?: string) {
    const elementHTML = document.getElementById(el) as HTMLElement;
    elementHTML.classList.toggle('container-desplegables');
    if(buttonId){
      const button = document.getElementById(buttonId) as HTMLElement;
      if ( !elementHTML.classList.contains('container-desplegables') ){
        button.classList.add('button-selected');
        button.classList.remove('border');
        button.classList.remove('border-buttons');
        button.classList.add('border-0'); 
      } else {
        button.classList.remove('button-selected');
        button.classList.add('border');
        button.classList.add('border-buttons');
        button.classList.remove('border-0'); 
      }
    }
  }

  stringToFloat(number: any): number {
    return parseFloat(number.toString().replace(',', '.')) || 0;
  }

  finalizar(): void {
    this.finalizarFlujo.emit();
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  agregarSaltosDeLinea(texto: string): string {
    return texto.replace(new RegExp('\n', 'g'), '<br />');
  }

  validaUsuarioAdmin(): boolean {
    return this._sessionService.getUserAccess().includes(this.access[3]);
  }

  volverEtapaPrevia(): void {
    this.bloqueaPasos5.emit(false);
    this.solicitudCreditoService.setStep(Number(this.stepper - 1));
    this.spinner.hide();
  }

  validaComparacion(): boolean {
    if(this.balanceManual && this.balanceManual.length > 0 && this.dataFiltrada && this.dataFiltrada.length > 0){
      const balanceManual = this.balanceManual[0];
      const f22 = this.dataFiltrada[0];
      if(balanceManual && f22){
        const periodoBalanceManual = balanceManual?.BalanceManualReporte?.Reporte?.periodo || '';
        const mesFinBalanceManual = balanceManual?.BalanceManualReporte?.Reporte?.mesFin || '';
        const balanceManualFecha = `${this.ultimoDiaMes(Number(mesFinBalanceManual), Number(periodoBalanceManual))}/${mesFinBalanceManual}/${periodoBalanceManual}`;
        if(balanceManualFecha && f22?.calculado?.fecha){
          const fechaF22 = f22?.calculado?.fecha;
          if(balanceManualFecha === fechaF22){
            return true;
          }
        }
      }
      
    }
    return false;
  }

  // Boton continuar etapa final

  continuar(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[9]?.optional === false){
        if(this.bodyModelos?.stages[9]?.completed){
          
          // Llamada a servicio para inciar aprobacion
          this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
            const error = resp?.ServicioAprobacional?.errors || null;
            if(error?.hassError === true){
              this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
              this.spinner.hide();
            } else {
              this.solicitudCreditoService.setStep('next');
              this.bloqueaPasos6.emit(true);
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar con el servicio para iniciar el flujo aprobacional');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        // Llamada a servicio para inciar aprobacion
        this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
          const error = resp?.ServicioAprobacional?.errors || null;
          if(error?.hassError === true){
            this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
            this.spinner.hide();
          } else {
            this.solicitudCreditoService.setStep('next');
            this.bloqueaPasos6.emit(true);
            this.spinner.hide();
          }
        }, (error: any) => {
          this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar con el servicio para iniciar el flujo aprobacional');
          this.spinner.hide();
        }));
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  descargarPdf(): void {
    const idOrganizacion = this.bodyModelos.idOrganizacion || '';
    const modelo = this.bodyModelos.workflowModel || '';
    if(this.idTransaccion && idOrganizacion && modelo) {
      this.urlDownloadPreview = '';
      this.urlDownload = '';
      this.spinner.show();
      this.pdfGeneratorService._generatePDF(this.idTransaccion, 'flujoCredito', modelo, idOrganizacion).subscribe(respuesta => {

        if(respuesta?.URLReporte) {
          this.urlDownload = respuesta?.URLReporte;
        }

        if(respuesta?.URLPreview) {
          this.urlDownloadPreview = respuesta?.URLPreview;
        }

        if(!respuesta?.URLPreview && !respuesta?.URLReporte) {
          console.error('Solicitud de Crédito - Ocurrio un error al recuperar la url de preview');
          this.spinner.hide();
          this.obtenerPdf();
        } else {
          if(respuesta?.URLPreview) {
            this.spinner.hide();
            this.modalPdfViewerService.open();
          }
          else if(!respuesta?.URLPreview && respuesta?.URLReporte) {
            this.spinner.hide();
            this.openPDF(respuesta?.URLReporte);
          } else {
            this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar el pdf');
            this.spinner.hide();
            this.obtenerPdf();
          }
        }

      }, (error: any) => {
        console.error('Solicitud de Crédito - Error al consultar servicio para crear pdf');
        this.spinner.hide();
        this.obtenerPdf();
      });
    }
  }

  async obtenerPdf(): Promise<void> {
    this.spinner.show();
    try {
      const contentPDF = await this.pdfGeneratorService._getPDF(this.idTransaccion, 'flujoCredito').toPromise();

      if(contentPDF?.URLReporte) {
        this.urlDownload = contentPDF?.URLReporte;
      } else {
        this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar la url para descargar el pdf');
      }

      if(contentPDF?.URLPreview) {
        this.urlDownloadPreview = contentPDF?.URLPreview;
      }

      if(!contentPDF?.URLPreview && !contentPDF?.URLReporte) {
        console.error('Solicitud de Crédito - Ocurrio un error al intentar obtener el pdf');
        this.spinner.hide();
      } else {
        if(contentPDF?.URLPreview) {
          this.spinner.hide();
          this.modalPdfViewerService.open();
        }
        else if(!contentPDF?.URLPreview && contentPDF?.URLReporte) {
          this.spinner.hide();
          this.openPDF(contentPDF?.URLReporte);
        } else {
          this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar el pdf');
          this.spinner.hide();
        }
      }

    } catch (error) {
      this.alertService.error('Solicitud de Crédito - Ocurrio un error al intentar recuperar el pdf');
      this.spinner.hide();
    }
  }

  openPDF(url: string): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', "FlujoDeCredito" + (this.rut ? `_${this.rut}_` : '') + (this.today ? `_${this.today}_` : '') + ".pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  validarF22VsBalanceManualReporte(balanceManualReporte: any, F22: any) {
    if(balanceManualReporte && balanceManualReporte.length > 0 && F22 && F22.length > 0){
      const balanceManual = balanceManualReporte[0];
      if(balanceManual && Object.keys(balanceManual).length > 0){
        const balanceManualFecha = this.getFechaManualBalance(balanceManual);
        if(F22.find((e: any) => e?.calculado?.fecha === balanceManualFecha)){
          return true;
        }
      }
      
    }
    return false;
  }

  getDiferencia(valueManual: any, valueF22: any) {
    if(valueManual != undefined && valueF22 != undefined) {
      return Number(valueManual) - Number(valueF22);
    }
    return '';
  }

  setCompareBalances(): void {
    if(this.validaComparacion()) {
      if(this.validarF22VsBalanceManualReporte(this.balanceManual, this.dataFiltrada)) {
        const balanceManual = this.balanceManual[0];
        const f22Selected = this.dataFiltrada.find((e: any) => e?.calculado?.fecha === this.getFechaManualBalance(balanceManual));
        const mapeos: any[] = [
          {titulo: "Activos Circulantes", balanceManual: "activoscorrientes", f22: "totalactivocirculante"},
          {titulo: "Activos Fijos", balanceManual: "activosnocorrientes", f22: "totalactivosfijos"},
          {titulo: "Total Activos", balanceManual: "activo", f22: "totalactivos"},
          {titulo: "Pasivo Exigible", balanceManual: "pasivo", f22: "totalpasivoexigible"},
          {titulo: "Patrimonio", balanceManual: "patrimonio", f22: "totalpatrimonio"},
          {titulo: "Ingresos de la Explotación", balanceManual: "ingresodeactiviadesordinariaser", f22: "ingresosdelaExplotacion"},
          {titulo: "(Costo de Explotación)", balanceManual: "costodeventaser", f22: "costodeExplotacion"},
          {titulo: "RESULTADO BRUTO", balanceManual: "gananciabruta", f22: "resultadobruto"},
          {titulo: "(Gastos Administrativos y Ventas)", balanceManual: "gastosdeadministracion", f22: "gastosAdministrativosyVentas"},
          {titulo: "EBITDA", balanceManual: "ebitda", f22: "ebitda"},
          {titulo: "(Depreciación del Ejercicio)", balanceManual: "depreciacionyamortizacion", f22: "depreciaciondelEjercicio"},
          {titulo: "EBIT (RESULTADO OPERACIONAL)", balanceManual: "resultadooperacional", f22: "resultadooperacional"},
          {titulo: "Ingresos Financieros", balanceManual: "ingresosfinancieros", f22: "ingresosFinancieros"},
          {titulo: "Ingresos fuera de la Explotación", balanceManual: "otrasganaciasperdidas", f22: "ingresosfueradelaExplotación"},
          {titulo: "(Gastos Financieros)", balanceManual: "costosfinancieros", f22: "gastosFinancieros"},
          {titulo: "Diferencia de Cambio (+/-)", balanceManual: "diferenciasdecambio", f22: "diferenciadeCambio"},
          {titulo: "Corrección Monetaria (+/-)", balanceManual: "correccionmonetaria", f22: "correccionMonetaria"},
          {titulo: "UTILIDAD ANTES DE IMPUESTOS", balanceManual: "utilidadantesdeimpuestos", f22: "utilidadantesdeimpuestos"},
          {titulo: "(Impuesto Renta)", balanceManual: "gastosporimpuestosalasganacias", f22: "impuestoRenta"},
          {titulo: "UTILIDAD NETA", balanceManual: "gananciaperdida", f22: "utilidadneta"}
        ];

        this.compareBalances.fechas = {
          f22: f22Selected?.calculado?.fecha,
          balanceManual: this.getFechaManualBalance(balanceManual)
        };
        this.compareBalances.tabla = [];

        const monedaBalanceManual = balanceManual?.BalanceManualReporte?.Reporte?.moneda || '';
        const monedaF22 = f22Selected?.calculado?.moneda || '';
        const comparaBalanceBase = () => {
          for(const item of mapeos) {
            const elemento = {
              titulo: item.titulo,
              balanceManual: balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual],
              f22: f22Selected?.calculado?.[item.f22],
              diferencia: ''
            };
  
            this.compareBalances.tabla.push(elemento);
          }
        };

        if(monedaBalanceManual && monedaF22 && (monedaF22.toUpperCase() === 'PESOS' || monedaBalanceManual.toUpperCase() === 'DOLARES')) {
          //monedaF22: .toUpperCase() === 'PESOS', .toUpperCase() === 'DOLARES'
          //monedaBalanceManual: [{value: 'M$', text: 'M$'}, {value: 'UF', text: 'UF'}, {value: 'USD', text: 'USD'}, {value: 'EUR', text: 'EUR'}]
          const fechaFormatted = this.formatterFecha(f22Selected?.calculado?.fecha);
          if(monedaF22.toUpperCase() === 'PESOS') {
            if(monedaBalanceManual === 'UF' || monedaBalanceManual === 'USD' || monedaBalanceManual === 'EUR') {
              const serviceMethod = monedaBalanceManual === 'UF' ? this.solicitudCreditoService.calcularUF : monedaBalanceManual === 'USD' ? this.solicitudCreditoService.obtenerDolar : this.solicitudCreditoService.obtenerEuro;
              serviceMethod.call(this.solicitudCreditoService, fechaFormatted).subscribe(resp => { //"2021-12-30T00:00:00.000Z"
                const valorMoneda = resp?.valores?.[0]?.valor || 0;
                if(valorMoneda > 0){
                  for(const item of mapeos) {
                    const f22Value = Number(f22Selected?.calculado?.[item.f22]) / valorMoneda;
                    const elemento = {
                      titulo: item.titulo,
                      balanceManual: balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual],
                      f22: f22Value,
                      diferencia: this.getDiferencia(balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual], f22Value)
                    };
          
                    this.compareBalances.tabla.push(elemento);
                  }
                } else {
                  console.error('Solicitud de Crédito - No se logró recuperar el valor de la moneda para comparación.', resp?.valores?.[0]?.valor);
                  comparaBalanceBase();
                }
              }, (error: any) => {
                this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
                comparaBalanceBase();
              });
            } else {
              for(const item of mapeos) {
                const f22Value = Number(f22Selected?.calculado?.[item.f22]) / 1000;
                const elemento = {
                  titulo: item.titulo,
                  balanceManual: balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual],
                  f22: f22Value,
                  diferencia: this.getDiferencia(balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual], f22Value)
                };
      
                this.compareBalances.tabla.push(elemento);
              }
            }
          }
          if(monedaF22.toUpperCase() === 'DOLARES') {
            if(monedaBalanceManual === 'USD') {
              for(const item of mapeos) {
                const elemento = {
                  titulo: item.titulo,
                  balanceManual: balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual],
                  f22: f22Selected?.calculado?.[item.f22],
                  diferencia: this.getDiferencia(balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual], f22Selected?.calculado?.[item.f22])
                };
      
                this.compareBalances.tabla.push(elemento);
              }
            } else {
              this.solicitudCreditoService.obtenerDolar(fechaFormatted).subscribe(resp => { //"2021-12-30T00:00:00.000Z"
                const valorDolar = resp?.valores?.[0]?.valor || 0;
                if(valorDolar > 0){
                  if(monedaBalanceManual === 'EUR' || monedaBalanceManual === 'UF') {
                    const serviceMethod = monedaBalanceManual === 'UF' ? this.solicitudCreditoService.calcularUF : this.solicitudCreditoService.obtenerEuro;
                    serviceMethod.call(this.solicitudCreditoService, fechaFormatted).subscribe(resp => { //"2021-12-30T00:00:00.000Z"
                      const valorMoneda = resp?.valores?.[0]?.valor || 0;
                      if(valorMoneda > 0){
                        for(const item of mapeos) {
                          const f22Value = Number(f22Selected?.calculado?.[item.f22]) / valorMoneda;
                          const elemento = {
                            titulo: item.titulo,
                            balanceManual: balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual],
                            f22: f22Value,
                            diferencia: this.getDiferencia(balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual], f22Value)
                          };
                
                          this.compareBalances.tabla.push(elemento);
                        }
                      } else {
                        console.error('Solicitud de Crédito - No se logró recuperar el valor de la moneda para comparación.', resp?.valores?.[0]?.valor);
                        comparaBalanceBase();
                      }
                    }, (error: any) => {
                      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
                      comparaBalanceBase();
                    });
                  } else { // monedaBalanceManual === 'M$'
                    for(const item of mapeos) {
                      const f22Value = (Number(f22Selected?.calculado?.[item.f22]) * valorDolar)/1000;
                      const elemento = {
                        titulo: item.titulo,
                        balanceManual: balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual],
                        f22: f22Value,
                        diferencia: this.getDiferencia(balanceManual?.BalanceManualReporte?.Reporte?.balance?.[item.balanceManual], f22Value)
                      };
            
                      this.compareBalances.tabla.push(elemento);
                    }
                  }
                } else {
                  console.error('Solicitud de Crédito - No se logró recuperar el valor de la moneda para comparación.', resp?.valores?.[0]?.valor);
                  comparaBalanceBase();
                }
              }, (error: any) => {
                this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
                comparaBalanceBase();
              });
            }
          }

        } else {
          comparaBalanceBase();
        }

      }

    }
  }


}
