<div class="row">
    <div class="col-12 mb-2">
        <button type="button" class="btn btn-primary" (click)="volver()">
            <span class="p-3"><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Volver</span>
        </button>
    </div>
    <div class="col-12 mt-5">
        <span class="shadow-sm rounded p-3 bg-light ml-4">
            <strong>Usuario:</strong> {{usuario || ''}}
        </span>
    </div>
    <div class="col-12 mt-5">
        <h1 class="ml-4">
            <strong>Asignación de ruts</strong>
        </h1>
    </div>
    <hr class="col-sm-12 col-md-10 col-lg-8 ml-5"/>
    <div class="col-sm-12 col-md-4">
        <div class="card text-center my-4" *ngIf="totalDisponibles > 0">
            <div class="card-header bg-primary">
                <div class="row">
                    <div class="col-12">
                        Ruts con credenciales sincronizadas por el ejecutivo
                        <div class="float-right mr-3">
                            <select class="form-control no-print" [(ngModel)]="currentItemDisponibles" (ngModelChange)="cambioPaginacion('disponibles')">
                                <option *ngFor="let element of paginationOptions" [ngValue]="element">
                                    {{ element }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table id="tableUsuarios" class="table table-hover border my-0">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center font-size"></th>
                            <th scope="col" class="text-center font-size">Rut</th>
                            <th scope="col" class="text-center font-size">Ejecutivo</th>
                            <th scope="col" class="text-center font-size">Fecha Modificación</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="paginaActualDisponibles && paginaActualDisponibles.length > 0 && paginaActualDisponibles[0]; else noPage">
                            <tr *ngFor="let item of paginaActualDisponibles | paginate:{id: 'pagination-disponibles', itemsPerPage: currentItemDisponibles, currentPage: currentPageDisponibles, totalItems: totalDisponibles}">
                                <td class="text-center">
                                    <input 
                                        type="checkbox" 
                                        (click)="agregarRut($event, (item?.rut + '-' + item?.dv), 'disponibles')"
                                        [checked]="isChecked('disponibles', item?.rut+'-'+item?.dv)"
                                    >
                                </td>
                                <td class="text-center">{{item?.rutFormatted ? (item?.rutFormatted | rutFormat) : ('')}}</td>
                                <td class="text-center">{{item?.id_usuario || ''}}</td>
                                <td class="text-center">{{item?.date | date: 'dd-MM-yyyy'}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="card-footer text-muted">
                <div class="p-2">
                    <div id="pagination">
                        <div class="col-sm-12 col-md-8 float-right">
                            <pagination-controls id="pagination-disponibles" (pageChange)="getPage($event, 'disponibles')"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card text-center my-4 max-height">
            <div class="card-header bg-primary">
                <div class="row">
                    <div class="col-12">
                        Buscar Rut
                        <div class="float-right mr-3">
                            <button class="btn btn-warning" 
                                title="Presione para limpiar los ruts buscados"
                                type="button"
                                (click)="limpiarBuscados()"
                            ><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 p-2">
                <div class="input-group mb-3">
                    <input id="table-complete-search" type="text" class="form-control" name="searchTermUsuario"
                        title="Ingrese un rut para buscar"
                        placeholder="Buscar rut"
                        [(ngModel)]="inputTextRut"
                        formatRut
                    >
                    <div class="input-group-append">
                        <button class="btn btn-outline-primary" 
                            title="Ingrese un rut para obtener usuario"
                            type="button"
                            (click)="buscarByRut()"
                        ><i class="fa fa-search" aria-hidden="true"></i></button>
                      </div>
                </div>
            </div>
            <div class="table-responsive">
                <table id="tableRutBusqueda" class="table table-hover border my-0">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center font-size"></th>
                            <th scope="col" class="text-center font-size">Rut</th>
                            <th scope="col" class="text-center font-size">Ejecutivo Sincroniza Credenciales</th>
                            <th scope="col" class="text-center font-size">Ejecutivo Cartera</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="rutBuscado && rutBuscado.length > 0; else noPage">
                            <tr *ngFor="let item of rutBuscado">
                                <td class="text-center">
                                    <input 
                                        type="checkbox" 
                                        (click)="agregarRut($event, (item?.rut + '-' + item?.dv), 'buscado')"
                                        [checked]="isChecked('buscado', item?.rut+'-'+item?.dv)"
                                    >
                                </td>
                                <td class="text-center">{{item?.rutFormatted ? (item?.rutFormatted | rutFormat) : ('')}}</td>
                                <td class="text-center">{{item?.sync == true ? (item?.userSync || '-') : '-'}}</td>
                                <td class="text-center">{{item?.hasOwner == true ? (item?.idUsuario || '-') : '-'}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-2  mt-5">
        <div class="row">
            <div class="col-sm-6 col-md-12 d-flex align-items-center my-2">
                <button type="button" class="btn btn-success mx-auto" (click)="asignar()"><i class="fa fa-angle-right mx-2" aria-hidden="true"></i></button>
            </div>
            <div class="col-sm-6 col-md-12 d-flex align-items-center my-2">
                <button type="button" class="btn btn-danger mx-auto" (click)="desasignar()"><i class="fa fa-angle-left mx-2" aria-hidden="true"></i></button>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card text-center my-4">
            <div class="card-header bg-primary">
                <div class="row">
                    <div class="col-12">
                        Ruts Asignados a la cartera del usuario
                        <div class="float-right mr-3">
                            <select class="form-control no-print" [(ngModel)]="currentItemCartera" (ngModelChange)="cambioPaginacion('cartera')">
                                <option *ngFor="let element of paginationOptions" [ngValue]="element">
                                    {{ element }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table id="tableUsuarios" class="table table-hover border my-0">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center font-size"></th>
                            <th scope="col" class="text-center font-size">Rut</th>
                            <th scope="col" class="text-center font-size">Ejecutivo</th>
                            <th scope="col" class="text-center font-size">Fecha Modificación</th>
                            <th scope="col" class="text-center font-size">Origen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="paginaActualCartera && paginaActualCartera.length > 0 && paginaActualCartera[0]; else noPage">
                            <tr *ngFor="let item of paginaActualCartera | paginate:{id: 'pagination-cartera', itemsPerPage: currentItemCartera, currentPage: currentPageCartera, totalItems: totalCartera}">
                                <td class="text-center">
                                    <input 
                                        type="checkbox" 
                                        (click)="agregarRut($event, item?.rut+'-'+item?.dv, 'cartera')"
                                        [checked]="isChecked('cartera', item?.rut+'-'+item?.dv)"
                                    >
                                </td>
                                <td class="text-center">{{item?.rutFormatted ? (item?.rutFormatted | rutFormat) : ('')}}</td>
                                <td class="text-center">{{item?.id_usuario}}</td>
                                <td class="text-center">{{item?.date | date: 'dd-MM-yyyy'}}</td>
                                <td class="text-center">{{item?.request_origin}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="card-footer text-muted">
                <div class="p-2">
                    <div id="pagination">
                        <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                            <pagination-controls id="pagination-cartera" (pageChange)="getPage($event, 'cartera')"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 text-center mt-5">
        <button type="button" class="btn btn-primary">
            <span class="p-3" (click)="guardar()">Guardar</span>
        </button>
    </div>
</div>


<ng-template #noPage>
    <tr>
        <td colspan="100%" class="text-center">Sin información</td>
    </tr>
</ng-template>
