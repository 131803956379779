import { Component, ElementRef, Input, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';

export interface ConfirmModel {
  //printable: boolean;
  dataOrg: any;
  dataOrgPrint?: any;
  onFocusId?: any;
  selectedList?: any[];
}

@Component({
  selector: 'app-malla-societaria-graph',
  templateUrl: './malla-societaria-graph.component.html',
  styleUrls: ['./malla-societaria-graph.component.scss']
})
export class MallaSocietariaGraphComponent implements AfterViewInit {
  public objectKeys = Object.keys;

  //@Input() printable: boolean = false;
  @Input() dataOrg: any = {};
  @Input() dataOrgPrint: any = {};
  @Input() selectedList: any[] = [];

  @ViewChild("chartContainer") chartContainer!: ElementRef;

  confirmData: ConfirmModel = {
    //printable: false,
    dataOrg: {},
    dataOrgPrint: {},
    onFocusId: null,
    selectedList: [],
  };
 
  public counter$: Observable<number> = new Observable;
  public counter = 0;
  public chart: any;
  private objSelected: any = {};
  public messages: { text: string, isError: boolean, isSuccess: boolean }[] = [];

  constructor(
    public dialogRef: MatDialogRef<MallaSocietariaGraphComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel,
  ) {
      this.confirmData = data;
  }

  confirm() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.chart) {
      this.chart = new OrgChart();
      this.updateChart(true);
    }
  }

  updateChart(primeraCarga = false, id?: string) {
    if(this.chartContainer){
      this.updateNodeSelected();
      this.chart
        .container(this.chartContainer.nativeElement)
        .nodeHeight((d: any) => 120) // 90
        .nodeWidth((d: any) => 220)
        .data(this.confirmData.dataOrg)
        .childrenMargin((d: any) => 40)
        .svgWidth(220)
        .compactMarginBetween((d: any) => 35)
        .compactMarginPair((d: any) => 30)
        .neighbourMargin((a: any, b: any) => 20)
        .initialZoom(1)
        .linkUpdate(function (d: any, i: any, arr: any) {
          // @ts-ignore //no reconoce el this como un elemento de d3
          d3.select(this)
            .attr('stroke', '#4D7BA0')
            .attr('stroke-width', 2);

            d.data._highlighted || d.data._upToTheRootHighlighted ? 
              // @ts-ignore //no reconoce el this como un elemento de d3
              d3.select(this).attr('stroke', '#d60d4c').attr('stroke-width', 5)
            : null;
        })
        .nodeContent((d: any) => {
          let template = d.data.template || '';
          if(this.objSelected && Object.keys(this.objSelected).length > 0){
            for(const index of Object.keys(this.objSelected)){
              const element = this.objSelected[index].find((item: any) => item.id === d.data.id && item.parentNodeId === d.data.parentNodeId);
              if(template.includes('class="h-100"') && element){
                template = template.replace('class="h-100"', 'class="h-100 selected-node"');
              }
            }
          }
          return template;
        })
        .render();

      if(primeraCarga == true){
        this.chart.expandAll().compact(true).fit().render();
      } else {
        if(id) {
          this.chart.expandAll().setCentered(id).render();
        }
      }

      (window as any).selectNode = this.selectNode.bind(this);
    }
   
    if(this.confirmData && this.confirmData.onFocusId != null) {
      this.chart.clearHighlighting();
      this.chart.collapseAll().fit();
      this.chart.setUpToTheRootHighlighted(this.confirmData.onFocusId).setCentered(this.confirmData.onFocusId).render();
    }
  }

  updateNodeSelected(): void {
    if(this.confirmData.selectedList && this.confirmData.selectedList.length > 0){
      this.objSelected = {};
      for(const idTable of this.confirmData.selectedList){
        const element = this.confirmData.dataOrg.find((item: any) => item.idTable === idTable);
        if(element) {
          if(!this.objSelected[element.entidad]){
            this.objSelected[element.entidad] = [];
          }
          this.objSelected[element.entidad].push({
            entidad: element.entidad,
            tiporut: element.tiporut,
            id: element.id,
            idTable: element.idTable,
            parentNodeId: element.parentNodeId
          });
        }
      }
    }

  }

  selectNode(id: string) {
    const checkbox = document.getElementById(id) as HTMLInputElement;
    if(checkbox){
      if(!checkbox.classList.contains('selected-node')){ // esta siendo seleccionado
        const entidad = checkbox.getAttribute('entidad');
        const tiporut = checkbox.getAttribute('tiporut');
        const id = checkbox.getAttribute('id');
        const idTable = checkbox.getAttribute('idTable');
        const parentNodeId = checkbox.getAttribute('parentNodeId');
        if(entidad) {
          if(!this.objSelected[entidad]){
            this.objSelected[entidad] = [];
          }

          if(!this.objSelected[entidad].find((item: any) => item.id === id && item.parentNodeId === parentNodeId)){
            this.objSelected[entidad].push({
              entidad: entidad,
              tiporut: tiporut,
              id: id,
              idTable: idTable,
              parentNodeId: parentNodeId
            });
          }

          const filterByTipoRut = this.objSelected[entidad].filter((item: any) => item.tiporut === tiporut);
          if(filterByTipoRut.length > 10){
            console.error('No se pueden seleccionar más de 10 elementos por entidad');
            this.addMessage('No se pueden seleccionar más de 10 elementos por entidad', true, false);
            // quitar elemento de la lista
            const index = this.objSelected[entidad].findIndex((item: any) => item.id === id && item.parentNodeId === parentNodeId);
            if(index > -1){
              this.objSelected[entidad].splice(index, 1);
            }
            return;
          }

          if(!checkbox.classList.contains('selected-node')){
            checkbox.classList.add('selected-node');
          }
        }
      } else { // esta siendo deseleccionado
        const entidad = checkbox.getAttribute('entidad');
        if(entidad && this.objSelected[entidad]){
          const id = checkbox.getAttribute('id');
          const parentNodeId = checkbox.getAttribute('parentNodeId');
          const index = this.objSelected[entidad].findIndex((item: any) => item.id === id && item.parentNodeId === parentNodeId);
          if(index > -1){
            this.objSelected[entidad].splice(index, 1);
          }
        }
        if(checkbox.classList.contains('selected-node')){
          checkbox.classList.remove('selected-node');
        }
      }
    }
  }

  zoomIn() {
    if(this.chart){
      this.chart.zoomIn();
    }
  }

  zoomOut() {
    if(this.chart){
      this.chart.zoomOut();
    }
  }

  clear() {
    if(this.chart){
      this.chart.clearHighlighting();
    }
    this.objSelected = {};

    const container = document.getElementById('chartContainer');

    if(container) {
      const elementos = container.querySelectorAll('.selected-node');
      if(elementos && elementos.length > 0){
        elementos.forEach((element: any) => {
          if(element.classList.contains('selected-node')){
            element.classList.remove('selected-node');
          }
        });
      }
    } else {
      console.error('Contenedor no encontrado');
      this.addMessage('Contenedor no encontrado', true, false);
    }
  }

  seleccionar() {
    if(this.objSelected && Object.keys(this.objSelected).length > 0){
      for(const key in this.objSelected){
        const filterByTipoRutDerivado = this.objSelected[key].filter((item: any) => item.tiporut === 'rutDerivado');
        const filterByTipoRutMalla = this.objSelected[key].filter((item: any) => item.tiporut === 'rutMalla');

        if(filterByTipoRutDerivado.length > 10 || filterByTipoRutMalla.length > 10){
          console.error('No se pueden seleccionar más de 10 elementos por entidad');
          this.addMessage('No se pueden seleccionar más de 10 elementos por entidad', true, false);
          return;
        }
      }

      this.dialogRef.close(this.objSelected);
        
    } else {
      console.error('No se han seleccionado ruts');
      this.addMessage('No se han seleccionado ruts', true, false);

    }
  }

  addMessage(mensaje: string, isError: boolean, isSuccess: boolean) : void {
    this.messages.push({ text: mensaje, isError: isError, isSuccess: isSuccess });
  }

  removeMessage(index: number) {
    this.messages.splice(index, 1);
  }

}
