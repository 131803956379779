import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SolicitudCreditoServiceWorkflow } from 'src/app/shared/services/solicitud-credito-workflow.service';
import { AlertService } from '../../_alert';
import { ModalEntidadesService } from 'src/app/shared/services/modal-entidades.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { parse, eval as expresEval } from 'expression-eval';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { RutFormatPipe } from 'src/app/shared/pipes/rutFormat.pipe';
import { MatDialog } from '@angular/material/dialog';
import { MallaSocietariaGraphComponent } from 'src/app/components/malla-societaria-graph/malla-societaria-graph.component';

@Component({
  selector: 'app-consumo-servicios-derivados-workflow',
  templateUrl: './consumo-servicios-derivados-workflow.component.html',
  styleUrls: ['./consumo-servicios-derivados-workflow.component.scss'],
  providers: [RutFormatPipe]
})
export class ConsumoServiciosDerivadosWorkflowComponent implements OnInit, OnDestroy {
  @Input() informeNuevo: boolean = false;
  @Input() idTransaccion: string = '';
  @Input() stepper!: number;
  @Input() rut: string = '';
  @Output() operacionNuevo = new EventEmitter<boolean>();

  @ViewChildren("checkboxesbuscar") checkboxesBuscar: QueryList<ElementRef> | undefined;
  @ViewChildren("checkboxesactualizar") checkboxesActualizar: QueryList<ElementRef> | undefined;

  @ViewChildren("checkboxesbuscarreporte") checkboxesBuscarReporte: QueryList<ElementRef> | undefined;
  @ViewChildren("checkboxesactualizarreporte") checkboxesActualizarReporte: QueryList<ElementRef> | undefined;

  @ViewChildren("checkboxesbuscarentidad") checkboxesBuscarEntidad: QueryList<ElementRef> | undefined;
  @ViewChildren("checkboxesactualizarentidad") checkboxesActualizarEntidad: QueryList<ElementRef> | undefined;

  @ViewChildren("checkboxesfila") checkboxesfila: QueryList<ElementRef> | undefined;
  @ViewChildren("checkboxesfilatodos") checkboxesfilatodos: QueryList<ElementRef> | undefined;
  
  public objectKeys = Object.keys;
  public objectEntries = Object.entries;
  public subscriptions: Subscription[] = [];
  public bodyModelos: any = {};
  public entidades: any[] = [];
  public contratoEntidades: any[] = [];
  public contratoServicios: any[] = [];
  public entidadesValidadas: any[] = [];

  public requestOriginal: any[] = [];
  public entidadesDerivadasAConsultar: any[] = [];
  public listaRutsParaConsultar: any[] = [];

  public entidadRutList: any = {};

  public responseConsulta: any = {};

  public objOrder: any[] = [];

  public camposInternos: any = {
    'idOrganizacion': this._sessionService.getOrganization(),
    'idUsuario': this._sessionService.getUserInfo().username,
    'requestOrigin': "WEB"
  };

  public seeResults: boolean = false;

  public entidadesDerivadasMalla: any = {};

  private razonSocialPrincipal: string = '';
  public graphMallaSocietaria: any[] = [];
  
  constructor(
    private _sessionService: SessionService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private modalService: ModalEntidadesService,
    private utilsService: UtilsService,
    private rutFormatPipe: RutFormatPipe,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.operacionNuevo.emit(false);
    this.obtenerFlujo();
  }

  // Obtiene el flujo y valida si fue la primera llegada a la vista
  obtenerFlujo() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      let paso: any;
      let pasoAnterior: any;
      resp.stages.forEach((e: any) => {
        if(Number(e.stageID) === Number(this.stepper + 2)){
          paso = e;
        } else if (e.stageID === '4'){
          pasoAnterior = e;
        }
      });
      // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
      if(pasoAnterior && (!pasoAnterior?.completed && pasoAnterior?.optional === false)){
        const pasosCompleted = resp.stages.filter((e: any) => e.completed);
        const cantidad = pasosCompleted.length;
        const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
        let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
        if (!step[0]?.step){
          step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
        }
        this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
        this.solicitudCreditoService.setStep(Number(step[0]?.step));
      } else {
        this.subscriptions.push(this.solicitudCreditoService.entidadesDerivadas(this.rut, this.idTransaccion).subscribe(
          resp => {
            this.razonSocialPrincipal = resp?.SolicitudDeCredito?.DatosBasicosSolicitud?.Nombre || '';
            this.entidades = resp?.SolicitudDeCredito?.Stage5Response?.Entidades; //listado de entidades
            this.contratoEntidades = resp?.SolicitudDeCredito?.Stage5Response?.Contrato;

            if(resp?.SolicitudDeCredito?.Stage5Response?.errors?.hasError === true){
              this.alertService.error(resp?.SolicitudDeCredito?.Stage5Response?.errors?.details?.message || 'Solicitud de Crédito - Ocurrio un error al obtener las entidades');
            }

            // Se debe validar entidades con el contrato
            this.validacionEntidadesContrato();
            
            if(this.entidades.length > 0 && this.contratoEntidades.length > 0){

              if(resp?.SolicitudDeCredito?.Stage6Response?.errors?.hassError === true){
                this.alertService.error(resp?.SolicitudDeCredito?.Stage6Response?.errors?.details?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los servicios disponibles');
              }

              if(resp?.SolicitudDeCredito?.Stage6Response?.ContratoServicios?.length > 0) {
                this.contratoServicios = resp?.SolicitudDeCredito?.Stage6Response?.ContratoServicios;
                if(Object.keys(resp?.SolicitudDeCredito?.Stage7Response).length > 0){
                  //if(Object.keys(resp?.SolicitudDeCredito?.Stage7Response?.ReportesDerivados).length > 0){ // ERROR por : ''
                    this.responseConsulta = this.mapeoResponseConsulta(resp?.SolicitudDeCredito?.Stage7Response);
                    this.crearRutsDerivadosMallaV2();
                    this.entidadesDerivadasAConsultar = (resp?.SolicitudDeCredito?.Stage7Response?.request || []);
                    this.requestOriginal = (JSON.parse(JSON.stringify(resp?.SolicitudDeCredito?.Stage7Response?.request)) || []);
                    this.seeResults = true;
                    this.spinner.hide();
                    if(Object.keys(this.responseConsulta).length <= 0){
                      this.consultaAutomatica();
                    }
                  //}
                } else {
                  this.spinner.hide();
                  this.consultaAutomatica();
                }
              } else {
                this.subscriptions.push(this.solicitudCreditoService.serviciosDisponibles(this.rut, this.idTransaccion, false).subscribe(respuesta => {
                  this.razonSocialPrincipal = this.razonSocialPrincipal ? this.razonSocialPrincipal : resp?.SolicitudDeCredito?.DatosBasicosSolicitud?.Nombre || '';
                  this.contratoServicios = respuesta?.SolicitudDeCredito?.Stage6Response?.ContratoServicios;
                  if(respuesta?.SolicitudDeCredito?.Stage6Response?.errors?.hassError === true){
                    this.alertService.error(respuesta?.SolicitudDeCredito?.Stage6Response?.errors?.details?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los servicios disponibles');
                    this.spinner.hide();
                  } else {
                    this.spinner.hide();
                    this.consultaAutomatica();
                  }
                }, (error: any) => {
                  this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener servicios disponibles');
                  this.spinner.hide();
                }));
              }
            }
          }, 
          (error) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener entidades derivadas');
            this.spinner.hide();
        }));
      }
      

    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito');
      this.spinner.hide();
    }));
    
  }

  cambioDeCampo(entidad: string, campo: string): void{
    const index = this.entidadesValidadas.findIndex(e => e.entidad === entidad)
    if(index >= 0){
      let order = '';

      const orderIndex = this.objOrder.findIndex(e => e.entidad === entidad);
      if(orderIndex >= 0){

        if(campo != this.objOrder[orderIndex].campo){
          this.objOrder[orderIndex].order = '';
        }
        if((this.objOrder[orderIndex].order === '' || this.objOrder[orderIndex].order === 'desc') && campo === this.objOrder[orderIndex].campo){
          this.objOrder[orderIndex].order = 'asc';
        } else if((this.objOrder[orderIndex].order === 'asc') && campo === this.objOrder[orderIndex].campo){
          this.objOrder[orderIndex].order = 'desc';
        }
        this.objOrder[orderIndex].campo = campo;
        order = this.objOrder[orderIndex].order;

      } else {
        this.objOrder.push({campo: campo, entidad: entidad, order: "asc"});
        order = "asc";
      }

      this.filtrarColumna(index, campo, order);
    }

  }

  filtrarColumna(index: number, campo: string, order: string): void {
    if(order === '' || order === 'asc'){
      this.entidadesValidadas[index].items.sort((a: any, b: any) => {
        if(campo == 'rut'){
          return parseFloat(a[campo]) - parseFloat(b[campo]);
        } else {
          const textA = this.obtenerAtributoEntidadValidada(a.atributos, campo);
          const textB = this.obtenerAtributoEntidadValidada(b.atributos, campo);
          return (textA > textB) ? 1 : ((textB > textA) ? -1 : 0)
        }
      });
    } else {
      this.entidadesValidadas[index].items.sort((a: any, b: any) => {
        if(campo == 'rut'){
          return parseFloat(b[campo]) - parseFloat(a[campo]);
        } else {
          const textA = this.obtenerAtributoEntidadValidada(a.atributos, campo);
          const textB = this.obtenerAtributoEntidadValidada(b.atributos, campo);
          return -1 *((textA > textB) ? 1 : ((textB > textA) ? -1 : 0))
        }
      });

    }    
  }

  isNumber(val: any): boolean { return typeof val === 'number'; }

  // Abre modal de entidades
  abrirModal(): void{
    this.modalService.open();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  // Valida si las entidades siguen el contrato
  validacionEntidadesContrato(): void {
    const entidadesValidadas: any[] = [];

    this.entidades.forEach((element: any) => {

      const indiceEntidad = this.contratoEntidades.findIndex((elemento: any) => elemento.key == element.entidad);
      if(indiceEntidad >= 0){
        const items: any[] = [];
        const entidad = element;
        entidad.name = this.contratoEntidades[indiceEntidad]?.name || element.entidad;
        
        element.items.forEach((item: any) => {
          const itemValidado = item;
          const atributos: any[] = [];

          this.contratoEntidades[indiceEntidad].outputVars.forEach((atributo: any) => {
            const indiceAtributo = item.atributos.findIndex((elemento: any) => elemento.name == atributo.name);
            if(indiceAtributo >= 0 && atributo?.display !== false ){ // agregar display !== false
              const atributoValidado = item.atributos[indiceAtributo];
              atributoValidado.displayName = atributo?.displayName || item.atributos[indiceAtributo].name;
              atributos.push(atributoValidado);
            } else if(indiceAtributo < 0 && atributo?.name !== 'rut' && atributo?.name !== 'Rut' && atributo?.display !== false ) {
              atributos.push({
                displayName: atributo?.displayName || '',
                name: atributo?.name || '',
                value: ""
              });
            }
          });

          itemValidado.atributos = atributos;

          items.push(itemValidado);
        });
        entidad.items = items;
        entidadesValidadas.push(entidad)
      }
    });

    this.entidadesValidadas = entidadesValidadas;
  }

  obtenerAtributoEntidadValidada(atributos: any[], nombreAtributo: string): string {
    const nombre = atributos.find(e => e?.name?.toUpperCase() === nombreAtributo.toUpperCase());
    if(nombre){
      return nombre?.value || '';
    }
    return '';
  }

  validaCantRuts(valorMaximo: number, entidad: string[], isMalla: boolean = false): boolean {
    let array: any = [];
    let respuesta: boolean = true;
    if(entidad.length > 0){
      entidad.forEach((item: string) => {
        if(item){
          if(this.entidadRutList[item]){

            const arrayFilter: any[] = isMalla ? this.entidadRutList[item].filter((e: any) => e.levelOneRut || e.levelOneRut === null) :
              this.entidadRutList[item].filter((e: any) => !e.levelOneRut);

            if(arrayFilter.length > 0){
              Object.values(arrayFilter).forEach((element) => {
                array = array.concat(element)
              }) 
            }
            if(array.length > valorMaximo){
              respuesta = false;
            }

          }
        }
      })
    }
    return respuesta;
  }

  // Agrega o quita rut para la consulta
  agregarRutServicio(agregar: boolean, entidad: string, rut: string, servicioKey: string, htmlID: string, levelOneRut?: string | null): void {
    if(agregar){
      if(!this.entidadRutList[entidad]){
        this.entidadRutList[entidad] = [];
        const obJ: any = {rut: rut, servicioKey: [], htmlIds: []};
        if(levelOneRut || levelOneRut === null){
          obJ.levelOneRut = levelOneRut;
        }
        const largo = this.entidadRutList[entidad].push(obJ);
        this.entidadRutList[entidad][largo - 1].servicioKey.push(servicioKey);
        this.entidadRutList[entidad][largo - 1].htmlIds.push(htmlID);
      } else {
        const index = levelOneRut || levelOneRut === null ? this.entidadRutList[entidad].findIndex((e:any) => e.rut === rut && e.levelOneRut === levelOneRut) : 
          this.entidadRutList[entidad].findIndex((e:any) => e.rut === rut && !e.levelOneRut);
        if(index < 0){
          const obJ: any = {rut: rut, servicioKey: [], htmlIds: []};
          if(levelOneRut || levelOneRut === null){
            obJ.levelOneRut = levelOneRut;
          }
          const long = this.entidadRutList[entidad].push(obJ);
          this.entidadRutList[entidad][long - 1].servicioKey.push(servicioKey);
          this.entidadRutList[entidad][long - 1].htmlIds.push(htmlID);
        } else {
          const indiceServicioKey = this.entidadRutList[entidad][index].servicioKey.findIndex((e: any) => e === servicioKey);
          if(indiceServicioKey >= 0){
            this.entidadRutList[entidad][index].servicioKey.splice(indiceServicioKey, 1);
          }
          const indiceServicioHTML = this.entidadRutList[entidad][index].htmlIds.findIndex((e: any) => e.includes(servicioKey));
          if(indiceServicioHTML >= 0){
            this.entidadRutList[entidad][index].htmlIds.splice(indiceServicioHTML, 1);
          }
          this.entidadRutList[entidad][index].servicioKey.push(servicioKey);
          this.entidadRutList[entidad][index].htmlIds.push(htmlID);

        }
      }

    } else {
      if(this.entidadRutList[entidad]){
        const index = levelOneRut || levelOneRut === null ? this.entidadRutList[entidad].findIndex((e:any) => e.rut === rut && e.levelOneRut === levelOneRut) : 
          this.entidadRutList[entidad].findIndex((e:any) => e.rut === rut && !e.levelOneRut);
        if(index >= 0){
          // buscar en lista de servicios y borrar
          const indiceServicio = this.entidadRutList[entidad][index].servicioKey.findIndex((e:any) => e === servicioKey);
          const indiceHtml = this.entidadRutList[entidad][index].htmlIds.findIndex((e:any) => e === htmlID);
          if(indiceServicio >= 0){
            this.entidadRutList[entidad][index].htmlIds.splice(indiceHtml, 1);
            this.entidadRutList[entidad][index].servicioKey.splice(indiceServicio, 1);
            if(this.entidadRutList[entidad][index].servicioKey.length === 0){
              this.entidadRutList[entidad].splice(index, 1);
              if(this.entidadRutList[entidad].length === 0){
                delete this.entidadRutList[entidad];
              }
            }
          }

        }
      }
    }
  }

  // Agrega los datos del servicio predeterminado, es decir con los valores que indica al inicio el servicio
  agregaServicioPredeterminado(entidad: string, rut: string, servicioPredeterminado: any, indexServicio: string, levelOneRut?: string | null): void {
    const valor: any = {};
    valor.index = indexServicio;

    servicioPredeterminado?.inputVars?.forEach((element: any) => {
      if(element.key === 'rut'){
        element.value = rut;
        valor[element.key] = element.value;
      } else if (this.camposInternos[element.key]) {
        element.value = this.camposInternos[element.key];
        valor[element.key] = element.value;
      } else if (element.displayDependency !== ''){
        const condicion = element.displayDependency;
        if(condicion !== ''){
          const condicionParsed = parse(condicion);
          const formTemp = valor; // objeto necesario para la validacion con datos desde la api
          const str =  expresEval(condicionParsed, {formTemp}); //condicion que viene con la variable formTemp desde back para evaluar

          if(str === true){
            element.value = element.value;
          } else {
            element.value = '';
          }
          valor[element.key] = element.value;
        }
      } else {
        valor[element.key] = element.value;
      }

    });

    const objEntidad: any = {
      entidad: entidad,
      items: []
    };

    const objRut: any = {
      rut: rut,
      servicioAConsultar: []
    };

    if(levelOneRut || levelOneRut === null){
      objRut.levelOneRut = levelOneRut;
    }

    const servicioAConsultar: any = {
      key: indexServicio,
      values: []
    }

    if(this.entidadesDerivadasAConsultar.length > 0){
      const indiceEntidad = this.entidadesDerivadasAConsultar.findIndex((elemento: any) => elemento.entidad == entidad);
      if(indiceEntidad >= 0){
        const indiceRut = levelOneRut || levelOneRut === null ? this.entidadesDerivadasAConsultar[indiceEntidad].items.findIndex((elemento: any) => elemento.rut == rut && elemento.levelOneRut == levelOneRut) : 
          this.entidadesDerivadasAConsultar[indiceEntidad].items.findIndex((elemento: any) => elemento.rut == rut && !elemento.levelOneRut);
        if(indiceRut >= 0){
          const indiceServicio = this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar.findIndex((elemento: any) => elemento.key == indexServicio);
          if(indiceServicio >= 0){
            this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar[indiceServicio].values = [];
            this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar[indiceServicio].values.push(servicioPredeterminado);
          } else {
            servicioAConsultar.values.push(servicioPredeterminado);
            this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar.push(servicioAConsultar);
          } 
        } else {
          servicioAConsultar.values.push(servicioPredeterminado);
          objRut.servicioAConsultar.push(servicioAConsultar);
          this.entidadesDerivadasAConsultar[indiceEntidad].items.push(objRut);
        }
      } else {
        servicioAConsultar.values.push(servicioPredeterminado);
        objRut.servicioAConsultar.push(servicioAConsultar);
        objEntidad.items.push(objRut);
        this.entidadesDerivadasAConsultar.push(objEntidad);
      }
    } else {
      servicioAConsultar.values.push(servicioPredeterminado);
      objRut.servicioAConsultar.push(servicioAConsultar);
      objEntidad.items.push(objRut);
      this.entidadesDerivadasAConsultar.push(objEntidad);
    }
  }

  // Quita el objeto de un servicio
  quitarServicioPredeterminado(entidad:string, rut: string, servicio: string, levelOneRut?: string | null): void{
    if(this.entidadesDerivadasAConsultar.length > 0){
      const indiceEntidad = this.entidadesDerivadasAConsultar.findIndex((elemento: any) => elemento.entidad == entidad);
      if(indiceEntidad >= 0){
        const indiceRut = levelOneRut || levelOneRut === null ? this.entidadesDerivadasAConsultar[indiceEntidad].items.findIndex((elemento: any) => elemento.rut == rut && elemento.levelOneRut == levelOneRut) : 
          this.entidadesDerivadasAConsultar[indiceEntidad].items.findIndex((elemento: any) => elemento.rut == rut && !elemento.levelOneRut);
        if(indiceRut >= 0){
          const indiceServicio = this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar.findIndex((elemento: any) => elemento.key == servicio);
          if(indiceServicio >= 0 && this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar[indiceServicio].values[0]){
            this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar[indiceServicio].values.splice(0, 1);
            if(this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar[indiceServicio].values.length === 0){
              this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar.splice(indiceServicio, 1);
              if(this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar.length === 0){
                this.entidadesDerivadasAConsultar[indiceEntidad].items.splice(indiceRut, 1);
                if(this.entidadesDerivadasAConsultar[indiceEntidad].items.length === 0){
                  this.entidadesDerivadasAConsultar.splice(indiceEntidad, 1);
                }
              }
            }
          }
        }
      }
    }
  }

  // Valida si existe un objeto guardado para consultar
  existeBodyGuardado(entidad:string, rut: string, servicio: string, levelOneRut?: string | null): string {
    if(this.entidadesDerivadasAConsultar.length > 0){
      const indiceEntidad = this.entidadesDerivadasAConsultar.findIndex((elemento: any) => elemento.entidad == entidad);
      if(indiceEntidad >= 0){
        const indiceRut = levelOneRut || levelOneRut === null ? this.entidadesDerivadasAConsultar[indiceEntidad].items.findIndex((elemento: any) => elemento.rut == rut && elemento.levelOneRut == levelOneRut) : 
          this.entidadesDerivadasAConsultar[indiceEntidad].items.findIndex((elemento: any) => elemento.rut == rut && !elemento.levelOneRut);
        if(indiceRut >= 0){
          const indiceServicio = this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar.findIndex((elemento: any) => elemento.key == servicio);
          if(indiceServicio >= 0 && this.entidadesDerivadasAConsultar[indiceEntidad].items[indiceRut].servicioAConsultar[indiceServicio].values[0]){
            return this.entidadesDerivadasAConsultar[indiceEntidad]?.items[indiceRut]?.servicioAConsultar[indiceServicio]?.values[0]?.type || ''
          }
        }
      }
    }
    return '';
  }

  // Valida si existe servicio buscar y guarda el objeto con datos predeterminados
  buscarServicio(event: any, entidad: string, servicio: string, indexServicio: number, rut: string): void{
    const checkboxActualizar = document.getElementById('checkboxactualizar-' + rut + '-' + entidad + '-' + servicio) as HTMLInputElement;
    const id = event?.target?.id;

    if(checkboxActualizar){
      checkboxActualizar.checked = false;
    }

    if (event?.target?.checked){
      if(this.existeBodyGuardado(entidad, rut, servicio) !== 'buscarHistorico'){
        const indiceServicio = this.contratoServicios[indexServicio].values.findIndex((elemento: any) => elemento.type === 'buscarHistorico');
        this.quitarServicioPredeterminado(entidad, rut, servicio);
    
        if(indiceServicio >= 0){
          const objActual = JSON.parse(JSON.stringify(this.contratoServicios[indexServicio].values[indiceServicio]));
          this.agregaServicioPredeterminado(entidad, rut, objActual, this.contratoServicios[indexServicio].key);
        }
      }
      this.agregarRutServicio(true, entidad, rut, this.contratoServicios[indexServicio].key, id);
    } else {
      this.quitarServicioPredeterminado(entidad, rut, servicio);
      this.agregarRutServicio(false, entidad, rut, this.contratoServicios[indexServicio].key, id);
    }

  }

  // Valida si existe servicio actualizar o crear y guarda el objeto con datos predeterminados
  actualizarServicio(event: any, entidad: string, servicio: string, indexServicio: number, rut: string): void{
    const checkboxBuscar = document.getElementById('checkboxbuscar-'+ rut + '-' + entidad + '-' + servicio) as HTMLInputElement;
    const id = event?.target?.id;

    if(checkboxBuscar){
      checkboxBuscar.checked = false;
    }

    if (event?.target?.checked){
      if(this.existeBodyGuardado(entidad, rut, servicio) !== 'crear'){
        const indiceServicio = this.contratoServicios[indexServicio].values.findIndex((elemento: any) => elemento.type === 'crear');
        this.quitarServicioPredeterminado(entidad, rut, servicio);
    
        if(indiceServicio >= 0){
          const objActual = JSON.parse(JSON.stringify(this.contratoServicios[indexServicio].values[indiceServicio]));
          this.agregaServicioPredeterminado(entidad, rut, objActual, this.contratoServicios[indexServicio].key);
        }
      }
      this.agregarRutServicio(true, entidad, rut, this.contratoServicios[indexServicio].key, id);
    } else {
      this.quitarServicioPredeterminado(entidad, rut, servicio);
      this.agregarRutServicio(false, entidad, rut, this.contratoServicios[indexServicio].key, id);
    }

  }

  buscarTodosReporte(event: any, entidad: string, servicio: string): void {
    const checkboxActualizar = document.getElementById('checkboxactualizar-'+ servicio + '-' + entidad) as HTMLInputElement;

    let quitarMalla: boolean = true;
    if(entidad.includes('-entidadMalla')){
      quitarMalla = false;
    }

    this.checkboxesBuscar?.forEach(element => {
      if(quitarMalla) {
        if(!(event?.target?.checked === element?.nativeElement?.checked) && element?.nativeElement?.id.includes('checkboxbuscar') === true 
        && element?.nativeElement?.id.includes(servicio) === true && element?.nativeElement?.id.includes(entidad) === true
        && !element?.nativeElement?.id.includes('-entidadMalla')){
          element.nativeElement.click()
        }
      } else {
        const entidadBase = entidad.replace('-entidadMalla', '');
        if(!(event?.target?.checked === element?.nativeElement?.checked) && element?.nativeElement?.id.includes('checkboxbuscar') === true 
        && element?.nativeElement?.id.includes(servicio) === true && element?.nativeElement?.id.includes(entidadBase) === true
        && element?.nativeElement?.id.includes('-entidadMalla') === true){
          element.nativeElement.click()
        }
      }
    })

    if(checkboxActualizar){
      checkboxActualizar.checked = false;
    }
  }

  actualizarTodosReporte(event: any, entidad: string, servicio: string): void {
    const checkboxBuscar = document.getElementById('checkboxbuscar-'+ servicio + '-' + entidad) as HTMLInputElement;

    let quitarMalla: boolean = true;
    if(entidad.includes('-entidadMalla')){
      quitarMalla = false;
    }

    this.checkboxesActualizar?.forEach(element => {
      if(quitarMalla) {
        if(!(event?.target?.checked === element?.nativeElement?.checked) && element?.nativeElement?.id.includes('checkboxactualizar') === true 
        && element?.nativeElement?.id.includes(servicio) === true && element?.nativeElement?.id.includes(entidad) === true
        && !element?.nativeElement?.id.includes('-entidadMalla') === true){
          element.nativeElement.click()
        }
      } else {
        const entidadBase = entidad.replace('-entidadMalla', '');
        if(!(event?.target?.checked === element?.nativeElement?.checked) && element?.nativeElement?.id.includes('checkboxactualizar') === true 
        && element?.nativeElement?.id.includes(servicio) === true && element?.nativeElement?.id.includes(entidadBase) === true
        && element?.nativeElement?.id.includes('-entidadMalla') === true){
          element.nativeElement.click()
        }
      }
    })
    
    if(checkboxBuscar){
      checkboxBuscar.checked = false;
    }
  }

  buscarTodosEntidad(event: any, entidad: string): void {
    const checkboxActualizar = document.getElementById('checkboxactualizar-' + entidad) as HTMLInputElement;

    let quitarMalla: boolean = true;
    if(entidad.includes('-entidadMalla')){
      quitarMalla = false;
    }

    this.checkboxesBuscar?.forEach(element => {
      if(quitarMalla) {
        if(!(event?.target?.checked === element?.nativeElement?.checked) && element?.nativeElement?.id.includes('checkboxbuscar') === true 
          && element?.nativeElement?.id.includes(entidad) === true
          && !element?.nativeElement?.id.includes('-entidadMalla') === true){
          element.nativeElement.click()
        }
      } else {
        const entidadBase = entidad.replace('-entidadMalla', '');
        if(!(event?.target?.checked === element?.nativeElement?.checked) && element?.nativeElement?.id.includes('checkboxbuscar') === true 
          && element?.nativeElement?.id.includes(entidadBase) === true && element?.nativeElement?.id.includes('-entidadMalla') === true){
          element.nativeElement.click()
        }
      }
    })

    if(checkboxActualizar){
      checkboxActualizar.checked = false;
    }
  }

  actualizarTodosEntidad(event: any, entidad: string): void {
    const checkboxBuscar = document.getElementById('checkboxbuscar-' + entidad) as HTMLInputElement;

    let quitarMalla: boolean = true;
    if(entidad.includes('-entidadMalla')){
      quitarMalla = false;
    }
    
    this.checkboxesActualizar?.forEach(element => {
      if(quitarMalla) {
        if(!(event?.target?.checked === element?.nativeElement?.checked) && element?.nativeElement?.id.includes('checkboxactualizar') === true 
          && element?.nativeElement?.id.includes(entidad) === true
          && !element?.nativeElement?.id.includes('-entidadMalla') === true){
          element.nativeElement.click()
        }
      } else {
        const entidadBase = entidad.replace('-entidadMalla', '');
        if(!(event?.target?.checked === element?.nativeElement?.checked) && element?.nativeElement?.id.includes('checkboxactualizar') === true 
          && element?.nativeElement?.id.includes(entidadBase) === true && element?.nativeElement?.id.includes('-entidadMalla') === true){
          element.nativeElement.click()
        }
      }
    })
    
    if(checkboxBuscar){
      checkboxBuscar.checked = false;
    }
  }

  habilitaFila(event: any, entidad: string, rut: string, id?: string) : void {
    let contador = 0;
    let idFila: string = '';
    let quitarMalla: boolean = true;

    if(entidad.includes('-entidadMalla')){
      quitarMalla = false;
      idFila = 'checkboxfila-' + id + '-' + entidad + '-entidadMalla';
    } else {
      idFila = 'checkboxfila-' + rut + '-' + entidad;
    }

    if(!quitarMalla && !id){
      return;
    }

    this.checkboxesfila?.forEach(element => {
      if(quitarMalla){
        if(element?.nativeElement?.checked === true && element?.nativeElement?.id === idFila){
          contador++;
        }
      } else {
        if(element?.nativeElement?.checked === true && element?.nativeElement?.id === idFila){
          contador++;
        }
      }
    });
    
    if(contador <= 10){
      this.checkboxesBuscar?.forEach(element => {
        if(quitarMalla) {
          if(element?.nativeElement?.id.includes('checkboxbuscar') === true && element?.nativeElement?.id.includes(entidad) === true 
            && element?.nativeElement?.id.includes(rut) === true
            && !element?.nativeElement?.id.includes('-entidadMalla') === true){
            if(event?.target?.checked === false && element?.nativeElement?.checked === true){
              element.nativeElement.click();
            }
            element.nativeElement.disabled = !event?.target?.checked;
          }
        } else {
          const entidadBase = entidad.replace('-entidadMalla', '');
          const idBase = 'checkboxbuscar-' + id + '-' + entidadBase;
          if(element?.nativeElement?.id.includes('checkboxbuscar') === true
            && element?.nativeElement?.id.includes(idBase) === true
            && element?.nativeElement?.id.includes('-entidadMalla') === true){
            if(event?.target?.checked === false && element?.nativeElement?.checked === true){
              element.nativeElement.click();
            }
            element.nativeElement.disabled = !event?.target?.checked;
          }
        }

      })
  
      this.checkboxesActualizar?.forEach(element => {
        if(quitarMalla) {
          if(element?.nativeElement?.id.includes('checkboxactualizar') === true && element?.nativeElement?.id.includes(entidad) === true 
            && element?.nativeElement?.id.includes(rut) === true
            && !element?.nativeElement?.id.includes('-entidadMalla') === true){
            if(event?.target?.checked === false && element?.nativeElement?.checked === true){
              element.nativeElement.click();
            }
            element.nativeElement.disabled = !event?.target?.checked;
          }
        } else {
          const entidadBase = entidad.replace('-entidadMalla', '');
          const idBase = 'checkboxactualizar-' + id + '-' + entidadBase;
          if(element?.nativeElement?.id.includes('checkboxactualizar') === true
            && element?.nativeElement?.id.includes(idBase) === true
            && element?.nativeElement?.id.includes('-entidadMalla') === true){
            if(event?.target?.checked === false && element?.nativeElement?.checked === true){
              element.nativeElement.click();
            }
            element.nativeElement.disabled = !event?.target?.checked;
          }
        }
      })
    } else {
      if(event?.target?.checked === true)
        event.target.checked = false;
    }

  }

  habilitaFilaTodos(event: any, entidad: string): void {
    let quitarMalla: boolean = true;
    if(entidad.includes('-entidadMalla')){
      quitarMalla = false;
    }

    if(!event?.target?.checked){
      this.checkboxesfila?.forEach(element => {
        if(quitarMalla) {
          if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxfila') === true && element?.nativeElement?.id.includes(entidad) === true
            && !element?.nativeElement?.id.includes('-entidadMalla') === true){
            element.nativeElement.click()
          }
        } else {
          if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxfila') === true && element?.nativeElement?.id.includes(entidad) === true){
            element.nativeElement.click()
          }
        }
      });
    } else {
      let contador = 0;
      this.checkboxesfila?.forEach(element => {
        if(quitarMalla) {
          if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxfila') === true && element?.nativeElement?.id.includes(entidad) === true
            && !element?.nativeElement?.id.includes('-entidadMalla') === true){
            contador++;
          }
        } else {
          if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxfila') === true && element?.nativeElement?.id.includes(entidad) === true){
            contador++;
          }
        }
      });

      if(contador <= 10){
        this.checkboxesfila?.forEach(element => {
          if(quitarMalla) {
            if(contador < 10 && element?.nativeElement?.checked === false && element?.nativeElement?.id.includes('checkboxfila') === true && element?.nativeElement?.id.includes(entidad) === true
              && !element?.nativeElement?.id.includes('-entidadMalla') === true){
              element.nativeElement.click()
              contador ++;
            }
          } else {
            if(contador < 10 && element?.nativeElement?.checked === false && element?.nativeElement?.id.includes('checkboxfila') === true && element?.nativeElement?.id.includes(entidad) === true){
              element.nativeElement.click()
              contador ++;
            }
          }
        });
      }
    }

  }

  limpiarChecked(entidad: string[]): void {
    if(entidad.length > 0){
      entidad.forEach(item => {
        if(item) {
          this.checkboxesBuscar?.forEach(element => {
            if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxbuscar') === true && element?.nativeElement?.id.includes(item) === true){
              element.nativeElement.click()
            }
          });
          this.checkboxesActualizar?.forEach(element => {
            if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxactualizar') === true && element?.nativeElement?.id.includes(item) === true){
              element.nativeElement.click()
            }
          });
          this.checkboxesBuscarReporte?.forEach(element => {
            if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxbuscar') === true && element?.nativeElement?.id.includes(item) === true){
              element.nativeElement.click()
            }
          });
          this.checkboxesActualizarReporte?.forEach(element => {
            if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxactualizar') === true && element?.nativeElement?.id.includes(item) === true){
              element.nativeElement.click()
            }
          });
          this.checkboxesBuscarEntidad?.forEach(element => {
            if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxbuscar') === true && element?.nativeElement?.id.includes(item) === true){
              element.nativeElement.click()
            }
          });
          this.checkboxesActualizarEntidad?.forEach(element => {
            if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxactualizar') === true && element?.nativeElement?.id.includes(item) === true){
              element.nativeElement.click()
            }
          });
          this.checkboxesfila?.forEach(element => {
            if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxfila') === true && element?.nativeElement?.id.includes(item) === true){
              element.nativeElement.click()
            }
          });
          this.checkboxesfilatodos?.forEach(element => {
            if(element?.nativeElement?.checked === true && element?.nativeElement?.id.includes('checkboxfilatodos') === true && element?.nativeElement?.id.includes(item) === true){
              element.nativeElement.click()
            }
          });
        }
      })
    }    
  }

  limpiarEntidadRutList(entidad: string[]): void {
    if(entidad.length > 0){
      entidad.forEach(e => {
        if(e){
          if(this.entidadRutList[e]){
            delete this.entidadRutList[e];
          }
        }
      })
    }
  }

  actualizaRequest(entidad: string[], request: any[]): void {
    if(request && entidad.length > 0){{
      entidad.forEach(item => {
        if(item) {
          const entidadRequest = request.find(e => e?.entidad === item)
          if(entidadRequest){
            const indexEntidad = this.entidadesDerivadasAConsultar.findIndex(e => e.entidad === item)
            if(indexEntidad >= 0){
              this.entidadesDerivadasAConsultar[indexEntidad] = entidadRequest;
            }
          }
        }
      })
    }
    }
  }

  // Consulta de servicios con el objeto formado previamente
  consultar(entidadArray: string[], isMalla: boolean, isAutomatica: boolean): void{
    if(entidadArray.length <= 0){
      this.alertService.error('Solicitud de Crédito - No se detectan entidades seleccionadas.');
      return;
    }

    if(isAutomatica){
      // No deberian haber datos de malla en automatica
      if(Object.keys(this.entidadRutList).length <= 0){
        this.alertService.error('Solicitud de Crédito - No se detectan datos seleccionados para consultar.');
        return;
      }
    } else {
      // Si no es automatica se valida por la unica entidad que se envia si tiene datos
      const arrayFilter: any[] = isMalla ? this.entidadRutList[entidadArray[0]].filter((e: any) => e.levelOneRut || e.levelOneRut === null) :
        this.entidadRutList[entidadArray[0]].filter((e: any) => !e.levelOneRut);
      if(Object.keys(arrayFilter).length <= 0){
        this.alertService.error('Solicitud de Crédito - No se detectan datos seleccionados para consultar.');
        return;
      }
    }

    if(!this.validaCantRuts(10, entidadArray)){
      this.alertService.error('Solicitud de Crédito - No se pueden realizar consultas con mas de 10 ruts seleccionados de la misma entidad.');
      return;
    }

    this.spinner.show();
    const entidadesFiltradas: any[] = [];
    const listaEntidades = Object.keys(this.entidadRutList);
    let existeServicios: boolean = false;

    entidadArray.forEach((itemEntidad: string) => {
      if(itemEntidad){
        this.entidadesDerivadasAConsultar.forEach((element: any) => {
          if(element.entidad === itemEntidad){
            if (listaEntidades.indexOf(element.entidad) >= 0) {
              const entidad: any = {
                entidad: element.entidad,
                items: []
              }
      
              element.items.forEach((item: any) => {
                let indiceRut: number = -1;
                if(isMalla) {
                  if(item?.levelOneRut || item?.levelOneRut === null){
                    indiceRut = this.entidadRutList[element.entidad].findIndex((elemento: any) => elemento.rut === item.rut 
                      && elemento.levelOneRut === item.levelOneRut);
                  }
                } else {
                  if(!item?.levelOneRut){
                    indiceRut = this.entidadRutList[element.entidad].findIndex((elemento: any) => elemento.rut === item.rut && !elemento?.levelOneRut);
                  }
                }
                if(indiceRut >= 0){
                  const objetoRut: any = {
                    rut: item.rut,
                    servicioAConsultar: []
                  }

                  if(item?.levelOneRut || item?.levelOneRut === null){
                    objetoRut.levelOneRut = item.levelOneRut;
                  }
      
                  item.servicioAConsultar.forEach((servicio: any) => {
                    const indiceServicio = this.entidadRutList[element.entidad][indiceRut].servicioKey.findIndex((elemento: any) => elemento === servicio.key);
                    if(indiceServicio >= 0){
                      objetoRut.servicioAConsultar.push(servicio);
                      existeServicios = true;
                    }
                  });
      
                  if(objetoRut.servicioAConsultar.length > 0){
                    entidad.items.push(objetoRut);
                  }
                }
              });
      
              if(entidad.items.length > 0){
                entidadesFiltradas.push(entidad);
              }
      
            }
          }
        });
      }
    })

    if(existeServicios){

      if(!this.validaValuesVacios(entidadesFiltradas)){
        this.alertService.error('Solicitud de Crédito - Se detectaron valores vacios en las consultas a reportes');
        this.spinner.hide();
        return;
      }

      this.subscriptions.push(this.solicitudCreditoService.consultarServiciosDerivados(this.rut, this.idTransaccion, entidadesFiltradas).subscribe(resp => {
        if(Object.keys(resp?.SolicitudDeCredito?.Stage7Response).length > 0){
          this.responseConsulta = this.mapeoResponseConsulta(resp?.SolicitudDeCredito?.Stage7Response);
          this.crearRutsDerivadosMallaV2();
          this.limpiarEntidadRutList(entidadArray);
          this.seeResults = true;
          this.limpiarChecked(entidadArray);
          this.actualizaRequest(entidadArray, resp?.SolicitudDeCredito?.Stage7Response?.request);
          this.requestOriginal = (JSON.parse(JSON.stringify(resp?.SolicitudDeCredito?.Stage7Response?.request)) || []);
        } else {
          this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar los datos de los reportes');
        }
        this.spinner.hide();
      }, (error: any) => {
        this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicios');
        this.spinner.hide();
      }));
    } else {
      this.alertService.error('Solicitud de Crédito - No se encuentran servicios seleccionados');
      this.spinner.hide();
    }
  }

  validaValuesVacios(request: any[]): boolean{
    let valido = true;
    request.forEach(element1 => {
      element1.items.forEach((element2: any) => {
        element2.servicioAConsultar.forEach((element3: any) => {
          element3.values.forEach((element4: any) => {
            const casoInvalido = element4.inputVars.find((e: any) => e.display === true && e.value == '')
            if(casoInvalido){
              valido = false;
            }
          });
        });
      });
    })
    return valido;
  }

  obtieneFecha(fechaReporte: any): string {
    if(fechaReporte && fechaReporte !== 'Sin Información'){
      /*if(Date.parse(fechaReporte)){
        return fechaReporte;
      } else {
        const fecha1 = fechaReporte.split(' ');
        if(fecha1[0]){
          const fecha2 = fecha1[0].split('-');
          if(fecha2[2]){
            const fecha = new Date(fecha2[2], (Number(fecha2[1]) - 1), fecha2[0]).toString();
            return fecha; 
          }
        }
        return ''
      }*/
      if(fechaReporte.includes('T')){
        return fechaReporte;
      } else {
        const fecha1 = fechaReporte.split(' ');
        if(fecha1[0]){
          const fecha2 = fecha1[0].split('-');
          if(fecha2[2]){
            const fecha = new Date(fecha2[2], (Number(fecha2[1]) - 1), fecha2[0]).toISOString();
            return fecha; 
          }
        }
        return ''
      }
    } else {
      return '';
    }
  }

  validaResponse(entidad: string, rut: string, servicio: string): boolean{
    if(this.responseConsulta?.[entidad]?.[rut]?.[servicio]){
      return true;
    }
    return false;
  }

  validaAnyResponse(entidad: string, rut: string): boolean {
    if(this.responseConsulta?.[entidad]?.[rut]){
      const result = this.contratoServicios.filter(s => s.display);
      const largoConsulta = Object.keys(this.responseConsulta?.[entidad]?.[rut]).length;
  
      if(largoConsulta >= result.length)
        return true;
      
    }
    return false;
  }

  mapeoResponseConsulta(Stage7Response: any): any {
    let respuesta: any = {};
    if(Stage7Response){
      respuesta = JSON.parse(JSON.stringify(Stage7Response));
      if(respuesta.request || respuesta.request == ''){
        delete respuesta.request;
      }
    }
    return respuesta;
  }

  validaBorrado(obj: any, entidad: string, rutEntidad: string, servicio: string): boolean {
    if(!(obj?.[entidad]?.[rutEntidad]?.[servicio]?.fechaReporte)){
      return true;
    }
    return false;
  }

  // Arma el objeto y llama a la api para borrar un servicio ya consultado
  borrarServicio(entidad: string, rutEntidad: string, servicio: string): void{
    const entidadesReporte: any[] = []
    if(entidad != ''){
      if(rutEntidad != ''){
        if(servicio != ''){
          entidadesReporte.push({key: entidad, items: [{rut: rutEntidad, services: [servicio]}]})
        }
      }
    }

    if(entidadesReporte.length > 0){
      this.spinner.show();
      this.subscriptions.push(this.solicitudCreditoService.borrarReportesDerivados(this.rut, this.idTransaccion, entidadesReporte).subscribe(resp => {
        if(Object.keys(resp?.SolicitudDeCredito?.Stage7Response).length > 0){
          if(this.validaBorrado(resp?.SolicitudDeCredito?.Stage7Response, entidad, rutEntidad, servicio)){
            this.responseConsulta = this.mapeoResponseConsulta(resp?.SolicitudDeCredito?.Stage7Response);
            this.crearRutsDerivadosMallaV2();
            this.limpiarEntidadRutList([entidad]);
            this.seeResults = true;
            this.limpiarChecked([entidad]);
            this.actualizaRequest([entidad], resp?.SolicitudDeCredito?.Stage7Response?.request);
            this.requestOriginal = (JSON.parse(JSON.stringify(resp?.SolicitudDeCredito?.Stage7Response?.request)) || []);
          } else {
            this.alertService.error('Solicitud de Crédito - Ocurrio un error al validar el borrado del reporte');
          }
        } else {
          this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar los datos de los reportes');
        }
        this.spinner.hide();
      }, (error: any) => {
        this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar el servicio de borrado');
        this.spinner.hide();
      }));
    }

  }

  // Arma el objeto y permite reconsultar un servicio consultado con error
  reconsultar(entidad: string, rutEntidad: string, servicio: string): void{
    const requestReconsulta: any[] = [];
    const indiceEntidad = this.requestOriginal.findIndex((elemento: any) => elemento.entidad == entidad);
    if(indiceEntidad >= 0){
      const indiceRut = this.requestOriginal[indiceEntidad].items.findIndex((elemento: any) => elemento.rut == rutEntidad);
      const objetoRut: any[] = []
      if(indiceRut >= 0){
        const indiceServicio = this.requestOriginal[indiceEntidad].items[indiceRut].servicioAConsultar.findIndex((elemento: any) => elemento.key == servicio);
        const objetoServicio: any[] = [];
        if(indiceServicio >= 0){
          if(this.requestOriginal[indiceEntidad].items[indiceRut].servicioAConsultar[indiceServicio].values.length > 0){
            objetoServicio.push(this.requestOriginal[indiceEntidad].items[indiceRut].servicioAConsultar[indiceServicio]);
          }
        }

        if(objetoServicio.length > 0){
          objetoRut.push({rut: this.requestOriginal[indiceEntidad].items[indiceRut].rut, servicioAConsultar: objetoServicio});
        }

      }

      if(objetoRut.length > 0){
        requestReconsulta.push({entidad: this.requestOriginal[indiceEntidad].entidad, items: objetoRut});
      }
    }

    this.spinner.show();
    if(requestReconsulta.length > 0){
      this.subscriptions.push(this.solicitudCreditoService.consultarServiciosDerivados(this.rut, this.idTransaccion, requestReconsulta).subscribe(resp => {
        if(Object.keys(resp?.SolicitudDeCredito?.Stage7Response).length > 0){
            this.responseConsulta = this.mapeoResponseConsulta(resp?.SolicitudDeCredito?.Stage7Response);
            this.crearRutsDerivadosMallaV2();
            this.limpiarEntidadRutList([entidad]);
            this.seeResults = true;
            this.limpiarChecked([entidad]);
            this.actualizaRequest([entidad], resp?.SolicitudDeCredito?.Stage7Response?.request);
            this.requestOriginal = (JSON.parse(JSON.stringify(resp?.SolicitudDeCredito?.Stage7Response?.request)) || []);
        } else {
          this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar los datos de los reportes');
        }
        this.spinner.hide();
      }, (error: any) => {
        this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicio de reportes');
        this.spinner.hide();
      }));
    } else {
      this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar datos para la consulta');
      this.spinner.hide();
    }

  }

  // Valida si la etapa esta completa y permite avanzar a la siguiente etapa
  avanzarFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[7]?.optional === false){
        if(this.bodyModelos?.stages[7]?.completed){
          this.solicitudCreditoService.setStep('next');
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        this.solicitudCreditoService.setStep('next');
        this.spinner.hide();
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  consultaAutomatica(): void {
    if(this.entidades.length > 0){
      const entidadesValidas: string[] = [];
      this.entidades.forEach((entidad: any) => {
        const entidadKey = entidad?.entidad;
        if(entidad?.items && entidad?.items.length > 0){
          entidad?.items.forEach((item: any) => {
            const rut = item?.rut;
            if(item?.preConsulta && item?.preConsulta.length > 0) {

              const entidadRepetida = entidadesValidas.findIndex(e => e === entidadKey);
              if(entidadRepetida < 0){
                entidadesValidas.push(entidadKey); 
              }

              item?.preConsulta.forEach((preConsulta: any) => {
                const key = preConsulta?.key;
                const type = preConsulta?.type;
                const htmlId = `${type === 'crear' ? "checkboxactualizar" : "checkboxbuscar"}-${rut}-${key}-${entidadKey}`;

                const indexServicio = this.contratoServicios.findIndex(e => key === e?.key);
                if(indexServicio >= 0) {

                  if(type != '') {
                    const indiceServicio = this.contratoServicios[indexServicio].values.findIndex((elemento: any) => elemento.type === type);
                    this.quitarServicioPredeterminado(entidadKey, rut, key);
                
                    if(indiceServicio >= 0){
                      const objActual = JSON.parse(JSON.stringify(this.contratoServicios[indexServicio].values[indiceServicio]));
                      this.agregaServicioPredeterminado(entidadKey, rut, objActual, this.contratoServicios[indexServicio].key);
                    }
                    this.agregarRutServicio(true, entidadKey, rut, key, htmlId);
                  }
                }

              });
            }
          });
        }
      });

      if(Object.keys(this.entidadRutList).length > 0 && this.entidadesDerivadasAConsultar.length > 0 && entidadesValidas.length > 0 && this.validaCantRuts(10, entidadesValidas)){
        this.consultar(entidadesValidas, false, true);
      } else {
        this.entidadRutList = {};
        this.entidadesDerivadasAConsultar = [];
      }

    }

  }

  validarTipoServicio(array: any[], tipo: string): boolean {
    if(array && array.length > 0){
      const index = array.findIndex(e => e?.type === tipo);
      if(index >= 0){
        return true;
      }
    }
    return false;
  }

  /* Funciones para ruts de malla societaria */

  // Valida si existe servicio buscar y guarda el objeto con datos predeterminados
  buscarServicioMalla(event: any, entidad: string, servicio: string, indexServicio: number, rut: string, idRuts: string, levelOneRut: string | null): void{
    if(!idRuts){
      return;
    }

    const checkboxActualizar = document.getElementById('checkboxactualizar-' + idRuts + '-' + entidad + '-' + servicio + '-entidadMalla') as HTMLInputElement;
    const id = event?.target?.id;

    if(checkboxActualizar){
      checkboxActualizar.checked = false;
    }

    if (event?.target?.checked){
      if(this.existeBodyGuardado(entidad, rut, servicio, levelOneRut) !== 'buscarHistorico'){
        const indiceServicio = this.contratoServicios[indexServicio].values.findIndex((elemento: any) => elemento.type === 'buscarHistorico');
        this.quitarServicioPredeterminado(entidad, rut, servicio, levelOneRut);
    
        if(indiceServicio >= 0){
          const objActual = JSON.parse(JSON.stringify(this.contratoServicios[indexServicio].values[indiceServicio]));
          this.agregaServicioPredeterminado(entidad, rut, objActual, this.contratoServicios[indexServicio].key, levelOneRut);
        }
      }
      this.agregarRutServicio(true, entidad, rut, this.contratoServicios[indexServicio].key, id, levelOneRut);
    } else {
      this.quitarServicioPredeterminado(entidad, rut, servicio, levelOneRut);
      this.agregarRutServicio(false, entidad, rut, this.contratoServicios[indexServicio].key, id, levelOneRut);
    }

  }

  actualizarServicioMalla(event: any, entidad: string, servicio: string, indexServicio: number, rut: string, idRuts: string, levelOneRut: string | null): void{
    if(!idRuts){
      return;
    }

    const checkboxBuscar = document.getElementById('checkboxbuscar-' + idRuts + '-' + entidad + '-' + servicio + '-entidadMalla') as HTMLInputElement;
    const id = event?.target?.id;

    if(checkboxBuscar){
      checkboxBuscar.checked = false;
    }

    if (event?.target?.checked){
      if(this.existeBodyGuardado(entidad, rut, servicio, levelOneRut) !== 'crear'){
        const indiceServicio = this.contratoServicios[indexServicio].values.findIndex((elemento: any) => elemento.type === 'crear');
        this.quitarServicioPredeterminado(entidad, rut, servicio, levelOneRut);
    
        if(indiceServicio >= 0){
          const objActual = JSON.parse(JSON.stringify(this.contratoServicios[indexServicio].values[indiceServicio]));
          this.agregaServicioPredeterminado(entidad, rut, objActual, this.contratoServicios[indexServicio].key, levelOneRut);
        }
      }
      this.agregarRutServicio(true, entidad, rut, this.contratoServicios[indexServicio].key, id, levelOneRut);
    } else {
      this.quitarServicioPredeterminado(entidad, rut, servicio, levelOneRut);
      this.agregarRutServicio(false, entidad, rut, this.contratoServicios[indexServicio].key, id, levelOneRut);
    }
  }

  showMalla(item: any): void {
    const elementsSelected: any[] = [];
    this.checkboxesfila?.forEach(element => {
      if(element?.nativeElement?.checked === true){
        elementsSelected.push(element?.nativeElement?.id);
      }
    });
    if(this.graphMallaSocietaria && this.graphMallaSocietaria.length > 0){
      const dialogRef = this.dialog.open(MallaSocietariaGraphComponent, {
        data: {
          //printable: false,
          dataOrg: this.graphMallaSocietaria,
          dataOrgPrint: {},
          onFocusId: item.idGraph,
          selectedList: elementsSelected
        }
      });

      dialogRef.afterClosed().subscribe(resp => {
       if(resp && Object.keys(resp).length > 0 && this.checkboxesfila && this.checkboxesfila.length > 0){
        const entidadArray: any[] = Object.keys(resp);
        this.limpiarEntidadRutList(entidadArray);
        this.limpiarChecked(entidadArray);

        for(const index in resp){
          for(const element of this.checkboxesfila){
            const checkboxId = resp[index].find((e: any) => e.idTable === element?.nativeElement?.id);
            if(checkboxId) {
              element.nativeElement.click();
            }
          }
        }
       }
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
    })
    }
  }

  setGraphMalla(): void {
    this.graphMallaSocietaria = [];
    if(!this.entidadesValidadas || this.entidadesValidadas.length <= 0){
      return;
    }
    if(!this.entidadesDerivadasMalla || Object.keys(this.entidadesDerivadasMalla).length <= 0){
      return;
    }

    let valido = false;
    for(const entidad of this.entidadesValidadas) {
      if(this.entidadesDerivadasMalla[entidad.entidad] && this.entidadesDerivadasMalla[entidad.entidad].length > 0) {
        valido = true;
        break;
      }
    }
    if(valido === false){
      return;
    }

    if(!this.responseConsulta || Object.keys(this.responseConsulta).length <= 0){
      return;
    }
    // el primer nivel es siempre el rut principal -> this.rut
    const arrayMalla: any[] = [];
      arrayMalla.push(this.utilsService.getElementMalla(
        'Rut'+'Principal'+'0'+this.rut,
        null, // padre
        false, // expanded
        'Principal', // tipo
        this.razonSocialPrincipal, // nombre
        this.rutFormatPipe.transform(this.rut) // rut formateado
      ));
    const clasesHTML: string[] = [
      'bg-entidad-0',
      'bg-entidad-1',
      'bg-entidad-2',
      'bg-entidad-3',
      'bg-entidad-4'
    ];
    const clasesRutHTML: string[] = [
      'bg-rut-entidad-0',
      'bg-rut-entidad-1',
      'bg-rut-entidad-2',
      'bg-rut-entidad-3',
      'bg-rut-entidad-4'
    ];
    for (const [index, entidad] of this.entidadesValidadas.entries()) {
      if(entidad.items && entidad.items.length > 0) {
        const rutsEntidad = entidad.items || [];
        // validar*
        /*const rutsEntidad = entidad.items.filter((e: any) => e.rut && this.responseConsulta[entidad.entidad]?.[e.rut]?.['mallaSocietaria']?.['content'] 
          && ((this.responseConsulta[entidad.entidad]?.[e.rut]?.['mallaSocietaria']?.['content']?.['participaciones'] && this.responseConsulta[entidad.entidad]?.[e.rut]?.['mallaSocietaria']?.['content']?.['participaciones'].length > 0)
          || (this.responseConsulta[entidad.entidad]?.[e.rut]?.['mallaSocietaria']?.['content']?.['socios'] && this.responseConsulta[entidad.entidad]?.[e.rut]?.['mallaSocietaria']?.['content']?.['socios'].length > 0)));*/
        if(rutsEntidad && rutsEntidad.length > 0) {
          // se agrega la entidad
          const idGraphEntidad = entidad.entidad+'Entidad'+'1'+this.rut;
          arrayMalla.push(this.utilsService.getElementMalla(
            idGraphEntidad,
            'Rut'+'Principal'+'0'+this.rut, // id padre
            true, // expanded
            'Entidad', // tipo
            entidad.name || entidad.entidad, // nombre
            '', // rut formateado
            undefined,
            undefined,
            undefined,
            (clasesHTML[index % clasesHTML.length])
          ));
          // se debe recorrer los ruts de la entidad filtrados (rutsEntidad) para agregarlos, este es el nivel 1 antiguo
          for(const rutEntidad of rutsEntidad) {
            const nameRut = rutEntidad.atributos.find((e: any) => e.name === 'nombre')?.value || '';
            const idGraphRutEntidad = entidad.entidad+'Rut'+'2'+rutEntidad.rut;
            arrayMalla.push(this.utilsService.getElementMalla(
              idGraphRutEntidad,
              idGraphEntidad, // id padre
              true, // expanded
              entidad.name, // tipo
              nameRut, // nombre
              this.rutFormatPipe.transform(rutEntidad.rut), // rut formateado
              entidad.entidad, // entidad
              'rutDerivado', // tipo de rut
              'checkboxfila-' + rutEntidad.rut + '-' +  entidad.entidad,
              (clasesRutHTML[index % clasesRutHTML.length])
            ));
          }
        }
      }
    }

    // nivel 2 antiguo en adelante
    for(const entidad of Object.keys(this.entidadesDerivadasMalla)) {
      if(this.entidadesDerivadasMalla[entidad] && this.entidadesDerivadasMalla[entidad].length > 0) {
        for(const rutMalla of this.entidadesDerivadasMalla[entidad]) {
          const nameRut = rutMalla.atributos.find((e: any) => e.name === 'nombre')?.value || '';
          arrayMalla.push(this.utilsService.getElementMalla(
            rutMalla?.idGraph || '',
            rutMalla?.idGraphPadre || '', // id padre
            true, // expanded
            rutMalla.tipo  === 'participacion' ? 'Sociedad' : 'Socio', // tipo
            nameRut, // nombre
            this.rutFormatPipe.transform(rutMalla.rut),
            entidad,
            'rutMalla', // rut formateado
            'checkboxfila-' + rutMalla?.id + '-' + rutMalla?.entidad + '-entidadMalla'
          ));
        }
      }
    }

    this.graphMallaSocietaria = arrayMalla || [];
  }


  crearRutsDerivadosMallaV2(): void {
    this.graphMallaSocietaria = [];
    if(!this.entidadesValidadas || this.entidadesValidadas.length <= 0){
      return;
    }
  
    if(!this.responseConsulta || Object.keys(this.responseConsulta).length <= 0){
      return;
    }
    // el primer nivel es siempre el rut principal -> this.rut
    const respuesta: any = {};
    for(const entidad of this.entidadesValidadas) {
      if(entidad.items && entidad.items.length > 0) {
        const rutsEntidad = entidad.items.filter((e: any) => e.rut && this.responseConsulta[entidad.entidad]?.[e.rut]?.['mallaSocietaria']?.['content'] 
          && ((this.responseConsulta[entidad.entidad]?.[e.rut]?.['mallaSocietaria']?.['content']?.['participaciones'] && this.responseConsulta[entidad.entidad]?.[e.rut]?.['mallaSocietaria']?.['content']?.['participaciones'].length > 0)
          || (this.responseConsulta[entidad.entidad]?.[e.rut]?.['mallaSocietaria']?.['content']?.['socios'] && this.responseConsulta[entidad.entidad]?.[e.rut]?.['mallaSocietaria']?.['content']?.['socios'].length > 0)));
        if(rutsEntidad && rutsEntidad.length > 0) {
          respuesta[entidad.entidad] = [];
          // se agrega la entidad          
          for(const rutEntidad of rutsEntidad) {
            const idGraphRutEntidad = entidad.entidad+'Rut'+'2'+rutEntidad.rut;
  
            // nivel 2 (antiguo) -> participaciones y socios desde ruts de entidades derivadas
            // socios y despues participaciones
            const socios: any[] = this.responseConsulta[entidad.entidad]?.[rutEntidad.rut]?.['mallaSocietaria']?.['content']?.['socios'] || [];
            const participaciones: any[] = this.responseConsulta[entidad.entidad]?.[rutEntidad.rut]?.['mallaSocietaria']?.['content']?.['participaciones'] || [];
            if(socios && socios.length > 0) {
              for(const socio of socios) { // nivel 2
                const idGraphRutSocioNivel2 = idGraphRutEntidad + '-' + entidad.entidad+'socio'+3+socio.rut;
                const obj: any = {
                  entidad: entidad.entidad, 
                  levelOneRutOriginal: null,
                  levelOneRut: rutEntidad.rut,
                  rut: socio?.rut,
                  rutsAsociados: [rutEntidad.rut],
                  rutPadreDisplay: rutEntidad.rut,
                  id: rutEntidad.rut + '-' + socio?.rut,
                  nivel: 2, 
                  idGraph: idGraphRutSocioNivel2,
                  idGraphPadre: idGraphRutEntidad,
                  tipo: "socio",
                  relacion: `El rut ${this.rutFormatPipe.transform(socio?.rut)} es socio de ${this.rutFormatPipe.transform(rutEntidad.rut)}`,
                  atributos: [
                    {
                      displayName: "Nombre",
                      name: "nombre",
                      value: socio?.nombre
                    }
                  ]
                };
                respuesta[entidad.entidad].push(obj);
                // validar si el rut esta en el response, si es asi se debe recorrer el resultado y agregar los ruts asociados
                const sociosNivel3: any[] = this.responseConsulta[entidad.entidad]?.[socio.rut]?.['mallaSocietaria']?.['content']?.['socios'] || [];
                const participacionesNivel3: any[] = this.responseConsulta[entidad.entidad]?.[socio.rut]?.['mallaSocietaria']?.['content']?.['participaciones'] || [];
  
                if(sociosNivel3 && sociosNivel3.length > 0) {
                  for(const socioNivel3 of sociosNivel3) { // nivel 3

                    const obj: any = {
                      entidad: entidad.entidad, 
                      levelOneRutOriginal: rutEntidad.rut,
                      levelOneRut: socio?.rut,
                      rut: socioNivel3?.rut,
                      rutsAsociados: [rutEntidad.rut, socio?.rut],
                      rutPadreDisplay: socio?.rut,
                      id: rutEntidad.rut + '-' + socio?.rut + '-' + socioNivel3?.rut,
                      nivel: 3, 
                      idGraph: idGraphRutSocioNivel2 + '-' + entidad.entidad+'socio'+4+socioNivel3.rut,
                      idGraphPadre: idGraphRutSocioNivel2,
                      tipo: "socio",
                      relacion: `El rut ${this.rutFormatPipe.transform(socioNivel3?.rut)} es socio de ${this.rutFormatPipe.transform(socio?.rut)}`,
                      atributos: [
                        {
                          displayName: "Nombre",
                          name: "nombre",
                          value: socioNivel3?.nombre
                        }
                      ]
                    };
                    respuesta[entidad.entidad].push(obj);
                  }
                }
  
                if(participacionesNivel3 && participacionesNivel3.length > 0) {
                  for(const participacionNivel3 of participacionesNivel3) { // nivel 3

                    const obj: any = {
                      entidad: entidad.entidad, 
                      levelOneRutOriginal: rutEntidad.rut,
                      levelOneRut: socio?.rut,
                      rut: participacionNivel3?.rut,
                      rutsAsociados: [rutEntidad.rut, socio?.rut],
                      rutPadreDisplay: socio?.rut,
                      id: rutEntidad.rut + '-' + socio?.rut + '-' + participacionNivel3?.rut,
                      nivel: 3, 
                      idGraph: idGraphRutSocioNivel2 + '-' + entidad.entidad+'participacion'+4+participacionNivel3.rut,
                      idGraphPadre: idGraphRutSocioNivel2,
                      tipo: "participacion",
                      relacion: `El rut ${this.rutFormatPipe.transform(participacionNivel3?.rut)} es participacion de ${this.rutFormatPipe.transform(socio?.rut)}`,
                      atributos: [
                        {
                          displayName: "Nombre",
                          name: "nombre",
                          value: participacionNivel3?.nombre
                        }
                      ]
                    };
                    respuesta[entidad.entidad].push(obj);
                  }
                }
  
              }
            }
  
            if(participaciones && participaciones.length > 0) {
              for(const participacion of participaciones) { // nivel 2
                const idGraphRutParticipacionNivel2 = idGraphRutEntidad + '-' + entidad.entidad+'participacion'+3+participacion.rut;

                const obj: any = {
                  entidad: entidad.entidad, 
                  levelOneRutOriginal: null,
                  levelOneRut: rutEntidad.rut,
                  rut: participacion?.rut,
                  rutsAsociados: [rutEntidad.rut],
                  rutPadreDisplay: rutEntidad.rut,
                  id: rutEntidad.rut + '-' + participacion?.rut,
                  nivel: 2, 
                  idGraph: idGraphRutParticipacionNivel2,
                  idGraphPadre: idGraphRutEntidad,
                  tipo: "participacion",
                  relacion: `El rut ${this.rutFormatPipe.transform(participacion?.rut)} es socio de ${this.rutFormatPipe.transform(rutEntidad.rut)}`,
                  atributos: [
                    {
                      displayName: "Nombre",
                      name: "nombre",
                      value: participacion?.nombre
                    }
                  ]
                };
                respuesta[entidad.entidad].push(obj);

                const sociosNivel3: any[] = this.responseConsulta[entidad.entidad]?.[participacion.rut]?.['mallaSocietaria']?.['content']?.['socios'] || [];
                const participacionesNivel3: any[] = this.responseConsulta[entidad.entidad]?.[participacion.rut]?.['mallaSocietaria']?.['content']?.['participaciones'] || [];
  
                if(sociosNivel3 && sociosNivel3.length > 0) {
                  for(const socioNivel3 of sociosNivel3) { // nivel 3

                    const obj: any = {
                      entidad: entidad.entidad, 
                      levelOneRutOriginal: rutEntidad.rut,
                      levelOneRut: participacion?.rut,
                      rut: socioNivel3?.rut,
                      rutsAsociados: [rutEntidad.rut, participacion?.rut],
                      rutPadreDisplay: participacion?.rut,
                      id: rutEntidad.rut + '-' + participacion?.rut + '-' + socioNivel3?.rut,
                      nivel: 3, 
                      idGraph: idGraphRutParticipacionNivel2 + '-' + entidad.entidad+'participacion'+4+socioNivel3.rut,
                      idGraphPadre: idGraphRutParticipacionNivel2,
                      tipo: "participacion",
                      relacion: `El rut ${this.rutFormatPipe.transform(socioNivel3?.rut)} es participación de ${this.rutFormatPipe.transform(participacion?.rut)}`,
                      atributos: [
                        {
                          displayName: "Nombre",
                          name: "nombre",
                          value: socioNivel3?.nombre
                        }
                      ]
                    };
                    respuesta[entidad.entidad].push(obj);
                  }
                }
  
                if(participacionesNivel3 && participacionesNivel3.length > 0) {
                  for(const participacionNivel3 of participacionesNivel3) { // nivel 3

                    const obj: any = {
                      entidad: entidad.entidad, 
                      levelOneRutOriginal: rutEntidad.rut,
                      levelOneRut: participacion?.rut,
                      rut: participacionNivel3?.rut,
                      rutsAsociados: [rutEntidad.rut, participacion?.rut],
                      rutPadreDisplay: participacion?.rut,
                      id: rutEntidad.rut + '-' + participacion?.rut + '-' + participacionNivel3?.rut,
                      nivel: 3, 
                      idGraph: idGraphRutParticipacionNivel2 + '-' + entidad.entidad+'participacion'+4+participacionNivel3.rut,
                      idGraphPadre: idGraphRutParticipacionNivel2,
                      tipo: "participacion",
                      relacion: `El rut ${this.rutFormatPipe.transform(participacionNivel3?.rut)} es participacion de ${this.rutFormatPipe.transform(participacion?.rut)}`,
                      atributos: [
                        {
                          displayName: "Nombre",
                          name: "nombre",
                          value: participacionNivel3?.nombre
                        }
                      ]
                    };
                    respuesta[entidad.entidad].push(obj);
                  }
                }
  
              }
            }
  
          }
        }
      }
    }
    this.entidadesDerivadasMalla = respuesta;
    this.setGraphMalla();
  }

}
