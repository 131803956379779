import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment as env } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import { AlertService } from 'src/app/components/_alert';
import { Title } from '@angular/platform-browser';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { CarteraRutService } from 'src/app/shared/services/cartera-rut.service';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-dashboard-mantenedor-cartera',
  templateUrl: './dashboard-mantenedor-cartera.component.html',
  styleUrls: ['./dashboard-mantenedor-cartera.component.scss']
})
export class DashboardMantenedorCarteraComponent {
  private access: string[] = [ESystemAccess.ADMINISTRACION_SUJETO_CREDITO, ESystemProfileList.WEB];
  public hasUserAccess = false;
  
  public usuarioSeleccionado = '';
  public showDashboard = true;
  public showAsignar = false;
  public currentItem = env.initItemPerPage;
  public currentPage = 0;
  public inputText: string = '';
  public users: any[] = [];
  public usersFiltrado: any[] = [];
  private totalizadoresUsuarios: any[] = [];
  private listadoErrores: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _usuariosService: UsuariosService,
    private _sessionService: SessionService,
    public alertService: AlertService,
    private readonly titleService: Title,
    private carteraRutService: CarteraRutService) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
      this.loadListUserDatasource();
    }
  }

  setResponse(service: string, resp: any): void {
    switch (service) {
      case 'totalizadoresUsuarios':
        this.totalizadoresUsuarios = resp || [];
        break;
      case 'getUsersForGroup':
        this.users = resp || [];
        break;
    }
  }

  setError(service: string, error: string): void {
    this.listadoErrores.push({ service, error });
  }

  getServices(service: string): any {
    const objeto: any = {
      'totalizadoresUsuarios': () => {
        return this.carteraRutService.obtenerUsuariosTotalizadores()
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrió un error al obtener cantidad de ruts'), of(null))));
      },
      'getUsersForGroup': () => {
        return this._usuariosService.getUsersForGroup()
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrió un error al obtener el listado de usuario'), of(null))));
      }
    };
    return objeto[service]();
  }

  loadListUserDatasource() {
    this.users = [];
    this.usersFiltrado = [];
    this.inputText = '';
    this.totalizadoresUsuarios = [];
    this.listadoErrores = [];

    const apiServices: any = [];
    apiServices.push(this.getServices('totalizadoresUsuarios'));
    apiServices.push(this.getServices('getUsersForGroup'));

    this.spinner.show();
    forkJoin(apiServices).subscribe(
      resp => {
        if(this.listadoErrores.length > 0) {
          for(const error of this.listadoErrores) {
            this.alertService.error(error.error);
          }
        }

        if(this.users.length > 0) {
          if(this.totalizadoresUsuarios.length > 0) {
            for(const user of this.users) {
              const totalizador = this.totalizadoresUsuarios.find((totalizador: any) => totalizador.idUsuario == user.userName);
              user.rutCount = totalizador?.rutCount || 0;
            }
          }

          this.filtrarObjeto();
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      error => {
        this.alertService.error(error.message || 'Ocurrió un error al consultar por los usuarios');
        this.spinner.hide();
      }
    );
  }
  
  filtrarObjeto(){
    this.currentPage = 0;
    this.usersFiltrado = this.inputText != '' ? this.users.filter((item: any) => 
    item?.userName?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.name?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.givenName?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.email?.toUpperCase().includes(this.inputText.toUpperCase())
    ) : this.users;
  }

  seleccionarUsuario(usuario: string): void {
    this.usuarioSeleccionado = usuario;
    this.showDashboard = false;
    this.showAsignar = true;
    this.users = [];
    this.usersFiltrado = [];
    this.inputText = '';
    this.totalizadoresUsuarios = [];
    this.listadoErrores = [];
  }

  volver(): void {
    this.usuarioSeleccionado = '';
    this.showDashboard = true;
    this.showAsignar = false;
    this.users = [];
    this.usersFiltrado = [];
    this.inputText = '';
    this.totalizadoresUsuarios = [];
    this.listadoErrores = [];
    this.loadListUserDatasource();
  }
}
