import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { LogoutComponent } from './logout/logout.component';
import { BoletinConcursalBuscarComponent } from './pages/boletin-concursal/boletin-concursal-buscar/boletin-concursal-buscar.component';
import { BoletinConcursalConsultarComponent } from './pages/boletin-concursal/boletin-consursal-consultar/boletin-consursal-consultar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EmpresaEnUnDiaBuscarComponent } from './pages/empresa-en-un-dia/empresa-en-un-dia-buscar/empresa-en-un-dia-buscar.component';
import { EmpresaEnUnDiaConsultarComponent } from './pages/empresa-en-un-dia/empresa-en-un-dia-consultar/empresa-en-un-dia-consultar.component';
import { OrganizationComponent } from './pages/organization/organization.component';
import { ReporteJudicialConsultarComponent } from './pages/reporte-judicial/reporte-judicial-consultar/reporte-judicial-consultar.component';
import { ReporteJudicialBuscarComponent } from './pages/reporte-judicial/reporte-judicial-buscar/reporte-judicial-buscar.component';
import { ReporteBasicoComponent } from './pages/reporte/reporte-basico/reporte-basico.component';
import { ReporteBuscarComponent } from './pages/reporte/reporte-buscar/reporte-buscar.component';
import { SolicitudesConsultarComponent } from './pages/solicitudes/solicitudes-consultar/solicitudes-consultar.component';
import { CrearUsuarioComponent } from './pages/usuarios/crear-usuario/crear-usuario.component';
import { EditarUsuarioComponent } from './pages/usuarios/editar-usuario/editar-usuario.component';
import { ListarUsuarioComponent } from './pages/usuarios/listar-usuario/listar-usuario.component';
import { AuthGuard } from './_guards/auth.guard';
import { SolicitudCreditoConsultarWorkflowComponent } from './pages/solicitud-credito-workflow/solicitud-credito-consultar-workflow/solicitud-credito-consultar-workflow.component';
import { SolicitudCreditoConsultarComponent } from './pages/solicitud-credito/solicitud-credito-consultar/solicitud-credito-consultar.component';
import { ResumenSolicitudCreditoComponent } from './components/solicitud-credito/resumen-solicitud-credito/resumen-solicitud-credito.component';
import { DeudaSbifLeasingConsultarComponent } from './pages/deuda-sbif-leasing/deuda-sbif-leasing-consultar/deuda-sbif-leasing-consultar.component';
import { DeudaSbifLeasingBuscarComponent } from './pages/deuda-sbif-leasing/deuda-sbif-leasing-buscar/deuda-sbif-leasing-buscar.component';
import { VehiculosBuscarComponent } from './pages/vehiculos/vehiculos-buscar/vehiculos-buscar.component';
import { VehiculosConsultarComponent } from './pages/vehiculos/vehiculos-consultar/vehiculos-consultar.component';
import { BienesRaicesConsultarComponent } from './pages/bienes-raices/bienes-raices-consultar/bienes-raices-consultar.component';
import { BienesRaicesBuscarComponent } from './pages/bienes-raices/bienes-raices-buscar/bienes-raices-buscar.component';
import { ListaOrganizacionesComponent } from './pages/organization/listar-organizaciones/listar-organizaciones.component';
import { EditarOrganizacionComponent } from './pages/organization/editar-organizacion/editar-organizacion.component';
import { CrearOrganizacionComponent } from './pages/organization/crear-organizacion/crear-organizacion.component';
import { ReporteConsolidadoComponent } from './pages/reporte-consolidado/reporte-consolidado.component';
import { FormularioF29ConsultarComponent } from './pages/formulario-f29/formulario-f29-consultar/formulario-f29-consultar.component';
import { FormularioF29BuscarComponent } from './pages/formulario-f29/formulario-f29-buscar/formulario-f29-buscar.component';
import { FormularioF22ConsultarComponent } from './pages/formulario-f22/formulario-f22-consultar/formulario-f22-consultar.component';
import { FormularioF22BuscarComponent } from './pages/formulario-f22/formulario-f22-buscar/formulario-f22-buscar.component';
import { ReporteFinancieroComponent } from './pages/reporte-financiero/reporte-financiero.component';
import { ReporteRentaBuscarComponent } from './pages/reporte-renta/reporte-renta-buscar/reporte-renta-buscar.component';
import { ReporteRentaConsultarComponent } from './pages/reporte-renta/reporte-renta-consultar/reporte-renta-consultar.component';
import { ReporteFinancieroConsolidadoComponent } from './pages/reporte-financiero-consolidado/reporte-financiero-consolidado.component';
import { CompraVentaBuscarComponent } from './pages/compra-venta/compra-venta-buscar/compra-venta-buscar.component';
import { CompraVentaConsultarComponent } from './pages/compra-venta/compra-venta-consultar/compra-venta-consultar.component';
import { CredencialesOrganizacionComponent } from './pages/credenciales/credenciales-organizacion/credenciales-organizacion.component';
import { CredencialesUsuarioComponent } from './pages/credenciales/credenciales-usuario/credenciales-usuario.component';
import { CamposPersonalizadosComponent } from './pages/campos-personalizados/campos-personalizados.component';
import { ReporteTesoreriaBuscarComponent } from './pages/reporte-tesoreria/reporte-tesoreria-buscar/reporte-tesoreria-buscar.component';
import { ReporteTesoreriaConsultarComponent } from './pages/reporte-tesoreria/reporte-tesoreria-consultar/reporte-tesoreria-consultar.component';
import { ReporteMallaSocietariaConsultarComponent } from './pages/reporte-malla-societaria/reporte-malla-societaria-consultar/reporte-malla-societaria-consultar.component';
import { ReporteMallaSocietariaBuscarComponent } from './pages/reporte-malla-societaria/reporte-malla-societaria-buscar/reporte-malla-societaria-buscar.component';
import { IngresoBalanceManualComponent } from './pages/ingreso-balance-manual/ingreso-balance-manual.component';
import { ReportePanelRiesgoComponent } from './pages/reporte-panel-riesgo/reporte-panel-riesgo.component';
import { ReporteLicitacionFechaComponent } from './pages/reporte-licitacion/reporte-licitacion-fecha/reporte-licitacion-fecha.component';
import { ListadoSolicitudesComponent } from './pages/sinc-credenciales/listado-solicitudes/listado-solicitudes.component';
import { GruposComponent } from './pages/usuarios/grupos/grupos.component';
import { CesionFacturasComponent } from './pages/cesion-facturas/cesion-facturas.component';
import { OfertasFinancierasModule } from './pages/ofertas-financieras/ofertas-financieras.module';
import { CobranzaOnlineListadoComponent } from './pages/cobranza-online/cobranza-online-listado/cobranza-online-listado.component';
import { CobranzaOnlinePublicarComponent } from './pages/cobranza-online/cobranza-online-publicar/cobranza-online-publicar.component';
import { CobranzaOnlineOnboardingComponent } from './pages/cobranza-online/cobranza-online-onboarding/cobranza-online-onboarding.component';
import { AdminCarteraListComponent } from './pages/administracion-cartera/admin-cartera-list/admin-cartera-list.component';
import { RequestAccessViewComponent } from './components/request-access-view/request-access-view.component';
import { AdminCarteraCreateComponent } from './pages/administracion-cartera/admin-cartera-create/admin-cartera-create.component';
import { AdminCarteraDetailComponent } from './pages/administracion-cartera/admin-cartera-detail/admin-cartera-detail.component';
import { AdminCarteraEditComponent } from './pages/administracion-cartera/admin-cartera-edit/admin-cartera-edit.component';
import { AdminCarteraProcessPanelComponent } from './pages/administracion-cartera/admin-cartera-process-panel/admin-cartera-process-panel.component';
import { AdminCarteraExecutionPanelComponent } from './pages/administracion-cartera/admin-cartera-execution-panel/admin-cartera-execution-panel.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardMantenedorCarteraComponent } from './pages/mantenedor-cartera/dashboard-mantenedor-cartera/dashboard-mantenedor-cartera.component';

const routes: Routes = [
  // { path: 'version.html', pathMatch: 'full' },
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: DashboardComponent, canActivate: [AuthGuard], data: {title: 'gtp-principal'} },
  {
    path: 'login',
    component: LoginComponent, 
    data: {title: 'login'}
  },
  /*{ path: 'callback', component: CallbackComponent },
  { path: 'logout', component: LogoutComponent },*/
  { path: 'organizacion', component: OrganizationComponent, canActivate: [AuthGuard], data: {title: 'gtp-organizacion'} },
  {
    path: 'reporte-basico',
    data: {breadcrumb: 'REPORTE BASICO'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'REPORTE BASICO > Consultar', title: 'rb-consultar'},
        component: ReporteBasicoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'REPORTE BASICO > Buscar', title: 'rb-buscar'},
        component: ReporteBuscarComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'reporte-financiero',
    data: {breadcrumb: 'REPORTE FINANCIERO'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'REPORTE FINANCIERO > Consultar', title: 'rf-consultar'},
        component: ReporteFinancieroComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'reporte-financiero-consolidado',
    data: {breadcrumb: 'REPORTE FINANCIERO CONSOLIDADO'},
    children: [
      {
        path: 'buscar',
        data: {breadcrumb: 'REPORTE FINANCIERO CONSOLIDADO > Buscar'},
        component: ReporteFinancieroConsolidadoComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'reporte-judicial',
    data: {breadcrumb: 'INFORME JUDICIAL'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'INFORME JUDICIAL > Consultar', title: 'ij-consultar'},
        component: ReporteJudicialConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'INFORME JUDICIAL > Buscar', title: 'ij-buscar'},
        component: ReporteJudicialBuscarComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'empresa-en-un-dia',
    data: {breadcrumb: 'EMPRESA EN UN DIA'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'EMPRESA EN UN DIA > Consultar', title: 'eeud-consultar'},
        component: EmpresaEnUnDiaConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'EMPRESA EN UN DIA > Buscar', title: 'eeud-buscar'},
        component: EmpresaEnUnDiaBuscarComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'boletin-concursal',
    data: {breadcrumb: 'BOLETIN CONCURSAL'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'BOLETIN CONCURSAL > Consultar', title: 'bc-consultar'},
        component: BoletinConcursalConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'BOLETIN CONCURSAL > Buscar', title: 'bc-buscar'},
        component: BoletinConcursalBuscarComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'deuda-sbif-leasing',
    data: {breadcrumb: 'DEUDA SBIF LEASING'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'DEUDA SBIF LEASING > Consultar', title: 'dsl-consultar'},
        component: DeudaSbifLeasingConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'DEUDA SBIF LEASING > Buscar', title: 'dsl-buscar'},
        component: DeudaSbifLeasingBuscarComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'solicitud-credito',
    data: {breadcrumb: 'SOLICITUD CREDITO'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'SOLICITUD CREDITO > Consultar', title: 'sc-consultar'},
        component: SolicitudCreditoConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'resumen/:id',
        data: {breadcrumb: 'SOLICITUD CREDITO > Resumen'},
        component: ResumenSolicitudCreditoComponent,
        canActivate: [AuthGuard],    
      }
    ]
  },{
    path: 'flujo-credito',
    data: {breadcrumb: 'FLUJO DE CREDITO'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'FLUJO DE CREDITO > Consultar', title: 'fc-consultar'},
        component: SolicitudCreditoConsultarWorkflowComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'vehiculos',
    data: {breadcrumb: 'VEHICULOS'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'VEHICULOS > Consultar', title: 've-consultar'},
        component: VehiculosConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'VEHICULOS > Buscar', title: 've-buscar'},
        component: VehiculosBuscarComponent,
        canActivate: [AuthGuard],    
      }
    ]
  },
  {
    path: 'bienes-raices',
    data: {breadcrumb: 'BIENES RAICES'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'BIENES RAICES > Consultar', title: 'br-consultar'},
        component: BienesRaicesConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'BIENES RAICES > Buscar', title: 'br-buscar'},
        component: BienesRaicesBuscarComponent,
        canActivate: [AuthGuard],    
      }
    ]
  },
  {
    path: 'formulario-f29',
    data: {breadcrumb: 'FORMULARIO F29'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'FORMULARIO F29 > Consultar'},
        component: FormularioF29ConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'FORMULARIO F29 > Buscar'},
        component: FormularioF29BuscarComponent,
        canActivate: [AuthGuard],    
      }
    ]
  },
  {
    path: 'formulario-f22',
    data: {breadcrumb: 'FORMULARIO F22'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'FORMULARIO F22 > Consultar'},
        component: FormularioF22ConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'FORMULARIO F22 > Buscar'},
        component: FormularioF22BuscarComponent,
        canActivate: [AuthGuard],    
      }
    ]
  },
  {
    path: 'reporte-renta',
    data: {breadcrumb: 'REPORTE RENTA'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'REPORTE RENTA > Consultar'},
        component: ReporteRentaConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'REPORTE RENTA > Buscar'},
        component: ReporteRentaBuscarComponent,
        canActivate: [AuthGuard],    
      }
    ]
  },
  {
    path: 'reporte-tesoreria',
    data: {breadcrumb: 'REPORTE TESORERIA'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'REPORTE TESORERIA > Consultar', title: 'te-consultar'},
        component: ReporteTesoreriaConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'REPORTE TESORERIA > Buscar', title: 'te-buscar'},
        component: ReporteTesoreriaBuscarComponent,
        canActivate: [AuthGuard],    
      }
    ]
  },
  {
    path: 'solicitudes',
    data: {breadcrumb: 'SOLICITUDES'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'SOLICITUDES > Consultar', title: 'so-consultar'},
        component: SolicitudesConsultarComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'reportes',
    data: {breadcrumb: 'REPORTES'},
    children: [
      {
        path: 'panelriesgo',
        data: {breadcrumb: 'REPORTES > Panel de Riesgo', title: 're-panel-riesgo'},
        component: ReportePanelRiesgoComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'reporte-consolidado',
    data: {breadcrumb: 'REPORTE CONSOLIDADO'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'REPORTE CONSOLIDADO > Consultar', title: 'rc-consultar'},
        component: ReporteConsolidadoComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'compra-venta',
    data: {breadcrumb: 'REPORTE COMPRA VENTA'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'REPORTE COMPRA VENTA > Consultar', title: 'rcv-consultar'},
        component: CompraVentaConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'REPORTE COMPRA VENTA > Buscar', title: 'rcv-buscar'},
        component: CompraVentaBuscarComponent,
        canActivate: [AuthGuard],    
      }
    ]
  },
  {
    path: 'usuarios',
    data: {breadcrumb: 'CONFIGURACIONES > Administración De Usuarios'},
    children: [
      {
        path: 'listar',
        data: {breadcrumb: 'CONFIGURACIONES > Administración De Usuarios > Listar', title: 'co-administracion-de-usuarios'},
        component: ListarUsuarioComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        data: {breadcrumb: 'CONFIGURACIONES > Administración De Usuarios > Crear'},
        component: CrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'editar/:id',
        data: {breadcrumb: 'CONFIGURACIONES > Administración De Usuarios > Editar'},
        component: EditarUsuarioComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'grupos',
    data: {breadcrumb: 'CONFIGURACIONES > Administración De Grupos', title: 'co-administracion-de-grupos'},
    component: GruposComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organizacion',
    data: {breadcrumb: 'CONFIGURACIONES > Organización'},
    children: [
      {
        path: 'listar',
        data: {breadcrumb: 'CONFIGURACIONES > Organización > Listar', title: 'co-organizaciones'},
        component: ListaOrganizacionesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        data: {breadcrumb: 'CONFIGURACIONES > Organización > Crear'},
        component: CrearOrganizacionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'editar/:id',
        data: {breadcrumb: 'CONFIGURACIONES > Organización > Editar'},
        component: EditarOrganizacionComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'credenciales',
    data: {breadcrumb: 'Administración de Credenciales'},
    children: [
      {
        path: 'organizacion',
        data: {breadcrumb: 'Administración de Credenciales > Organzación', title: 'co-administracion-credenciales-organizacion'},
        component: CredencialesOrganizacionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'usuarios',
        data: {breadcrumb: 'Administración de Credenciales > Usuarios', title: 'co-administracion-credenciales-usuarios'},
        component: CredencialesUsuarioComponent,
        canActivate: [AuthGuard],    
      }
    ]
  },
  { 
    path: 'campos-personalizados', 
    data: {breadcrumb: 'Campos Personalizados'},
    component: CamposPersonalizadosComponent, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'reporte-malla-societaria',
    data: {breadcrumb: 'REPORTE MALLA SOCIETARIA'},
    children: [
      {
        path: 'consultar',
        data: {breadcrumb: 'REPORTE MALLA SOCIETARIA > Consultar', title: 'rms-consultar'},
        component: ReporteMallaSocietariaConsultarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscar',
        data: {breadcrumb: 'REPORTE MALLA SOCIETARIA > Buscar', title: 'rms-buscar'},
        component: ReporteMallaSocietariaBuscarComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  { 
    path: 'balance-manual', 
    data: {breadcrumb: 'Balance Manual'},
    component: IngresoBalanceManualComponent, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'reporte-mercado-publico',
    data: {breadcrumb: 'MERCADO PÚBLICO'},
    children: [
      {
        path: 'fecha',
        data: {breadcrumb: 'MERCADO PÚBLICO > Fecha', title: 'mp-fecha'},
        component: ReporteLicitacionFechaComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'solicitudes-credenciales',
    data: {breadcrumb: 'Solicitudes Sincronización'},
    component: ListadoSolicitudesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cesion-facturas',
    data: {breadcrumb: 'MONITOREO DE CESION FACTURAS'},
    children: [
      {
        path: 'monitoreo',
        data: {breadcrumb: 'MONITOREO DE CESION FACTURAS', title: 'cf-monitoreo'},
        component: CesionFacturasComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  { 
    path: 'administracion-cartera',
    data: {breadcrumb: 'Administración de Cartera'},
    component: AdminCarteraListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion-cartera-crear',
    data: {breadcrumb: 'Administración de Cartera'},
    component: AdminCarteraCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion-cartera-detalle/:id',
    data: {breadcrumb: 'Administración de Cartera'},
    component: AdminCarteraDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion-cartera-editar/:id',
    data: {breadcrumb: 'Administración de Cartera'},
    component: AdminCarteraEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion-cartera-detalle/:id',
    data: {breadcrumb: 'Administración de Cartera'},
    component: AdminCarteraDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion-cartera-procesos/:id',
    data: {breadcrumb: 'Administración de Cartera'},
    component: AdminCarteraProcessPanelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion-cartera-ejecuciones/:id',
    data: {breadcrumb: 'Administración de Cartera'},
    component: AdminCarteraExecutionPanelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion-cartera-ejecuciones/:id/:modelId',
    data: {breadcrumb: 'Administración de Cartera'},
    component: AdminCarteraExecutionPanelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'request-access',
    data: {breadcrumb: 'Acceso'},
    component: RequestAccessViewComponent,
    canActivate: [AuthGuard]
  },
  { path: 'ofertas-financieras', loadChildren: () => OfertasFinancierasModule },
  {
    path: 'cobranza-online',
    data: {breadcrumb: 'COBRANZA ONLINE'},
    children: [
      {
        path: 'buscar',
        data: {breadcrumb: 'COBRANZA ONLINE > Buscar', title: 'co-consultar'},
        component: CobranzaOnlineListadoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'publicar',
        data: {breadcrumb: 'COBRANZA ONLINE > Publicar', title: 'co-consultar'},
        component: CobranzaOnlinePublicarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'onboarding',
        data: {breadcrumb: 'COBRANZA ONLINE > Onboarding', title: 'co-onboarding'},
        component: CobranzaOnlineOnboardingComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  { 
    path: 'admin-sujeto-credito',
    data: {breadcrumb: 'Administrar Sujetos de Crédito', title: 'adm-sujetoc'},
    component: DashboardMantenedorCarteraComponent,
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
