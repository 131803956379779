import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
//import { SimpleModalService } from 'ngx-simple-modal';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ReporteConsolidadoViewComponent } from 'src/app/components/reporte-consolidado-view/reporte-consolidado-view.component';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemOrganizacion, ESystemProfileList } from 'src/app/enum/EAccess';
import { IBoletinConcursalCreateReportResponse } from 'src/app/interface/IBoletinConcursalCreateReportResponse';
import { IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';
import { IPoderJudicialGetReportReponse } from 'src/app/interface/IPoderJudicialGetReportReponse';
import { IReportBienesRaices } from 'src/app/models/bienes-raices/bienes-raices.model';
import { IReporteBasico } from 'src/app/models/reporte-buscar/reporte-buscar.model';
import { ICarpetaTributariaElectronicaReporteModel } from 'src/app/models/reporte-full/reporte-full.model';
import { IReporteVehiculos } from 'src/app/models/vehiculos/vehiculos.model';
import { BienesRaicesService } from 'src/app/shared/services/bienes-raices.service';
import { BoletinConcursalService } from 'src/app/shared/services/boletin-concursal.service';
import { EmpresaEnUnDiaService } from 'src/app/shared/services/empresa-en-un-dia.service';
import { ReporteFullService } from 'src/app/shared/services/reporte-full.service';
import { ReporteJudicialService } from 'src/app/shared/services/reporte-judicial.service';
import { ReporteService } from 'src/app/shared/services/reporte.service';
import { SbifLeasingService } from 'src/app/shared/services/sbif-leasing.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { VehiculosService } from 'src/app/shared/services/vehiculos.service';
import { rutValidate } from 'rut-helpers';
import { RUT } from 'src/app/shared/utils/rut';
import { RutFormatPipe } from 'src/app/shared/pipes/rutFormat.pipe';
import { ModalCarpetaService } from 'src/app/shared/services/modal-carpeta.service';
import { FormularioF29Service } from 'src/app/shared/services/formulario-f29.service';
import { FormularioF22Service } from 'src/app/shared/services/formulario-f22.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CarteraRutService } from 'src/app/shared/services/cartera-rut.service';


export interface IReporteConsolidado {
  id: string,
  inclur: boolean,
  update: boolean,
  obj: any,
  comercial?: string,
  error?: string,
  msg?: string,
  busqueda?: boolean,
  vendor?: string
}

interface ConsolidadoReportFormValues {
  rut: string;
}

@Component({
  selector: 'app-reporte-consolidado',
  templateUrl: './reporte-consolidado.component.html',
  styleUrls: ['./reporte-consolidado.component.scss'],
  providers: [RutFormatPipe]
})


export class ReporteConsolidadoComponent implements OnInit, OnDestroy {
  @ViewChild('reporteConsolidadoView') reporteConsolidadoView!: ReporteConsolidadoViewComponent;
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef> | undefined;
  // Variables para el reporte basico
  public objReporteBasico: IReporteBasico | null = null;
  public radioReporteBasico: string = '';
  public switchReporteBasico: boolean = false;
  public switchUpdateReporteBasico: boolean = false;
  private accessReporteBasico: string[] = [ESystemAccess.REPORTEBASICO_BUSCAR, ESystemAccess.REPORTEBASICO,
  ESystemAccess.REPORTEBASICO_INFORMECOMERCIAL];
  public permisoReporteComercial: boolean = false;
  public reporteBasicohasUserAccess = false;

  // Variables para el reporte judicial
  public objReporteJudicial: IPoderJudicialGetReportReponse | null = null;
  public switchReporteJudicial: boolean = false;
  public switchUpdateReporteJudicial: boolean = false;
  private accessJudicial: string[] = [ESystemAccess.REPORTE_PODERJUDICIAL_BUSCAR, ESystemAccess.REPORTE_PODERJUDICIAL];
  public reporteJudicialhasUserAccess = false;

  // Variables para el reporte boletin concursal
  public objReporteBoletinConcursal: IBoletinConcursalCreateReportResponse | null = null;
  public switchReporteBoletinConcursal: boolean = false;
  public switchUpdateReporteBoletinConcursal: boolean = false;
  private accessBoletinConcursal: string[] = [ESystemAccess.REPORTE_BOLETINCONCURSAL, ESystemAccess.REPORTE_BOLETINCONCURSAL_BUSCAR];
  public boletinConcursalhasUserAccess = false;

  // Variables para el reporte deuda sbif
  public objReporteDeudaSbif: any | null = null;
  public switchReporteDeudaSbif: boolean = false;
  public switchUpdateReporteDeudaSbif: boolean = false;
  private accessDeudaSbif: string[] = [ESystemAccess.REPORTE_DEUDASBIF_LEASING_BUSCAR, ESystemAccess.REPORTE_DEUDASBIF_LEASING];
  public deudaSbifhasUserAccess = false;

  // Variables para el reporte financiero
  public objReporteFinanciero: ICarpetaTributariaElectronicaReporteModel | null = null;
  public switchReporteFinanciero: boolean = false;
  public switchUpdateReporteFinanciero: boolean = false;
  private accessReporteFinanciero: string[] = [ESystemAccess.CARPETA_TRIBUTARIA_BUSCAR, ESystemAccess.CARPETA_TRIBUTARIA];
  public reporteFinancierohasUserAccess = false;

  // Variables para el reporte bienes raices
  public objReporteBienesRaices: IReportBienesRaices | null = null;
  public switchReporteBeienesRaices: boolean = false;
  public switchUpdateReporteBeienesRaices: boolean = false;
  private accessBienesRaices: string[] = [ESystemAccess.REPORTE_PROPIEDADES_BUSCAR, ESystemAccess.REPORTE_PROPIEDADES];
  public bienesRaiceshasUserAccess = false;

  // Variables para el reporte vehiculos
  public objReporteVehiculos: IReporteVehiculos | null = null;
  public switchReporteVehiculos: boolean = false;
  public switchUpdateReporteVehiculos: boolean = false;
  private accessVehiculos: string[] = [ESystemAccess.REPORTE_VEHICULOS_BUSCAR, ESystemAccess.REPORTE_VEHICULOS];
  public vehiculoshasUserAccess = false;

  // Variables para el reporte empresa en un dia
  public objReporteEmpresaDia: IEmpresaEnUnDiaCreateReportResponse | null = null;
  public switchReporteEmpresaDia: boolean = false;
  public switchUpdateReporteEmpresaDia: boolean = false;
  private accessEmpresaDia: string[] = [ESystemAccess.REPORTE_EMPRESAENUNDIA_BUSCAR, ESystemAccess.REPORTE_EMPRESAENUNDIA];
  public empresaDiahasUserAccess = false;

  public consolidadoReportForm: UntypedFormGroup;
  public rut: string = '';
  public today: string = '';
  public showReporte: boolean = false;
  public showReporteGeneral: boolean = false;
  public activateRutHelper: boolean = false;
  public organizacionValid: boolean = false;
  public groupNameConsulting: string[] = [];
  public objGeneralReport: IReporteConsolidado[] = [];
  public titlePrint: string = '';
  private accessOrg: string[] = ESystemOrganizacion.ORGANIZACION_ACCESS;
  private subscriptions: Subscription[] = [];
  public fechaReporte: string = '';
  public vendor: string = '';

  constructor(
    private reporteService: ReporteService,
    private reporteJudicialService: ReporteJudicialService,
    private boletinConcursalService: BoletinConcursalService,
    private sbifLeasingService: SbifLeasingService,
    private reporteFullService: ReporteFullService,
    private bienesRaicesService: BienesRaicesService,
    private vehiculosService: VehiculosService,
    private empresaEnUnDiaService: EmpresaEnUnDiaService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog,
    private sessionService: SessionService,
    private utilsService: UtilsService,
    private formBuilder: UntypedFormBuilder,
    private rutFormatPipe: RutFormatPipe,
    private modalService: ModalCarpetaService,
    private formularioF22Service: FormularioF22Service,
    private formularioF29Service: FormularioF29Service,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private carteraRutService: CarteraRutService
    ) {

    this.consolidadoReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]]
    }) as FormGroupTyped<ConsolidadoReportFormValues>;
    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);

    this.verificarAccesoReporteBasico();
    this.verificarAccesoJudicial();
    this.verificarAccesoReporteFinanciero();
    this.verificarAccesoBienesRaices();
    this.verificarAccesoVehiculos();
    this.verificarAccesoEmpresaDia();
    this.verificarAccesoBoletinConcursal();
    this.verificarAccesoDeudaSbif();

    if (this.accessOrg[0] === this.sessionService.getOrganization()) {
      this.organizacionValid = true;
    }
  }

  open() {
    this.modalService.open();
  }

  onChanges(): void {
    this.subscriptions.push(this.consolidadoReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    }));
  }

  submit(): void {
    if (!this.consolidadoReportForm.valid) {
      this.utilsService.validateAllFormFields(this.consolidadoReportForm);
      return;
    }
    const { rut } = this.consolidadoReportForm.getRawValue();
    this.rut = RUT.applyBackendFormat(rut);

    this.carteraRutService.validateRutCartera(this.rut).subscribe((isValid: boolean) => {
      if (!isValid) {
        this.reporteFinancierohasUserAccess = false;
      } else {
        if (this.sessionService.getUserAccess().includes(this.accessReporteFinanciero[0]) &&
        this.sessionService.getUserAccess().includes(this.accessReporteFinanciero[1])) {
          this.reporteFinancierohasUserAccess = true;
        } else {
          this.reporteFinancierohasUserAccess = false;
        }
      }
      this.showReporte = true;
      this.setToday(rut);
    });

  }

  clearForm(): void {
    this.consolidadoReportForm.reset();
    this.activateRutHelper = false;
  }

  closeReport(): void {
    this.clearForm();
    this.showReporte = false;
    this.objReporteBasico = null;
    this.radioReporteBasico = '';
    this.switchReporteBasico = false;
    this.switchUpdateReporteBasico = false;
    this.objReporteJudicial = null;
    this.switchReporteJudicial = false;
    this.switchUpdateReporteJudicial = false;
    this.objReporteBoletinConcursal = null;
    this.switchReporteBoletinConcursal = false;
    this.switchUpdateReporteBoletinConcursal = false;
    this.objReporteDeudaSbif = false;
    this.switchReporteDeudaSbif = false;
    this.switchUpdateReporteDeudaSbif = false;
    this.objReporteFinanciero = null;
    this.switchReporteFinanciero = false;
    this.switchUpdateReporteFinanciero = false;
    this.objReporteBienesRaices = null;
    this.switchReporteBeienesRaices = false;
    this.switchUpdateReporteBeienesRaices = false;
    this.objReporteVehiculos = null;
    this.switchReporteVehiculos = false;
    this.switchUpdateReporteVehiculos = false;
    this.objReporteEmpresaDia = null;
    this.switchReporteEmpresaDia = false;
    this.switchUpdateReporteEmpresaDia = false;
    this.showReporte = false;
    this.objGeneralReport = [];
    this.groupNameConsulting = [];
    this.showReporteGeneral = false;
    this.limpiarVendor();
  }

  handleUpload(event: any): void {
    if (!this.objGeneralReport.find(item => item.id === 'reporteFinanciero')) {
      this.preventReporteBasico(true);
      event.preventDefault();
      return;
    }

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const form = {
          pdf: reader?.result?.toString().split(',')[1]
        }
        this.enviarDocumento(form);
      };
    }
  }

  enviarDocumento(form: any): void {

    this.spinner.show();
    this.reporteFullService['procesarReport'](RUT.applyBackendFormat(this.rut), form).subscribe(
      (response: any) => {
        this.spinner.hide();
        const trx = response?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.IDTransaccion;
        this.obtenerInformeCompleto(trx);
      },
      (error: any) => {
        this.spinner.hide();
        this.alertService.error(error.error.message || 'Ocurrio un problema');
      }
    );
  }

  obtenerInformeCompleto(trx: string) {
    this.spinner.show();
    this.reporteFullService.calcularVentas(RUT.applyBackendFormat(this.rut), trx).subscribe(
      (response: ICarpetaTributariaElectronicaReporteModel) => {
        this.spinner.hide();
        if (Object.keys(response).length > 0) {
          this.setResponse('reporteFinanciero', response);
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.setError('reporteFinanciero');
      }
    );
  }

  /**
   * @description
   * verificar si el usuario tiene acceso al reporte basico
   */
  verificarAccesoReporteBasico(): void {
    if (this.sessionService.getUserAccess().includes(this.accessReporteBasico[0]) &&
      this.sessionService.getUserAccess().includes(this.accessReporteBasico[1])) {
      this.reporteBasicohasUserAccess = true;

      this.sessionService.getUserAccess().includes(this.accessReporteBasico[2]) ?
        this.permisoReporteComercial = true : this.radioReporteBasico = '10';
    }
  }

  /**
   * @description
   * verificar si el usuario tiene acceso al reporte judicial
   */
  verificarAccesoJudicial(): void {
    if (this.sessionService.getUserAccess().includes(this.accessJudicial[0]) &&
      this.sessionService.getUserAccess().includes(this.accessJudicial[1])) {
      this.reporteJudicialhasUserAccess = true;
    }
  }

  /**
   * @description
   * verificar si el usuario tiene acceso al reporte financiero
   */
  verificarAccesoReporteFinanciero(): void {
    if (this.sessionService.getUserAccess().includes(this.accessReporteFinanciero[0]) &&
      this.sessionService.getUserAccess().includes(this.accessReporteFinanciero[1])) {
      this.reporteFinancierohasUserAccess = true;
    }
  }

  /**
   * @description
   * verificar si el usuario tiene acceso al reporte bienes raices
   */
  verificarAccesoBienesRaices(): void {
    if (this.sessionService.getUserAccess().includes(this.accessBienesRaices[0]) &&
      this.sessionService.getUserAccess().includes(this.accessBienesRaices[1])) {
      this.bienesRaiceshasUserAccess = true;
    }
  }

  /**
   * @description
   * verificar si el usuario tiene acceso al reporte vehiculos
   */
  verificarAccesoVehiculos(): void {
    if (this.sessionService.getUserAccess().includes(this.accessVehiculos[0]) &&
      this.sessionService.getUserAccess().includes(this.accessVehiculos[1])) {
      this.vehiculoshasUserAccess = true;
    }
  }

  /**
   * @description
   * verificar si el usuario tiene acceso al reporte empresa en un dia
   */
  verificarAccesoEmpresaDia(): void {
    if (this.sessionService.getUserAccess().includes(this.accessEmpresaDia[0]) &&
      this.sessionService.getUserAccess().includes(this.accessEmpresaDia[1])) {
      this.empresaDiahasUserAccess = true;
    }
  }

  /**
   * @description
   * verificar si el usuario tiene acceso al reporte boletin concursal
   */
  verificarAccesoBoletinConcursal(): void {
    if (this.sessionService.getUserAccess().includes(this.accessBoletinConcursal[0]) &&
      this.sessionService.getUserAccess().includes(this.accessBoletinConcursal[1])) {
      this.boletinConcursalhasUserAccess = true;
    }
  }

  /**
   * @description
   * verificar si el usuario tiene acceso al reporte deuda sbif
   */
  verificarAccesoDeudaSbif(): void {
    if (this.sessionService.getUserAccess().includes(this.accessDeudaSbif[0]) &&
      this.sessionService.getUserAccess().includes(this.accessDeudaSbif[1])) {
      this.deudaSbifhasUserAccess = true;
    }
  }

  /**
   * @description
   * Obtiene el historico del reporte basico
   */
  getReporteBasico(check: boolean): void {
    if (this.reporteBasicohasUserAccess) {

      if (!check) {
        this.objReporteBasico = null;
        this.switchUpdateReporteBasico = false;
        if(this.permisoReporteComercial){
          this.radioReporteBasico = '';
        }
        this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'reporteBasico');
        this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'reporteBasico');
        return;
      }

      !this.groupNameConsulting.includes('reporteBasico') && check ? this.groupNameConsulting.push('reporteBasico') : null;

    }
  }

  /**
   * @description
   * Obtiene el historico del reporte judicial
   */
  getReporteJudicial(event: any): void {
    if (this.reporteJudicialhasUserAccess) {
      if (!event.target.checked) {
        this.objReporteJudicial = null;
        this.switchUpdateReporteJudicial = false;
        this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'reporteJudicial');
        this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'reporteJudicial');
        return;
      }

      !this.groupNameConsulting.includes('reporteJudicial') && event ? this.groupNameConsulting.push('reporteJudicial') : null;

    }
  }

  /**
   * @description
   * Obtiene el historico del boletin concursal
   */
  getReporteBoletinConcursal(event: any): void {
    if (this.boletinConcursalhasUserAccess) {

      if (!event.target.checked) {
        this.objReporteBoletinConcursal = null;
        this.switchUpdateReporteBoletinConcursal = false;
        this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'boletinConcursal');
        this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'boletinConcursal');
        return;
      }

      !this.groupNameConsulting.includes('boletinConcursal') && event ? this.groupNameConsulting.push('boletinConcursal') : null;

    }
  }

  /**
   * @description
   * Obtiene el historico de la Deuda Sbif
   */
  getReporteDeudaSbif(event: any): void {
    if (this.deudaSbifhasUserAccess) {

      if (!event.target.checked) {
        this.objReporteDeudaSbif = null;
        this.switchUpdateReporteDeudaSbif = false;
        this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'deudaSbif');
        this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'deudaSbif');
        return;
      }

      !this.groupNameConsulting.includes('deudaSbif') && event ? this.groupNameConsulting.push('deudaSbif') : null;

    }
  }

  /**
   * @description
   * Obtiene el historico del reporte financiero
   */
  getReporteFinanciero(event: any): void {
    if (this.reporteFinancierohasUserAccess) {

      if (!event.target.checked) {
        this.objReporteFinanciero = null;
        this.switchUpdateReporteFinanciero = false;
        this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'reporteFinanciero');
        this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'reporteFinanciero');
        return;
      }

      !this.groupNameConsulting.includes('reporteFinanciero') && event ? this.groupNameConsulting.push('reporteFinanciero') : null;
    }
  }

  /**
   * @description
   * Obtiene el historico de Bienes raices
   */
  getReporteBienesRaices(event: any): void {
    if (this.bienesRaiceshasUserAccess) {

      if (!event.target.checked) {
        this.objReporteFinanciero = null;
        this.switchUpdateReporteBeienesRaices = false;
        this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'bienesRaices');
        this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'bienesRaices');
        return;
      }

      !this.groupNameConsulting.includes('bienesRaices') && event ? this.groupNameConsulting.push('bienesRaices') : null;
    }
  }

  /**
   * @description
   * Obtiene el historico de vehiculos
   */
  getReporteVehiculos(event: any): void {
    if (this.vehiculoshasUserAccess) {

      if (!event.target.checked) {
        this.objReporteVehiculos = null;
        this.switchUpdateReporteVehiculos = false;
        this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'vehiculos');
        this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'vehiculos');
        return;
      }

      !this.groupNameConsulting.includes('vehiculos') && event ? this.groupNameConsulting.push('vehiculos') : null;

    }
  }

  /**
   * @description
   * Obtiene el historico de empresa en un dia
   */
  getReporteEmpresaDia(event: any): void {
    if (this.empresaDiahasUserAccess) {

      if (!event.target.checked) {
        this.objReporteEmpresaDia = null;
        this.switchUpdateReporteEmpresaDia = false;
        this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'empresaDia');
        this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'empresaDia');
        return;
      }

      !this.groupNameConsulting.includes('empresaDia') && event ? this.groupNameConsulting.push('empresaDia') : null;

    }
  }

  /**
   * @description
   * Muestra una modal indicado que se debe seleccionar una opcion para continuar
   */
  preventReporteBasico(financiero = false): void {
    const msg = financiero ? 'Para actualizar debe primero seleccionar el informe' : 'Para generar el reporte debe seleccionar una opción';
    /*const dialogRef = */this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Reporte Consolidado',
        message: msg,
        hideCancelmButtom: true,
        textBoton: ['Ok'],
      }
    });

    /*dialogRef.afterClosed().subscribe(resp => { // validar
    });*/

    /*this.simpleModalService.addModal(ConfirmDialogComponent, {
      title: 'Reporte Consolidado',
      message: msg,
      hideCancelmButtom: true,
      textBoton: ['Ok'],
    });*/
  }

  /** 
   * @description
   * Actualiza el objeto general de reportes
   * @param event
   * @param id  
  */
  updateGlobalReport(event: any, id: string): void {
    const obj = this.objGeneralReport.find((e: IReporteConsolidado) => e.id === id);
    if (obj) {
      obj.update = event.target.checked
    }
  }

  /**
   * @description
   * switch para consultar el historico del reporte basico 
   * @param event 
   * @returns 
   */
  switchReporte(event: any): void {
    if (!this.radioReporteBasico) {
      event.preventDefault();
      this.preventReporteBasico();
      return;
    }
    this.limpiarVendor();
    this.getReporteBasico(event.target.checked);
  }

  formatDate(date: string): string {
    if (!date) return '';
    return this.utilsService.formatDate(date);
  }

  setFechaReporte(): void{
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    this.fechaReporte = dd + '-' + mm + '-' + yyyy;
  }

  submitReporte(): void {
    this.showReporteGeneral = true;
    this.setFechaReporte();
    this.reporteConsolidadoView.setReporteConsolidado(this.objGeneralReport);
  }

  filterObjetoGeneral(id: string): any {
    const temp = this.objGeneralReport.find((e: IReporteConsolidado) => e.id === id);
    return temp ? temp : null;
  }

  updateReporteGeneral(event: any): void {    
    this.objGeneralReport = event;
    this.updateAllReports();
    this.updateSwitchActualizar();
  }

  updateAllReports(): any {

    const keyValues = [
      'reporteBasico',
      'reporteFinanciero',
      'bienesRaices',
      'vehiculos',
      'empresaDia',
      'boletinConcursal',
      'deudaSbif',
      'reporteJudicial'
    ];

    const objeto: any = {
      'reporteBasico': (obj: IReporteBasico) => {
        this.objReporteBasico = obj;
      },
      'reporteFinanciero': (obj: ICarpetaTributariaElectronicaReporteModel) => {
        this.objReporteFinanciero = obj;
      },
      'bienesRaices': (obj: IReportBienesRaices) => {
        this.objReporteBienesRaices = obj;
      },
      'vehiculos': (obj: IReporteVehiculos) => {
        this.objReporteVehiculos = obj;
      },
      'empresaDia': (obj: IEmpresaEnUnDiaCreateReportResponse) => {
        this.objReporteEmpresaDia = obj;
      },
      'boletinConcursal': (obj: IBoletinConcursalCreateReportResponse) => {
        this.objReporteBoletinConcursal = obj;
      },
      'deudaSbif': (obj: any) => {
        this.objReporteDeudaSbif = obj;
      },
      'reporteJudicial': (obj: IPoderJudicialGetReportReponse) => {
        this.objReporteJudicial = obj;
      }
    };

    keyValues.forEach((element: string) => {
      const objTemp = this.objGeneralReport.find(e => e.id === element);
      objTemp ? objeto[element](objTemp.obj) : false;
      if(element === 'reporteBasico'){
        this.vendor = objTemp?.vendor || '';
      }
    });

  }

  getOnlyReports(): any[] {
    const reports: any[] = [];
    this.objGeneralReport.filter(f => f.obj).map(e => reports.push({ [e.id]: e.obj }));
    return reports;
  }

  validacionExtra(): boolean {
    const reporteFinanciero = this.filterObjetoGeneral('reporteFinanciero');
    if (reporteFinanciero && !reporteFinanciero.obj) {
      return false;
    }
    return true;
  }

  async callServices(): Promise<void> {
    this.groupNameConsulting = await this.orderServices(this.groupNameConsulting);
    let apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsulting) {
        apiServices.push(this.getServices(value))
      }
    }
    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
    }));
  }

  setResponseModal(service:string, response: any): void {
    if (!this.objGeneralReport.find(item => item.id === 'reporteFinanciero')) {
      this.preventReporteBasico(true);
      return;
    }
    else {
      this.objGeneralReport = this.objGeneralReport.filter((f: IReporteConsolidado) => f.id !== service),
      this.setResponse(service, {consultaFinanciero: response});
    }
  }

  setResponse(service: string, response: any): void {
    if (service !== 'reporteFinanciero' && service !== 'deudaSbif' && service !== 'empresaDia') {
      Object.keys(response).length > 0 ? this.updateObjetoReporte(service, response) : null;
      this.groupNameConsulting = this.groupNameConsulting.filter(f => f !== service);
      this.objGeneralReport.push({
        id: service,
        inclur: true,
        update: this.getObjReporte(service) && Object.keys(this.getObjReporte(service)).length > 0 ? false : true,
        obj: this.getObjReporte(service) && Object.keys(this.getObjReporte(service)).length > 0 ? this.getObjReporte(service) : null,
        busqueda: true
      });
    } else if (service === 'empresaDia') {
      Object.keys(response).length > 0 ? this.updateObjetoReporte(service, response) : null;
      this.groupNameConsulting = this.groupNameConsulting.filter(f => f !== service);
      this.objGeneralReport = this.objGeneralReport.filter(f => f.id !== service);
      this.objGeneralReport.push({
        id: service,
        inclur: true,
        update: this.getObjReporte(service) && Object.keys(this.getObjReporte(service)).length > 0 ? false : true,
        obj: this.getObjReporte(service) && Object.keys(this.getObjReporte(service)).length > 0 ? this.getObjReporte(service) : null,
        busqueda: true
      });
    } else {
      this.setResponseOthers(service, response);
    }

  }

  setResponseOthers(service: string, response: any): void {
    let incluir = false;

    if (service === 'deudaSbif') {
      //Object.keys(response).length > 0 ? (
        this.groupNameConsulting = this.groupNameConsulting.filter(f => f !== service),
        this.updateObjetoReporte(service, response),
        incluir = true
      //) : null;
    }

    if (service === 'reporteFinanciero') {
      this.objGeneralReport = this.objGeneralReport.filter(f => f.id !== service);

      Object.keys(response).length > 0 ? (
        this.groupNameConsulting = this.groupNameConsulting.filter(f => f !== service),
        this.updateObjetoReporte(service, response),
        incluir = true
      ) : (
        this.objGeneralReport.push({
          id: service,
          inclur: false,
          update: false,
          obj: null,
          busqueda: true
        })
      );
    }

    if (incluir) {
      this.objGeneralReport.push({
        id: service,
        inclur: true,
        update: this.getObjReporte(service) && Object.keys(this.getObjReporte(service)).length > 0 ? false : true,
        obj: this.getObjReporte(service) && Object.keys(this.getObjReporte(service)).length > 0 ? this.getObjReporte(service) : null,
        busqueda: true
      });
    }
  }

  setError(service: string): void {
    this.objGeneralReport.push({
      id: service,
      inclur: true,
      update: false,
      obj: null,
      msg: 'Ocurrió un error - Busque Nuevamente'
    })
  }

  orderServices(group: string[]): string[] {
    if (group.includes('empresaDia')) {
      const index = group.indexOf('empresaDia');
      group.splice(index, 1);
      group.push('empresaDia');
    }
    return group;
  }

  getServices(service: string): any {
    const objeto: any = {
      'reporteBasico': () => {
        return this.reporteService.basicReportLastUpdated(this.rut, this.radioReporteBasico)
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError(() => (this.setError(service), of(null))));
      },
      'reporteFinanciero': () => {
        return new Promise<void>(resolve => {
          const consultaFinanciero = this.reporteFullService.obtenerLastUpdate(this.rut);
          const consultaF29 = this.formularioF29Service.buscarReportesCant(this.rut, 3);
          const consultaF22 = this.formularioF22Service.listarRenta(this.rut);
          forkJoin({
            consultaFinanciero,
            consultaF29,
            consultaF22
          }).subscribe((response:any) => {
            this.setResponse(service, response);
            resolve();
          },
          (error) => {
            this.setError(service), of(null);
            resolve();
          });
        });
      },
      'bienesRaices': () => {
        return this.bienesRaicesService.obtenerLastUpdate(this.rut).pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
          .pipe(
            catchError(() => (this.setError(service), of(null))));
      },
      'vehiculos': () => {
        return this.vehiculosService.obtenerLastUpdate(this.rut).pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
          .pipe(
            catchError(() => (this.setError(service), of(null))));
      },
      'boletinConcursal': () => {
        return this.boletinConcursalService.boletinConcursalReportLastUpdated(this.rut).pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
          .pipe(
            catchError(() => (this.setError(service), of(null))));
      },
      'deudaSbif': () => {
        return this.sbifLeasingService.searchReport(this.rut).pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
          .pipe(
            catchError(() => (this.setError(service), of(null))));
      },
      'reporteJudicial': () => {
        return this.reporteJudicialService.judicialReportLastUpdated(this.rut).pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
          .pipe(
            catchError(() => (this.setError(service), of(null))));
      },
      'empresaDia': () => {
        return this.empresaEnUnDiaService.empresaEnUnDiaReportLastUpdated(this.rut, 'CERTIFICADOS').pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
          .pipe(
            catchError(() => (this.setError(service), of(null))));
      }
    };
    return objeto[service]();
  }

  updateObjetoReporte(reporte: string, response: any): void {
    const objeto: any = {
      'reporteBasico': (resp: any) => {
        this.objReporteBasico = resp;
      },
      'reporteFinanciero': (resp: any) => {

        // Validacion de tipo manual o automatico
        if(resp?.consultaF22?.length > 0){
          resp.consultaF22 = resp?.consultaF22.filter((e: any) => (e?.Formulario22Reporte?.Reporte?.tipo !== 'manual' || (e?.Formulario22Reporte?.Reporte && !e?.Formulario22Reporte?.Reporte?.tipo)));
        }

        // Validacion de tipo manual o automatico
        if(resp?.consultaFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.f22?.length > 0){
          resp.consultaFinanciero.CarpetaTributariaElectronicaReporte.Reporte.f22 = resp?.consultaFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.f22.filter((e: any) => 
            (e?.tipo !== 'manual' || !e?.tipo));
        }

        if(resp?.consultaFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.SubEstadoTransaccion === "COMPLETED-CALCULATED" && 
          !(resp?.consultaF22) && !(resp?.consultaF29)){

          this.objReporteFinanciero = resp.consultaFinanciero;

        } else if(resp?.consultaFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.SubEstadoTransaccion === "COMPLETED-CALCULATED" || 
          resp?.consultaF22?.length > 0 || resp?.consultaF29?.length > 0 ){

            let respuesta: ICarpetaTributariaElectronicaReporteModel | any = {CarpetaTributariaElectronicaReporte: {
              DatosBasicosSolicitud: {},
              DatosSolicitante: {},
              Reporte: {
                f22: [],
                f29: [],
                socios: resp?.consultaFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [],
                representantes: []
              }
            }};

            if((resp?.consultaFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.SubEstadoTransaccion === "COMPLETED-CALCULATED")){
              respuesta = JSON.parse(JSON.stringify(resp.consultaFinanciero));
            }
            else if(!(resp?.consultaFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.SubEstadoTransaccion === "COMPLETED-CALCULATED")
              && resp?.consultaF22?.length > 0){
                respuesta.CarpetaTributariaElectronicaReporte.DatosBasicosSolicitud = resp?.consultaF22[0].Formulario22Reporte.DatosBasicosSolicitud;
                respuesta.CarpetaTributariaElectronicaReporte.DatosSolicitante = resp?.consultaF22[0].Formulario22Reporte.DatosSolicitante;
            }
            else if(!(resp?.consultaFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.SubEstadoTransaccion === "COMPLETED-CALCULATED")
              && resp?.consultaF29?.length > 0 && !(resp?.consultaF22?.length > 0)){       
                respuesta.CarpetaTributariaElectronicaReporte.DatosBasicosSolicitud = resp?.consultaF29[0].Formulario29Reporte.DatosBasicosSolicitud;
                respuesta.CarpetaTributariaElectronicaReporte.DatosSolicitante = resp?.consultaF29[0].Formulario29Reporte.DatosSolicitante;
            }

            if(resp.consultaF29.length > 0){
              const f29 = resp.consultaF29.map((x: any) => {
                return x.Formulario29Reporte.Reporte;
              }).reverse();
              const fechaActualReporte = Date.parse(respuesta?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte);
              const fechaF29 = Date.parse(resp?.consultaF29[resp.consultaF29.length - 1]?.Formulario29Reporte?.DatosBasicosSolicitud?.FechaReporte);
              if(fechaF29 > fechaActualReporte){
                respuesta.CarpetaTributariaElectronicaReporte.DatosBasicosSolicitud.FechaReporte = resp?.consultaF29[resp.consultaF29.length - 1]?.Formulario29Reporte?.DatosBasicosSolicitud?.FechaReporte;
              }
              
              // Se filtra la cantidad de periodos en la api
              respuesta.CarpetaTributariaElectronicaReporte.Reporte.f29 = f29; 
            }
            
            if(resp.consultaF22.length > 0){
              const f22 = resp.consultaF22.map((x: any) => {
                return x.Formulario22Reporte.Reporte;
              }).reverse();
              const fechaActualReporte = Date.parse(respuesta?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte);
              const fechaF22 = Date.parse(resp?.consultaF22[resp.consultaF22.length - 1]?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte);
              if(fechaF22 > fechaActualReporte){
                respuesta.CarpetaTributariaElectronicaReporte.DatosBasicosSolicitud.FechaReporte = resp?.consultaF22[resp?.consultaF22.length - 1]?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
              }
              // Se filtra la cantidad de periodos con el slice
              respuesta.CarpetaTributariaElectronicaReporte.Reporte.f22 = f22.slice(0, 3);
            }

            this.objReporteFinanciero = respuesta;

        } else {
          null;
        }
      },
      'bienesRaices': (resp: any) => {
        this.objReporteBienesRaices = resp;
      },
      'vehiculos': (resp: any) => {
        this.objReporteVehiculos = resp;
      },
      'boletinConcursal': (resp: any) => {
        this.objReporteBoletinConcursal = resp;
      },
      'deudaSbif': (resp: any) => {
        //this.objReporteDeudaSbif = resp;
        this.objReporteDeudaSbif = resp?.Documentos?.SbifOnline ?
          resp : null;
      },
      'reporteJudicial': (resp: any) => {
        this.objReporteJudicial = resp;
      },
      'empresaDia': (resp: any) => {
        this.objReporteEmpresaDia = resp;
      }
    };
    objeto[reporte](response);
  }

  getObjReporte(reporte: string): any {
    const objeto: any = {
      'reporteBasico': () => {
        return this.objReporteBasico;
      },
      'reporteFinanciero': () => {
        return this.objReporteFinanciero;
      },
      'bienesRaices': () => {
        return this.objReporteBienesRaices;
      },
      'vehiculos': () => {
        return this.objReporteVehiculos;
      },
      'boletinConcursal': () => {
        return this.objReporteBoletinConcursal;
      },
      'deudaSbif': () => {
        return this.objReporteDeudaSbif;
      },
      'reporteJudicial': () => {
        return this.objReporteJudicial;
      },
      'empresaDia': () => {
        return this.objReporteEmpresaDia;
      }
    };
    return objeto[reporte]();
  }

  setToday(rut: string) {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) :
      new Date().getMonth() + 1)}${new Date().getDate()}`;
    this.titlePrint = `ReporteConsolidado_${this.rutFormatPipe.transform(rut)}_${this.today}`;
  }

  printPdf() {
    window.print();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  validaPermisos(element: any): boolean{

    if(element.id === 'reporteBasico' && this.reporteBasicohasUserAccess){
      return true;
    } else if (element.id === 'poderJudicial' && this.reporteJudicialhasUserAccess) {
      return true;
    } else if (element.id === 'boletinConcursal' && this.boletinConcursalhasUserAccess) {
      return true;
    } else if (element.id === 'deudaSbif' && this.deudaSbifhasUserAccess) {
      return true;
    } else if (element.id === 'reporteFinanciero' && this.reporteFinancierohasUserAccess) {
      return true;
    } else if (element.id === 'reporteBienesRaices' && this.bienesRaiceshasUserAccess) {
      return true;
    } else if (element.id === 'reporteVehiculos' && this.vehiculoshasUserAccess) {
      return true;
    } else if (element.id === 'reporteEmpresaDia' && this.empresaDiahasUserAccess) {
      return true;
    }

    return false;
  }

  selectAll(event: any): void {
    if(this.permisoReporteComercial){
      this.radioReporteBasico = '11';
    } else {
      this.radioReporteBasico = '10';
    }
    if(event?.target?.checked === false){
      this.limpiarVendor();
    }
    this.checkboxes?.forEach(element => {
      if(this.validaPermisos(element.nativeElement)){
        if(!(event.target.checked === element.nativeElement.checked)){
          element.nativeElement.click()
        }
      }
    })

  }

  validaErroresAct(): boolean {
    const temp = this.objGeneralReport.find((e: IReporteConsolidado) => (e.error && e.error !== '') && e.obj === null); // && e.id !== "deudaSbif"
    return temp ? true : false;
  }

  /* Funciones para quitar seleccion al presionar el icono x */
  
   quitarReporteBasico(): void {
    this.limpiarVendor()
    this.getReporteBasico(false);
    this.switchReporteBasico = false;
  }

  limpiarVendor(): void {
    this.vendor = '';
  }

   quitarReporteJudicial(): void {
    if (this.reporteJudicialhasUserAccess) {
      this.objReporteJudicial = null;
      this.switchUpdateReporteJudicial = false;
      this.switchReporteJudicial = false;
      this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'reporteJudicial');
      this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'reporteJudicial');
      return;
    }
  }

  quitarReporteBoletinConcursal(): void {
    if (this.boletinConcursalhasUserAccess) {
      this.objReporteBoletinConcursal = null;
      this.switchUpdateReporteBoletinConcursal = false;
      this.switchReporteBoletinConcursal = false;
      this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'boletinConcursal');
      this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'boletinConcursal');
      return;
    }
  }

  quitarReporteDeudaSbif(): void {
    if (this.deudaSbifhasUserAccess) {
      this.objReporteDeudaSbif = null;
      this.switchUpdateReporteDeudaSbif = false;
      this.switchReporteDeudaSbif = false;
      this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'deudaSbif');
      this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'deudaSbif');
      return;
    }
  }

  quitarReporteFinanciero(): void {
    if (this.reporteFinancierohasUserAccess) {
      this.objReporteFinanciero = null;
      this.switchUpdateReporteFinanciero = false;
      this.switchReporteFinanciero = false;
      this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'reporteFinanciero');
      this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'reporteFinanciero');
      return;
    }
  }

  quitarReporteBienesRaices(): void {
    if (this.bienesRaiceshasUserAccess) {
      this.objReporteFinanciero = null;
      this.switchUpdateReporteBeienesRaices = false;
      this.switchReporteBeienesRaices = false;
      this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'bienesRaices');
      this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'bienesRaices');
      return;
    }
  }

  quitarReporteVehiculos(): void {
    if (this.vehiculoshasUserAccess) {
      this.objReporteVehiculos = null;
      this.switchUpdateReporteVehiculos = false;
      this.switchReporteVehiculos = false;
      this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'vehiculos');
      this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'vehiculos');
      return;
    }
  }

  quitarReporteEmpresaDia(): void {
    if (this.empresaDiahasUserAccess) {
      this.objReporteEmpresaDia = null;
      this.switchUpdateReporteEmpresaDia = false;
      this.switchReporteEmpresaDia = false;
      this.objGeneralReport = this.objGeneralReport.filter(item => item.id !== 'empresaDia');
      this.groupNameConsulting = this.groupNameConsulting.filter(item => item !== 'empresaDia');
      return;
    }
  }

  updateSwitchActualizar(): void {
    (this.filterObjetoGeneral('reporteBasico')?.update === true && !this.switchUpdateReporteBasico) ? this.switchUpdateReporteBasico = true : null;
    (this.filterObjetoGeneral('reporteJudicial')?.update === true && !this.switchUpdateReporteJudicial) ? this.switchUpdateReporteJudicial = true : null;
    (this.filterObjetoGeneral('boletinConcursal')?.update === true && !this.switchUpdateReporteBoletinConcursal) ? this.switchUpdateReporteBoletinConcursal = true : null;
    (this.filterObjetoGeneral('deudaSbif')?.update === true && !this.switchUpdateReporteDeudaSbif) ? this.switchUpdateReporteDeudaSbif = true : null;
    (this.filterObjetoGeneral('bienesRaices')?.update === true && !this.switchUpdateReporteBeienesRaices) ? this.switchUpdateReporteBeienesRaices = true : null;
    (this.filterObjetoGeneral('vehiculos')?.update === true && !this.switchUpdateReporteVehiculos) ? this.switchUpdateReporteVehiculos = true : null;
  }
}
