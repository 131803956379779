import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { environment as env } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CarteraRutService {
  private endpoint: string = env.backend.asignadorDeCartera.uri;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) { }

  obtenerListaCarteraUsuario(IDUsuarioConsultado: string, itemsByPage: number, page: number): Observable<any> {
    const headers = new HttpHeaders()
      .set("IDOrganizacion", this._sessionService.getOrganization())
      .set("IDUsuario", this._sessionService.getUserInfo().username)
      .set("RequestOrigin", 'WEB')
      .set("IDUsuarioConsultado", IDUsuarioConsultado)
      .set("ItemsByPage", itemsByPage.toString())
      .set("Page", page.toString());

    return this.http.get<any>(`${this.endpoint}listarCarteraUsuario`, { headers });
  }

  obtenerListaRutsUsuarioSecretInteraction(IDUsuarioConsultado: string, itemsByPage: number, page: number): Observable<any> {
    const headers = new HttpHeaders()
      .set("IDOrganizacion", this._sessionService.getOrganization())
      .set("IDUsuario", this._sessionService.getUserInfo().username)
      .set("RequestOrigin", 'WEB')
      .set("IDUsuarioConsultado", IDUsuarioConsultado)
      .set("ItemsByPage", itemsByPage.toString())
      .set("Page", page.toString());

    return this.http.get<any>(`${this.endpoint}listarRutSecretInteraction`, { headers });
  }

  buscarRut(rut: string): Observable<any> {
    const headers = new HttpHeaders()
      .set("IDOrganizacion", this._sessionService.getOrganization())
      .set("IDUsuario", this._sessionService.getUserInfo().username)
      .set("RequestOrigin", 'WEB')
      .set("rut", rut);

    return this.http.get<any>(`${this.endpoint}buscarRut`, { headers });
  }

  updateCartera(IDUsuarioConsultado: string, rutToAdd: string[], rutToDelete: string[]): Observable<any> {
    const headers = new HttpHeaders()
      .set("IDOrganizacion", this._sessionService.getOrganization())
      .set("IDUsuario", this._sessionService.getUserInfo().username)
      .set("RequestOrigin", 'WEB')
      .set("IDUsuarioConsultado", IDUsuarioConsultado);

      const payload: any = {
        rutList: {
          rutToAdd: rutToAdd,
          rutToDelete: rutToDelete
        }
      }

    return this.http.post<any>(`${this.endpoint}updateCartera`, payload, { headers });
  }

  obtenerUsuariosTotalizadores(): Observable<any> {
    const headers = new HttpHeaders()
      .set("IDOrganizacion", this._sessionService.getOrganization())
      .set("IDUsuario", this._sessionService.getUserInfo().username)
      .set("RequestOrigin", 'WEB')

    return this.http.get<any>(`${this.endpoint}obtenerUsuariosTotalizadores`, { headers });
  }

  verificarCartera(rut: string): Observable<any> {
    const headers = new HttpHeaders()
      .set("IDOrganizacion", this._sessionService.getOrganization())
      .set("IDUsuario", this._sessionService.getUserInfo().username)
      .set("RequestOrigin", 'WEB')
      .set("rut", rut)

    return this.http.get<any>(`${this.endpoint}verificarCartera`, { headers });
  }

  validateRutCartera(rut: string): Observable<boolean> {
    if (!rut) return of(false);
  
    if (!rut.includes('-')) {
      // Agregar guion antes del último dígito si no tiene guion.
      rut = rut.slice(0, -1) + '-' + rut.slice(-1);
    }
  
    this.spinner.show();
    return this.verificarCartera(rut).pipe(
      map((data: any) => {
        this.spinner.hide();
        if (data && data?.grantedUser && data?.grantedUser == true) {
          return true;
        } else {
          this.alertService.error('El rut ingresado no está asociado a la cartera.');
          return false;
        }
      }),
      catchError((error: any) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al validar el rut en la cartera.');
        this.spinner.hide();
        return of(false);
      })
    );
  }

}
