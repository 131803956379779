<div class="panel panel-default border-0 not-print">
  <div class="panel-body">
    <div class="btn-option">
      <button *ngIf="showReportes && stepper === 5" [printTitle]='"FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
        [useExistingCss]="true" printSectionId="print-section" ngxPrint
        class="btn btn-primary float-right">Descargar PDF</button>
    </div>
  </div>
</div>
  
<div class="col-sm-12" id="print-section">
  <!--Informacion del Cliente-->
  <div class="row mt-3">
    <div class="col-sm-12 mb-3">
      <div class="card">
        <h5 class="card-header list-group-item cuadro-color">Información del Cliente</h5>
        <div class="card-body">
          <div class="row">
            <ng-container *ngIf="informacionCliente.length > 1; else elseInformacionCliente">
              <ng-container *ngFor="let item of informacionCliente">
                <div class="col-sm-6">
                  <ng-container *ngTemplateOutlet="tableTemplate; context: { $implicit: {campo: item} }">
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #elseInformacionCliente>
              <div class="col-sm-12">
                <ng-container *ngTemplateOutlet="tableTemplate; context: { $implicit: {campo: informacionCliente[0]} }">
                </ng-container>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Riesgo y Antecedentes Salfa-->
  <div class="row mt-3" *ngIf="stepper === 5 || plantillaNoEditable === true">
    <!--RIESGO-->
    <div class="col-sm-6 full-width-col">
      <div class="card">
        <h5 class="card-header list-group-item cuadro-color">RIESGO</h5>
        <div class="card-body box-riesgo">
          <div class="table-responsive">
            <table class="table table-hover" *ngIf="informeRiesgo && objectKeys(informeRiesgo).length > 0">
              <tbody>
                <tr>
                  <th colspan="1" width="40%">Riesgo Industria:</th>
                  <td colspan="4" width="60%" class="text-center">
                    <div class="form-control" disabled>
                      {{informeRiesgo?.riesgoIndustria}}
                    </div>
                  </td>
                </tr>
                  <tr>
                    <th colspan="1">Protestos internos vigentes:</th>
                    <td colspan="4">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-6 pl-0">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text" disabled>#</span>
                              </div>
                              <div class="form-control border-left-0" disabled>
                                {{informeRiesgo?.protestosInternosVigentesCantidad}}
                              </div>
                            </div>
                          </div>
                          <div class="col-6 pr-0">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text" disabled>$</span>
                              </div>
                              <div class="form-control border-left-0" disabled>
                                {{informeRiesgo?.protestosInternosVigentesMonto}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="1">Morosidades internas:</th>
                    <td colspan="4" class="text-center">
                      <div class="form-control" disabled>{{informeRiesgo?.morosidadesInternas | number: '1.0-0'}}</div>
                    </td>
                  </tr>
                <tr>
                  <th colspan="1">Morosidades internas +30:</th>
                  <td colspan="4" class="text-center">
                    <div class="form-control" disabled>
                      {{informeRiesgo?.morosidadesInternasMayores30 | number: '1.0-0'}}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colspan="5">
                    <div class="mx-auto dynamic-colunm mb-5">
                      <app-custom-bars [riesgo]="informeRiesgo?.score"></app-custom-bars>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colspan="5">
                    <app-pie-chart [value]="informeRiesgo?.score"></app-pie-chart>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--Antecedentes Salfa-->
    <div class="col-sm-6 full-width-col">
      <div class="card">
        <h5 class="card-header list-group-item cuadro-color">Antecedentes Salfa</h5>
        <div class="card-body table-responsive">
          <table class="table mb-0">
            <tbody>
              <ng-container *ngFor="let item of antecedentesSalfa">
                <tr>
                  <th width='30%'><i class="promedio" *ngIf="item?.titlePromedio === true">X </i>{{item?.title}}</th>
                  <td width='70%'>
                    <div class="form-control" disabled>
                      <ng-container *ngTemplateOutlet="inputTableTemplate; context: { $implicit: {campo: item} }">
                      </ng-container>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row pagebreak">
    <div class="col-sm-12 mt-3">      
      <ng-container *ngIf="stepper === 5 || plantillaNoEditable === true; else camposPersonalizadosEditables">
        <div class="card mt-3">
          <h5 class="card-header list-group-item cuadro-color">
            Condición de Ventas
          </h5>
          <div class="card-body not-print">
            <ng-container *ngIf="atributos && atributos.length > 0">
              <ngx-masonry [updateLayout]="updateMasonryLayout">
                <div ngxMasonryItem 
                [className]="seccionesCamposPersonalizados.length === 1 ? 'card my-2 masonry-item item-card-1 m-2' : seccionesCamposPersonalizados.length === 2 ? 'card my-2 masonry-item item-card-2 m-2' : 'card my-2 masonry-item item-card m-2'"
                *ngFor="let seccion of seccionesCamposPersonalizados"
                >
                  <div class="card-header py-0">
                    <h4 class="my-2">
                      {{seccion}}
                    </h4>
                  </div>
                  <div class="card-body">
  
                    <div class="row" *ngFor="let campo of atributos">
  
                      <ng-container *ngIf="seccion===campo.section && campo.creditRequestActive == true && campo.enabled == true">
                        <div class="container-fluid">
  
                          <div class="col-sm-12 col-md-4 my-1 font-weight-bold">
                            {{campo.nameAttribute}}
                          </div>
  
                          <div class="col-sm-12 col-md-8 my-1">

                            <span>
                              <ng-container *ngIf="objectKeys(campo.allowedValues).length > 0; else notAllowedValues">
                                <ng-container [ngSwitch]="campo.typeAttibute">
                                  <ng-container *ngSwitchCase="'Date'">
                                    {{campo.allowedValues[campo.value] | date: 'dd-MM-yyyy'}}
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'Number'">
                                    <ng-container [ngSwitch]="campo.formatAttribute">
                                      <ng-container *ngSwitchCase="'0.0'">
                                        {{campo.allowedValues[campo.value] | number: '1.0-2'}}
                                      </ng-container>
                                      <ng-container *ngSwitchDefault>
                                        {{campo.allowedValues[campo.value] | number: '1.0-0'}}
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'Boolean'">
                                    {{campo.allowedValues[campo.value] ? 'Si' : 'No'}}
                                  </ng-container>
                                  <ng-container *ngSwitchDefault>
                                    {{campo.allowedValues[campo.value] || ''}}
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              <ng-template #notAllowedValues>
                                <ng-container [ngSwitch]="campo.typeAttibute">
                                  <ng-container *ngSwitchCase="'Date'">
                                    {{campo.value | date: 'dd-MM-yyyy'}}
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'Number'">
                                    <ng-container [ngSwitch]="campo.formatAttribute">
                                      <ng-container *ngSwitchCase="'0.0'">
                                        {{campo.value | number: '1.0-2'}}
                                      </ng-container>
                                      <ng-container *ngSwitchDefault>
                                        {{campo.value | number: '1.0-0'}}
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'Boolean'">
                                    {{campo.value ? 'Si' : 'No'}}
                                  </ng-container>
                                  <ng-container *ngSwitchDefault>
                                    {{campo.value || ''}}
                                  </ng-container>
                                </ng-container>
                              </ng-template>
                            </span>
                            
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ngx-masonry>
            </ng-container>
          </div>

          <div class="card-body d-none div-print">
            <ng-container *ngIf="atributos && atributos.length > 0">

              <div class="row">
                <div class="col-12" *ngFor="let seccion of seccionesCamposPersonalizados">
                  <div class="card my-2 m-2">
                    <div class="card-header py-0">
                      <h4 class="my-2">
                        {{seccion}}
                      </h4>
                    </div>
                    <div class="card-body">
    
                      <div class="row" *ngFor="let campo of atributos">
    
                        <ng-container *ngIf="seccion===campo.section && campo.creditRequestActive == true && campo.enabled == true">
                          <div class="container-fluid">
    
                            <div class="col-sm-12 col-md-4 my-1 font-weight-bold">
                              {{campo.nameAttribute}}
                            </div>
    
                            <div class="col-sm-12 col-md-8 my-1">
    
                              <span>
                                <ng-container *ngIf="objectKeys(campo.allowedValues).length > 0; else notAllowedValues">
                                  <ng-container [ngSwitch]="campo.typeAttibute">
                                    <ng-container *ngSwitchCase="'Date'">
                                      {{campo.allowedValues[campo.value] | date: 'dd-MM-yyyy'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Number'">
                                      <ng-container [ngSwitch]="campo.formatAttribute">
                                        <ng-container *ngSwitchCase="'0.0'">
                                          {{campo.allowedValues[campo.value] | number: '1.0-2'}}
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                          {{campo.allowedValues[campo.value] | number: '1.0-0'}}
                                        </ng-container>
                                      </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Boolean'">
                                      {{campo.allowedValues[campo.value] ? 'Si' : 'No'}}
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                      {{campo.allowedValues[campo.value] || ''}}
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                                <ng-template #notAllowedValues>
                                  <ng-container [ngSwitch]="campo.typeAttibute">
                                    <ng-container *ngSwitchCase="'Date'">
                                      {{campo.value | date: 'dd-MM-yyyy'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Number'">
                                      <ng-container [ngSwitch]="campo.formatAttribute">
                                        <ng-container *ngSwitchCase="'0.0'">
                                          {{campo.value | number: '1.0-2'}}
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                          {{campo.value | number: '1.0-0'}}
                                        </ng-container>
                                      </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Boolean'">
                                      {{campo.value ? 'Si' : 'No'}}
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                      {{campo.value || ''}}
                                    </ng-container>
                                  </ng-container>
                                </ng-template>
                              </span>
                              
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </ng-container>
          </div>

        </div>
      </ng-container>
      <ng-template #camposPersonalizadosEditables>
        <div class="card mt-3">
          <h5 class="card-header list-group-item cuadro-color">
            Detalle del negocio
          </h5>
          <div class="card-body">
            <ng-container *ngIf="fieldsReady && atributos && atributos.length > 0">
              <form [formGroup]="camposPersonalizadosForm">
                <ngx-masonry [updateLayout]="updateMasonryLayout">
                  <div ngxMasonryItem 
                  [className]="seccionesCamposPersonalizados.length === 1 ? 'card my-2 masonry-item item-card-1 m-2' : seccionesCamposPersonalizados.length === 2 ? 'card my-2 masonry-item item-card-2 m-2' : 'card my-2 masonry-item item-card m-2'"
                  *ngFor="let seccion of seccionesCamposPersonalizados"
                  >
                    <div class="card-header py-0">
                      <h4 class="my-2">
                        {{seccion}}
                      </h4>
                    </div>
                    <div class="card-body">
    
                      <div class="row" *ngFor="let campo of atributos">
    
                        <ng-container *ngIf="seccion===campo.section && campo.creditRequestActive == true && campo.enabled == true">
                          <div class="container-fluid">
    
                            <div class="col-sm-12 col-md-4 my-1">
                              {{campo.nameAttribute}}
                            </div>
    
                            <div class="col-sm-12 col-md-8 my-1" *ngIf="objectKeys(campo.allowedValues).length === 0">
                              <input [attr.type]="campo.typeAttibute" class="form-control"
                              [formControlName]="campo.nameAttribute">
                              <div class="w-100 mb-2">
                                <ng-container *ngIf="!camposPersonalizadosForm.get(campo.nameAttribute)?.errors?.invalidValue; else invalidValue">
                                  <app-error [control]="camposPersonalizadosForm.get(campo.nameAttribute)" 
                                  [validarValid]="campo.typeAttibute === 'Number' ? true : false"
                                  [formato]="campo.formatAttribute"
                                  ></app-error>
                                </ng-container>
                              </div>
                            </div>
                              
                            <ng-container *ngIf="objectKeys(campo.allowedValues).length > 0">
                              <div class="col-sm-12 col-md-8 my-1">
                                <select (click)="actualizaLayout()" class="form-control text-capitalize"
                                  [formControlName]="campo.nameAttribute">
                                  <option 
                                  [attr.selected]="campo.defaultValue === '' ? true : null"
                                  value="">Seleccione...</option>
                                  <ng-container *ngFor="let item of objectKeys(campo.allowedValues); let i = index">
                                    <option class="text-capitalize"
                                      [attr.selected]="campo.defaultValue === item ? true : null"
                                      [value]="item">
                                      {{campo.allowedValues[item]}}
                                    </option>
                                    </ng-container>
                                </select>
                                <div class="w-100">
                                  <ng-container *ngIf="!camposPersonalizadosForm.get(campo.nameAttribute)?.errors?.invalidValue; else invalidValue">
                                    <app-error [control]="camposPersonalizadosForm.get(campo.nameAttribute)"></app-error>
                                  </ng-container>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ngx-masonry>

                <div class="col-12 mt-5">
                  <div class="form-group form-actions d-flex justify-content-center">
                    <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="guardarCamposPersonalizados()">
                      Guardar Campos
                    </button>
                  </div>
                </div>

              </form>
            </ng-container>
          </div>
        </div>
      </ng-template>
      
    </div>
  </div>

  <!--Línea de Credito M$-->
  <div class="mt-3">
    <p class="subTitle text-center w-100">
      Línea de Credito M$
    </p>

    <div class="mt-1 w-100">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>N° Tickets</th>
              <th>Razón Social</th>
              <th>Rut</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{lineaCreditoMTickets.nTickets}}</td>
              <td>{{lineaCreditoMTickets.razonSocial}}</td>
              <td>{{lineaCreditoMTickets.rut ? (lineaCreditoMTickets.rut | rutFormat) : ''}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="container mx-0 px-0 w-100" *ngIf="bodyModelos?.workflowModel === 'lineaDeCredito'">
      <div class="col-sm-12 col-md-6 col-lg-6 px-0">
        <div class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="cuadro-color" width="50%">Monto Preaprobado</th>
                <td width="50%" [ngClass]="{'text-right': stepper === 5 || plantillaNoEditable === true}">
                  <ng-container *ngIf="stepper === 5 || plantillaNoEditable === true; else montoPreAprobadoEditable">
                    {{montoPreAprobado | number : '1.0-2'}}
                  </ng-container>
                  <ng-template #montoPreAprobadoEditable>
                    <input type="number" 
                      class="form-control" 
                      id="montoPreAprobado" 
                      placeholder="Monto Preaprobado" 
                      [(ngModel)]="montoPreAprobado"
                      (appChangeKeyup)="handleEvent(['recalcularTablaLineaCredito'])">
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <div class="table-responsive" *ngIf="bodyModelos?.workflowModel === 'lineaDeCredito'">
      <table class="table table-bordered table-fixed" *ngIf="objetoLineaCredito.length > 0">
        <thead>
          <tr>
            <th class="cuadro-color-table" width="200px"></th>
            <th class="cuadro-color-table" *ngFor="let item of objetoLineaCredito">
              {{item.UnidadNegocio}}
            </th>
            <th class="cuadro-color-table"></th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th class="cuadro-color-table headRight">Líneas de Negocio</th>
            <th class="cuadro-color-table" *ngFor="let item of objetoLineaCredito">
              {{item.ACC}}
            </th>
            <th class="cuadro-color-table">Total</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th class="cuadro-color-table headRight">
              <div class="input-group"> Monto de Línea de Crédito Solicitada </div>
            </th>
            <th class="cuadro-color-table" *ngFor="let item of objetoLineaCredito; let i = index;">
              <div class="input-group">
                <ng-template #onlyText>
                  <div class="form-control" disabled>{{item.lcSolicitada}}</div>
                </ng-template>

                <ng-container *ngIf="!(this.stepper === 5 || this.plantillaNoEditable === true); else onlyText">
                  <input type="text" class="form-control border-right-0" #ref (input)="setRowLineaAprobada(ref.value, item, 'valueAcc');
                    setAddRow(ref.value, item, 'lcSolicitada')" mask="separator" [(ngModel)]="objetoLineaCredito[i].lcSolicitada"
                    thousandSeparator="." [readonly]="montoPreAprobado === 0 || !montoPreAprobado" (click)="showAlert()">
                </ng-container>
              </div>
            </th>
            <th class="cuadro-color-table">
              <div class="input-group">
                <div class="form-control" disabled>
                  {{objetoLineaCredito | sumTd: 'lcSolicitada' | number : '1.0-0'}}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="headRight">% de utilización</td>
            <td *ngFor="let item of objetoLineaCredito">
              {{item.valueAcc | number: '1.0-2'}} %
            </td>
            <td>
              {{objetoLineaCredito | sumTd: 'valueAcc' | number : '1.0-2'}} %
            </td>
          </tr>
          <tr>
            <td class="headRight">Monto de Línea de Crédito Actual</td>
            <td *ngFor="let item of objetoLineaCredito">
              {{item.ImporteML | number: '1.0-0'}}
            </td>
            <td>
              {{objetoLineaCredito | sumTd: 'ImporteML' | number : '1.0-0'}}
            </td>
          </tr>
          <tr>
            <td class="headRight">Condición de pago (Acuerdo 30 días)</td>
            <td *ngFor="let item of objetoLineaCredito">
              {{item.acuerdo30 }}
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="headRight">LC Utilizada (por línea de negocio)</td>
            <td *ngFor="let item of objetoLineaCredito">
              {{item.lcActual | number : '1.0-0'}}
            </td>
            <td>{{objetoLineaCredito | sumTd: 'lcActual' | number : '1.0-0'}}</td>
          </tr>
          <tr>
            <td class="headRight">LC útil/LC Aprobada: dividir LC Utilizada (Por línea de negocio)</td>
            <td *ngFor="let item of objetoLineaCredito">
              {{item.lcAprobada | number : '1.0-2'}}
              <div class="pl-3 pull-right">%</div>
            </td>
            <td>
              {{objetoLineaCredito | sumTd: 'lcAprobada' | number : '1.0-2'}}
              <div class="pl-3 pull-right">%</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div class="break-box">
    <div class="card mb-5 mt-3" *ngIf="stepper === 5 || plantillaNoEditable === true">
      <h5 class="card-header list-group-item cuadro-title">
        Estados Financieros M$
      </h5>
    </div>
    <div class="col-12">

      <div class="row mt-3" *ngIf="stepper === 5 || plantillaNoEditable === true">

        <div class="col-sm-6 pl-sm-0" *ngFor="let item of estadosFinancierosM">
          <div class="card">
            <div class="card-body">
              <table class="table">
                <ng-container *ngFor="let element of item">
                  <tr>
                    <th width="50%">
                      <div class="pt-3">{{element?.title || ''}}</div>
                    </th>
                    <td>
                      <div type="text" class="form-control" disabled>
                        <ng-container *ngTemplateOutlet="inputTableTemplate; context: { $implicit: {campo: element} }">
                        </ng-container>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
      </div>
  
      <div class="tributaria pagebreak" *ngIf="stepper === 5 || plantillaNoEditable === true">
        <div class="row mt-3">
          <p class="subTitle text-center w-100">
            Carpeta Tributaria M$
          </p>

          <div class="mt-1 w-100" *ngIf="carpetaTributaria.length > 0">
            <app-dynamic-colunm-table [data]='carpetaTributaria' [divider]='"group"' [header]='headerCarpetaTributaria'
              [clasess]="'table-bordered'" id="info-tributaria">
            </app-dynamic-colunm-table>
          </div>
        </div>
      </div>
  
      <div class="row mt-3" *ngIf="stepper === 5 || plantillaNoEditable === true">
        <p class="subTitle text-center w-100">
          Información de Balance/Estado de Resultado M$ 
        </p>
  
        <div class="col-sm-6" *ngFor="let item of informacionBalanceEstadoResultadoM">
          <div class="card">
            <div class="card-body">
              <table class="table">
                <tbody>

                  <ng-container *ngFor="let element of item">
                    <tr>
                      <th width="50%">
                        <div class="pt-3">{{element?.title || ''}}</div>
                      </th>
                      <td>
                        <div type="text" class="form-control" disabled>
                          <ng-container *ngTemplateOutlet="inputTableTemplate; context: { $implicit: {campo: element} }">
                          </ng-container>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
      </div>
  
      
      <div class="row mt-3">
  
        <p class="subTitle text-center w-100">
          Flujos Futuros Comprometidos M$
        </p>
        <div class="w-100 mb-3" *ngIf="objFlujosFuturos.length > 0">
          <div class="table-responsive">
            <table class="table table-bordered" *ngIf="objFlujosFuturos.length > 0">
              <thead>
                  <tr>
                    <th>Fecha</th>
                    <th *ngFor="let item of titlesFlujosFuturos">
                      {{item}}
                    </th>
                    <th>Total</th>
                  </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of objFlujosFuturos | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                  <td class="text-capitalize">{{ item.Periodo_Vencimiento | date: 'LLL'}}
                    {{ item.Periodo_Vencimiento | date: 'YYYY'}}
                  </td>
                  <td *ngFor="let col of titlesFlujosFuturos">
                    {{item[quitarEspacios(col)] || 0 | number:'1.0-0'}}
                  </td>
                  <td>{{item | sumTd: false: ['Periodo_Vencimiento'] | number:'1.0-0'}}</td>
                </tr>
              </tbody>
            </table>
            <div id="pagination">
              <div class="col-xs-10 not-print">
                <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
              </div>
              <div class="col-xs-2 text-right not-print">
                <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
              </div>
            </div> 
          </div>
        </div>
  
        
        <p class="subTitle text-center w-100" [ngClass]="{'pagebreak': objFlujosHistoricos.length > 0 && titlesFlujosHistoricos.length > 0}">
          Facturacion Histórica M$
        </p>
  
        <div class="w-100" *ngIf="showReportes && objFlujosHistoricos.length > 0 && titlesFlujosHistoricos.length > 0">
          <app-dynamic-colunm-table [data]='objFlujosHistoricos' [divider]='"divider"' [header]='quitarEspacios(titlesFlujosHistoricos)'
            [groupHeader]="'listadoFacturacionHistorica'" [clasess]="'table-bordered'">
          </app-dynamic-colunm-table>
        </div>
      </div>


    </div>
  </div>

  <div class="break-box">
    <div class="card mb-3">
      <h5 class="card-header list-group-item cuadro-title">
        Malla Societaria
      </h5>
    </div>
    <div class="row" *ngIf="mallaSocietariaTable.length > 0">
      <div class="col-12">
        <div class="col-sm-12 px-0 mb-3">
          <div class="responsive-table">
            <table class="table">
              <thead>
                <tr>
                <th width='30%'>Socios</th>
                <th width='35%'>Nombre</th>
                <th width='35%'>Rut</th>
                </tr>
              </thead>
                <tbody>
                  <tr
                    *ngFor="let item of mallaSocietariaTable | paginate:{itemsPerPage: currentItemMalla, currentPage: currentPageMalla}; let i = index">
                    <td>
                      Socio {{i+1}}
                    </td>
                    <td>
                      <div class="form-control" disabled>
                        {{item.nombre}}
                      </div>
                    </td>
                    <td>
                      <div class="form-control" disabled>
                        {{item.rut}}
                      </div>
                    </td>
                  </tr>
                </tbody>
            </table>
            <div id="pagination">
              <div class="col-xs-10 not-print">
                <pagination-controls (pageChange)="currentPageMalla=$event"></pagination-controls>
              </div>
              <div class="col-xs-2 text-right not-print">
                <app-paginator-by-items [itemsPerPageInput]="currentItemMalla" (itemsPerPage)="currentItemMalla = $event"></app-paginator-by-items>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12">
        <p class="subTitle text-center"> DICOM M$</p>
      </div>
    </div>
    
    <div class="row">
      <div class="col-sm-6" *ngFor="let item of dicomMTable">
        <div class="responsive-table">
          <table class="table">
            <thead>
              <tr>
                <th class="extraHead" width='45%'>{{item.name}}</th>
                <th class="extraHead" width='55%'>{{item.fecha | date: 'dd-MM-YYYY'}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="1">
                  Score (Historial)
                </td>
                <td colspan="4">
                  <div class="form-control" disabled>{{item.score | number: '1.0-0'}}</div>
                </td>
              </tr>
              <tr>
                <td>Morosidades</td>
                <td colspan="4">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6" [ngClass]="i === 0? 'pl-0': 'pr-0'"
                        *ngFor="let morosidades of item.morosidades; let i = index">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" disabled>{{i === 0? '#': 'M$'}}</span>
                          </div>
                          <div class="form-control border-left-0" disabled>{{morosidades.morosidad |
                            number: '1.0-0'}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Protestos</td>
                <td colspan="4">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6" [ngClass]="i === 0? 'pl-0': 'pr-0'"
                        *ngFor="let protestos of item.protestos; let i = index">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" disabled>{{i === 0? '#': 'M$'}}</span>
                          </div>
                          <div class="form-control border-left-0" disabled>{{protestos.protesto |
                            number: '1.0-0'}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Multas Previsionales</td>
                <td colspan="4">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6" [ngClass]="i === 0? 'pl-0': 'pr-0'"
                        *ngFor="let multas of item.multas; let i = index">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" disabled>{{i === 0? '#': 'M$'}}</span>
                          </div>
                          <div class="form-control border-left-0" disabled>{{multas.multa | number:
                          '1.0-0'}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
    
    <ng-container>

      <div class="row">
        <div class="col-12">
          <p class="subTitle text-center"> Deuda Sbif </p>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-sm-12" *ngIf="reporteDeudaSbif && objectKeys(reporteDeudaSbif).length > 0">
          <app-deuda-leasing-sbif-viewer [content]='reporteDeudaSbif' [typeTX]="false" [showHeader]="false">
          </app-deuda-leasing-sbif-viewer>
        </div>
        <div class="col-sm-12 mt-3" *ngIf="deudaSbifDerivados && deudaSbifDerivados.length > 0">
          <div class="table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th class="cuadro-color">Rut</th>
                  <th class="cuadro-color">Nombre</th>
                  <th class="cuadro-color">Periodo</th>
                  <th class="cuadro-color">Vigente M$</th>
                  <th class="cuadro-color">Deteriorada M$</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="deudaSbifDerivados && deudaSbifDerivados.length > 0; else sbifNoData">
                  <tr *ngFor="let item of deudaSbifDerivados">
                    <td>{{item?.rut ? (item?.rut | rutFormat) : ''}}</td>
                    <td>{{item?.nombre || ''}}</td>
                    <td>{{item?.periodoFormatted || ''}}</td>
                    <td>{{item?.vigente || item?.vigente == 0 ? (item?.vigente | number : '1.0-0') : ''}}</td>
                    <td>{{item?.deteriorada || item?.deteriorada == 0 ? (item?.deteriorada | number : '1.0-0') : ''}}</td>
                  </tr>
                </ng-container>
                <ng-template #sbifNoData>
                  <tr>
                    <td colspan="100%" class="text-center">Sin información</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </ng-container>
  </div>

  <!--grafico malla societaria-->
  
  <div class="break-box">
    <div class="card mb-3">
      <h5 class="card-header list-group-item cuadro-title">
        Malla Societaria
      </h5>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-sm-12">
        
          <div class="row no-print" *ngIf="mallaSocietariaGraph && mallaSocietariaGraph.length > 0">
            <div class="col-sm-12 px-5 mb-2">
              <button type="button" class="btn btn-primary btn-sm mx-1" (click)="zoomIn()">
                <i class="fa fa-search-plus" aria-hidden="true"></i>
              </button>
              <button type="button" class="btn btn-secondary btn-sm mx-1" (click)="zoomOut()">
                <i class="fa fa-search-minus" aria-hidden="true"></i>
              </button>
            </div>
  
            <div class="col-12 shadow" *ngIf="showMalla">
              <div #chartContainer class="myChart" id="chartContainer"></div>
            </div>
          </div>
          
          <div id="print-section-malla-flujo" *ngIf="mallaSocietariaGraphPrint && objectKeys(mallaSocietariaGraphPrint).length > 0">
            <div class="nested-table-container">
              <div class="nested-table-row print-table-header">
                <div class="rs-nested-table-cl">Razon Social</div>
                
                <div class="type-and-rut">
                  <div>Tipo</div>
                  <div>RUT</div>
                </div>
              </div>
          
              <br>
          
              <app-print-nested-table [dataSource]="mallaSocietariaGraphPrint"></app-print-nested-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="card mt-3 mb-5">
      <h5 class="card-header list-group-item cuadro-title">
        Anexo
      </h5>
    </div>
    <div class="row mt-3 anexoBox">
      <div class="col-sm-12 mb-5">
        <div class="box-graph-flujo" *ngIf="graphReady">
          <app-line-bars [serie]='serie' [title]='"Facturación/Saldo"' [categorias]='categoria' [yAxix]='yAxix'
            [plotOptions]='plotOptions'></app-line-bars>
        </div>
      </div>
    </div>
  </div>

  <!--archivos anexos-->
  <div class="col-12 my-2 not-print" *ngIf="showArchivos">
    <app-modulo-archivos [idTransaccion]="idTransaccion" [rut]="rut" [closed]="closed"></app-modulo-archivos>
  </div>

</div>

<ng-container *ngIf="plantillaNoEditable === false">
  <ng-template #botonFinalizar>
    <div class="not-print">
      <ng-container *ngIf="validaUsuarioAdmin()">
        <div class="col-12 d-flex justify-content-center pt-4">
          <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="volverEtapaPrevia()">
            Devolver Etapa Previa
          </button>
          <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="continuar()">
            Continuar
          </button>
        </div>
      </ng-container>
    </div>
  </ng-template>
  
  <ng-container *ngIf="stepper === 4 else botonFinalizar">
  
    <div class="col-12 d-flex justify-content-center pt-4">
      <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="guardarFicha()">
        Guardar Ficha
      </button>
      <button class="btn btn-sm btn-primary mx-2 rounded-header" [disabled]="objectKeys(fichaGuardada).length > 0 ? null : true" (click)="avanzarFlujo()">
        Continuar
      </button>
    </div>
  
  </ng-container>
</ng-container>
  
  
  

<ng-template #tableTemplate let-elemento>
  <div class="table-responsive">
    <table class="table">
      <tbody>
        <ng-container *ngFor="let item of elemento?.campo">
          <tr *ngIf="!item?.scoreValue || (item?.scoreValue && (stepper === 5 || plantillaNoEditable === true))">
            <th colspan="1" width="40%">{{item.title || ''}}:</th>
            <td colspan="4" width="60%">
              <ng-container *ngIf="item?.values && item?.values.length === 2; else else2ValuesLength">
                <div class="col-12">
                  <div class="row">
                    <ng-container *ngFor="let itemValue of item?.values; let i = index">
                      <div class="col-6 pl-0" [ngClass]="i == 0 ? 'col-6 pl-0' : 'col-6 pr-0'">
                        <div class="input-group">
                          <div class="input-group-prepend" *ngIf="itemValue?.inputPrepend">
                            <span class="input-group-text" disabled>{{itemValue?.inputPrepend || ''}}</span>
                          </div>
                          <div class="form-control" disabled [ngClass]="{'border-left-0': itemValue?.inputPrepend}">
                            <ng-container *ngTemplateOutlet="inputTableTemplate; context: { $implicit: {campo: itemValue} }">
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-template #else2ValuesLength>
                <ng-container *ngIf="item?.values && item?.values.length > 0">
                  <ng-container *ngIf="item?.values[0]?.type === 'semaforo'; else elseSemaforo">
                    <ng-container [ngSwitch]="item?.values[0]?.value">
                      <ng-container *ngSwitchCase="'Verde'">
                        <div class="circulo verde"></div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'Amarillo'">
                        <div class="circulo amarillo"></div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'Rojo'">
                        <div class="circulo rojo"></div>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-template #elseSemaforo>
                    <div class="input-group">
                      <div class="input-group-prepend" *ngIf="item?.values[0]?.inputPrepend">
                        <span class="input-group-text" disabled>{{item?.values[0]?.inputPrepend || ''}}</span>
                      </div>
                      <div class="form-control" disabled [ngClass]="{'border-left-0': item?.values[0]?.inputPrepend}">
                        <ng-container *ngTemplateOutlet="inputTableTemplate; context: { $implicit: {campo: item?.values[0]} }">
                        </ng-container>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-template>                                                
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #inputTableTemplate let-elemento>
    <ng-container [ngSwitch]="elemento?.campo?.type">
        <ng-container *ngSwitchCase="'rut'">
            {{elemento?.campo?.value | rutFormat}}
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
            {{elemento?.campo?.value | date:'dd-MM-yyyy'}}
        </ng-container>
        <ng-container *ngSwitchCase="'intNumber'">
            {{elemento?.campo?.value | number: '1.0-0'}}
        </ng-container>
        <ng-container *ngSwitchCase="'decNumber'">
            {{elemento?.campo?.value | number: '1.0-2'}}
        </ng-container>
        <ng-container *ngSwitchDefault>
            {{elemento?.campo?.value || ''}}
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #invalidValue>
  <div class="w-100 mt-2 error-custom">
      <span class="text-danger font-weight-bolder">
        * El valor del campo es invalido
      </span>
    </div>
</ng-template>