export enum ESystemAccess {
    REPORTEBASICO = "REPORTEBASICO",
    REPORTEBASICO_INFORMECOMERCIAL = "REPORTEBASICO-INFORMECOMERCIAL",
    CLIENTES_USER_ADMIN = "CLIENTES-USER-ADMIN",
    CLIENTES_CREDS_ADMIN = "CLIENTES-CREDS-ADMIN",
    REPORTEBASICO_BUSCAR = "REPORTEBASICO-BUSCAR",
    REPORTE_EMPRESAENUNDIA = "REPORTE-EMPRESAENUNDIA",
    REPORTE_EMPRESAENUNDIA_BUSCAR = "REPORTE-EMPRESAENUNDIA-BUSCAR",
    REPORTE_PODERJUDICIAL = "REPORTE-PODERJUDICIAL",
    REPORTE_PODERJUDICIAL_BUSCAR = "REPORTE-PODERJUDICIAL-BUSCAR",
    REPORTE_F29_BUSCAR = "REPORTE-F29-BUSCAR",
    REPORTE_F29 = "REPORTE-F29",
    REPORTE_F22_BUSCAR = "REPORTE-F22-BUSCAR",
    REPORTE_F22 = "REPORTE-F22",
    REPORTE_BOLETINCONCURSAL = "REPORTE-BOLETINCONCURSAL",
    REPORTE_BOLETINCONCURSAL_BUSCAR = "REPORTE-BOLETINCONCURSAL-BUSCAR",
    SOLICITUD_CREDITO = "SOLICITUD-CREDITO",
    SOLICITUD_CREDITO_BUSCAR = "SOLICITUD-CREDITO-BUSCAR",
    FLUJO_CREDITO = "FLUJO-CREDITO",
    FLUJO_CREDITO_ADMIN = "FLUJO-CREDITO-ADMIN",
    FLUJO_CREDITO_BUSCAR = "FLUJO-CREDITO-BUSCAR",
    FLUJO_CREDITO_BUSCAR_ADMIN = "FLUJO-CREDITO-BUSCAR-ADMIN",
    REPORTE_DEUDASBIF_LEASING_BUSCAR = "REPORTE-DEUDASBIF-LEASING-BUSCAR",
    REPORTE_DEUDASBIF_LEASING = "REPORTE-DEUDASBIF-LEASING",
    CARPETA_TRIBUTARIA_BUSCAR = "CARPETA-TRIBUTARIA-BUSCAR",
    CARPETA_TRIBUTARIA = "CARPETA-TRIBUTARIA",
    REPORTE_PROPIEDADES_BUSCAR = 'REPORTE-PROPIEDADES-BUSCAR',
    REPORTE_PROPIEDADES = 'REPORTE-PROPIEDADES',
    REPORTE_VEHICULOS_BUSCAR = 'REPORTE-VEHICULOS-BUSCAR',
    REPORTE_VEHICULOS = 'REPORTE-VEHICULOS',
    REPORTE_LIBRO_COMPRA_VENTA_BUSCAR = 'REPORTE-LIBRO-COMPRA-VENTA-BUSCAR',
    REPORTE_LIBRO_COMPRA_VENTA = 'REPORTE-LIBRO-COMPRA-VENTA',
    REPORTE_MALLA_SOCIETARIA = 'REPORTE-MALLA-SOCIETARIA',
    REPORTE_MALLA_SOCIETARIA_BUSCAR = 'REPORTE-MALLA-SOCIETARIA-BUSCAR',
    CAMPOS_PERSONALIZADOS = 'CAMPOS-PERSONALIZADOS',
    CAMPOS_PERSONALIZADOS_BUSCAR = 'CAMPOS-PERSONALIZADOS-BUSCAR',
    TESORERIA = 'TESORERIA',
    TESORERIA_BUSCAR = 'TESORERIA-BUSCAR',
    BALANCE_MANUAL = 'BALANCE-MANUAL',
    BALANCE_MANUAL_BUSCAR = 'BALANCE-MANUAL-BUSCAR',
    BALANCE_MANUAL_ADMIN = 'BALANCE-MANUAL-ADMIN',
    MERCADO_PUBLICO = 'MERCADO-PUBLICO',
    MERCADO_PUBLICO_BUSCAR = 'MERCADO-PUBLICO-BUSCAR',
    SINCRONIZAR_CREDENCIALES = 'SINCRONIZAR-CREDENCIALES',
    SINCRONIZAR_CREDENCIALES_BUSCAR = 'SINCRONIZAR-CREDENCIALES-BUSCAR',
    COBRANZA_ONLINE_PUBLICAR = 'COBRANZA-ONLINE-PUBLICAR',
    COBRANZA_ONLINE_BUSCAR = 'COBRANZA-ONLINE-BUSCAR',
    OFERTAS_FINANCIERAS = 'OFERTAS-FINANCIERAS',
    REPORTE_CESION_FACTURAS = 'REPORTE-CESIONFACTURAS',
    REPORTE_CESION_FACTURAS_BUSCAR = 'REPORTE-CESIONFACTURAS-BUSCAR',
    ADMINISTRACION_CARTERA = 'ADMINISTRADOR-CARTERA',
    ADMINISTRACION_CARTERA_ADMIN = 'ADMINISTRADOR-CARTERA-ADMIN',
    ADMINISTRACION_SUJETO_CREDITO = 'ADMINISTRADOR-SUJETO-CREDITO',
    CLIENTES_CREDS_READ = 'CLIENTES-CREDS-READ'
}

export enum ESystemProfileList {
    WEB = 'WEB'
}

export enum ESsystemUserProfile {
    USERUNIQUE = 'soporte-clientes@gestionatupyme.cl'
}

export const ESystemOrganizacion = {
    ORGANIZACION_ACCESS: ['DESARROLLO', 'SOPORTE']
}