import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { SessionService } from './session.service';
import { UtilsService } from './utils.service';
import { map } from 'rxjs/operators';
import { listadoLineaCreditoDetalle } from 'src/app/models/utils/utils.model';
import { AlertService } from 'src/app/components/_alert';
import { UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SolicitudCreditoServiceWorkflow extends UtilsService {
  private endpoint: string = env.backend.SolicitudCreditoWorkflow.url;
  private servicio: string = env.backend.SolicitudCreditoWorkflow.servicio;
  private calculadora: string = env.backend.calculadoraUF.url;
  private tipoSolicitud: string = '';
  public listadoLineaCreditoDetalle: any = listadoLineaCreditoDetalle;
  public ResumenObjSubject: Subject<any> = new Subject();
  public ResumenObjSubjectObs$ = this.ResumenObjSubject.asObservable();
  public resumenObj: any[] = [];

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    private alertService: AlertService) {
    super();
  }

  // stageId es el stage desde el flujo, y step es la pantalla
  public stepsOptions: any[] = [
    {stageID: '1', step: 1},
    {stageID: '2', step: 1},
    {stageID: '3', step: 2},
    {stageID: '4', step: 2},
    {stageID: '5', step: 3},
    {stageID: '6', step: 3},
    {stageID: '7', step: 3},
    {stageID: '8', step: 3},
    {stageID: '9', step: 4},
    {stageID: '10', step: 5},
    {stageID: '11', step: 6}
  ]

  obtenerDolar(fecha: string): Observable<any> {
    const request = {
      fechaInicio: fecha,
      fechaFin: fecha,
      indicador: "DOLAR"
    };
    return this.http.post(`${this.calculadora}`, request);
  }

  obtenerEuro(fecha: string): Observable<any> {
    const request = {
      fechaInicio: fecha,
      fechaFin: fecha,
      indicador: "EURO"
    };
    return this.http.post(`${this.calculadora}`, request);
  }
  
  calcularUF(fecha: string): Observable<any> {
    const request = {
      fechaInicio: fecha,
      fechaFin: fecha,
      indicador: "UF"
    };
    return this.http.post(`${this.calculadora}`, request);
  }

  /**
  * @description
  * Servicio para consultar los servicios seleccionados en la segunda etapa del workflow
  * @param servicioAConsultar any
  * @param idTransaccion string
  * @param rut string
  */
   consultarServicios(servicioAConsultar: any, idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    const request = {
      idTransaccion: idTransaccion,
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      servicioAConsultar: {servicioAConsultar: servicioAConsultar}
    };

    return this.http.post<any>(`${this.endpoint}obtenerReportes`, request);
  }

  /**
  * @description
  * Servicio para borrar un reporte indicado segun su key
  * @param serviceKey string
  * @param idTransaccion string
  * @param rut string
  */
   borrarReportePrincipal(serviceKey: string, idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    const request = {
      idTransaccion: idTransaccion,
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      serviceKey: serviceKey
    };
    return this.http.post<any>(`${this.endpoint}borrarReportePrincipal`, request);
  }

  /**
  * @description
  * Servicio para buscar los servicios disponibles, que se muestran en la segunda pantalla del workflow
  * @param rut string
  * @param idTransaccion string
  * @param principal boolean
  */
   serviciosDisponibles(rut: string, idTransaccion: string, principal: boolean): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    const request = {
      idTransaccion: idTransaccion,
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      esPrincipal : principal
    };
    return this.http.post<any>(`${this.endpoint}obtenerListadoServicios`, request);
  }


  /**
  * @description
  * Servicio para consultar los servicios seleccionados en la segunda etapa del workflow
  * @param servicioAConsultar any
  * @param idTransaccion string
  * @param rut string
  */
   consultarServiciosDerivados(rut: string, idTransaccion: string, entidadesAConsultar: any[]): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    const request = {
      idTransaccion: idTransaccion,
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      entidadesDerivadasAConsultar: entidadesAConsultar
    };
    return this.http.post<any>(`${this.endpoint}obtenerReportesDerivados`, request);
  }

  /**
  * @description
  * Servicio para consultar los servicios seleccionados en la segunda etapa del workflow
  * @param servicioAConsultar any
  * @param idTransaccion string
  * @param rut string
  */
   borrarReportesDerivados(rut: string, idTransaccion: string, entidadesReportes: any[]): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    const request = {
      idTransaccion: idTransaccion,
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      entidadesReportes: entidadesReportes
    };

    return this.http.post<any>(`${this.endpoint}borrarReportesDerivados`, request);
  }

  /**
  * @description
  * Servicio para entrega las entidades derivadas de un rut
  * @param rut string
  * @param idTransaccion string
  */
   entidadesDerivadas(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    const request = {
      idTransaccion: idTransaccion,
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
    };

    return this.http.post<any>(`${this.endpoint}obtenerEntidadesDerivadas`, request);
  }

  /**
  * @description
  * Servicio para entrega las entidades derivadas de un rut
  * @param rut string
  * @param idTransaccion string
  */
   agregarBorrarDerivadas(rut: string, idTransaccion: string, agregarEntidad: boolean, entidadesDerivadas: any[]): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    const request = {
      idTransaccion: idTransaccion,
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      entidadesDerivadas: entidadesDerivadas,
      agregarEntidad: agregarEntidad
    };
    return this.http.post<any>(`${this.endpoint}agregarBorrarEntidadesDerivadas`, request);
  }

  /**
   * @description
   * Servicio para buscar las solicitudes realizadas
   * @param rut string
   */
  searchSolicitudesCreditoList(rut: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    return this.http.get(`${this.endpoint}obtenerTransacciones`, { params })
      .pipe(map((response: any) => {
        return response.map((item: any, i: number) => {
          item['numSolicitud'] = item.IDTransaccion.substr(item.IDTransaccion.length - 5),
            item['date'] = this.formatDate(item['FechaReporte']);
            //item['Modelo'] = this.listadoLineaCreditoDetalle[item['Modelo']];
          return item;
        });
      }));
  }

  validacionAutomatico(automatico: any): string {
    if(automatico === true){
      return 'Si';
    } else if(automatico === false){
      return 'No'
    } else {
      return '';
    }
  }

  /**
   * @description
   * Servicio para buscar las solicitudes realizadas
   * @param rut string
   */
   searchSolicitudesAdmin(): Observable<any> {
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    return this.http.get(`${this.endpoint}obtenerTransaccionesAdmin`, { params })
      .pipe(map((response: any) => {
        return response.map((item: any, i: number) => {
          item['numSolicitud'] = item.IDTransaccion.substr(item.IDTransaccion.length - 5),
          item['AutomaticoDisplay'] = this.validacionAutomatico(item.Automatico),
          item['EstadoVisible'] = this.mapeoSolicitudesEstadosVisibles(item.EstadoTransaccion),
            item['date'] = this.formatDate(item['FechaReporte']);
            //item['Modelo'] = this.listadoLineaCreditoDetalle[item['Modelo']];
          return item;
        });
      }));
  }

  /**
   * @description
   * Servicio para buscar las solicitudes realizadas
   * @param rut string
   */
   searchSolicitudesUsuario(): Observable<any> {
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    return this.http.get(`${this.endpoint}obtenerTransaccionesUsuarios`, { params })
      .pipe(map((response: any) => {
        return response.map((item: any, i: number) => {
          item['numSolicitud'] = item.IDTransaccion.substr(item.IDTransaccion.length - 5),
          item['AutomaticoDisplay'] = this.validacionAutomatico(item.Automatico),
          item['EstadoVisible'] = this.mapeoSolicitudesEstadosVisibles(item.EstadoTransaccion),
            item['date'] = this.formatDate(item['FechaReporte']);
            //item['Modelo'] = this.listadoLineaCreditoDetalle[item['Modelo']];
          return item;
        });
      }));
  }

  /**
  * @description
  * Funcion que indica el estado visible a partir del estado devuelto por la api
  * @param estado string
  * COMPLETED → En Evaluación, CLOSED → Evaluado, IP → En Progreso
  */
  mapeoSolicitudesEstadosVisibles(estado: string): string {
    if(estado === 'COMPLETED'){
      return 'En Evaluación';
    } else if(estado === 'CLOSED'){
      return 'Evaluado';
    } else if (estado === 'IP'){
      return 'En Progreso';
    }
    return '';
  }

  /**
  * @description
  * Servicio para buscar la informacion de reportes principales
  * @param idTransaccion string
  * @param rut string
  */
   buscarReportePrincipal(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idTransaccion", idTransaccion);
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<any>(`${this.endpoint}buscarReportePrincipal`, { params })
  }

  /**
  * @description
  * Servicio para buscar la informacion de reportes principales
  * @param idTransaccion string
  * @param rut string
  */
  buscarReportes(idTransaccion: string, rut: string, principal: boolean, reportKey: string, entidad: string, rutDerivado: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idTransaccion", idTransaccion);
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("principal", principal);
    params = params.append("reportKey", reportKey);
    entidad ? params = params.append("entidad", entidad) : null;
    rutDerivado ? params = params.append("rutDerivado", rutDerivado) : null;
    return this.http.get<any>(`${this.endpoint}buscarReportes`, { params })
  }

  /**
  * @description
  * Servicio para buscar la informacion de reportes derivados
  * @param idTransaccion string
  * @param rut string
  */
   buscarReportesDerivados(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idTransaccion", idTransaccion);
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<any>(`${this.endpoint}buscarReportesDerivados`, { params })
  }

  /**
  * @description
  * Servicio para buscar la informacion basica de una solicitud
  * @param idTransaccion string
  * @param rut string
  */
   buscarFichaDeCredito(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idTransaccion", idTransaccion);
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<any>(`${this.endpoint}buscarFichaDeCredito`, { params })
  }

  /**
  * @description
  * Servicio para entrega las entidades derivadas de un rut
  * @param rut string
  * @param idTransaccion string
  * @param ficha object
  */
   guardarFichaDeCredito(rut: string, idTransaccion: string, ficha: any): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    const request = {
      idTransaccion: idTransaccion,
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      fichaDeCredito: ficha
    };
    return this.http.post<any>(`${this.endpoint}guardarFichaDeCredito`, request);
  }

  /**
   * @description
   * Servicio que devuelve los datos de la transaccion solicitada
   * @param rut  string
   * @param idTransaccion string 
   */
  obtenerTransaccion(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    let params = new HttpParams(); 
    params = params.append("rut", rut);
    params = params.append("idTransaccion", idTransaccion);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    
    return this.http.get<any>(`${this.endpoint}obtenerTX`, { params });
  }

  syncUserCredentials(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
    };
    return this.http.post<any>(`${this.endpoint}transaction/${idTransaccion}/credentials/sync`, request);
  }
  /**
   * @description
   * Servicio que obtiene el contrato para un modelo indicado
   * @param rut  string
   */
  obtenerContrato(rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };
    
    return this.http.post(`${this.endpoint}obtenerContratoCamposManuales`, request);
  }

  /**
   * @description
   * Metodo 1-1 para actualizar la informacion de los campos personalizados
   * @param rut  string
   * @param idTransaccion string
   * @param campos array
   **/
  updateCamposPersonalizados(campos: any[] = [], rut: string, idTransaccion: string, stageID: number = 1, atributosCSV?: string, camposCSV?: string, modelo?: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      idTransaccion: idTransaccion,
      requestOrigin: "WEB",
      camposPersonalizados: {},
      stageID: stageID
    };
    if(campos.length > 0){
      request.camposPersonalizados.Attributos = campos;
    } else if (atributosCSV){
      request.camposPersonalizados.csvAtributos = atributosCSV;
    } 
    if (camposCSV){
      request.camposPersonalizados.csvSolicitudCredito = camposCSV;
    }
    if(modelo){
      request.modelo = modelo;
    }

    return this.http.post(`${this.endpoint}actualizarCamposPersonalizados`, request);    
  }

  /**
   * @description
   * Metodo que permite calcula el score una solicitud
   * @param idTransaccion string  
   * @param rut string 
   * @returns
   */
   calcularScore(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      idTransaccion: idTransaccion
    };
    return this.http.post(`${this.endpoint}calcularScore`, request);
  }

  /**
   * @description
   * Servicio que devuelve los datos del calculo de score
   * @param rut  string
   * @param idTransaccion string 
   */
   buscarCalculoScore(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;

    let params = new HttpParams(); 
    params = params.append("rut", rut);
    params = params.append("idTransaccion", idTransaccion);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", "WEB");
    return this.http.get<any>(`${this.endpoint}buscarCalculoScore`, { params });
  }


  /**
   * @description
   *  Metodo para saber en que paso se encuentra la solicitud
   * @param idTransaccion 
   * @param rut 
   * @returns 
   */
  obtenerFlujo(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idTransaccion", idTransaccion);
    params = params.append("model", this.getTipoSolicitud());
    params = params.append("rut", rut);
    return this.http.get(`${this.endpoint}obtenerFlujo`, { params })
  }

  /**
   * @description
   * Metodo que permite eliminar una solicitud
   * @param idTransaccion string  
   * @param rut string 
   * @param obj any[]
   * @returns
   */
  eliminarSolicitud(idTransaccion: string, rut: string): Observable<any> {
    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      idTransaccion: idTransaccion
    };
    return this.http.post(`${this.endpoint}borrarTransaccion`, request);
  }

  /**
   * @description
   * Metodo que permite iniciar el flujo aprobacional de una transaccion
   * @param idTransaccion string  
   * @param rut string 
   * @returns
   */
   iniciarFlujoAprobacional(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      idTransaccion: idTransaccion
    };
    return this.http.post(`${this.endpoint}iniciarFlujoAprobacional`, request);
  }

  /**
   * @description
   * Metodo que permite obtener el flujo aprobacional de una transaccion
   * @param idTransaccion string  
   * @param rut string 
   * @returns
   */
   obtenerFlujoAprobacional(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", "WEB");
    params = params.append("idTransaccion", idTransaccion);
    return this.http.get(`${this.endpoint}obtenerFlujoAprobacional`, { params });
  }

  /**
   * @description
   * Metodo que permite actualizar el flujo aprobacional de una transaccion
   * @param idTransaccion string  
   * @param rut string 
   * @param obj any[] -> debera incluirse
   * @returns
   */
   actualizarFlujoAprobacional(idTransaccion: string, rut: string, objeto: any, etapa: number | string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      idTransaccion: idTransaccion,
      etapa: etapa,
      atributosAprobacionales: objeto
    };
    return this.http.post(`${this.endpoint}actualizarFlujoAprobacional`, request);
  }

  /**
   * @description
   * Metodo que permite cerrar una solicitud
   * @param idTransaccion string  
   * @param rut string 
   * @returns
   */
   cerrarFlujo(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      idTransaccion: idTransaccion,
      model: this.getTipoSolicitud()
    };
    return this.http.post(`${this.endpoint}cerrarFlujo`, request);
  }


  getObtenerModelos(): Observable<any> {
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    return this.http.get(`${this.endpoint}obtenerModelos`, { params })
  }

  getAntecedentesComercialesEmpresa(resumen: any, nombre: string, score: string, fecha: string) {
    const result: any[] = [];

    if (resumen) {
      result.push({
        name: nombre || '',
        fecha: this.formatDate(fecha) || '',
        score: score || 0,
        morosidades: [
          { morosidad: resumen.MorosidadesCantidad || 0 },
          { morosidad: resumen.MorosidadesMonto || 0 }],
        protestos: [
          { protesto: resumen.ProtestoCantidad || 0 },
          { protesto: resumen.ProtestosMonto || 0 }],
        multas: [
          { multa: resumen.InfraccionesLaboralesCantidad || 0 },
          { multa: resumen.InfraccionesLaboralesMonto || 0 }]
      });
    }
    return result
  }

  getAntecedentesComercialesSocios(obj: any[]) {
    const result: any[] = [];
    if (obj) {
      if (obj.length > 0) {
        obj.map((e: any) => {
          if (Object.keys(e.Reportes).length > 0 && e.Reportes?.basicReportInformeComercial && Object.keys(e.Reportes?.basicReportInformeComercial)?.length > 0) {
            const resumenObj = e.Reportes?.basicReportInformeComercial?.AntecedentesComerciales?.Resumen;
            result.push({
              name: e.Nombre + ' ' + e.Rut,
              fecha: this.formatDate(e.Reportes?.basicReportInformeComercial?.InformacionBasica?.FechaReporte) || '',
              score: e.Reportes?.basicReportInformeComercial?.Riesgo?.Clasificacion || 0,
              morosidades: [
                { morosidad: resumenObj?.MorosidadesCantidad || 0 },
                { morosidad: resumenObj?.MorosidadesMonto || 0 }],
              protestos: [
                { protesto: resumenObj?.ProtestoCantidad || 0 },
                { protesto: resumenObj?.ProtestosMonto || 0 }],
              multas: [
                { multa: resumenObj?.InfraccionesLaboralesCantidad || 0 },
                { multa: resumenObj?.InfraccionesLaboralesMonto || 0 }]
            })
          }
        })
      }
    }
    return result
  }

  setTipoSolicitud(tipoSolicitud: string): boolean {
    this.tipoSolicitud = tipoSolicitud;
    return true;
  }

  getTipoSolicitud(): string {
    return this.tipoSolicitud;
  }

  /**
   * @description
   * Metodo que permite adjuntar un archivo al flujo de credito
   * @param idTransaccion string  
   * @param rut string 
   * @param archivoAdjuntoNombre string
   * @param archivoAdjuntoBase64 string
   * @returns
   */
  guardarArchivoAdjunto(idTransaccion: string, rut: string, archivoAdjuntoNombre: string, archivoAdjuntoBase64: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      idTransaccion: idTransaccion,
      archivoAdjuntoNombre: archivoAdjuntoNombre,
      archivoAdjuntoBase64: archivoAdjuntoBase64
    };
    return this.http.post(`${this.endpoint}guardarArchivoAdjunto`, request);
  }

  /**
   * @description
   * Metodo que permite obtener el listado de archivos adjuntos en el flujo de credito
   * @param idTransaccion string  
   * @param rut string 
   * @returns
   */
  listarArchivosAdjuntos(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", "WEB");
    params = params.append("idTransaccion", idTransaccion);
    return this.http.get(`${this.endpoint}listarArchivosAdjuntos`, { params });
  }

  /**
   * @description
   * Metodo que permite borrar un archivo adjunto al flujo de credito
   * @param idTransaccion string  
   * @param rut string 
   * @param archivosAdjuntosLista string[]
   * @returns
   */
  borrarArchivoAdjunto(idTransaccion: string, rut: string, archivosAdjuntosLista: string[]): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      idTransaccion: idTransaccion,
      archivosAdjuntosLista: archivosAdjuntosLista
    };
    return this.http.post(`${this.endpoint}borrarArchivoAdjunto`, request);
  }

  /**
   * @description
   * Metodo que permite obtener el contrato de permisos para visualizar ficha configurada
   * @param idOrganizacion string  
   * @returns
   */
  getPermissions(): Observable<any> {
    let params = new HttpParams();
    params = params.append("organizacion", this._sessionService.getOrganization());
    return this.http.get(`${this.endpoint}creditFlow/models/permissions`, { params });
  }

}
