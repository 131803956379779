import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { ICarpetaTributariaElectronicaReporteModel, ReportFullFormValue } from 'src/app/models/reporte-full/reporte-full.model';
import { SessionService } from 'src/app/shared/services/session.service';
import { rutValidate } from 'rut-helpers';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { ReporteFullService } from 'src/app/shared/services/reporte-full.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { RUT } from 'src/app/shared/utils/rut';
import { CredencialesService } from 'src/app/shared/services/credenciales.service';

@Component({
  selector: 'app-reporte-full-consultar',
  templateUrl: './reporte-full-consultar.component.html',
  styleUrls: ['./reporte-full-consultar.component.scss']
})
export class ReporteFullConsultarComponent {
  @ViewChild('tabsRef', { static: false }) tabsRef?: TabsetComponent;
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.CARPETA_TRIBUTARIA, ESystemProfileList.WEB,
    ESystemAccess.CLIENTES_CREDS_READ,
    ESystemAccess.CLIENTES_CREDS_ADMIN
  ];
  public hasUserAccess = false;
  public reportFullConsultarForm: FormGroup;
  public reportFullFormSend!: FormGroup;
  public hasInformeAccess: boolean = false;
  public showReporte: boolean = false;
  public showoptions: boolean = false;
  public showContent: boolean = false;
  @Input() rut: string | any;
  @Input() nombreBasico: string = '';
  public valueTab: string | undefined = 'Procesar';
  public reporteFullContent!: ICarpetaTributariaElectronicaReporteModel | null;
  public tempFile: any;
  public today: string = '';
  private subscriptions: Subscription[] = [];
  public informacionErrores: any[] = [];
  public pdfDescargaCTE: string = '';
  public consultacredencialesSinc = false;
  public credencialesSinc = false;
  @Output() actualizarReporte = new EventEmitter<string>();

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private fb: UntypedFormBuilder,
    private credencialesService: CredencialesService,
    private reporteFullService: ReporteFullService,
    private utilsService: UtilsService) {

    this.reportFullConsultarForm = this.fb.group({
      rut: ['', [Validators.required, gtpRutValidator()]]
    }) as FormGroupTyped<ReportFullFormValue>;
    this.onChanges();
  }

  ngOnInit(): void {
    this.setToday();
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    this.showoptions = true;
    this.utilsService.changeSpanExtra(' > Carpeta Tributaria');
    if(this.valueTab === 'Crear')
      this.initCredentials();
    else
      this.initForm();
  }

  initForm(): void {
    this.reportFullFormSend = this.fb.group({});
    const form = this.reportFullFormSend;

    this.valueTab === 'Procesar' ?
      (form.addControl('pdf', new UntypedFormControl('', [Validators.required])),
        form.addControl('validar', new UntypedFormControl(true, [Validators.required])))
      : (
        form.addControl('codigoCte', new UntypedFormControl('', [Validators.required])),
        form.addControl('claveCte', new UntypedFormControl('', [Validators.required]))
      )
  }

  initFormCrear(addClaveSii: boolean = true): void {
    this.reportFullFormSend = this.fb.group({});
    const form = this.reportFullFormSend;

    form.addControl('email', new UntypedFormControl('', [Validators.required, Validators.email]));
    if(addClaveSii) {
      form.addControl('claveSii', new UntypedFormControl('', [Validators.required]));
      form.addControl('sincronizar', new UntypedFormControl(false, [Validators.required]));
    }
  }

  onSelect(data: TabDirective): void {
    this.valueTab = data.heading;
    this.tempFile = null;
    if(this.valueTab === 'Crear')
      this.initCredentials();
    else
      this.initForm();
    
  }

  validaCredsAdmin(): boolean {
    if(this._sessionService.getUserAccess().includes(this.access[3])) {
      return true;
    } else {
      return false;
    }
  }

  initCredentials(): void {
    this.credencialesSinc = false;
    this.consultacredencialesSinc = false;
    /*if(!this._sessionService.getUserAccess().includes(this.access[2]) && !this._sessionService.getUserAccess().includes(this.access[3])) {
      this.alertService.error('No tiene permisos para realizar esta acción.');
      return;
    }*/
    this.spinner.show();
    this.credencialesService.buscarSecretos(this.rut).subscribe(
      (data: any) => {
        if(this.validaCredencialReturn(data?.services)){
          this.initFormCrear(false)
        } else {
          this.alertService.warn('No se han encontrado las credenciales del rut sincronizadas.');
          this.initFormCrear(true);
        }
        this.consultacredencialesSinc = true;
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al obtener las credenciales del rut.');
        this.initFormCrear(true);
        this.consultacredencialesSinc = true;
        this.spinner.hide();
    });
  }

  validaCredencialReturn(lista: any[]): boolean {
    if(lista && lista.length > 0){
      const objeto = lista.find(e => e?.name === 'SII')
      if(objeto && objeto?.configurationStatus === true){
        this.credencialesSinc = true;
        return true;
      } else {
        this.credencialesSinc = false;
        return false;
      }
    } else {
      this.credencialesSinc = false;
      return false;
    }
  }

  onChanges(): void {
    this.subscriptions.push(this.reportFullConsultarForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    }));
  }

  submit() {
    if (!this.reportFullFormSend.valid) {
      this.utilsService.validateAllFormFields(this.reportFullFormSend);
      return;
    }
    const form = this.reportFullFormSend.getRawValue();

    if(this.valueTab === 'Crear') {
      this.submitCrear(form);
    } else {
      const service = this.valueTab === 'Procesar' ? 'procesarReport' : 'descargarReport';
  
      let validar: boolean = true;
      if (this.valueTab === 'Procesar') {
        form.pdf = this.tempFile;
        validar = form.validar;
      }
  
      this.spinner.show();
      
      this.reporteFullService[service](RUT.applyBackendFormat(this.rut), form, validar).subscribe(
        (response: any) => {
          this.spinner.hide();
          const trx = response?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.IDTransaccion;
          this.setDetalleErrores(response);
          this.obtenerInformeCompleto(trx);
        },
        (error: any) => {
          this.spinner.hide();
          this.alertService.error(error.error.message || 'Ocurrio un problema');
        }
      );
    }

  }

  submitCrear(formTemp: any): void {
    const sincronizar = formTemp?.sincronizar;
    const claveSii = formTemp?.claveSii;
    if(formTemp?.hasOwnProperty('sincronizar')){
      delete formTemp.sincronizar;
    }

    if(sincronizar === true){
      this.validarCreds(claveSii, formTemp);
    } else {
      this.consultarCrearReporte(formTemp);
    }

  }

  validarCreds(password: string, form: any): void {
    this.spinner.show();

    this.credencialesService.validarCredenciales(RUT.applyBackendFormat(this.rut), password, 'SII').subscribe(
      (data: any) => {
        this.obtenerContratoCreds(password, form);
      },
      ({ error }) => {
        const mensaje: string = error.message ? error.message : error && typeof(error) == 'string' ? error : 'Ha ocurrido un error al validar las credenciales.'; 
        this.alertService.error(mensaje);
        this.spinner.hide();
    });
  }

  obtenerContratoCreds(password: string, form: any): void {
    this.spinner.show();

    this.credencialesService.obtenerContrato().subscribe(
      (data: any) => {
        const services: any = data?.services?.find((e: any) => e.name === 'SII')
        if(services){
          services.attributes.forEach((element: any) => {
            if(element.name === 'userName') element.value = RUT.applyBackendFormat(this.rut);
            if(element.name === 'password') element.value = password;
            if(element.name === 'orgAccess') element.value = true;
          });
          this.guardarCreds([services], form)
        } else {
          this.alertService.error('Ha ocurrido un error al obtener el contrato de credenciales.');
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al validar las credenciales.');
        this.spinner.hide();
    });
  }

  guardarCreds(contrato: any, form: any){
    this.spinner.show();
    this.credencialesService.crearActualizarCredenciales(RUT.applyBackendFormat(this.rut), contrato).subscribe(
      (data: any) => {
        this.consultarCrearReporte(form);
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al crear/actualizar las credenciales.');
        this.spinner.hide();
    });
  }

  consultarCrearReporte(form: any): void {
    this.spinner.show();
      
    this.reporteFullService.createReport(RUT.applyBackendFormat(this.rut), form).subscribe(
      (response: any) => {
        this.spinner.hide();
        const trx = response?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.IDTransaccion;
        this.setDetalleErrores(response);
        this.obtenerInformeCompleto(trx);
      },
      (error: any) => {
        this.spinner.hide();
        this.alertService.error(error.error.message || 'Ocurrio un problema');
      }
    );
  }

  obtenerInformeCompleto(trx: string) {
    this.spinner.show();
    this.pdfDescargaCTE = '';
    this.reporteFullService.calcularVentas(RUT.applyBackendFormat(this.rut), trx).subscribe(
      (response: ICarpetaTributariaElectronicaReporteModel) => {
        
        if(this.informacionErrores.length > 0 )
          this.alertService.error("Se han detectado errores en el procesamiento de la Carpeta Tributaria solicitada. Al final de la pantalla encontrará la lista de estos errores.");

        this.spinner.hide();
        this.showContent = true;
        this.showoptions = false;
        this.pdfDescargaCTE = response?.CarpetaTributariaElectronicaReporte?.Reporte?.pdf || response?.pdf || '';
        this.reporteFullContent = response;
      },
      (error: any) => {
        this.spinner.hide();
        this.alertService.error(error.error.message);
      }
    );
  }

  setDetalleErrores(response: ICarpetaTributariaElectronicaReporteModel): void{
    const errores: any[] = [];
    const estadoActual = response?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.EstadoTransaccion || '';
    const subEstadoActual = response?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.SubEstadoTransaccion || '';
    if(estadoActual === 'COMPLETED' && subEstadoActual !== 'COMPLETED'){

      if(subEstadoActual === "F22-ERROR" || subEstadoActual === "FORM-ERROR"){
        response?.CarpetaTributariaElectronicaReporte?.Reporte?.f22.forEach(elementof22 => {
          if(elementof22.error || elementof22.message){
            errores.push(elementof22);
          }
        });
      }
      if(subEstadoActual === "F29-ERROR" || subEstadoActual === "FORM-ERROR"){
        response?.CarpetaTributariaElectronicaReporte?.Reporte?.f29.forEach(elementof29 => {
          if(elementof29.error || elementof29.message){
            errores.push(elementof29);
          }
        });
      }

    }

    this.informacionErrores = errores;
  }

  public closeReport(): void {
    this.reporteFullContent = null;
    this.showContent = false;
    this.showoptions = false;
    this.actualizarReporte.emit(this.rut); // Se indica el rut para que el componente padre actualice la informacion
    this.utilsService.changeSpanExtra('');
  }

  public clearForm(): void {
    this.reportFullConsultarForm.reset();
    this.activateRutHelper = false;
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.tempFile = reader?.result?.toString().split(',')[1];
    };
  }

  Limpiar(): void {
    this.reportFullFormSend.reset();
    this.reportFullFormSend?.get('validar')?.setValue(true);
    this.reportFullFormSend?.get('sincronizar')?.setValue(false);
    this.activateRutHelper = false;
  }

  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
