<div class="mx-auto">
    <div class="row">
        <div class="col-12 mx-auto">
            <div class="card w-100 mx-auto border-0">
                <div class="card-body">

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover border my-0">
                            <thead>
                                <tr>
                                    <th class="bg-primary"></th>
                                    <th class="align-middle bg-primary font-head-size">Manual</th>
                                    <th class="align-middle bg-primary font-head-size">Automático</th>
                                    <th class="align-middle bg-primary font-head-size">Diferencia</th>
                                </tr>
                                <tr>
                                    <th class="bg-primary"></th>
                                    <th class="align-middle bg-primary font-head-size">{{data.fechas.balanceManual || ''}}</th>
                                    <th class="align-middle bg-primary font-head-size">{{data.fechas.f22 || ''}}</th>
                                    <th class="bg-primary"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let item of data.tabla">
                                    <tr>
                                        <th class="bg-primary font-head-size">{{item.titulo || ''}}</th>
                                        <td>{{item?.balanceManual | number: '1.0-0'}}</td>
                                        <td>{{item?.f22 | number: '1.0-0'}}</td>
                                        <td>{{item?.diferencia | number: '1.0-0'}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
