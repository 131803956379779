<div class="panel panel-default border-0 not-print">
    <div class="panel-body">
        <div class="btn-option">
            <button [printTitle]='"FlujoDeCreditoAprobacion_"+(rut | rutFormat)+"_"+today'
                [useExistingCss]="true" printSectionId="print-section" ngxPrint
                class="btn btn-primary float-right">Descargar PDF</button>
        </div>
    </div>
</div>

<div class="col-sm-12">
    <div class="row p-3">

        <div class="col-sm-12 my-2 only-print">
            <div class="row">
                <div class="col-12 my-1 text-center" *ngIf="fechaReporte">
                    <h2>
                        Ficha de Aprobación: {{fechaReporte | date: 'dd/MM/yyyy' }}
                    </h2>
                </div>
                <div class="col-12 my-1 text-center" *ngIf="fichaDeCredito?.ficha?.servicioFichaForm?.nombreComite">
                    <h3 class="font-weight-bold">
                        {{fichaDeCredito?.ficha?.servicioFichaForm?.nombreComite || ''}}
                    </h3>
                </div>
                <div class="col-12 my-1 text-center" *ngIf="rut">
                    <h3 class="font-weight-bold">
                        {{rut || '' | rutFormat}}
                    </h3>
                </div>
                <div class="col-12 my-1 text-center" *ngIf="tipoSolicitudNombre !== ''">
                    <h4>
                        {{tipoSolicitudNombre || ''}}{{numeroSolicitud ? ' - N° Solicitud ' + numeroSolicitud : ''}} 
                    </h4>
                </div>
            </div>
        </div>

        <div class="col-sm-12 my-2 not-print">
            <div class="row">
                <h3 class="col-12 my-2 mb-4 cuadro-title p-3 rounded-header">
                  Aprobación
                </h3>
            </div>
        </div>

        <!--por validar-->
        <ng-container *ngIf="automatico === true && calculo === true && estadoSolicitud">

            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto my-5">
    
                <div class="row p-5">
                    <div class="col-sm-12 shadow-lg">
                        <div class="row p-3">
                            <div class="col-sm-12 text-center">
                                <h3 class="font-weight-bold">{{estadoSolicitud?.EstadoSolicitudEtapaActual}}</h3>
                            </div>

                            <hr class="largo-linea mx-auto">
    
                            <div class="col-sm-12">
                                <h5 class="font-weight-bold">Total Montos Aprobados: {{estadoSolicitud?.TotalMontos | number: '1.0-0'}}</h5>
                            </div>
    
                            <ng-container *ngFor="let item of objectKeys(estadoSolicitud?.Montos)">
                                <div class="col-sm-12" *ngIf="item !== 'decision' && item !== 'automatico'">
                                    {{obtenerNombreProductoAutomatico(item)}}: {{validarTypeDate(item) ? (item ? (dateToYYYYMMDD(estadoSolicitud?.Montos[item]) | date: 'dd-MM-yyyy') : '') : (estadoSolicitud?.Montos[item] | number: '1.0-2')}}
                                </div>
                            </ng-container>

                            <hr class="largo-linea mx-auto">
    
                            <div class="col-sm-12 mt-3">
                                <h5>Tipo Decisión: {{estadoSolicitud?.TipoDecision || ''}}</h5>
                            </div>
                            <div class="col-sm-12">
                                Fecha: {{estadoSolicitud?.Fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                            </div>
    
                            <div class="col-sm-12 my-2">
                                <h4 class="font-weight-bold">Decisión: {{estadoSolicitud?.Decision}}
                                    <ng-container *ngIf="version === 1">
                                        <i class="fa fa-times-circle-o text-danger" aria-hidden="true" *ngIf="estadoSolicitud?.Decision === 'RECHAZADA'"></i>
                                        <i class="fa fa-check-circle-o text-success" aria-hidden="true" *ngIf="estadoSolicitud?.Decision === 'APROBADA'"></i>
                                    </ng-container>
                                    <ng-container *ngIf="version === 2">
                                        <ng-container *ngIf="decisionV2GetAprobado(estadoSolicitud?.Decision) === true">
                                            <i class="fa fa-check-circle-o text-success" aria-hidden="true"></i>
                                        </ng-container>
                                        <ng-container *ngIf="decisionV2GetAprobado(estadoSolicitud?.Decision) === false">
                                            <i class="fa fa-times-circle-o text-danger" aria-hidden="true"></i>
                                        </ng-container>
                                    </ng-container>
                                </h4>
                            </div>

                            <ng-container *ngIf="estadoTransaccion !== 'CLOSED'">

                                <div class="col-sm-10 mx-auto my-4 not-print">

                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="col-sm-6  my-2 text-center">
                                                <button type="button" class="btn btn-primary" (click)="cerrarSolicitud()">Cerrar Solicitud</button>
                                            </div>
                                            <div class="col-sm-6  my-2 text-center">
                                                <button type="button" class="btn btn-success" (click)="revisionManual()">Revision Manual</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </ng-container>
                            
    
                        </div>
    
                    </div>
                </div>
    
            </div>

        </ng-container>

        <ng-container *ngIf="automatico === false && estadoSolicitud">

            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto my-5">
    
                <div class="row p-5">
                    <div class="col-sm-12 shadow-lg">
                        <div class="row p-3">
                            <div class="col-sm-12 text-center">
                                <h3 class="font-weight-bold">{{estadoSolicitud?.EstadoSolicitudEtapaActual}}</h3>
                            </div>
                            <div class="col-sm-12 my-2">
                                <h4 class="font-weight-bold">Etapa Actual: {{estadoSolicitud?.NombreEtapaActual}}</h4>
                            </div>

                            <hr class="largo-linea mx-auto">
    
                            <div class="col-sm-12">
                                <h5 class="font-weight-bold">Total Montos Aprobados: {{estadoSolicitud?.TotalMontos | number: '1.0-2'}}</h5>
                            </div>
    
                            <ng-container *ngIf="version === 1; else montoV2">
                                <ng-container *ngFor="let item of estadoSolicitud?.Montos">
                                    <div class="col-sm-12">
                                        {{obtenerNombre(item?.nombre) + ':'}} {{ validarTypeDate(item?.nombre) ? (item?.valor ? (dateToYYYYMMDD(item?.valor) | date: 'dd-MM-yyyy') : '') : (item?.valor | number: '1.0-2')}}
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #montoV2>
                                <ng-container *ngFor="let item of estadoSolicitud?.Montos">
                                    <div class="col-sm-12">
                                        {{(item?.displayName || item?.nombre) + ':'}} {{(item?.valor | number: '1.0-2')}}
                                    </div>
                                </ng-container>
                            </ng-template>
                            
                            <!--otros-->
                            <ng-container *ngIf="version === 2 && estadoSolicitud?.Otros && estadoSolicitud?.Otros.length > 0">
                                <hr class="largo-linea mx-auto">
    
                                <div class="col-sm-12 mt-2">
                                    <h5 class="font-weight-bold">Otros Campos:</h5>
                                </div>
    
                                <ng-container *ngFor="let item of estadoSolicitud?.Otros">
                                    <div class="col-sm-12">
                                        <ng-container [ngSwitch]="item?.tipo">
                                            <ng-container *ngSwitchCase="'NUM'">
                                                {{obtenerNombre(item?.displayName) + ':'}} {{(item?.valor | number: '1.0-2')}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'STRING'">
                                                <ng-container *ngIf="item?.valores && item?.valores.length > 0; else headerV2NoValoresShow">
                                                    {{obtenerNombre(item?.displayName) + ':'}} {{showOpcionValueV2(item.valor, item.valores) || ''}}
                                                </ng-container>
                                                <ng-template #headerV2NoValoresShow>
                                                    {{obtenerNombre(item?.displayName) + ':'}} {{(item?.valor || '')}}
                                                </ng-template>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DATE'">
                                                {{obtenerNombre(item?.displayName) + ':'}} {{getFechaDefaultFormat(item?.formato, item?.valor) || ''}}
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{obtenerNombre(item?.displayName) + ':'}} {{item?.valor}}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <hr class="largo-linea mx-auto">

                            <div class="col-sm-12 mt-3">
                                <h5>Tipo Decisión: {{estadoSolicitud?.TipoDecision || ''}}</h5>
                            </div>
                            <div class="col-sm-12">
                                Fecha: {{estadoSolicitud?.Fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                            </div>

                            <div class="col-sm-12 my-2">
                                <h4 class="font-weight-bold">Decisión: {{estadoSolicitud?.Decision}}
                                    <ng-container *ngIf="version === 1">
                                        <i class="fa fa-times-circle-o text-danger" aria-hidden="true" *ngIf="estadoSolicitud?.Decision === 'RECHAZADA'"></i>
                                        <i class="fa fa-check-circle-o text-success" aria-hidden="true" *ngIf="estadoSolicitud?.Decision === 'APROBADA'"></i>
                                    </ng-container>
                                    <ng-container *ngIf="version === 2">
                                        <ng-container *ngIf="decisionV2GetAprobado(estadoSolicitud?.Decision) === true">
                                            <i class="fa fa-check-circle-o text-success" aria-hidden="true"></i>
                                        </ng-container>
                                        <ng-container *ngIf="decisionV2GetAprobado(estadoSolicitud?.Decision) === false">
                                            <i class="fa fa-times-circle-o text-danger" aria-hidden="true"></i>
                                        </ng-container>
                                    </ng-container>
                                </h4>

                            </div>
                            
                        </div>
    
                    </div>
                </div>
    
            </div>
    
            <div class="col-sm-12 my-5">
    
                <div class="row">
    
                    <ng-container *ngFor="let item of servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones; let i = index">
    
                        <div class="col-sm-12 col-md-5 col-lg-3 col-xl-2 margin-arrow">
    
                            <div class="col-sm-12 my-2">
                                <span class="dot" [ngClass]="getClassStatus(item)">
                                    <h5 class="titulo-paso" [ngClass]="{'text-white': getClassStatus(item) === 'dot-ok' || getClassStatus(item) === 'dot-denied' || getClassStatus(item) === 'dot-wait'}">{{ item.id + ' - ' + item.nombre || 'Paso ' +  item.id}}</h5>
                                </span>
                                <h5 class="aprobadores-paso pointer-cursor"
                                    title="{{obtenerAprobadores(item)}}">
                                    Aprobadores
                                </h5>
                                <h5 class="grupos-paso pointer-cursor" *ngIf="getClassStatus(item) !== 'dot-ok' || getClassStatus(item) !== 'dot-denied'"
                                    title="{{obtenerGrupoAprobadores(item)}}">
                                    Usuarios/Grupos
                                </h5>
                                <div class="arrow">
                                    <div class="line"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                            
                            <div class="col-sm-12 my-5 not-print">
                                <div class="row">
                                    <div class="col-sm-12 text-center">
                                        <button type="button" class="btn btn-primary" [disabled]="getClassStatus(item)==='dot-wait' || getClassStatus(item)==='dot-disabled'" 
                                            (click)="seleccionPaso(item, i)">
                                            Detalles
                                        </button>
                                    </div>
                                </div>
                            </div>
    
                        </div>
    
                    </ng-container>
    
                </div>
    
            </div>

            <ng-template #printContent>

                <div class="col-sm-12 my-2 detalles-item shadow-lg p-4 only-print" *ngIf="objectKeys(itemSeleccionado).length > 0">
            
                    <div class="row">
            
                        <div class="col-sm-12">

                            <ng-container *ngIf="version === 2">
                                <div class="col-sm-12 col-md-8 col-lg-6 my-3">
                                    <div class="form-group">
                                        <label class="text-primary">Decisión:</label>
                                        <span>
                                            {{decisionV2GetName(itemSeleccionado?.valores?.aprobado) || '-'}}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
            
                            <ng-container *ngIf="version === 1">
                                <div class="col-sm-12 my-3">
                                    <div class="table-responsive">
                                        <table class="table table-sm table-hover table-bordered">
                                            <thead>
                                            <tr>
                                                <th scope="col" class="text-center bg-primary font-head-size py-1">Producto</th>
                                                <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Propuesta</th>
                                                <th scope="col" class="text-center bg-primary font-head-size py-1">Monto Resuelto</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let item of tablaProductosMapeados">
                                                    <tr>
                                                        <th>{{item?.nombre}}</th>
                                                        <td>{{item?.lineaPropuesta === '' ? ('-') : (item?.lineaPropuesta | number: '1.0-2')}}</td>
                                                        <td>{{obtenerMontoNoAdmin(itemSeleccionado?.valores?.montos, item.id) | number: '1.0-2'}}</td>
                                                    </tr>
                                                </ng-container>
                                                <ng-container *ngFor="let item of tablaProductosMapeadosDate">
                                                    <tr>
                                                        <th>{{item?.nombre}}</th>
                                                        <td>{{item?.lineaPropuesta === '' ? ('-') : (item?.lineaPropuesta | date: 'dd-MM-yyyy')}}</td>
                                                        <td>{{obtenerMontoNoAdminDate(itemSeleccionado?.valores?.montos, item.id) | date: 'dd-MM-yyyy'}}</td>
                                                    </tr>
                                                </ng-container>                                          
                                                <tr>
                                                    <th class="bg-primary">Total Propuesta</th>
                                                    <td>{{totalProducto?.totalesLineaPropuesta || 0 | number: '1.0-2'}}</td>
                                                    <td>{{obtenerTotalNoAdmin(itemSeleccionado?.valores?.montos) || '-' | number: '1.0-2'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="version === 2">
                                <ng-container *ngIf="itemSeleccionado?.valores?.montos && itemSeleccionado?.valores?.montos.length > 0">
                                    <div class="col-sm-12 px-5 py-4">
                                        <label class="text-primary">Montos</label>
                                        <div class="form-row">
                                            <ng-container *ngFor="let item of itemSeleccionado?.valores?.montos">

                                                <div class="form-group col-sm-12 col-md-6 my-2 px-4">
                                                    <label>{{item?.displayName || item?.nombre}}: </label>
                                                    <span>
                                                        {{item?.valor || item?.valor == 0 ? (item?.valor | number: '1.0-2') : ''}}
                                                    </span>
                                                </div>
                                                
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                                
                                <ng-container *ngIf="itemSeleccionado?.valores?.otros && itemSeleccionado?.valores?.otros.length > 0">
                                    <div class="col-sm-12 px-5 py-4">
                                        <label class="text-primary">Otros Campos</label>
                                        <div class="form-row">
                                            <ng-container *ngFor="let item of itemSeleccionado?.valores?.otros">

                                                <div class="form-group col-sm-12 col-md-6 my-2 px-4">
                                                    <label >{{item?.displayName}}: </label>
                                                    <span [ngSwitch]="item.tipo">
                                                        <ng-container *ngSwitchCase="'DATE'">
                                                            {{getFechaDefaultFormat(item.formato, item.valor) || ''}}
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'STRING'">
                                                            <ng-container *ngIf="item?.valores && item?.valores.length > 0; else inputV2NoValoresShow">
                                                                {{showOpcionValueV2(item.valor, item.valores) || ''}}
                                                            </ng-container>
                                                            <ng-template #inputV2NoValoresShow>
                                                                {{item.valor || ''}}
                                                            </ng-template>                                                                
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'NUM'">
                                                            {{item.valor | number: '1.0-2'}}
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            {{item.valor}}
                                                        </ng-container>
                                                    </span>
                                                </div>
                                                
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
            
                            <!--comentarios-->
                            <div class="col-sm-12 my-5">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="container-fluid text-dark">
            
                                            <ng-container *ngIf="motorDeCalculos?.ResponseHistorico && objectKeys(motorDeCalculos?.ResponseHistorico).length > 0 && motorDeCalculos?.ResponseHistorico?.Fecha !== '' && motorDeCalculos?.ResponseHistorico?.Decision !== ''">
            
                                                <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header"
                                                    [ngClass]="{'dot-ok': motorDeCalculos?.ResponseHistorico?.Decision === 'APROBADA', 'dot-denied' : motorDeCalculos?.ResponseHistorico?.Decision === 'RECHAZADA' }"
                                                >
                                                        Cálculo Automático
                                                    </h3>
                                                    <div id="etapa-motorcalculo">
                                        
                                                        <div class="container-fluid p-4">
                                            
                                                            <div class="d-flex flex-start">
                                                                <div>
                                                                    <h5 class="fw-bold mb-1">Motor de Cálculo</h5>
                                                                    <div class="d-flex align-items-center mb-3">
                                                                    <h6 class="mb-0">
                                                                        {{motorDeCalculos?.ResponseHistorico?.Fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                    </h6>
                                                                    </div>
                                                                    Decisión:
                                                                    <p class="mb-0">{{motorDeCalculos?.ResponseHistorico?.Decision}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
            
                                                    </div>
            
                                            </ng-container>
            
                                            <ng-container *ngFor="let item of arrayComentarios">
            
                                                <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header not-print"
                                                    [ngClass]="item.class"
                                                >
                                                    {{item.id + ' - ' + item.etapa}}
                                                </h3>
                                                <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header only-print"
                                                    [ngClass]="item.class"
                                                >
                                                    {{'Aprobador ' + item.id + (item.usuario ? ': ' + item.usuario : '')}}
                                                </h3>
                                                <div id="etapa-{{item.id}}">
                                    
                                                    <div class="container-fluid p-4">
                                        
                                                        <div class="d-flex flex-start">
                                                            <div>
                                                                <h5 class="fw-bold mb-1">{{item.usuario}}</h5>
                                                                <div class="d-flex align-items-center mb-3">
                                                                <h6 class="mb-0">
                                                                    {{item.fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                </h6>
                                                                </div>
                                                                Comentarios:
                                                                <p class="mb-0" [innerHTML]="agregarSaltosDeLinea(item.comentario)"></p>
                                                            </div>
                                                        </div>
                                                    </div>
            
                                                </div>
                
                                            </ng-container>
            
                                            <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header not-print"
                                                    [ngClass]="getClassStatus(itemSeleccionado)"
                                                >
                                                    {{itemSeleccionado?.id + ' - ' + itemSeleccionado?.nombre}}
                                            </h3>
                                            <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header only-print"
                                                [ngClass]="getClassStatus(itemSeleccionado)"
                                            >
                                                {{'Aprobador ' + (itemSeleccionado?.id || itemSeleccionado?.id == 0 ? itemSeleccionado?.id : '') + (itemSeleccionado?.valores?.usuarioApruebaRechaza ? ': ' + itemSeleccionado?.valores?.usuarioApruebaRechaza : '')}}
                                            </h3>
                                            <div id="etapa-actual">
                                
                                                <div class="container-fluid p-4">
                                    
                                                    <div class="d-flex flex-start">
                                                        <div>
                                                            <h5 class="fw-bold mb-1">{{itemSeleccionado?.valores?.usuarioApruebaRechaza}}</h5>
                                                            <div class="d-flex align-items-center mb-3">
                                                            <h6 class="mb-0">
                                                                {{itemSeleccionado?.fechaActualizacion | date: 'dd-MM-yyyy H:mm:ss' }}
                                                            </h6>
                                                            </div>
                                                            Comentarios:
                                                            <p class="mb-0" [innerHTML]="agregarSaltosDeLinea(itemSeleccionado?.valores?.comentarios)"></p>
                                                        </div>
                                                    </div>
                                                </div>
            
                                            </div>
                                
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                        </div>
            
                    </div>
            
                </div>
            
            </ng-template>

            <ng-container *ngIf="pasoSeleccionado !== null else printContent">
    
                <div class="col-sm-12 my-2 detalles-item shadow-lg p-4">
    
                    <div class="row">

                        <ng-container *ngIf="version === 1">
                            <div class="col-sm-12">
                
                                <ng-template #valoresNoAdmin>
        
                                    <div class="col-sm-12 my-3">
                                        <div class="table-responsive">
                                            <table class="table table-sm table-hover table-bordered">
                                                <thead>
                                                <tr>
                                                    <th scope="col" class="text-center bg-primary font-head-size py-1">Producto</th>
                                                    <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Propuesta</th>
                                                    <th scope="col" class="text-center bg-primary font-head-size py-1">Monto Resuelto</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngFor="let item of tablaProductosMapeados">
                                                        <tr>
                                                            <th>{{item?.nombre}}</th>
                                                            <td>{{item?.lineaPropuesta === '' ? ('-') : (item?.lineaPropuesta | number: '1.0-2')}}</td>
                                                            <td>{{obtenerMontoNoAdmin(itemSeleccionado?.valores?.montos, item.id) | number: '1.0-2'}}</td>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngFor="let item of tablaProductosMapeadosDate">
                                                        <tr>
                                                            <th>{{item?.nombre}}</th>
                                                            <td>{{item?.lineaPropuesta === '' ? ('-') : (item?.lineaPropuesta | date: 'dd-MM-yyyy')}}</td>
                                                            <td>{{obtenerMontoNoAdminDate(itemSeleccionado?.valores?.montos, item.id) | date: 'dd-MM-yyyy'}}</td>
                                                        </tr>
                                                    </ng-container>                                            
                                                    <tr>
                                                        <th class="bg-primary">Total Propuesta</th>
                                                        <td>{{totalProducto?.totalesLineaPropuesta || 0 | number: '1.0-2'}}</td>
                                                        <td>{{obtenerTotalNoAdmin(itemSeleccionado?.valores?.montos) || '-' | number: '1.0-2'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
        
                                    <div class="col-sm-12 my-5">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="container-fluid text-dark">
    
                                                    <ng-container *ngIf="motorDeCalculos?.ResponseHistorico && objectKeys(motorDeCalculos?.ResponseHistorico).length > 0 && motorDeCalculos?.ResponseHistorico?.Fecha !== '' && motorDeCalculos?.ResponseHistorico?.Decision !== ''">
    
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('etapa-motorcalculo')"
                                                            [ngClass]="{'dot-ok': motorDeCalculos?.ResponseHistorico?.Decision === 'APROBADA', 'dot-denied' : motorDeCalculos?.ResponseHistorico?.Decision === 'RECHAZADA' }"
                                                        >
                                                                Cálculo Automático
                                                            </h3>
                                                            <div class="container-desplegables" id="etapa-motorcalculo">
                                                
                                                                <div class="container-fluid p-4">
                                                    
                                                                    <div class="d-flex flex-start">
                                                                        <div>
                                                                            <h5 class="fw-bold mb-1">Motor de Cálculo</h5>
                                                                            <div class="d-flex align-items-center mb-3">
                                                                            <h6 class="mb-0">
                                                                                {{motorDeCalculos?.ResponseHistorico?.Fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                            </h6>
                                                                            </div>
                                                                            Decisión:
                                                                            <p class="mb-0">{{motorDeCalculos?.ResponseHistorico?.Decision}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                            </div>
    
                                                    </ng-container>
        
                                                    <ng-container *ngFor="let item of arrayComentarios">
    
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header not-print" (click)="show('etapa-' + item.id)"
                                                                [ngClass]="item.class"
                                                            >
                                                                {{item.id + ' - ' + item.etapa}}
                                                        </h3>
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header only-print"
                                                            [ngClass]="item.class"
                                                        >
                                                            {{'Aprobador ' + item.id + (item.usuario ? ': ' + item.usuario : '')}}
                                                        </h3>
    
                                                        <div class="container-desplegables" id="etapa-{{item.id}}">
                                            
                                                            <div class="container-fluid p-4">
                                                
                                                                <div class="d-flex flex-start">
                                                                    <div>
                                                                        <h5 class="fw-bold mb-1">{{item.usuario}}</h5>
                                                                        <div class="d-flex align-items-center mb-3">
                                                                        <h6 class="mb-0">
                                                                            {{item.fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                        </h6>
                                                                        </div>
                                                                        Comentarios:
                                                                        <p class="mb-0" [innerHTML]="agregarSaltosDeLinea(item.comentario)"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                        
                                                    </ng-container>
    
                                                    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header not-print" (click)="show('etapa-actual')"
                                                            [ngClass]="getClassStatus(itemSeleccionado)"
                                                        >
                                                            {{itemSeleccionado?.id + ' - ' + itemSeleccionado?.nombre}}
                                                    </h3>
                                                    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header only-print"
                                                        [ngClass]="getClassStatus(itemSeleccionado)"
                                                    >
                                                        {{'Aprobador ' + itemSeleccionado?.id + (itemSeleccionado?.valores?.usuarioApruebaRechaza ? ': ' + itemSeleccionado?.valores?.usuarioApruebaRechaza : '')}}
                                                    </h3>
    
                                                    <div class="container-desplegables" id="etapa-actual">
                                        
                                                        <div class="container-fluid p-4">
                                            
                                                            <div class="d-flex flex-start">
                                                                <div>
                                                                    <h5 class="fw-bold mb-1">{{itemSeleccionado?.valores?.usuarioApruebaRechaza}}</h5>
                                                                    <div class="d-flex align-items-center mb-3">
                                                                    <h6 class="mb-0">
                                                                        {{itemSeleccionado?.fechaActualizacion | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                    </h6>
                                                                    </div>
                                                                    Comentarios:
                                                                    <p class="mb-0" [innerHTML]="agregarSaltosDeLinea(itemSeleccionado?.valores?.comentarios)"></p>
                                                                </div>
                                                            </div>
                                                        </div>
        
                                                    </div>
                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                </ng-template>
                                
                
                                <ng-container *ngIf="validaOpcionesActualAdmin(itemSeleccionado) && estadoTransaccion !== 'CLOSED' else valoresNoAdmin">
                
                                    <div class="col-sm-12 my-3">
                    
                                        <form [formGroup]="valoresEtapaForm">
                                            <div class="table-responsive">
                                                <table class="table table-sm table-hover table-bordered">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="text-center bg-primary font-head-size py-1">Producto</th>
                                                        <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Propuesta</th>
                                                        <th scope="col" class="text-center bg-primary font-head-size py-1">Monto Resuelto</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngFor="let item of tablaProductosMapeados">
                                                            <tr>
                                                                <th>{{item?.nombre}}</th>
                                                                <td>{{item?.lineaPropuesta === '' ? ('-') : (item?.lineaPropuesta | number: '1.0-2')}}</td>
                                                                <td>
                                                                    <input class="form-control" type="number" name="{{item.id}}" id="{{item.id}}" formControlName="{{item.id}}">
                                                                    <div class="w-100">
                                                                        <app-error [control]="valoresEtapaForm.get(item.id)"></app-error>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                        <ng-container *ngFor="let item of tablaProductosMapeadosDate">
                                                            <tr>
                                                                <th>{{item?.nombre}}</th>
                                                                <td>{{item?.lineaPropuesta === '' ? ('-') : (item?.lineaPropuesta | date: 'dd-MM-yyyy')}}</td>
                                                                <td>
                                                                    <input class="form-control" type="date" name="{{item.id}}" id="{{item.id}}" formControlName="{{item.id}}">
                                                                    <div class="w-100">
                                                                        <app-error [control]="valoresEtapaForm.get(item.id)"></app-error>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-container>                                                 
                                                        <tr>
                                                            <th class="bg-primary">Total Propuesta</th>
                                                            <td>{{totalProducto?.totalesLineaPropuesta || 0 | number: '1.0-2'}}</td>
                                                            <td>{{obtenerTotal() || 0 | number: '1.0-2'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </form>
                                    </div>
        
                                    <div class="col-sm-12 my-5">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="container-fluid text-dark">
    
                                                    <ng-container *ngIf="motorDeCalculos?.ResponseHistorico && objectKeys(motorDeCalculos?.ResponseHistorico).length > 0 && motorDeCalculos?.ResponseHistorico?.Fecha !== '' && motorDeCalculos?.ResponseHistorico?.Decision !== ''">
    
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('etapa-motorcalculo')"
                                                            [ngClass]="{'dot-ok': motorDeCalculos?.ResponseHistorico?.Decision === 'APROBADA', 'dot-denied' : motorDeCalculos?.ResponseHistorico?.Decision === 'RECHAZADA' }"
                                                        >
                                                                Cálculo Automático
                                                            </h3>
                                                            <div class="container-desplegables" id="etapa-motorcalculo">
                                                
                                                                <div class="container-fluid p-4">
                                                    
                                                                    <div class="d-flex flex-start">
                                                                        <div>
                                                                            <h5 class="fw-bold mb-1">Motor de Cálculo</h5>
                                                                            <div class="d-flex align-items-center mb-3">
                                                                            <h6 class="mb-0">
                                                                                {{motorDeCalculos?.ResponseHistorico?.Fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                            </h6>
                                                                            </div>
                                                                            Decisión:
                                                                            <p class="mb-0">{{motorDeCalculos?.ResponseHistorico?.Decision}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                            </div>
    
                                                    </ng-container>
        
                                                    <ng-container *ngFor="let item of arrayComentarios">
    
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header not-print" (click)="show('etapa-' + item.id)"
                                                            [ngClass]="item.class"
                                                        >
                                                            {{item.id + ' - ' + item.etapa}}
                                                        </h3>
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header only-print"
                                                            [ngClass]="item.class"
                                                        >
                                                            {{'Aprobador ' + item.id + (item.usuario ? ': ' + item.usuario : '')}}
                                                        </h3>
    
                                                        <div class="container-desplegables" id="etapa-{{item.id}}">
                                            
                                                            <div class="container-fluid p-4">
                                                
                                                                <div class="d-flex flex-start">
                                                                    <div>
                                                                        <h5 class="fw-bold mb-1">{{item.usuario}}</h5>
                                                                        <div class="d-flex align-items-center mb-3">
                                                                        <h6 class="mb-0">
                                                                            {{item.fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                        </h6>
                                                                        </div>
                                                                        Comentarios:
                                                                        <p class="mb-0" [innerHTML]="agregarSaltosDeLinea(item.comentario)"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                        
                                                    </ng-container>
    
                                                    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header not-print" (click)="show('etapa-actual')"
                                                        [ngClass]="getClassStatus(itemSeleccionado)"
                                                    >
                                                        {{itemSeleccionado?.id + ' - ' + itemSeleccionado?.nombre}}
                                                    </h3>
                                                    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header only-print"
                                                        [ngClass]="getClassStatus(itemSeleccionado)"
                                                    >
                                                        {{'Aprobador ' + itemSeleccionado?.id + (itemSeleccionado?.valores?.usuarioApruebaRechaza ? ': ' + itemSeleccionado?.valores?.usuarioApruebaRechaza : '')}}
                                                    </h3>
    
                                                    <div id="etapa-actual" [ngClass]="{'container-desplegables': servicioAprobacional?.definicionAprobaciones?.etapaActual !== itemSeleccionado?.id}">
                                        
                                                        <div class="container-fluid p-4">
                                            
                                                            <div class="d-flex flex-start">
                                                                <div class="container-fluid p-0">
                                                                    <h5 class="fw-bold mb-1">{{itemSeleccionado?.valores?.usuarioApruebaRechaza}}</h5>
                                                                    <div class="d-flex align-items-center mb-3">
                                                                    <h6 class="mb-0">
                                                                        {{itemSeleccionado?.fechaActualizacion | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                    </h6>
                                                                    </div>
                                                                    Comentarios:
                                                                    <div class="col-sm-12 pb-4 pl-0">
                                                                        <textarea class="form-control tamano-textarea" id="comentarios" name="comentarios" maxlength="500" [(ngModel)]="comentarios"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
        
                                                    </div>
                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                </ng-container>
                            </div>
            
        
                            <ng-container *ngIf="validaOpcionesActualAdmin(itemSeleccionado) && estadoTransaccion !== 'CLOSED'">
        
                                <div class="col-sm-12">
                                    <div class="col-sm-6  my-2 text-center">
                                        <button type="button" class="btn btn-success" (click)="guardarValores(itemSeleccionado?.valores, itemSeleccionado.id, true)">Aprobar</button>
                                    </div>
                                    <div class="col-sm-6  my-2 text-center">
                                        <button type="button" class="btn btn-danger" (click)="guardarValores(itemSeleccionado?.valores, itemSeleccionado.id, false)">Rechazar</button>
                                    </div>
                                </div>
        
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="version === 2">

                            <div class="col-sm-12">

                                <!--valores formulario solo visibles-->
                                <ng-template #valoresNoAdminV2>

                                    <!--decision-->
                                    <div class="col-sm-12 col-md-8 col-lg-6 my-3">
                                        <div class="form-group">
                                            <label class="text-primary">Decisión:</label>
                                            <span>
                                                {{decisionV2GetName(itemSeleccionado?.valores?.aprobado) || '-'}}
                                            </span>
                                        </div>
                                    </div>
        
                                    <ng-container *ngIf="itemSeleccionado?.valores?.montos && itemSeleccionado?.valores?.montos.length > 0">
                                        <div class="col-sm-12 px-5 py-4">
                                            <label class="text-primary">Montos</label>
                                            <div class="form-row">
                                                <ng-container *ngFor="let item of itemSeleccionado?.valores?.montos">

                                                    <div class="form-group col-sm-12 col-md-6 my-2 px-4">
                                                        <label>{{item?.displayName || item?.nombre}}: </label>
                                                        <span>
                                                            {{item?.valor || item?.valor == 0 ? (item?.valor | number: '1.0-2') : ''}}
                                                        </span>
                                                    </div>
                                                    
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                    
                                    <ng-container *ngIf="itemSeleccionado?.valores?.otros && itemSeleccionado?.valores?.otros.length > 0">
                                        <div class="col-sm-12 px-5 py-4">
                                            <label class="text-primary">Otros Campos</label>
                                            <div class="form-row">
                                                <ng-container *ngFor="let item of itemSeleccionado?.valores?.otros">

                                                    <div class="form-group col-sm-12 col-md-6 my-2 px-4">
                                                        <label >{{item?.displayName}}: </label>
                                                        <span [ngSwitch]="item.tipo">
                                                            <ng-container *ngSwitchCase="'DATE'">
                                                                {{getFechaDefaultFormat(item.formato, item.valor) || ''}}
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'STRING'">
                                                                <ng-container *ngIf="item?.valores && item?.valores.length > 0; else inputV2NoValoresShow">
                                                                    {{showOpcionValueV2(item.valor, item.valores) || ''}}
                                                                </ng-container>
                                                                <ng-template #inputV2NoValoresShow>
                                                                    {{item.valor || ''}}
                                                                </ng-template>                                                                
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'NUM'">
                                                                {{item.valor | number: '1.0-2'}}
                                                            </ng-container>
                                                            <ng-container *ngSwitchDefault>
                                                                {{item.valor}}
                                                            </ng-container>
                                                        </span>
                                                    </div>
                                                    
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!--comentarios-->
                                    <div class="col-sm-12 my-5">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="container-fluid text-dark">
    
                                                    <ng-container *ngIf="motorDeCalculos?.ResponseHistorico && objectKeys(motorDeCalculos?.ResponseHistorico).length > 0 && motorDeCalculos?.ResponseHistorico?.Fecha !== '' && motorDeCalculos?.ResponseHistorico?.Decision !== ''">
    
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('etapa-motorcalculo')"
                                                            [ngClass]="{'dot-ok': motorDeCalculos?.ResponseHistorico?.Decision === 'APROBADA', 'dot-denied' : motorDeCalculos?.ResponseHistorico?.Decision === 'RECHAZADA' }"
                                                        >
                                                                Cálculo Automático
                                                            </h3>
                                                            <div class="container-desplegables" id="etapa-motorcalculo">
                                                
                                                                <div class="container-fluid p-4">
                                                    
                                                                    <div class="d-flex flex-start">
                                                                        <div>
                                                                            <h5 class="fw-bold mb-1">Motor de Cálculo</h5>
                                                                            <div class="d-flex align-items-center mb-3">
                                                                            <h6 class="mb-0">
                                                                                {{motorDeCalculos?.ResponseHistorico?.Fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                            </h6>
                                                                            </div>
                                                                            Decisión:
                                                                            <p class="mb-0">{{motorDeCalculos?.ResponseHistorico?.Decision}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                            </div>
    
                                                    </ng-container>
        
                                                    <ng-container *ngFor="let item of arrayComentarios">
    
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header not-print" (click)="show('etapa-' + item.id)"
                                                                [ngClass]="item.class"
                                                            >
                                                                {{item.id + ' - ' + item.etapa}}
                                                        </h3>
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header only-print"
                                                            [ngClass]="item.class"
                                                        >
                                                            {{'Aprobador ' + item.id + (item.usuario ? ': ' + item.usuario : '')}}
                                                        </h3>
    
                                                        <div class="container-desplegables" id="etapa-{{item.id}}">
                                            
                                                            <div class="container-fluid p-4">
                                                
                                                                <div class="d-flex flex-start">
                                                                    <div>
                                                                        <h5 class="fw-bold mb-1">{{item.usuario}}</h5>
                                                                        <div class="d-flex align-items-center mb-3">
                                                                        <h6 class="mb-0">
                                                                            {{item.fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                        </h6>
                                                                        </div>
                                                                        Comentarios:
                                                                        <p class="mb-0" [innerHTML]="agregarSaltosDeLinea(item.comentario)"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                        
                                                    </ng-container>
    
                                                    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header not-print" (click)="show('etapa-actual')"
                                                            [ngClass]="getClassStatus(itemSeleccionado)"
                                                        >
                                                            {{itemSeleccionado?.id + ' - ' + itemSeleccionado?.nombre}}
                                                    </h3>
                                                    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header only-print"
                                                        [ngClass]="getClassStatus(itemSeleccionado)"
                                                    >
                                                        {{'Aprobador ' + itemSeleccionado?.id + (itemSeleccionado?.valores?.usuarioApruebaRechaza ? ': ' + itemSeleccionado?.valores?.usuarioApruebaRechaza : '')}}
                                                    </h3>
    
                                                    <div class="container-desplegables" id="etapa-actual">
                                        
                                                        <div class="container-fluid p-4">
                                            
                                                            <div class="d-flex flex-start">
                                                                <div>
                                                                    <h5 class="fw-bold mb-1">{{itemSeleccionado?.valores?.usuarioApruebaRechaza}}</h5>
                                                                    <div class="d-flex align-items-center mb-3">
                                                                    <h6 class="mb-0">
                                                                        {{itemSeleccionado?.fechaActualizacion | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                    </h6>
                                                                    </div>
                                                                    Comentarios:
                                                                    <p class="mb-0" [innerHTML]="agregarSaltosDeLinea(itemSeleccionado?.valores?.comentarios)"></p>
                                                                </div>
                                                            </div>
                                                        </div>
        
                                                    </div>
                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                </ng-template>

                                <!--combobox decision-->
                                <ng-container *ngIf="validaOpcionesActualAdmin(itemSeleccionado) && estadoTransaccion !== 'CLOSED'">
                                    <div class="col-sm-12 col-md-8 col-lg-6 my-3" *ngIf="servicioAprobacional?.definicionAprobaciones?.estadosAprobacionales && servicioAprobacional?.definicionAprobaciones?.estadosAprobacionales.length > 0">
                                        <div class="form-group">
                                            <label for="decision" class="text-primary">Decisión</label>
                                            <select id="decision" class="form-control" [(ngModel)]="decisionV2" (change)="changeValidators()">
                                                <option value="" disabled selected>Seleccione una opción</option>
                                                <option *ngFor="let element of servicioAprobacional?.definicionAprobaciones?.estadosAprobacionales" [ngValue]="element.nombre">
                                                    {{ element.displayName }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="validaOpcionesActualAdmin(itemSeleccionado) && estadoTransaccion !== 'CLOSED' else valoresNoAdminV2">

                                    <form [formGroup]="valoresEtapaForm">

                                        <ng-container *ngIf="itemSeleccionado?.valores?.montos && itemSeleccionado?.valores?.montos.length > 0">
                                            <div class="col-sm-12 px-5 py-4">
                                                <label class="text-primary">Montos</label>
                                                <div class="form-row">
                                                    <ng-container *ngFor="let item of itemSeleccionado?.valores?.montos">
                                                        
                                                        <ng-container *ngIf="item?.tipo === 'NUM'">
                                                            <div class="form-group col-sm-12 col-md-6 my-2 px-4">
                                                                <label for="{{item.nombre}}" class="cursor-pointer" [ngClass]="{'font-weight-normal': !isRequired(item.nombre)}"
                                                                    >{{item?.displayName || item?.nombre}}:
                                                                </label>
                                                                <input 
                                                                    type="number" 
                                                                    class="form-control" 
                                                                    id="{{item.nombre}}"
                                                                    name="{{item.nombre}}"
                                                                    formControlName="{{item.nombre}}"
                                                                >
                                                                <div class="w-100 mb-2">
                                                                    <app-error [control]="valoresEtapaForm.get(item.nombre)"></app-error>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                        <!--montos-->
                                        <ng-container *ngIf="itemSeleccionado?.valores?.otros && itemSeleccionado?.valores?.otros.length > 0">
                                            <div class="col-sm-12 px-5 py-4">
                                                <label class="text-primary">Otros Campos</label>
                                                <div class="form-row">
                                                    <ng-container *ngFor="let item of itemSeleccionado?.valores?.otros">
    
                                                        <div class="form-group col-sm-12 col-md-6 my-2 px-4">
                                                            <label for="{{item.nombre}}" class="cursor-pointer" [ngClass]="{'font-weight-normal': !isRequired(item.nombre)}"
                                                                >{{item?.displayName}}:
                                                            </label>

                                                            <ng-container *ngIf="item?.valores && item?.valores.length > 0 && item?.tipo === 'STRING'; else inputV2NoValores">
                                                                <select class="form-control"
                                                                    formControlName="{{item.nombre}}">
                                                                    <option value="" disabled selected>Seleccione una opción</option>
                                                                    <ng-container *ngFor="let campoValor of item.valores">
                                                                        <option
                                                                            [value]="campoValor.key">
                                                                            {{campoValor.value}}
                                                                        </option>
                                                                    </ng-container>
                                                                </select>
                                                            </ng-container>
                                                            <ng-template #inputV2NoValores>
                                                                <input 
                                                                    type="{{getTypeV2(item.tipo)}}" 
                                                                    class="form-control" 
                                                                    id="{{item.nombre}}"
                                                                    name="{{item.nombre}}"
                                                                    formControlName="{{item.nombre}}"
                                                                >
                                                            </ng-template>
                                                            <div class="w-100 mb-2">
                                                                <app-error [control]="valoresEtapaForm.get(item.nombre)"></app-error>
                                                            </div>
                                                        </div>
                                                        
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </form>

                                    <!--comentarios-->
                                    <div class="col-sm-12 my-5">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="container-fluid text-dark">
    
                                                    <ng-container *ngIf="motorDeCalculos?.ResponseHistorico && objectKeys(motorDeCalculos?.ResponseHistorico).length > 0 && motorDeCalculos?.ResponseHistorico?.Fecha !== '' && motorDeCalculos?.ResponseHistorico?.Decision !== ''">
    
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('etapa-motorcalculo')"
                                                            [ngClass]="{'dot-ok': motorDeCalculos?.ResponseHistorico?.Decision === 'APROBADA', 'dot-denied' : motorDeCalculos?.ResponseHistorico?.Decision === 'RECHAZADA' }"
                                                        >
                                                                Cálculo Automático
                                                            </h3>
                                                            <div class="container-desplegables" id="etapa-motorcalculo">
                                                
                                                                <div class="container-fluid p-4">
                                                    
                                                                    <div class="d-flex flex-start">
                                                                        <div>
                                                                            <h5 class="fw-bold mb-1">Motor de Cálculo</h5>
                                                                            <div class="d-flex align-items-center mb-3">
                                                                            <h6 class="mb-0">
                                                                                {{motorDeCalculos?.ResponseHistorico?.Fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                            </h6>
                                                                            </div>
                                                                            Decisión:
                                                                            <p class="mb-0">{{motorDeCalculos?.ResponseHistorico?.Decision}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                            </div>
    
                                                    </ng-container>
        
                                                    <ng-container *ngFor="let item of arrayComentarios">
    
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header not-print" (click)="show('etapa-' + item.id)"
                                                            [ngClass]="item.class"
                                                        >
                                                            {{item.id + ' - ' + item.etapa}}
                                                        </h3>
                                                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header only-print"
                                                            [ngClass]="item.class"
                                                        >
                                                            {{'Aprobador ' + item.id + (item.usuario ? ': ' + item.usuario : '')}}
                                                        </h3>
    
                                                        <div class="container-desplegables" id="etapa-{{item.id}}">
                                            
                                                            <div class="container-fluid p-4">
                                                
                                                                <div class="d-flex flex-start">
                                                                    <div>
                                                                        <h5 class="fw-bold mb-1">{{item.usuario}}</h5>
                                                                        <div class="d-flex align-items-center mb-3">
                                                                        <h6 class="mb-0">
                                                                            {{item.fecha | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                        </h6>
                                                                        </div>
                                                                        Comentarios:
                                                                        <p class="mb-0" [innerHTML]="agregarSaltosDeLinea(item.comentario)"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                        
                                                    </ng-container>
    
                                                    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header not-print" (click)="show('etapa-actual')"
                                                        [ngClass]="getClassStatus(itemSeleccionado)"
                                                    >
                                                        {{itemSeleccionado?.id + ' - ' + itemSeleccionado?.nombre}}
                                                    </h3>
                                                    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header only-print"
                                                        [ngClass]="getClassStatus(itemSeleccionado)"
                                                    >
                                                        {{'Aprobador ' + itemSeleccionado?.id + (itemSeleccionado?.valores?.usuarioApruebaRechaza ? ': ' + itemSeleccionado?.valores?.usuarioApruebaRechaza : '')}}
                                                    </h3>
    
                                                    <div id="etapa-actual" [ngClass]="{'container-desplegables': servicioAprobacional?.definicionAprobaciones?.etapaActual !== itemSeleccionado?.id}">
                                        
                                                        <ng-container *ngIf="itemSeleccionado?.valores?.comentarios !== undefined">
                                                            <div class="container-fluid p-4">
                                                
                                                                <div class="d-flex flex-start">
                                                                    <div class="container-fluid p-0">
                                                                        <h5 class="fw-bold mb-1">{{itemSeleccionado?.valores?.usuarioApruebaRechaza}}</h5>
                                                                        <div class="d-flex align-items-center mb-3">
                                                                        <h6 class="mb-0">
                                                                            {{itemSeleccionado?.fechaActualizacion | date: 'dd-MM-yyyy H:mm:ss' }}
                                                                        </h6>
                                                                        </div>
                                                                        <div class="form-group col-sm-12 my-1 px-4">
                                                                            <label for="comentarios" class="cursor-pointer">
                                                                                Comentarios:
                                                                            </label>
                                                                            <textarea 
                                                                                class="form-control tamano-textarea" 
                                                                                id="comentarios" 
                                                                                name="comentarios" 
                                                                                maxlength="500" 
                                                                                [(ngModel)]="comentarios"
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
        
                                                    </div>
                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>

                                <!--boton continuar - para confirmar seleccion combo y valores de formularios-->
                                <ng-container *ngIf="validaOpcionesActualAdmin(itemSeleccionado) && estadoTransaccion !== 'CLOSED'">
                                    <div class="col-sm-12 mt-3">
                                        <div class="row">
                                            <div class="col-sm-6 mx-auto my-3 text-center">
                                                <button type="button" class="btn btn-primary" (click)="guardarValoresV2()" [attr.disabled]="!decisionV2 ? true : null">
                                                    Continuar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>

                        </ng-container>
        
                    </div>
    
                </div>
    
            </ng-container>

        </ng-container>

        <ng-container *ngIf="showFicha">

            <div class="col-sm-12 my-2">

                <div class="row pagebreak">

                    <ng-container *ngIf="validaClave('plantillaDemo') 
                        || validaClave('plantillaCesceOld') 
                        || validaClave('capitalExpressNuevosCampos') 
                        || validaClave('plantillaSodimac') 
                        || validaClave('plantillaInge') 
                        || validaClave('plantillaCesce') 
                        || validaClave('plantillaPuntual') 
                        || validaClave('plantillaFlujoComercial')
                        || validaClave('plantillaCapexModelo1')
                        || validaClave('plantillaLineaDeCredito')
                        || validaClave('plantillaVentaNueva')
                        || validaClave('plantillaRenegociacion')
                        || validaClave('plantillaCapitalTrabajo')
                        || validaClave('plantillaPreFactoring')"
                    >
                        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('ficha')">
                            Ficha de credito y Score
                        </h3>

                        <div class="col-12">
                            <div class="container-desplegables" id="ficha">
    
                                <ng-container *ngIf="validaClave('plantillaLineaDeCredito') || validaClave('plantillaVentaNueva') || validaClave('plantillaRenegociacion')">
                                    <app-plantilla-salfa-linea [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed" [obtenerFlujoResp]="bodyModelos"></app-plantilla-salfa-linea>
                                </ng-container>

                                <ng-container *ngIf="validaClave('plantillaDemo')">
                                    <app-plantilla-demo [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-demo>
                                </ng-container>
    
                                <ng-container *ngIf="validaClave('plantillaCesceOld')">
                                    <app-plantilla-cesce-old [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-cesce-old>
                                </ng-container>
    
                                <ng-container *ngIf="validaClave('capitalExpressNuevosCampos')">
                                    <app-plantilla-capital-nc [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-capital-nc>
                                </ng-container>

                                <ng-container *ngIf="validaClave('plantillaInterfactor')">
                                    <app-plantilla-interfactor [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-interfactor>
                                </ng-container>

                                <ng-container *ngIf="validaClave('plantillaSodimac')">
                                    <app-plantilla-sodimac [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-sodimac>
                                </ng-container>

                                <ng-container *ngIf="validaClave('plantillaPanelRiesgo')">
                                    <app-plantilla-panel-riesgo [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-panel-riesgo>
                                </ng-container>

                                <ng-container *ngIf="validaClave('plantillaInge')">
                                    <app-plantilla-inge [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-inge>
                                </ng-container>

                                <ng-container *ngIf="validaClave('plantillaCesce')">
                                    <app-plantilla-cesce [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-cesce>
                                </ng-container>

                                <ng-container *ngIf="validaClave('plantillaPuntual')">
                                    <app-plantilla-puntual-caratula [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-puntual-caratula>
                                </ng-container>

                                <ng-container *ngIf="validaClave('plantillaFlujoComercial')">
                                    <app-plantilla-flujo-comercial [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-flujo-comercial>
                                </ng-container>

                                <ng-container *ngIf="validaClave('plantillaCapexModelo1')">
                                    <app-plantilla-capex-modelo1 [idTransaccion]="idTransaccion" [rut]="rut" [plantillaNoEditable]="true" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-capex-modelo1>
                                </ng-container>

                                <ng-container *ngIf="validaClave('plantillaCapitalTrabajo')">
                                    <app-plantilla-puente-financiero-ct [idTransaccion]="idTransaccion" [plantillaNoEditable]="true" [rut]="rut" [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-puente-financiero-ct>
                                </ng-container>
                                <ng-container *ngIf="validaClave('plantillaPreFactoring')">
                                    <app-plantilla-puente-financiero-ct [type]="'pre-factoring'"  [plantillaNoEditable]="true"[idTransaccion]="idTransaccion" [rut]="rut"  [objEntradaFicha]="fichaDeCredito" [objEntradaMotor]="motorDeCalculos" [closed]="closed"></app-plantilla-puente-financiero-ct>
                                </ng-container>
                            
                            </div>
                        </div>
                        
        
                    </ng-container>

                </div>
            
            </div>
            
        </ng-container>
        

    </div>
</div>