import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import Stepper from 'bs-stepper';
//import { SimpleModalService } from 'ngx-simple-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin, of } from 'rxjs';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { SolicitudCreditoServiceWorkflow } from 'src/app/shared/services/solicitud-credito-workflow.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AlertService } from '../../_alert';
import { RUT } from 'src/app/shared/utils/rut';
import { rutValidate } from 'rut-helpers';
import { environment as env } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map } from 'rxjs/operators';
import { CarteraRutService } from 'src/app/shared/services/cartera-rut.service';

@Component({
  selector: 'app-solicitud-credito-viewer-workflow',
  templateUrl: './solicitud-credito-viewer-workflow.component.html',
  styleUrls: ['./solicitud-credito-viewer-workflow.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SolicitudCreditoViewerWorkflowComponent implements OnInit, OnDestroy {
  @Output() closeSolicitud = new EventEmitter<boolean>();
  public rut: string = '';
  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];
  public groupNameConsultingAdmin: string[] = ['searchSolicitudesAdmin', 'getPermisos'];
  public groupNameConsultingUser: string[] = ['searchSolicitudesUsuario', 'getPermisos'];
  public errores: any[] = [];
  public permisos: any[] = [];
  
  public hasUserAccess = false;
  public organization = '';
  public currentStep: number = 0;
  public disabledPasos: boolean = false;
  public disabledPasosEtapa6: boolean = false;
  public stepper!: Stepper;
  public stepContent: boolean = false;
  public header: any[] = ['numSolicitud', 'EstadoTransaccion', 'Modelo', 'date', 'search', 'edit', 'trash'];
  public dataTable: any[] = [];
  public tipoSolicitud = '';
  public tipoSolicitudContent: boolean = false;
  public tipoSolicitudSel: any[] = [];
  public showNumSol: boolean = false;
  public informacionBasica: any = {};
  public informacionBasicaJudicial: any = {};
  public idTransaccion!: string;
  public numeroSolicitud!: number;
  public subscriptions: Subscription[] = [];
  public tipoEvento: string = '';
  //public respContrato: any = {};
  public operacionNuevo: boolean = false;
  public currentPage = 0;
  public inputText: string = '';
  public arrayFiltrado: any[] = [];
  public currentItem = env.initItemPerPage;
  public order = '';
  public campoOrder = '';
  public cargaMasiva: boolean = false;

  constructor(
    private readonly elementRef: ElementRef,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private _sessionService: SessionService,
    private alertService: AlertService,
    private router: Router,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog,
    private carteraRutService: CarteraRutService
  ) { }

  ngOnInit() {

    this.organization = this._sessionService.getOrganization();
    const storeTable = [];
    storeTable.push({ rut: this.rut });

    if (this._sessionService.getUserAccess().includes(this.access[0]) || this._sessionService.getUserAccess().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    const stepperEl = this.elementRef.nativeElement.querySelector('#stepper1');


    stepperEl.addEventListener('show.bs-stepper', (event: any) => {
      this.currentStep = event.detail.to;
    });

    this.stepper = new Stepper(stepperEl, {
      linear: false,
      animation: true
    });

    this.subscriptions.push(this.solicitudCreditoService.refreshStep$.subscribe((resp: any) => {

      if (resp === 'next') {
        this.stepper.next();
      } else if (resp === 'prev') {
        this.stepper.previous();
      } else {
        this.stepper.to(resp);
      }
    }));

    this.getData();
  }

  counter(i: number) {
    return new Array(i);
  }

  showList() {

    if (this.stepContent) {
      this.stepper.to(0);
    } else {
      this.tipoSolicitudContent = false;
      this.getData();
    }
    this.stepContent = false;
    this.showNumSol = false;

    if (this.tipoEvento === 'edit') {
      this.tipoSolicitudContent = false;
      this.getData();
    }
    this.rut = '';
  }

  incluirModeloDisplay(obj: any): any[] {
    obj.forEach((element: any) => {
      const indice = this.tipoSolicitudSel.findIndex((elemento: any) => elemento.modeloId == element.Modelo);
      if(indice >= 0){
        element.Modelo = this.tipoSolicitudSel[indice].displayName || '';
      } else {
        element.Modelo = element.Modelo;
      }
    });
    return obj;
  }

  setResponse(service: string, response: any): void {
    if (service === 'searchSolicitudesAdmin' || service === 'searchSolicitudesUsuario'){
      this.dataTable = response;
    }
    else if (service === 'getPermisos'){
      this.permisos = response || [];
    }
  }

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  getServices(service: string): any {
    const objeto: any = {
      'searchSolicitudesAdmin': () => {
        return this.solicitudCreditoService.searchSolicitudesAdmin()
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar flujos de creditos'), of(null))));
      },
      'searchSolicitudesUsuario': () => {
        return this.solicitudCreditoService.searchSolicitudesUsuario()
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar flujos de creditos'), of(null))));
      },
      'getPermisos': () => {
        return this.solicitudCreditoService.getPermissions()
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de obtención de permisos'), of(null))));
      }
    };
    return objeto[service]();
  }

  async getData(): Promise<void> {
    this.errores = [];
    const apiServices: any = [];

    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserAccess().includes(this.access[1])) {
      if (this.groupNameConsultingAdmin.length > 0) {
        for await (const value of this.groupNameConsultingAdmin) {
          apiServices.push(this.getServices(value))
        }
      }
    }

    if(this._sessionService.getUserAccess().includes(this.access[0]) && !this._sessionService.getUserAccess().includes(this.access[1])) {
      if (this.groupNameConsultingUser.length > 0) {
        for await (const value of this.groupNameConsultingUser) {
          apiServices.push(this.getServices(value))
        }
      }
    }

    if(apiServices.length > 0){
      this.spinner.show();
      this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
        this.spinner.hide();
        if (this.errores.length > 0){
          this.errores.forEach((element: any) => {
            this.alertService.error(element.msg);
          });
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.getModelos();
          this.filtrarObjeto();
          this.ordenarLista();
        }
      },
        (error) => {
          this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar flujos de creditos');
          this.spinner.hide();
        }
      ));
    }
  }

  ordenarLista(){
    this.arrayFiltrado.sort(function(a, b) {
      const fechaA = new Date(a.date);
      const fechaB = new Date(b.date);
      return fechaA < fechaB ? 1 : -1;
    });
  }

  filtrarObjeto(){
    this.currentPage = 0;
    this.arrayFiltrado = this.inputText != '' ? this.dataTable.filter((item: any) => 
    item?.Rut.includes(this.inputText.replace(/[^a-zA-Z0-9]/g, ''))
    || item?.Rut.includes(RUT.applyBackendFormat(this.inputText.replace(/[^a-zA-Z0-9]/g, '')))
    || item?.EstadoVisible?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.Nombre?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.Modelo?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.Decision?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.IDUsuario?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.IDUsuarioCreador?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.AutomaticoDisplay?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.numSolicitud?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.date?.split(" ")[0].split("-").reverse().join("-").includes(this.inputText)) : this.dataTable;
  }

  buscarFila(fila: any){
    const rut = fila.Rut.replace(/[^a-zA-Z0-9]/g, '');
    if(rutValidate(rut)){
      this.rut = rut;
      if(this._sessionService.getUserAccess().includes(this.access[2]) || this._sessionService.getUserAccess().includes(this.access[3])){

        this.carteraRutService.validateRutCartera(this.rut).subscribe((isValid: boolean) => {
          if (!isValid) {
            return;
          } else {
            this.buscarFlujo(fila);
          }
        });
      } else {
        //Solo estado closed -> se debera validar los permisos
      }
    } else {
      this.alertService.error("Rut invalido.");
    }
  }

  buscarFlujo(fila: any) {
    this.changeDisabledPasos(false);
    this.changeDisabledPasosEtapa6(false);

    this.rut = fila.Rut.replace('-','');
    this.idTransaccion = fila.IDTransaccion;
    this.numeroSolicitud = fila.numSolicitud;
    this.tipoSolicitudContent = true;

    if (fila.EstadoTransaccion === 'IP' || fila.EstadoTransaccion === 'COMPLETED' || fila.EstadoTransaccion === 'CLOSED') {
      this.getStepSolicitud();
      this.tipoEvento = 'edit';
    }

  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      this.arrayFiltrado.sort((a, b) => {
        if(campo === 'Modelo' || campo === 'EstadoVisible' || campo === 'Nombre' || campo === 'IDUsuario' || campo === 'IDUsuarioCreador' || campo === 'Decision' || campo === 'AutomaticoDisplay'){
          const textA = a[campo];
          const textB = b[campo];
          return textA.localeCompare(textB)
        } else if((campo === 'date')){
          const fechaA = new Date(a[campo]);
          const fechaB = new Date(b[campo]);
          return fechaA > fechaB ? 1 : -1;
        } /*else if((campo === 'fechaInicioActividades')){
          const dateA = new Date(a[campo]),
          dateB = new Date(b[campo]);
          if (!a[campo] && b[campo]) return -1;
          else if (a[campo] && !b[campo]) return 1;
          else if (dateA === dateB) return 0;
          else return (dateA > dateB) ? 1 : (dateB > dateA ? -1 : 0);
        } */else {
          return parseFloat(a[campo]) - parseFloat(b[campo]);
        }
      });
    } else {
      this.arrayFiltrado.sort(function(a, b) {
        if(campo === 'Modelo' || campo === 'EstadoVisible' || campo === 'Nombre' || campo === 'IDUsuario' || campo === 'IDUsuarioCreador' || campo === 'Decision' || campo === 'AutomaticoDisplay'){
          const textA = a[campo];
          const textB = b[campo];
          return (-1 * textA.localeCompare(textB));
        } else if(campo === 'date'){
          const fechaA = new Date(a[campo]);
          const fechaB = new Date(b[campo]);
          return fechaA < fechaB ? 1 : -1;
        } /*else if((campo === 'fechaInicioActividades')){
          const dateA = new Date(a[campo]),
          dateB = new Date(b[campo]);
          if (!a[campo] && b[campo]) return 1;
          else if (a[campo] && !b[campo]) return -1;
          else if (dateA === dateB) return 0;
          else return (-1*((dateA > dateB) ? 1 : (dateB > dateA ? -1 : 0)));
        } */else {
          return parseFloat(b[campo]) - parseFloat(a[campo]);
        }
      });
    }
  }

  getModelos(): void {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.getObtenerModelos().subscribe(resp => {
      this.spinner.hide();
      this.tipoSolicitudSel = resp;
      this.dataTable = this.incluirModeloDisplay(this.dataTable);
      this.tipoSolicitud = this.tipoSolicitudSel[0]?.modeloId || '';
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los modelos disponibles');
      this.spinner.hide();
    }));
  }

  /*procesarEvent(event: any) {
    this.idTransaccion = event.obj.IDTransaccion;
    this.numeroSolicitud = event.obj.numSolicitud;
    this.tipoSolicitudContent = true;

    if (event.type === 'edit') {
      this.getStepSolicitud();
      this.tipoEvento = 'edit';
    }

    if (event.type === 'search') {
      this.getStepSolicitud();
      this.tipoEvento = 'edit';
    }

    /*if (event.type === 'search') {
      this.getSearchReporte(this.idTransaccion);
      this.tipoEvento = 'search';
    } 

    if (event.type === 'trash') {
      this.deleteSolicitud(this.idTransaccion);
      this.tipoSolicitudContent = false;
    }
  }*/

  
  eliminarTransaccion(elemento: any): void {
    if(elemento?.IDTransaccion && elemento?.Rut){
      this.deleteSolicitud(elemento?.IDTransaccion, elemento?.Rut);
      this.tipoSolicitudContent = false;
    }
  }

  getStepSolicitud() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.spinner.hide();
      if (resp) {
        if (resp.workflowModel) {
          this.tipoSolicitud = resp.workflowModel; // modelo
          if (this.solicitudCreditoService.setTipoSolicitud(this.tipoSolicitud)) {
            this.stepContent = true;
            this.showNumSol = true;
            this.operacionNuevo = false;

            (resp?.cargaMasiva === true) ? (this.cargaMasiva = true) : (this.cargaMasiva = false);

            const pasosCompleted = resp.stages.filter((e: any) => e.completed);
            const cantidad = pasosCompleted.length;
            const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1]?.stageID || 0);

            let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
            if (!step[0]?.step){
              step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));

            }
            if(step && step[0].step > 0)
              if(ultimoPasoCompletado === 9){ // Tiene una etapa intermedia
                this.solicitudCreditoService.setStep(Number(step[0].step) - 1);

              } else if(ultimoPasoCompletado === 10) { // Tiene una etapa intermedia que depende del estado de la siguiente
                const inProgress11 = resp.stages.find((e: any) => e.stageID === '11') || null;
                this.changeDisabledPasos(true, step);

                if(inProgress11?.inProgress === false){
                  this.solicitudCreditoService.setStep(Number(step[0].step) - 1);

                } else {
                  // Bloquea el resto de pasos para todos
                  this.changeDisabledPasosEtapa6(true, step);
                  this.solicitudCreditoService.setStep(Number(step[0].step));

                }
              } else if(ultimoPasoCompletado > 10) {
                // Bloquea el resto de pasos para todos
                this.changeDisabledPasosEtapa6(true, step);
                this.solicitudCreditoService.setStep(Number(step[0].step));

              } else {
                this.solicitudCreditoService.setStep(Number(step[0].step));

              }
          }
        }
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito');
      this.spinner.hide();
    }));

  }

  /*getSearchReporte(idTransaccion: string): void {
    this.router.navigate(['/solicitud-credito/resumen', idTransaccion], { state: { rut: this.rut } });
  }*/

  deleteSolicitud(idTransaccion: string, rut: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Flujo de Crédito',
        message: `Esta seguro que desea eliminar este flujo de crédito N° ${idTransaccion.substring(idTransaccion.length - 5)}?`,
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.spinner.show();
        this.subscriptions.push(this.solicitudCreditoService.eliminarSolicitud(idTransaccion, rut).subscribe(resp => {
          this.getData();
        }, (error: any) => {
          this.spinner.hide();
          this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al eliminar la solicitud');
        }));
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error inesperado');
    });
    /*this.simpleModalService.addModal(ConfirmDialogComponent, {
      title: 'Flujo de Crédito',
      message: `Esta seguro que desea eliminar este flujo de crédito N° ${idTransaccion.substring(idTransaccion.length - 5)}?`,
    }).subscribe((resp) => {
      if (resp) {
        this.spinner.show();
        this.subscriptions.push(this.solicitudCreditoService.eliminarSolicitud(idTransaccion, rut).subscribe(resp => {
          this.getData();
        }, (error: any) => {
          this.spinner.hide();
          this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al eliminar la solicitud');
        }));
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error inesperado');
    });*/
  }


  showTipoSolicitud() {
    this.tipoSolicitudContent = true;
  }

  setTipoSolicitud() {
    this.changeDisabledPasos(false);
    this.changeDisabledPasosEtapa6(false);

    if(this._sessionService.getUserAccess().includes(this.access[2]) || this._sessionService.getUserAccess().includes(this.access[3])){

      if(rutValidate(this.rut)){

        this.carteraRutService.validateRutCartera(this.rut).subscribe((isValid: boolean) => {
          if (!isValid) {
            return;
          } else {
            if(this.tipoSolicitud !== ''){
              if (this.solicitudCreditoService.setTipoSolicitud(this.tipoSolicitud)) {
                this.spinner.show();
                this.idTransaccion = '';
                this.tipoSolicitud = this.solicitudCreditoService.getTipoSolicitud();
                this.stepContent = true;
              }
            }
          }
        });

      } else {
        this.alertService.error('Solicitud de Crédito - Ingrese un rut válido');
      }

    } else {
      this.alertService.error('Solicitud de Crédito - No posee los permisos para realizar esta acción');
    }

  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  obtenerNombreTipoSolicitud(): string {
    const indice = this.tipoSolicitudSel.findIndex((elemento: any) => elemento.modeloId == this.tipoSolicitud);
    if(indice >= 0){
      return this.tipoSolicitudSel[indice]?.displayName || '';
    } else {
      return ''
    }
  }

  validaClave(plantilla: string): boolean {
    if(this.permisos && this.permisos.length > 0) {
      const permisos = this.permisos.find((e: any) => e.plantilla == plantilla);
      if(permisos && permisos?.modelos && permisos.modelos.length > 0){
        return (permisos.modelos.indexOf(this.tipoSolicitud) > -1);
      }
    }

    return false;
  }

  hasAccessToDelete(): boolean {
    if (this._sessionService.getUserAccess().includes(this.access[3])) {
      return true;
    }
    return false;
  }

  changeDisabledPasos(valor: boolean, step: any = null): void {
    if(valor === true && step != null){
      if(Number(step[0].step) === 5 && !this._sessionService.getUserAccess().includes(this.access[3])){
        this.disabledPasos = true;
      }
    } else {
      this.disabledPasos = false;
    }
  }

  changeDisabledPasosEtapa6(valor: boolean, step: any = null): void {
    if(valor === true && step != null){
      if(Number(step[0].step) === 6){
        this.disabledPasosEtapa6 = true;
      }
    } else {
      this.disabledPasosEtapa6 = false;
    }
  }

  changeDisabledPasosSinValidacionPaso(valor: boolean): void {
    if(valor === true){
      this.disabledPasos = true;
    } else {
      this.disabledPasos = false;
    }
  }

  changeDisabledPasosEtapa6SinValidacionPaso(valor: boolean): void {
    if(valor === true){
      this.disabledPasosEtapa6 = true;
    } else {
      this.disabledPasosEtapa6 = false;
    }
  }

}
