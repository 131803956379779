import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { CompraVentaService } from 'src/app/shared/services/compra-venta.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { RUT } from 'src/app/shared/utils/rut';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CredencialesService } from 'src/app/shared/services/credenciales.service';
import { CesionFacturasService } from 'src/app/shared/services/cesion-facturas.service';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CarteraRutService } from 'src/app/shared/services/cartera-rut.service';

interface CompraVentaReportFormValues {
  rut: string;
  claveSii?: string;
  sincronizar?: boolean;
}

@Component({
  selector: 'app-compra-venta-consultar',
  templateUrl: './compra-venta-consultar.component.html',
  styleUrls: ['./compra-venta-consultar.component.scss']
})
export class CompraVentaConsultarComponent implements OnInit {
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.REPORTE_LIBRO_COMPRA_VENTA, ESystemProfileList.WEB,
    ESystemAccess.CLIENTES_CREDS_READ,
    ESystemAccess.CLIENTES_CREDS_ADMIN,
  ];
  public groupNameConsulting: string[] = ['primerPeriodo', 'segundoPeriodo', 'tercerPeriodo'];
  private accessCesion: string[] = [
    ESystemProfileList.WEB,
    ESystemAccess.REPORTE_CESION_FACTURAS,
    ESystemAccess.REPORTE_CESION_FACTURAS_BUSCAR
  ];
  public hasUserAccess = false;
  public compraVentaReportForm: UntypedFormGroup;
  public today: string = '';
  public rut: string = '';

  public showReport = false;
  public actualPeriodo: number = 0;
  public consultacredencialesSinc = false;
  public credencialesSinc = false;

  public errores: any[] = [];
  public anyCompleted: boolean = false;
  public allFailed: boolean = false;

  public erroresCesion: any[] = [];
  public responseCesion: any = null;
  public periodosAsync: number[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private _sessionService: SessionService,
    private compraVentaService: CompraVentaService,
    private utilsService: UtilsService,
    private credencialesService: CredencialesService,
    private cesionFacturasService: CesionFacturasService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private carteraRutService: CarteraRutService
  ) {
    this.compraVentaReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
      claveSii: ['', Validators.required]
    }) as FormGroupTyped<CompraVentaReportFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.setToday();
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  onChanges(): void {
    this.compraVentaReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  getCesionAutorizacion(): boolean {
    if (this._sessionService.getUserProfileList().includes(this.accessCesion[0]) && this._sessionService.getUserAccess().includes(this.accessCesion[1])
      && this._sessionService.getUserAccess().includes(this.accessCesion[2])) {
      return true;  
    } else {
      return false;
    }
  }

  validaCredsAdmin(): boolean {
    if(this._sessionService.getUserAccess().includes(this.access[3])) {
      return true;
    } else {
      return false;
    }
  }

  guardarCreds(rut: string, contrato: any){
    this.spinner.show();
    this.credencialesService.crearActualizarCredenciales(rut, contrato).subscribe(
      (data: any) => {
        this.crearReporte("", rut);
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al crear/actualizar las credenciales.');
        this.showReport = false;
        this.spinner.hide();
    });
  }

  obtenerContratoCreds(rut: string, password: string): void {
    this.spinner.show();

    this.credencialesService.obtenerContrato().subscribe(
      (data: any) => {
        const services: any = data?.services?.find((e: any) => e.name === 'SII')
        if(services){
          services.attributes.forEach((element: any) => {
            if(element.name === 'userName') element.value = rut;
            if(element.name === 'password') element.value = password;
            if(element.name === 'orgAccess') element.value = true;
          });
          this.guardarCreds(rut, [services])
        } else {
          this.alertService.error('Ha ocurrido un error al obtener el contrato de credenciales.');
          this.showReport = false;
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al validar las credenciales.');
        this.showReport = false;
        this.spinner.hide();
    });
  }

  validarCreds(rut: string, password: string): void {
    this.spinner.show();

    this.credencialesService.validarCredenciales(RUT.applyBackendFormat(rut), password, 'SII').subscribe(
      (data: any) => {
        this.obtenerContratoCreds(rut, password);
      },
      ({ error }) => {
        const mensaje: string = error.message ? error.message : error && typeof(error) == 'string' ? error : 'Ha ocurrido un error al validar las credenciales.'; 
        this.alertService.error(mensaje);
        this.showReport = false;
        this.spinner.hide();
    });
  }

  validaCredencialReturn(lista: any[]): boolean {
    if(lista && lista.length > 0){
      const objeto = lista.find(e => e?.name === 'SII')
      if(objeto && objeto?.configurationStatus === true){
        this.credencialesSinc = true;
        return true;
      } else {
        this.credencialesSinc = false;
        return false;
      }
    } else {
      this.credencialesSinc = false;
      return false;
    }
  }

  changeRut(): void {
    this.limpiarClave();
  }

  limpiarClave(): void {
    this.compraVentaReportForm.removeControl('claveSii');
    this.compraVentaReportForm.removeControl('sincronizar');
    this.credencialesSinc = false;
    this.consultacredencialesSinc = false;
  }

  addClaveForm(): void {
    this.compraVentaReportForm.addControl('claveSii', new UntypedFormControl('', [Validators.required]));
    this.compraVentaReportForm.addControl('sincronizar', new UntypedFormControl(false, [Validators.required]));
  }

  searchReport() {
    if(this.consultacredencialesSinc) {

      if (!this.compraVentaReportForm.valid) {
        this.utilsService.validateAllFormFields(this.compraVentaReportForm);
        return;
      }
  
      const formTemp = this.compraVentaReportForm.getRawValue();
      const rut = formTemp?.rut;
      const claveSii = formTemp?.claveSii;
      const sincronizar = formTemp?.sincronizar;
  
      if (!rutValidate(rut)) {
        return;
      }

      this.carteraRutService.validateRutCartera(rut).subscribe((isValid: boolean) => {
        if (!isValid) {
          return;
        } else {
          const today = new Date();
          this.actualPeriodo = today.getFullYear();
          this.rut = rut;
    
          if(sincronizar === true){
            this.validarCreds(rut, claveSii);
          } else {
            this.crearReporte(claveSii, rut);
          }
        }
      });

    } else {

      /*if(!this._sessionService.getUserAccess().includes(this.access[2]) && !this.validaCredsAdmin()) {
        this.alertService.error('No tiene permisos para realizar esta acción.');
        return;
      }*/

      this.rut = this.compraVentaReportForm.get('rut')?.value;
      if (!this.compraVentaReportForm.valid) {
        this.alertService.warn("Rut no valido");
        return
      }

      this.carteraRutService.validateRutCartera(this.rut).subscribe((isValid: boolean) => {
        if (!isValid) {
          return;
        } else {
          this.spinner.show();
          this.credencialesService.buscarSecretos(this.rut).subscribe(
            (data: any) => {
              if(this.validaCredencialReturn(data?.services)){
                const today = new Date();
                this.actualPeriodo = today.getFullYear();
                this.crearReporte("", this.rut);
              } else {
                this.alertService.warn('No se han encontrado las credenciales del rut sincronizadas.');
                this.addClaveForm();
                this.consultacredencialesSinc = true;
                this.spinner.hide();
              }
            },
            ({ error }) => {
              this.alertService.error(error.message || 'Ha ocurrido un error al obtener las credenciales del rut.');
              this.spinner.hide();
          });
        }
      });
    }

  }

  public clearForm() {
    this.limpiarClave();
    this.compraVentaReportForm.reset();
    this.activateRutHelper = false;
  }

  setError(reporte: string, error: string): void {
    if(reporte === 'procesarPeriodoLCV') {
      this.allFailed = true;
      this.errores.push({
        periodo: "-",
        mes: "-",
        msg: error || `Ha ocurrido un error al acceder a la información del reporte generado`
      });
    } else if(reporte === 'crearReporteCesion') {
      this.erroresCesion.push({
        msg: error
      })
    }
  }

  getServices(service: string, 
    rut: string, 
    claveSii: string,
    tipo?: string, // cesion
    idTx?: string // cesion obtener
  ): any {
    const objeto: any = {
      'procesarPeriodoLCV': () => {
        return this.compraVentaService.procesarPeriodo(RUT.applyBackendFormat(rut), this.periodosAsync, claveSii)
          .pipe(
            map(resp => {
              this.processResponses(resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrió un error al realizar la consulta de creacion de reporte libro compra venta'), of(null))));
      },
      'crearReporteCesion': () => {
        return this.cesionFacturasService.crearReporte('', RUT.applyBackendFormat(rut), tipo ? tipo : '', claveSii, false)
        .pipe(
          map(resp => {
            this.responseCesion = resp;
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Ha ocurrido un error al crear el reporte de cesión de facturas'), of(null))));
      },
      'obtenerTxLCV': () => {
        return this.compraVentaService.obtenerTx(RUT.applyBackendFormat(rut), this.periodosAsync)
          .pipe(
            map(resp => {
              this.processResponses(resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrió un error al realizar la consulta de creacion de reporte libro compra venta'), of(null))));
      },
      'obtenerTxCesion': () => {
        return this.cesionFacturasService.obtenerReportePorId('', idTx ? idTx : '')
        .pipe(
          map(resp => {
            this.responseCesion = resp;
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Ha ocurrido un error al crear el reporte de cesión de facturas'), of(null))));
      }
    };
    return objeto[service]();
  }

  crearReporte(claveSii: string, rut: string): void {
    this.erroresCesion = [];
    this.responseCesion = null;
    this.errores = [];
    this.anyCompleted = false;
    this.allFailed = false;
    this.periodosAsync = [(this.actualPeriodo), (this.actualPeriodo - 1), (this.actualPeriodo - 2)];

    const apiServices: any = [];

    apiServices.push(this.getServices('procesarPeriodoLCV', rut, claveSii));
    if(this.getCesionAutorizacion()) {
      apiServices.push(this.getServices('crearReporteCesion', rut, claveSii, 'cedente'));
    }

    if(apiServices.length > 0) {
      this.spinner.show();

      forkJoin(apiServices).subscribe((resp) => {
        if(this.allFailed) {
          this.periodosAsync = [];
        }

        if(this.erroresCesion.length > 0) { // mostrar errores de cesion, no bloquea el proceso
          this.erroresCesion.forEach(e => {
            this.alertService.error(e.msg);
          });
          this.erroresCesion = [];
        }

        if(this.responseCesion) { // validar si la respuesta de cesion no es erronea
          if(this.responseCesion?.CesionFacturasReporte?.DatosBasicosSolicitud?.EstadoTransaccion === 'ERROR' || 
            this.responseCesion?.CesionFacturasReporte?.DatosBasicosSolicitud?.SubEstadoTransaccion === 'ERROR'
          ) {
            this.alertService.error('Ha ocurrido un error al crear el reporte de cesión de facturas');
            this.responseCesion = null;
          }
        }
        
        if(this.periodosAsync.length > 0) {
          this.llamadaAsincrona(rut);
        } else {
          this.finFlujo();
        }
        
      },
        (error) => {
          this.alertService.error(error?.message || 'Ocurrió un error al realizar la consulta de creacion de reporte');
          this.spinner.hide();
        }
      );
    }

  }

  llamadaAsincrona(rut: string, delay: number = 0, llamadas: number = 21): void {
    if(llamadas > 0 && (this.periodosAsync.length > 0 || this.responseCesion?.CesionFacturasReporte?.DatosBasicosSolicitud?.EstadoTransaccion === 'IN-PROGRESS')) {
      setTimeout(() => {
        const apiServices: any = [];
        if(this.periodosAsync.length > 0) {
          apiServices.push(this.getServices('obtenerTxLCV', rut, ''));
        }
        if(this.responseCesion?.CesionFacturasReporte?.DatosBasicosSolicitud?.EstadoTransaccion === 'IN-PROGRESS') {
          apiServices.push(this.getServices('obtenerTxCesion', rut, '', '', this.responseCesion?.CesionFacturasReporte?.DatosBasicosSolicitud?.IDTransaccion));
        }

        if(apiServices.length > 0) {
          forkJoin(apiServices).subscribe((resp) => {
            if(this.allFailed) { // si todos los reportes fallaron se debe detener el proceso
              this.periodosAsync = [];
            }
    
            if(this.erroresCesion.length > 0) { // mostrar errores de cesion, no bloquea el proceso
              this.erroresCesion.forEach(e => {
                this.alertService.error(e.msg);
              });
              this.erroresCesion = [];
            }
    
            if(this.responseCesion) { // validar si la respuesta de cesion no es erronea
              if(this.responseCesion?.CesionFacturasReporte?.DatosBasicosSolicitud?.EstadoTransaccion === 'ERROR' || 
                this.responseCesion?.CesionFacturasReporte?.DatosBasicosSolicitud?.SubEstadoTransaccion === 'ERROR'
              ) {
                this.alertService.error('Ha ocurrido un error al crear el reporte de cesión de facturas');
                this.responseCesion = null;
              }
            }
            
            if(!this.allFailed && (this.periodosAsync.length > 0 || this.responseCesion?.CesionFacturasReporte?.DatosBasicosSolicitud?.EstadoTransaccion === 'IN-PROGRESS')) {
              this.llamadaAsincrona(rut, 15000, llamadas - 1);
            } else {
              this.finFlujo();
            }            
          },
            (error) => {
              this.alertService.error(error?.message || 'Ocurrió un error al realizar la consulta de creacion de reporte');
              this.spinner.hide();
            }
          );
        } else {
          this.finFlujo();
        }
      }, delay);
    } else {
      this.finFlujo();
    }
    
    
  }

  finFlujo(): void {    
    if(this.errores.length > 0) { // valida si hay errores y si estan repetidos para mostrarlos
      const mensajesMostrados: Set<string> = new Set();
  
      this.errores.forEach(e => {
        const mensaje: string = e?.msg || 'Ha ocurrido con uno de los reportes generados';
        if (!mensajesMostrados.has(mensaje)) {
          this.alertService.error(mensaje);
          mensajesMostrados.add(mensaje);
        }
      });
    }

    if(this.anyCompleted === true) {
      this.showReport = true;
      this.spinner.hide();
    } else {
      this.anyCompleted = false;
      this.showReport = false;
      if(this.errores.length < 1) {
        this.alertService.error('No se ha logrado obtener ningun reporte completado');
      }
      this.spinner.hide();
    }
  }

  processResponses(respuestaConsulta: any): void {  
    if(!(respuestaConsulta && Array.isArray(respuestaConsulta) && respuestaConsulta.length > 0)) {
      return;
    }
    
    const array: any[] = respuestaConsulta;
    const periodosOriginal: number[] = [...this.periodosAsync];

    for(const periodo of periodosOriginal) {
      const elementosFiltrados: any[] = array.filter(e => e?.LibroCompraVentaReporte?.Reporte?.periodo == periodo);
      if(elementosFiltrados.length > 0 && elementosFiltrados.every(e => e?.LibroCompraVentaReporte?.DatosBasicosSolicitud?.EstadoTransaccion !== 'IN-PROGRESS')) {
        const index = this.periodosAsync.findIndex(e => e == periodo);
        if (index >= 0) {
          this.periodosAsync.splice(index, 1);
        }
      }
    }

    for(const elemento of array) {
      if(elemento.LibroCompraVentaReporte?.DatosBasicosSolicitud?.EstadoTransaccion === 'ERROR') {
        this.errores.push({
          periodo: elemento?.LibroCompraVentaReporte?.Reporte?.periodo,
          mes: elemento.LibroCompraVentaReporte?.Reporte?.mes || "-",
          msg: elemento.LibroCompraVentaReporte?.Reporte?.error || `Ha ocurrido un error al consultar el reporte con periodo ${elemento?.LibroCompraVentaReporte?.Reporte?.periodo} y con mes ${elemento.LibroCompraVentaReporte?.Reporte?.mes}`
        });
      }
    }

    if(array.some(e => e?.LibroCompraVentaReporte?.DatosBasicosSolicitud?.EstadoTransaccion === 'COMPLETED')) {
      this.anyCompleted = true;
    }
    if(array.every(e => e?.LibroCompraVentaReporte?.DatosBasicosSolicitud?.EstadoTransaccion === 'ERROR')) {
      this.allFailed = true;
    }
  }

  public closeReport(): void {
    this.showReport = false;
    this.rut = '';
  }

}
