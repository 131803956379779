import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-balance-manual',
  templateUrl: './balance-manual.component.html',
  styleUrls: ['./balance-manual.component.scss']
})
export class BalanceManualComponent {

  @Input() balanceManualReporte: any = {};

  public contratoResultados: any = {};
  public secciones: any[] = [];
  public valores: any[] = [];
  public arrayContrato: any[] = [];

  ngOnInit(): void {
    this.obtencionDeValores();
  }

  obtencionDeValores(): void {
    //this.spinner.show();
    this.valores = [];
    this.secciones = [];

    this.obtenerContrato();
    this.obtenerValores();
    this.obtenerSecciones();
    this.arrayContrato = this.generarContratoFromContrato();
    //this.obtenerDatosInput();

    //this.spinner.hide();
  }

  obtenerContrato(): void {
    const element = this.balanceManualReporte.find((e: any) => e?.BalanceManualReporte?.Reporte?.tipo === 'balance');
    if(element){
      this.contratoResultados = element?.BalanceManualReporte?.Reporte?.definicionBalance?.output || {};
    } else {
      this.contratoResultados = this.balanceManualReporte[0]?.BalanceManualReporte?.Reporte?.definicionBalance?.output || {};
    }
  }

  obtenerValores(): void {
    this.balanceManualReporte.forEach((element: any) => {
      const objeto: any = element?.BalanceManualReporte?.Reporte || {};
      if(Object.keys(objeto).length > 0 && objeto?.periodo){
        objeto.IDTransaccion = element?.DatosBasicosSolicitud?.IDTransaccion || '';
        this.valores.push(objeto);
      }
    });

    if(this.valores.length > 0){
      this.valores.sort(function(a, b) {
        // Convertir el campo "periodo" a un número para comparar correctamente
        const periodoA = Number(a.periodo);
        const periodoB = Number(b.periodo);
        
        // Comparar los valores de periodo
        if (periodoA > periodoB) {
          return -1;
        } else if (periodoA < periodoB) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  obtenerSecciones(): void {
    const sectionMap = new Map();
  
    for (const key in this.contratoResultados) {
      const nivel1 = this.contratoResultados[key].nivel1;
      const nivel1Name = this.contratoResultados[key].nivel1Name;
      const nivel2 = this.contratoResultados[key].nivel2;
      const nivel2Name = this.contratoResultados[key].nivel2Name;
      const nivel3 = this.contratoResultados[key].nivel3;
      const nivel3Name = this.contratoResultados[key].nivel3Name;
  
      if (!sectionMap.has(nivel1)) {
        sectionMap.set(nivel1, { nivel1, nivel1Name, nivel2: [] });
      }
  
      const sectionObj = sectionMap.get(nivel1);
      const nivel2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
  
      if (!nivel2Obj) {
        sectionObj.nivel2.push({ nivel2, nivel2Name, nivel3: [] });
      } else {
        const section2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
        const nivel3Obj = section2Obj.nivel3.find((subSec: any) => subSec.nivel3 === nivel3);
    
        if (!nivel3Obj) {
          section2Obj.nivel3.push({ nivel3, nivel3Name });
        }
      }
  
    }
  
    this.secciones = Array.from(sectionMap.values());

    //if(this.visualizacionExtendida === true)
    //this.secciones = this.secciones.filter(e => e.nivel1 === 'liquideseindicadores');

  }

  generarContratoFromContrato(): any[] {
    const respuesta: any[] = [];
  
    this.secciones.forEach(element1 => {
      if (!element1?.nivel1) {
        const arrayUndefined: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1) || [];
        const objeto: any = { variables: arrayUndefined };
        respuesta.push(objeto);
      } else {
        const objetoNivel1: any = {
          nivel1Name: element1?.nivel1Name || "",
          nivel1: element1?.nivel1 || "",
          variables: [],
          nivel2: []
        };
  
        if (element1?.nivel2 && element1?.nivel2.length > 0) {
          element1.nivel2.forEach((element2: any) => {
            if (!element2?.nivel2) {
              const arrayNivel2: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2) || [];
              const objeto: any = {
                nivel2Name: "",
                nivel2: "",
                variables: arrayNivel2
              };
              objetoNivel1.nivel2.push(objeto);
            } else {
              const objetoNivel2: any = {
                nivel2Name: element2?.nivel2Name || "",
                nivel2: element2?.nivel2 || "",
                variables: [],
                nivel3: []
              };
  
              if (element2?.nivel3 && element2?.nivel3.length > 0) {
                element2.nivel3.forEach((element3: any) => {
                  if (!element3?.nivel3) {
                    const arrayNivel3: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objeto: any = {
                      nivel3Name: "",
                      nivel3: "",
                      variables: arrayNivel3
                    };
                    objetoNivel2.nivel3.push(objeto);
                  } else {
                    const arrayNivel3: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objetoNivel3: any = {
                      nivel3Name: element3?.nivel3Name || "",
                      nivel3: element3?.nivel3 || "",
                      variables: arrayNivel3 || []
                    };
                    objetoNivel2.nivel3.push(objetoNivel3);
                  }
                });
              } else {
                const arrayNivel2: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2) || [];
                objetoNivel2.variables.push(...arrayNivel2);
              }
  
              objetoNivel1.nivel2.push(objetoNivel2);
            }
          });
        } else {
          const arrayNivel1: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1) || [];
          objetoNivel1.variables.push(...arrayNivel1);
        }
  
        respuesta.push(objetoNivel1);
      }
    });
  
    return respuesta || [];
  }

  filtrarObjetoNiveles(objeto: any, valor1: any, valor2: any = undefined, valor3: any = undefined): any[] {
    const resultado: any[] = [];
    for (const key in objeto) {
      const lista: any[] = [];
      if (objeto.hasOwnProperty(key) && objeto[key]?.nivel1 === valor1 && objeto[key]?.nivel2 === valor2 && objeto[key]?.nivel3 === valor3) {
        const objetoElemento: any = {
          key: key,
          valor: objeto[key]?.name,
          type: objeto[key]?.type || "String"
        };
        lista.push(objetoElemento);

        this.valores.forEach((element: any) => {
          const objetoElemento: any = {
            valor: (element?.balance[key] || element?.balance[key] == 0) ? element?.balance[key] : (element?.campos[key] || element?.campos[key] == 0) ? element?.campos[key] : "",
            type: objeto[key]?.type
          };
          lista.push(objetoElemento);
        })
        resultado.push(lista);
      }
    }
  
    return resultado;
  }

  validaVariables(variables: any[], nivelKey: string): boolean {
    let respuesta: boolean = true;
    if(variables && variables.length === 1 && nivelKey) {
      const variable = variables[0] || [];
      if(nivelKey === variable?.[0]?.key){
        respuesta = false
      }
    } 
    return respuesta;
  }

  toggleContent(event: any, id: string): void{
    let estadoClosed: boolean | null = null;
    if(event?.target?.tagName && event?.target?.tagName.toUpperCase() === 'TH') {
      if(event?.target?.parentElement) {
        const parentElement: any = event?.target?.parentElement;
        if ( parentElement.classList.contains('closed') ){
          parentElement.classList.remove('closed');
          estadoClosed = false;
        } else {
          parentElement.classList.add('closed');
          estadoClosed = true;
        }
      }
    } else if(event?.target?.tagName && event?.target?.tagName.toUpperCase() === 'TR') {
      const element: any = event?.target;
      if ( element.classList.contains('closed') ){
        element.classList.remove('closed');
        estadoClosed = false;
      } else {
        element.classList.add('closed');
        estadoClosed = true;
      }

    }

    if(estadoClosed != null){
      const elementosHTML: any = document.querySelectorAll(`[id^="${id}"]`);
      for (const elementoHTML of elementosHTML) {
        if(elementoHTML){
          if(estadoClosed === true) {
            elementoHTML.classList.contains('d-none') ? null : elementoHTML.classList.add('d-none');
          } else {
            elementoHTML.classList.contains('d-none') ? elementoHTML.classList.remove('d-none') : null;
            elementoHTML.classList.contains('closed') ? elementoHTML.classList.remove('closed') : null;
          }
        }
      }
    }
  }

  obtenerDatosColumna(index: number): string {
    if(index >= 0){
      const tipo: string = this.valores[index]?.tipo || '';
      const periodo: string = this.valores[index]?.periodo.toString() || '';
      const mesInicio: number = this.valores[index]?.mesInicio || '';
      const mesFin: number = this.valores[index]?.mesFin || '';
      return `${tipo} ${periodo} ${this.obtenerNombreMes(mesInicio)} - ${this.obtenerNombreMes(mesFin)}`;
    }
    return '';
  }

  obtenerNombreMes(mes: number): string {
    const meses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    // Verificar que el número esté entre 1 y 12
    if (mes && mes >= 1 && mes <= 12) {
        return meses[mes - 1].toUpperCase();
    } else {
        return "";
    }
  }

  obtenerValorTotal(nivelKey: string, objeto: any): any {
    if(nivelKey && objeto && Object.keys(objeto).length > 0){
      const valor: any = (objeto?.balance?.[nivelKey] || objeto?.balance?.[nivelKey] == 0) ? (objeto?.balance?.[nivelKey]) : 
        (objeto?.campos?.[nivelKey] || objeto?.campos?.[nivelKey] == 0) ? (objeto?.campos?.[nivelKey]) : ("");
      return valor;
    }
    return '';
  }

  obtenerValorTotalType(nivelKey: string, objeto: any): string {
    if(nivelKey && objeto && Object.keys(objeto).length > 0){
      return objeto?.definicionBalance?.output?.[nivelKey]?.type ||  "";
    }
    return '';
  }

  validaCampo(campo: any[]): boolean {
    let respuesta: boolean = true;
    if(campo && campo.length > 0) {
      for (const property in this.contratoResultados) {
        if(this.contratoResultados[property]?.nivel1){
          if(this.contratoResultados[property]?.nivel1 === campo?.[0]?.key) return false;
        }
        if(this.contratoResultados[property]?.nivel2){
          if(this.contratoResultados[property]?.nivel2 === campo?.[0]?.key) return false;
        }
        if(this.contratoResultados[property]?.nivel3){
          if(this.contratoResultados[property]?.nivel3 === campo?.[0]?.key) return false;
        }
      }

      // logica para quitar campos vacios
      if(campo.filter((e, index) => index > 0).every((e, index) => !e?.valor || e?.valor === '' || e?.valor === 0 || e?.valor === '0')) {
        return false;
      }
    } 
    return respuesta;
  }

  validaFilaTotal(nivel1: any): boolean {
    let respuesta: boolean = false;
    // Verificar si el valor total de la fila es diferente de vacío
    if (this.valores.some((valor: any) => this.obtenerValorTotal(nivel1, valor) !== null && this.obtenerValorTotal(nivel1, valor) !== undefined && this.obtenerValorTotal(nivel1, valor) !== "")) {
      respuesta = true;
    }

    return respuesta;
  }

  esNumeroOStringNumerico(valor: any): boolean {
    if (typeof valor === "number") {
      return true;
    }

    if (typeof valor === "string" && valor.trim() !== "") {
      return /^[0-9]+$/.test(valor);
    }

    return false;
  }

  obtenerTipoString(tipo: string): string {
    if(tipo === 'balance'){
      return 'Balance';
    } else if(tipo === 'prebalance') {
      return 'Pre Balance';
    } else {
      return '';
    }
  }


}
