<div class="container__form solicitud-credito-box" id="print-section">
    <div class="panel panel-default border-0">
        <div class="panel-body">
            <div class="btn-option not-print">
                <div>
                    <button class="btn btn-primary" (click)="showList()" *ngIf="stepContent || (tipoSolicitudContent && !stepContent)">Volver</button>
                </div>
            </div>
            <div class="container-gtp" [ngClass]="{'not-print': currentStep === 5}">
                <h2 class="text-center mt-3">Flujo de credito</h2>
                <h5 class="text-center"><span class="badge bg-secondary text-center" *ngIf="rut !== ''">{{rut | rutFormat}}</span>
                    <h3 class="text-center mt-3" *ngIf="stepContent">
                        {{obtenerNombreTipoSolicitud()}} 
                        <span *ngIf="showNumSol"> - N° Solicitud {{numeroSolicitud}}</span> 
                    </h3>
                </h5>
            </div>
            <div *ngIf="!tipoSolicitudContent">
                <p class="subTitle text-center">
                    Listado de Solicitudes
                </p>

                <div class="text-right" *ngIf="hasUserAccess">
                    <button type="button" class="btn btn-sm btn-success" (click)="tipoSolicitudContent = true;">
                        <i class="fa fa-plus"></i> Nueva
                    </button>
                </div>
                <ng-container *ngIf="dataTable.length > 0">
                    <div class="card text-center my-4">
                        <div class="card-header">
                            <div class="my-0 row d-flex px-4">
    
                                <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                    <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                                </div>
    
                                <div class="col-xs-3 col-sm-auto p-2">
                                    <div class="input-group mb-3">
                                        <input id="table-complete-search" type="text" class="form-control" name="searchTerm" [(ngModel)]="inputText" (keyup)="filtrarObjeto()">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary btn-input-add" type="button">
                                                <i class="fa fa-search mx-2" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-xs-3 col-sm-auto">
                            <div class="table-responsive">
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col" class="cursor-pointer" (click)="cambioDeCampo('Rut')">
                                        <span>Rut</span>
                                        <div class="float-right">
                                            <i class="fa fa-caret-up d-block" [ngClass]="order == 'asc' && campoOrder=='Rut' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                            <i class="fa fa-caret-down d-block" [ngClass]="(order == 'desc' || order == '') && campoOrder=='Rut' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                        </div>
                                        </th>
                                        <th scope="col" class="cursor-pointer" (click)="cambioDeCampo('Nombre')">
                                            <span>Nombre</span>
                                            <div class="float-right">
                                                <i class="fa fa-caret-up d-block" [ngClass]="order == 'asc' && campoOrder=='Nombre' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                                <i class="fa fa-caret-down d-block" [ngClass]="(order == 'desc' || order == '') && campoOrder=='Nombre' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="cursor-pointer" (click)="cambioDeCampo('IDUsuario')">
                                            <span>Modificado Por</span>
                                            <div class="float-right">
                                                <i class="fa fa-caret-up d-block" [ngClass]="order == 'asc' && campoOrder=='IDUsuario' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                                <i class="fa fa-caret-down d-block" [ngClass]="(order == 'desc' || order == '') && campoOrder=='IDUsuario' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="cursor-pointer" (click)="cambioDeCampo('IDUsuarioCreador')">
                                            <span>Creado Por</span>
                                            <div class="float-right">
                                                <i class="fa fa-caret-up d-block" [ngClass]="order == 'asc' && campoOrder=='IDUsuarioCreador' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                                <i class="fa fa-caret-down d-block" [ngClass]="(order == 'desc' || order == '') && campoOrder=='IDUsuarioCreador' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="cursor-pointer" (click)="cambioDeCampo('EstadoVisible')">
                                            <span>Estado</span>
                                            <div class="float-right">
                                                <i class="fa fa-caret-up d-block" [ngClass]="order == 'asc' && campoOrder=='EstadoVisible' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                                <i class="fa fa-caret-down d-block" [ngClass]="(order == 'desc' || order == '') && campoOrder=='EstadoVisible' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                            </div>
                                        </th>

                                        <th scope="col" class="cursor-pointer" (click)="cambioDeCampo('Decision')">
                                            <span class="font-weight-bold">Decisión</span>
                                            <div class="float-right">
                                                <i class="fa fa-caret-up d-block" [ngClass]="order == 'asc' && campoOrder=='Decision' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                                <i class="fa fa-caret-down d-block" [ngClass]="(order == 'desc' || order == '') && campoOrder=='Decision' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="cursor-pointer" (click)="cambioDeCampo('AutomaticoDisplay')">
                                            <span class="font-weight-bold">Automático</span>
                                            <div class="float-right">
                                                <i class="fa fa-caret-up d-block" [ngClass]="order == 'asc' && campoOrder=='AutomaticoDisplay' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                                <i class="fa fa-caret-down d-block" [ngClass]="(order == 'desc' || order == '') && campoOrder=='AutomaticoDisplay' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                            </div>
                                        </th>

                                        <th scope="col" class="cursor-pointer" (click)="cambioDeCampo('Modelo')">
                                            <span>Modelo</span>
                                            <div class="float-right">
                                                <i class="fa fa-caret-up d-block" [ngClass]="order == 'asc' && campoOrder=='Modelo' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                                <i class="fa fa-caret-down d-block" [ngClass]="(order == 'desc' || order == '') && campoOrder=='Modelo' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="cursor-pointer" (click)="cambioDeCampo('date')">
                                            <span class="font-weight-bold">Fecha</span>
                                            <div class="float-right">
                                                <i class="fa fa-caret-up d-block" [ngClass]="order == 'asc' && campoOrder=='date' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                                <i class="fa fa-caret-down d-block" [ngClass]="(order == 'desc' || order == '') && campoOrder=='date' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="cursor-pointer" (click)="cambioDeCampo('IDTransaccion')">
                                            <span>ID Transaccion</span>
                                            <div class="float-right">
                                                <i class="fa fa-caret-up d-block" [ngClass]="order == 'asc' && campoOrder=='IDTransaccion' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                                <i class="fa fa-caret-down d-block" [ngClass]="(order == 'desc' || order == '') && campoOrder=='IDTransaccion' ? 'font-weight-bold' : 'text-muted'" aria-hidden="true"></i>
                                            </div>
                                        </th>
                                        <ng-container *ngIf="hasAccessToDelete()">
                                            <th>
                                                <div></div>
                                            </th>
                                        </ng-container>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of arrayFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                            <tr>
                                                <th class="cursor-pointer" (click)="buscarFila(item)" scope="row">{{item?.Rut || '' | rutFormat}}</th>
                                                <td class="cursor-pointer" (click)="buscarFila(item)">{{item?.Nombre || ''}}</td>
                                                <td class="cursor-pointer" (click)="buscarFila(item)">{{item?.IDUsuario || ''}}</td>
                                                <td class="cursor-pointer" (click)="buscarFila(item)">{{item?.IDUsuarioCreador || ''}}</td>
                                                <td class="cursor-pointer" (click)="buscarFila(item)"><span class="label" [class]='item?.EstadoTransaccion'>{{item?.EstadoVisible}}</span></td>
                                                <td class="cursor-pointer" (click)="buscarFila(item)">{{item?.Decision || ''}}</td>
                                                <td class="cursor-pointer" (click)="buscarFila(item)">{{item?.AutomaticoDisplay || ''}}</td>
                                                <td class="cursor-pointer" (click)="buscarFila(item)">{{item?.Modelo || ''}}</td>
                                                <td class="cursor-pointer" (click)="buscarFila(item)">{{item?.date || '' | date: 'dd-MM-yyyy'}}</td>
                                                <td class="cursor-pointer" (click)="buscarFila(item)">{{item?.numSolicitud || ''}}</td>
                                                <td *ngIf="hasAccessToDelete()">
                                                    <button (click)="eliminarTransaccion(item)" class="btn-group bg-transparent border-0">
                                                        <i class="text-danger fa fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer text-muted">
                            <div class="p-2">
                                <div id="pagination">
                                    <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                        <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="row mt-5" *ngIf="tipoSolicitudContent && !stepContent">
                <div class="col-sm-6 mx-auto">
                    <div class="row">
                        <div class="col-sm-6 mx-auto">
                            <label for="rut" class="pt-2">Rut</label>
                            <input type="text" class="form-control" id='rut' name='rut' [(ngModel)]="rut" formatRut>
                        </div>
                        <div class="col-sm-6 mx-auto">
                            <label for="staticEmail" class="pt-2">Tipo de solicitud</label>
                            <select class="form-control" [(ngModel)]="tipoSolicitud">
                                <ng-container *ngFor="let item of tipoSolicitudSel">
                                    <option [value]="item.modeloId">{{item.displayName}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12 mx-auto text-center">
                            <button type="button" class="btn btn-sm btn-primary" (click)="setTipoSolicitud()">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'d-none': !stepContent}">

                <div id="stepper1" class="bs-stepper mt-5">
                    <div class="row1">
                        <div class="bs-stepper-header pb-4 not-print">
                            <div class="step" [ngClass]="{'disabled-button': disabledPasos || disabledPasosEtapa6 }" data-target="#step1" disabled>
                                <button class="step-trigger" [disabled]="disabledPasos || disabledPasosEtapa6">
                                    <span class="bs-stepper-circle">1</span>
                                    <span class="bs-stepper-label"> Campos Manuales </span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" [ngClass]="{'disabled-button': disabledPasos || disabledPasosEtapa6 }" data-target="#step2" disabled>
                                <button class="step-trigger" [disabled]="disabledPasos || disabledPasosEtapa6">
                                    <span class="bs-stepper-circle">2</span>
                                    <span class="bs-stepper-label"> Consumo de Servicios </span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" [ngClass]="{'disabled-button': disabledPasos || disabledPasosEtapa6 }" data-target="#step3" disabled>
                                <button class="step-trigger" [disabled]="disabledPasos || disabledPasosEtapa6">
                                    <span class="bs-stepper-circle">3</span>
                                    <span class="bs-stepper-label"> Consumo de Servicios Derivados </span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" [ngClass]="{'disabled-button': disabledPasos || disabledPasosEtapa6 }" data-target="#step4" disabled>
                                <button class="step-trigger" [disabled]="disabledPasos || disabledPasosEtapa6">
                                    <span class="bs-stepper-circle">4</span>
                                    <span class="bs-stepper-label"> Ficha Cliente</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#step5" [ngClass]="{'disabled-button': disabledPasosEtapa6 }" disabled>
                                <button class="step-trigger" [disabled]="disabledPasosEtapa6">
                                    <span class="bs-stepper-circle">5</span>
                                    <span class="bs-stepper-label"> En Evaluación</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#step6" disabled>
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">6</span>
                                    <span class="bs-stepper-label"> En Aprobación</span>
                                </button>
                            </div>
                        </div>
                        <div class="bs-stepper-content col-12">
                            <div id="step1" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 0">
                                    <ng-container *ngIf="stepContent">
                                        <app-campos-manuales-workflow [stepper]="1" [rut]="rut" [idTransaccion]="idTransaccion" [tipoSolicitud]="tipoSolicitud" [operacionNuevo]="operacionNuevo" [cargaMasiva]="cargaMasiva" (idTransaccionNueva)="idTransaccion = $event"></app-campos-manuales-workflow>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="step2" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 1">
                                    <ng-container *ngIf="stepContent">
                                        <app-consumo-servicio-workflow [stepper]="2" [step]="false" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event"></app-consumo-servicio-workflow>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="step3" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 2">
                                    <ng-container *ngIf="stepContent">
                                        <app-consumo-servicios-derivados-workflow [stepper]="3" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event"></app-consumo-servicios-derivados-workflow>
                                    </ng-container>
                                </div>
                            </div>

                            <div id="step4" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 3">
                                    <ng-container *ngIf="stepContent">
                                        <ng-container *ngIf="validaClave('plantillaDemo')">
                                            <app-plantilla-demo [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-demo>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaCesceOld')">
                                            <app-plantilla-cesce-old [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-cesce-old>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('capitalExpressNuevosCampos')">
                                            <app-plantilla-capital-nc [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-capital-nc>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaInterfactor')">
                                            <app-plantilla-interfactor [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-interfactor>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaSodimac')">
                                            <app-plantilla-sodimac [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-sodimac>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaPanelRiesgo')">
                                            <app-plantilla-panel-riesgo [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-panel-riesgo>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaInge')">
                                            <app-plantilla-inge [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-inge>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaCesce')">
                                            <app-plantilla-cesce [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-cesce>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaPanelRiesgoConstrumart')">
                                            <app-plantilla-perfil-caratula [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-perfil-caratula>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaPuntual')">
                                            <app-plantilla-puntual-caratula [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-puntual-caratula>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaFlujoComercial')">
                                            <app-plantilla-flujo-comercial  [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-flujo-comercial>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaPanelRiesgoCredyt')">
                                            <app-panel-riesgo-credyt [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-panel-riesgo-credyt>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaCapexModelo1')">
                                            <app-plantilla-capex-modelo1 [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-capex-modelo1>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaLineaDeCredito') || validaClave('plantillaVentaNueva') || validaClave('plantillaRenegociacion')">
                                            <app-plantilla-salfa-linea [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-salfa-linea>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaCapitalTrabajo')">
                                            <app-plantilla-puente-financiero-ct [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-puente-financiero-ct>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaPreFactoring')">
                                            <app-plantilla-puente-financiero-ct [type]="'pre-factoring'" [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-plantilla-puente-financiero-ct>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="step5" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 4">
                                    <ng-container *ngIf="stepContent">
                                        <ng-container *ngIf="validaClave('plantillaDemo')">
                                            <app-plantilla-demo [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-demo>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaCesceOld')">
                                            <app-plantilla-cesce-old [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-cesce-old>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('capitalExpressNuevosCampos')">
                                            <app-plantilla-capital-nc [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-capital-nc>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaInterfactor')">
                                            <app-plantilla-interfactor [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-interfactor>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaSodimac')">
                                            <app-plantilla-sodimac [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-sodimac>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaPanelRiesgo')">
                                            <app-plantilla-panel-riesgo [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-panel-riesgo>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaInge')">
                                            <app-plantilla-inge [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-inge>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaCesce')">
                                            <app-plantilla-cesce [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-cesce>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaPanelRiesgoConstrumart')">
                                            <app-plantilla-perfil-caratula [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-perfil-caratula>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaPuntual')">
                                            <app-plantilla-puntual-caratula [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-puntual-caratula>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaFlujoComercial')">
                                            <app-plantilla-flujo-comercial [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-flujo-comercial>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaPanelRiesgoCredyt')">
                                            <app-panel-riesgo-credyt [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-panel-riesgo-credyt>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaCapexModelo1')">
                                            <app-plantilla-capex-modelo1 [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-capex-modelo1>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaLineaDeCredito') || validaClave('plantillaVentaNueva') || validaClave('plantillaRenegociacion')">
                                            <app-plantilla-salfa-linea [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-salfa-linea>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('capitalTrabajo')">
                                            <app-plantilla-puente-financiero-ct [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (finalizarFlujo)="showList()" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-puente-financiero-ct>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaCapitalTrabajo')">
                                            <!-- <app-panel-riesgo-credyt [stepper]="4" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)"></app-panel-riesgo-credyt> -->
                                            <app-plantilla-puente-financiero-ct [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-puente-financiero-ct>
                                        </ng-container>
                                        <ng-container *ngIf="validaClave('plantillaPreFactoring')">
                                            <app-plantilla-puente-financiero-ct [type]="'pre-factoring'" [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut" (operacionNuevo)="operacionNuevo = $event" (bloqueaPasos5)="changeDisabledPasosSinValidacionPaso($event)" (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"></app-plantilla-puente-financiero-ct>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="step6" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 5">
                                    <ng-container *ngIf="stepContent">
                                        <app-aprobacion-workflow [stepper]="6" 
                                            [idTransaccion]="idTransaccion" 
                                            [rut]="rut" 
                                            [tipoSolicitud]="tipoSolicitud" 
                                            [tipoSolicitudNombre]="obtenerNombreTipoSolicitud() || tipoSolicitud"
                                            [numeroSolicitud]="numeroSolicitud"
                                            [permisos]="permisos"
                                            (operacionNuevo)="operacionNuevo = $event" 
                                            (bloqueaPasos6)="changeDisabledPasosEtapa6SinValidacionPaso($event)"
                                        ></app-aprobacion-workflow>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>